import React from 'react';
// import { t, Trans } from '@lingui/macro';
import { withI18nProps, withI18n } from '@lingui/react';

import { Select, SelectOption } from '@geobank/components/src/forms/Select';

interface SelectFieldProps {
  name: string;
  value: string | number;
  options: { value: string | number; label: string }[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectField: React.FC<SelectFieldProps & withI18nProps> = props => {
  const { name, value, options, onChange, i18n } = props;

  return (
    <Select name={name} value={value} onChange={onChange}>
      {options.map(option => (
        <SelectOption key={option.value} value={option.value}>
          {/* Warning: Only strings and numbers are supported as <option> children. */}
          {/* <Trans id={option.label} /> --> not work */}
          {i18n._(option.label)}
        </SelectOption>
      ))}
    </Select>
  );
};

export default React.memo(withI18n()(SelectField));
