// filename: MapInstance, Map, MapGeoportal,

// SingletonModuleScopedInstance
// https://medium.com/@dmnsgn/singleton-pattern-in-es6-d2d021d150ae

// {defaults as defaultControls} - https://github.com/openlayers/openlayers/blob/b54b7c59896c6a03ef8db5110108266ad7da55bf/src/ol/control/util.js
import {
  canOrderImageByInstrument,
  canOrderOperativeImageAndGetDiffByDate,
} from 'components/utils/order';
import { Collection, Feature } from 'ol';
import { Attribution, ScaleLine, Zoom } from 'ol/control';
// import TileLayer from 'libs/ol/layer/Tile';
import {
  // availableSearchStyle,
  blockedStyle,
  boldStyle,
  // clickedStyle,
  disabledStyle,
  emptyStyle,
  geofenceStyle,
  getCustomStyle,
  intersectionStyle,
  selectStyle,
  thinStyle,
} from './featureStyles';

import { ImageMetadata } from 'ducks/metadata';
import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { LayerGroup } from 'libs/ol/layer/Group';
import VectorLayer from 'libs/ol/layer/Vector';
// import { Select } from 'ol/interaction';
import MousePosition from 'ol/control/MousePosition';
import { toStringHDMS } from 'ol/coordinate'; // createStringXY
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
// import { Modify, Snap } from 'ol/interaction';
import WKT from 'ol/format/WKT';
import Geometry from 'ol/geom/Geometry';
import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import ImageLayer from 'ol/layer/Image';
import OLMap from 'ol/Map';
import { Vector as VectorSource } from 'ol/source';
import ImageSource from 'ol/source/Image';
import Static from 'ol/source/ImageStatic';
import Style from 'ol/style/Style';
import OLView from 'ol/View';
import { Debugger } from 'utils/logging';
import { getCanvasPatternV1 } from './canvas';
import BrightnessTemperatureControl from './CustomControls/BrightnessTemperatureControl/BrightnessTemperatureControl';
import { getLayersDataFromImageMetadata } from './helpers/layer';
import { createSimpleVectorLayer } from './layers'; // createSimpleXYZLayer
import { CreateLayerBundle } from './types';
import { createLayer } from './utils';
// import { formatArea } from '../DrawControl/DrawControl';

// import { Style, Fill, Stroke, Icon } from 'ol/style'; // Circle
// import WKT from 'ol/format/WKT';
// import { Vector as VectorSource, OSM } from 'ol/source';
// import { fromLonLat, toLonLat } from 'ol/proj';

// previews
// import Collection from 'ol/Collection';
// import Static from 'ol/source/ImageStatic';
// import ImageLayer from 'ol/layer/Image';

const debug = Debugger('Map'); // TAG

// LayerGroups, LAYER_GROUP
export enum LAYER_GROUPS {
  baseLayers = 'BASE_LAYERS',
  imageLayers = 'IMAGE_LAYERS',
  userLayers = 'USER_LAYERS',
  topLayers = 'TOP_LAYERS',
}

export type GeometryFormat = 'WKT' | 'GeoJSON';

// main layer groups (default)
const defaultLayers = [
  new LayerGroup({
    name: LAYER_GROUPS.baseLayers,
    zIndex: 10,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.imageLayers,
    zIndex: 20,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.userLayers,
    zIndex: 30,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.topLayers,
    zIndex: 40,
  }),
];

// let instance: GeoportalMap;

// MapInstance, GPMap,
class GeoportalMap {
  private static _instance?: GeoportalMap;

  // public currentFeatureNum: number;
  // public currentFeatureIds: number[];
  private layIds: Map<any, any> = new Map();
  // private layIdsAist: Map<any, any> = new Map();

  // private selectedLay?: []

  public _currentBaseLayerId?: number;
  public _currentNameLayerId?: string = '8660222';
  private _olMap: OLMap;

  private _currentSelectedMetadataFeature?: Feature;
  private _currentSelectedMetadataFeatureStyle?: Style;

  constructor(...params: any) {
    if (GeoportalMap._instance) {
      // return GeoportalMap._instance;
      throw new Error(
        'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
      );
    }

    // this.currentFeatureNum = 0;
    // this.currentFeatureIds = [];

    // map params
    const layers = params.layers || defaultLayers;
    // const controls = params.controls || [];
    // controls
    const scaleLineControl = new ScaleLine(); // https://openlayers.org/en/latest/examples/scale-line.html?q=vector
    // const zoomSlider = new ZoomSlider(); // https://openlayers.org/en/latest/examples/zoomslider.html?q=coordinates
    const zoomControl = new Zoom();
    // https://openlayers.org/en/latest/apidoc/module-ol_control_Attribution-Attribution.html
    const attributionControl = new Attribution({
      collapsed: false,
      collapsible: true,
      // label: 'i »',
      collapseLabel: '«',
    });

    const mousePositionControl = new MousePosition({
      // coordinateFormat: toStringHDMS, // createStringXY(4),
      // coordinateFormat: coord => toStringHDMS(coord || []),
      // coordinateFormat: (coord): string => (coord ? toStringHDMS(coord): ''),
      coordinateFormat: coord => toStringHDMS(coord!),
      projection: 'EPSG:4326',
      // comment the following two lines to have the mouse position
      // be placed within the map.
      // className: 'custom-mouse-position',
      // target: document.getElementById('mouse-position'), // если разместить в компоненте реакт - не работает, т.к., видимо, вызывается конструктор раньше монтирования
      // undefinedHTML: '&nbsp;', // очищает поле при уходе с карты
      // ! TODO Fix undefinedHTML for MousePosition
      // undefinedHTML: '', // сохраняет последнее значение
    });
    const controls = [scaleLineControl, zoomControl, attributionControl, mousePositionControl]; // defaultControls().extend([scaleLineControl]);

    // TODO - measure
    // https://openlayers.org/en/latest/examples/measure.html?q=coordinates

    // openlayers map
    this._olMap = new OLMap({
      // wrapX: false,
      layers,
      // layers: [
      //   new TileLayer({
      //     id: 11,
      //     name: 'osm',
      //     source: new OSM(),
      //   }),
      // ],
      controls,
      view: new OLView({
        projection: 'EPSG:3857',
        // center: [0, 0],
        center: [8000000, 9000000],
        zoom: 4,
        minZoom: 2, // 0
        maxZoom: 20, // 28
      }),
    });
    // instance = this;
    // return instance;
    GeoportalMap._instance = this;
  }

  public static getInstance() {
    if (GeoportalMap._instance) {
      return GeoportalMap._instance;
    } else {
      throw new Error('Singleton instance not created');
    }
  }

  public getOlMap() {
    return this._olMap;
  }

  public setView(view: OLView) {
    this._olMap.setView(view);
  }

  private _setCurrentNameLayer(id: string) {
    this._currentNameLayerId = id;
  }

  private _findLayerGroup(name: LAYER_GROUPS): LayerGroup {
    const group = this.getOlMap()
      .getLayers()
      .getArray()
      .find(layer => layer.get('name') === name && layer instanceof LayerGroup);
    return group as LayerGroup;
  }

  // _findLayerBy
  private _findChildLayerBy(parent: LayerGroup, filterFunc: (item: any) => boolean) {
    // const layer = this._findLayerGroup(groupName)
    const layer = parent.getLayers().getArray().find(filterFunc);
    return layer;
  }

  // _parseLayer, _prepareLayer
  private _parseLayer(layer: any): BaseLayer {
    if (layer instanceof Layer) {
      return layer;
    } else {
      if (!layer.type) {
        throw new Error('Layer type is empty');
      }
      return createLayer(layer.type, layer);
    }
  }

  /* public addLayer(layer: BaseLayer) {
    this._olMap.addLayer(layer);
  } */

  public setBaseLayers(layers: any[]) {
    const group = this._findLayerGroup(LAYER_GROUPS.baseLayers); // было imageLayers ?!
    // group.addLayer(this._parseLayer(layer));
    const olLayers: BaseLayer[] = [];
    layers.forEach(item => {
      if (item.config.options.visible || item.config.options.visibility) {
        this._currentBaseLayerId = item.id;
      }
      olLayers.push(this._parseLayer(item));
    });
    // this.getLayerGroup().getLayers()
    group.getLayers().extend(olLayers);
  }

  // selectBaseLayer(oldValue, newValue) {
  public selectBaseLayer(value: number) {
    const oldValue = this._currentBaseLayerId;
    const newValue = value;

    const group = this._findLayerGroup(LAYER_GROUPS.baseLayers);
    const layers = group.getLayers().getArray();
    const currLayer = layers.find(elem => elem.get('id') === oldValue);
    const newLayer = layers.find(elem => elem.get('id') === newValue);

    newLayer!.set('visible', true);
    // currLayer &&
    currLayer!.set('visible', false);

    this._currentBaseLayerId = value;
  }

  public readGeometry(
    geometry: any,
    geometryType: GeometryFormat,
    projected: boolean = true
  ): Feature<Geometry> {
    const format = geometryType === 'WKT' ? new WKT() : new GeoJSON();
    const feature = format.readFeature(
      geometry,
      projected ? { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' } : {}
    );
    return feature;
  }

  public writeGeometry(geometry: any, geometryType: GeometryFormat, projected: boolean = true) {
    const format = geometryType === 'WKT' ? new WKT() : new GeoJSON();
    const feature = format.writeFeature(
      geometry,
      projected ? { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' } : {}
    );
    return feature;
  }

  // TODO - завернуть в утилиту?, назвать parseVectorFeature()
  public _parseFeature(value: ImageMetadata) {
    if (!value.geometry) {
      return null;
    }
    const canOrderOperativeImage = canOrderOperativeImageAndGetDiffByDate(
      value.acquisitionDateBegin || value.acquisitionDateInstant
    )[0];
    const canOrderImage = canOrderImageByInstrument(
      value.instrumentIdentifier,
      value.platformIdentifier
    );
    const format = new WKT();
    const feature = format.readFeature(value.geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    canOrderImage && canOrderOperativeImage
      ? feature.setStyle(thinStyle)
      : feature.setStyle(blockedStyle);
    feature.setProperties({ identifier: value.identifier });
    feature.setId(value.identifier);
    feature.set('_type', 'metadata'); // temp!!!
    return feature;
  }

  public addGeofenceLayer(geofenceGeojson: any, isZoomNeed = false) {
    const feature = this.readGeometry(geofenceGeojson, 'GeoJSON');
    feature.setStyle(geofenceStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.ensureCurrentRegionLayer('geofence');
    layer.setSource(vectorSource);

    const geofence = new GeoJSON();
    const geoJSON = geofence.writeFeatureObject(feature);

    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
    return geoJSON;
  }

  public changeGeofencelayerStyle(style: Style) {
    const layer = this.ensureCurrentRegionLayer('geofence');
    layer.setStyle(style);
  }

  public addIntersectionLayer(intersectionGeojson: any, isZoomNeed = false) {
    const feature = new GeoJSON().readFeature(intersectionGeojson);
    feature.setStyle(intersectionStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.ensureCurrentRegionLayer('intersection');
    layer.setSource(vectorSource);

    const geofence = new GeoJSON();
    const geoJSON = geofence.writeFeatureObject(feature);

    // if (feature && isZoomNeed) {
    //   this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    // }
    return geoJSON;
  }

  public addRegionOfInterestLayer(object: any, isZoomNeed = false) {
    const feature = new GeoJSON().readFeature(object.geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    feature.setId(object.id);
    feature.set('_type', object.type);
    feature.setStyle(object.geometry.coordinates.length === 1 ? selectStyle : disabledStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.ensureCurrentRegionLayer();
    layer.setSource(vectorSource);

    const roi = new GeoJSON();
    const geoJSON = roi.writeFeatureObject(feature);

    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
    return geoJSON;
  }

  public getUserLayerByName(layerName: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === layerName
    ) as VectorLayer;
    return layer;
  }

  public removeLayerByName(layerName: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === layerName
    ) as VectorLayer;
    this._olMap.removeLayer(layer);
  }

  public getUserLayerById(layerName: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('id') === layerName
    ) as VectorLayer;
    return layer;
  }

  // select object on map
  public selectObject(object: any, isZoomNeed = false) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);

    // public ensureCurrentRegionLayer() {}
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'objectsLayer'
    ) as VectorLayer;
    if (!layer) {
      const bundle: CreateLayerBundle = {
        id: 778,
        name: 'objectsLayer',
        options: { url: '', zIndex: 30 },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    // return layer;

    let feature;
    // if (object.geometry.constructor.name === 'Object') {
    if (object.type === 'osm') {
      const format = new GeoJSON();
      feature = format.readFeature(object.geometry);
    } else {
      // feature = new Feature(object.geometry)
      feature = new Feature({
        geometry: object.geometry.clone(), // копируем геометрию, чтобы потом два раза не трансформировалась
        // geometry: new Polygon(polyCoords),
        // labelPoint: new Point(labelCoords),
        // name: 'My Polygon',
        _type: object.type, // gptl-images
        data: object.data,
      });
    }
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    feature.setId(object.id);
    // feature.set('_type', 'region'); // пока отключено: добавление в закладки и выделение

    debug.log('selectObject', object, feature);
    layer.getSource()!.clear();
    layer.getSource()!.addFeature(feature);

    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry().getExtent(), { maxZoom: 10 });
    }
  }

  public clearObjects() {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);

    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'objectsLayer'
    ) as VectorLayer;

    if (layer) {
      layer.getSource()!.clear();
      // console.log('clear');
    }
  }

  public toggleImageContour(
    imageMdIds: string[],
    isImagesFullResActive: boolean[],
    flag: boolean | undefined = true
  ) {
    for (let i = 0; i < imageMdIds.length; i++) {
      const metadataId = imageMdIds[i];
      const isViewFullImageActive = isImagesFullResActive[i];
      const isImageContourActive = !flag;

      this.toggleMetadataContour(metadataId, isViewFullImageActive, isImageContourActive);
    }
  }
  public toggleMetadataContour(
    metadataId: ImageMetadata['identifier'], // metadataId: number,
    isViewFullImageActive?: boolean,
    isImageContourActive?: boolean
  ) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;

    const feature = layer.getSource()!.getFeatureById(metadataId);

    const featureStyle = feature!.getStyle() as Style;
    let newStyle;
    if (
      (!featureStyle || featureStyle.getStroke()) &&
      (feature!.get('__hidden') === false || feature!.get('__hidden') === undefined)
    ) {
      // feature.set('__hidden', true);
      // feature.setStyle(emptyStyle); // hide
      newStyle = emptyStyle;
    } else {
      // feature.set('__hidden', false);
      // feature.setStyle(null); // show
      // feature.setStyle(
      //   this._currentSelectedMetadataFeature!.getId() === feature.getId() ? boldStyle : thinStyle
      // );
      if (isViewFullImageActive === true) {
        const fillColor = getCanvasPatternV1();
        newStyle = getCustomStyle({ fill: { color: fillColor } });
      } else if (this._currentSelectedMetadataFeature?.getId() === feature!.getId()) {
        newStyle = boldStyle;
      } else {
        newStyle = thinStyle;
      }
    }
    feature!.setStyle(newStyle);
    // console.log('style feature', feature.getStyle());
    // feature.setStyle(new Style());
    // console.log('style feature', feature.getStyle());

    feature!.set('__hidden', isImageContourActive); // !isImageContourActive
  }

  // previewsTemp,
  public selectMetadataFeature(metadataId: ImageMetadata['identifier'], isZoomNeed = true) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    if (this._currentSelectedMetadataFeature && this._currentSelectedMetadataFeatureStyle) {
      this._currentSelectedMetadataFeature.get('__hidden') === true
        ? this._currentSelectedMetadataFeature.setStyle(emptyStyle)
        : this._currentSelectedMetadataFeature.setStyle(this._currentSelectedMetadataFeatureStyle);
    }
    // feature может быть null, если не добавлена на карту из-за `poleAreaIntersection`
    const feature = layer.getSource()!.getFeatureById(metadataId);
    this._currentSelectedMetadataFeature = feature!;
    this._currentSelectedMetadataFeatureStyle = feature!.getStyle() as Style;
    feature && feature.setStyle(boldStyle);
    // this.mapInstance.getView().fit(feature.getGeometry().getExtent())
    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
    debug.log('selected feature', feature);
  }

  public clearMetadataFeatures(ids: Array<ImageMetadata['identifier']>) {
    for (const id of ids) {
      // console.log(ids)
      this.removeMetadataFeature(id);
      for (const val of this.layIds.values()) {
        if (val.split(':')[1] === id && this.layIds.size !== 0) {
          // console.log('has', id);
          const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
          const deleteLay: any = group
            .getLayers()
            .getArray()
            .filter(el => el.getProperties().name === val);
          // console.log(deleteLay);
          if (deleteLay.length !== 0) this.layIds.delete(deleteLay[0].getProperties().id);
          group.getLayers().remove(deleteLay[0]);
          // console.log('clear', this.layIds, ids);
        }
      }
    }
  }

  public clearRegionsOfInterest() {
    const layer = this.ensureCurrentRegionLayer();
    layer.getSource()!.clear();
  }

  public removeMetadataFeature(metadataId: ImageMetadata['identifier']) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;

    const feature = layer.getSource()!.getFeatureById(metadataId);
    layer.getSource()!.removeFeature(feature!);

    // remove image previews
    const previewLayer = group
      .getLayers()
      .getArray()
      .find(_layer => _layer.get('name') === `previews:${metadataId}`);
    previewLayer && group.getLayers().remove(previewLayer);
    // remove image full
    const imageLayer = group
      .getLayers()
      .getArray()
      .find(_layer => _layer.get('name') === `images:${metadataId}`);
    imageLayer && group.getLayers().remove(imageLayer);
  }

  // TODO - rename toggleMetadataPreview() {
  // createImageFeature(previews, feature) {
  public toggleImagePreview(imagesMetadata: ImageMetadata[], flag: boolean | undefined = true) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    //
    for (const md of imagesMetadata) {
      const layer = this._findChildLayerBy(
        group,
        l => l.get('name') === `previews:${md.identifier}`
      );
      if (flag === false && layer) {
        group.getLayers().remove(layer);
        // return;
        // || flag === undefined
      } else if (flag === true && !layer) {
        this.createImageFeature(md);
      }
    }
  }

  public clearImageFeatures(group: LayerGroup, mds: ImageMetadata[]) {
    for (const md of mds) {
      const layer = this._findChildLayerBy(
        group,
        item => item.get('name') === `previews:${md.identifier}`
      );
      layer && group.getLayers().remove(layer);
      // console.log('clear image feature');
    }
    return;
  }

  public createImageFeature(md: ImageMetadata) {
    const previews = md.previews;
    // for bbp previews
    // tslint:disable-next-line: strict-type-predicates
    if (previews.length === 1 && previews[0].geometry === undefined) {
      previews[0].geometry = md.geometry;
    }

    // remove layer (toggle)
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    this.clearImageFeatures(group, [md]);
    // const layer = this._findChildLayerBy(group, item2 => item2.get('name') === `previews:${md.id}`);
    // if (layer) {
    //   group.getLayers().remove(layer);
    //   return;
    // }
    // end

    const layersCollection = new Collection<ImageLayer<ImageSource>>();
    const featureGroup = new LayerGroup({
      // TODO -
      // extent: feature.getGeometry().getExtent(),
      // layerGroupId: previews[0].id,
      name: `previews:${md.identifier}`,
    });

    for (const preview of previews) {
      const format = new WKT();
      const vectorFeature = format.readFeature(preview.geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      const pro = this.getOlMap().getView().getProjection();
      const il = new ImageLayer({
        source: new Static({
          attributions: '© Роскосмос',
          url: preview.url,
          projection: pro,
          imageExtent: vectorFeature.getGeometry()!.getExtent(),
        }),
        visible: true,
        zIndex: 31,
      });

      layersCollection.push(il);
      // this.mapInstance.addLayer(layer)
    }
    // debugger;
    featureGroup.setLayers(layersCollection);
    // this.getOlMap().addLayer(featureGroup);
    group.getLayers().push(featureGroup);
    // this.mapInstance.addLayer(featureGroup);
    // this.mapInstance.getView().fit(feature.getGeometry().getExtent())
  }

  /* public toggleViewFullImage(coverageElement: any) {
    // console.log(coverageElement);
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item2 => item2.get('name') === `images:${coverageElement.id}`,
    );
    if (layer) {
      group.getLayers().remove(layer);
      return;
    }

    const bundle: CreateLayerBundle = {
      id: coverageElement.id * -1, // TODO - пересечение с metadata???
      name: `images:${coverageElement.id}`,
      options: {
        url: `https://vtms.gptl.ru/tiles/{z}/{x}/{y}?url=${coverageElement.resourceUrls.http}`,
        zIndex: 35,
        visible: true,
      },
    };
    layer = createSimpleXYZLayer(bundle);
    this.addUserLayer(layer);
  } */
  // toggleImageFullRes(
  //   imagesMetadata: ImageMetadataDetail[],
  //   flag: boolean | undefined = true
  // ) {
  public toggleImageFullRes(imagesMetadata: ImageMetadataDetail[], flag: boolean, layId: number) {
    const layersData = getLayersDataFromImageMetadata(imagesMetadata);
    if (imagesMetadata[0].platformIdentifier === 'AI2D') {
      const curAistMulti = layersData.filter(lay => {
        const difVal = lay.config.options.url.split('/');
        const tiff = difVal[difVal.length - 1].split('.')[0].split('_');
        const processingLevel = tiff[tiff.length - 1];
        if (processingLevel === 'S') {
          return lay;
        } else {
          return null;
        }
      });
      // console.log(layersData, curAistMulti, 652)
      curAistMulti.forEach(layerData =>
        this.toggleViewFullImageAist(layerData, layerData.__mdIdentifier, this.layIds)
      );
    } else {
      layersData.forEach(layerData =>
        this.toggleViewFullImage(layerData, layerData.__mdIdentifier, this.layIds)
      );
    }
    // if( imagesMetadata.length > 1){
    // console.log(layersData, layersData[0].id);

    // }

    // let selectedLay: any[] | null = layersData.filter(lay => lay.id === -layId);
    // selectedLay = null;
  }

  public toggleImageFullRes2B(imagesMetadata: ImageMetadataDetail[], flag: boolean, layId: number) {
    const layersData = getLayersDataFromImageMetadata(imagesMetadata);
    if (imagesMetadata[0].platformIdentifier === 'AI2D') {
      const curAistPan = layersData.filter(lay => {
        const difVal = lay.config.options.url.split('/');
        const tiff = difVal[difVal.length - 1].split('.')[0].split('_');
        const processingLevel = tiff[tiff.length - 1];
        if (processingLevel === '10') {
          return lay;
        } else {
          return null;
        }
      });
      // console.log(layersData, curAistPan, imagesMetadata, 675)
      curAistPan.forEach(layerData =>
        this.toggleViewFullImageAist(layerData, layerData.__mdIdentifier, this.layIds)
      );
    } else {
      layersData.forEach(layerData =>
        this.toggleViewFullImage(layerData, layerData.__mdIdentifier, this.layIds)
      );
    }
  }

  public toggleViewFullImage(layerData: any, metadataIdentifier: string, layIds: Map<any, any>) {
    // находим группу и слой с тайловым слоем
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
    // находим группу и слой с маршрутами (метаданные)
    const mdLayer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    const mdFeature = mdLayer
      .getSource()!
      .getFeatures()
      .find(item => item.get('identifier') === metadataIdentifier);
    // если слой есть, то удалёем его и сбрасываем стили
    // console.log(layer, 665)
    if (layIds.has(layerData.id) && layer) {
      // console.log('close');
      const deleteLay: any = group
        .getLayers()
        .getArray()
        .filter(el => el.getProperties().id === layerData.id);
      group.getLayers().remove(deleteLay[0]);
      // console.log(deleteLay, 11111);
      layIds.delete(layerData.id);
      // console.log(this.layIds, layIds.size, mdFeature, 683);

      if (layIds.size === 0) {
        // console.log(thinStyle, boldStyle, 680);
        mdFeature?.setStyle(
          this._currentSelectedMetadataFeature?.getId() === mdFeature.getId()
            ? boldStyle
            : thinStyle
        );
      }

      return;
    }

    this.layIds.set(layerData.id, layerData.name);
    // console.log(this.layIds)
    // const f = mdLayer.getSource().getFeatureById(metadataId);
    // fill.setColor(pattern!);
    // f?.setStyle(style);
    // если слоя нет - добавляем векторной фиче (слой маршрутов) заливку в точечку =)
    const fillColor = getCanvasPatternV1();
    mdFeature?.setStyle(getCustomStyle({ fill: { color: fillColor } }));
    // console.log(this.layIds, mdFeature, fillColor, this.ensureCurrentRegionLayer(), 705);
    // debugger;

    // добавляем слой с тайлами на карту
    layer = this._parseLayer(layerData); // zIndex: 32

    this.addUserLayer(layer);
    // group
    //   .getLayers()
    //   .getArray()
    //   .forEach(el => console.log(el.getProperties().id));
  }

  public toggleViewFullImageAist(
    layerData: any,
    metadataIdentifier: string,
    layIds: Map<any, any>
  ) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
    // находим группу и слой с маршрутами (метаданные)
    const mdLayer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    const mdFeature = mdLayer
      .getSource()!
      .getFeatures()
      .find(item => item.get('identifier') === metadataIdentifier);
    // если слой есть, то удалёем его и сбрасываем стили
    // console.log(layerData, 665)
    if (layIds.has(layerData.name) && layer) {
      // console.log('close');
      const deleteLay: any = group
        .getLayers()
        .getArray()
        .filter(el => el.getProperties().name === layerData.name);
      group.getLayers().remove(deleteLay[0]);
      // console.log(deleteLay, 11111);
      layIds.delete(layerData.name);
      // console.log(this.layIds, layIds.size, mdFeature, deleteLay, 683);

      if (layIds.size === 0) {
        // console.log(thinStyle, boldStyle, 680);
        mdFeature?.setStyle(
          this._currentSelectedMetadataFeature?.getId() === mdFeature.getId()
            ? boldStyle
            : thinStyle
        );
      }

      return;
    }

    this.layIds.set(layerData.name, layerData.name);
    // console.log(this.layIds)
    // const f = mdLayer.getSource().getFeatureById(metadataId);
    // fill.setColor(pattern!);
    // f?.setStyle(style);
    // если слоя нет - добавляем векторной фиче (слой маршрутов) заливку в точечку =)
    const fillColor = getCanvasPatternV1();
    mdFeature?.setStyle(getCustomStyle({ fill: { color: fillColor } }));
    // console.log(this.layIds, mdFeature, fillColor, this.ensureCurrentRegionLayer(), 705);
    // debugger;

    // добавляем слой с тайлами на карту
    layer = this._parseLayer(layerData); // zIndex: 32

    this.addUserLayer(layer);
    // group
    //   .getLayers()
    //   .getArray()
    //   .forEach(el => console.log(el, 794));
  }

  public toggleNameLayer(layerData: any) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item2 => item2.get('id') === this._currentNameLayerId
    );
    if (layer) {
      group.getLayers().remove(layer);
    }
    if (layerData !== undefined) {
      layer = this._parseLayer(layerData);
      this._setCurrentNameLayer(layerData.id);
      debug.log('toggleNameLayer', layer);

      this.addUserLayer(layer);
    }
  }

  public clearImageLayer(layerId: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(group, item => item.get('id') === layerId);
    group.getLayers().remove(layer!);
  }

  public clearMapControl() {
    const btControl = this._olMap
      .getControls()
      .getArray()
      .find(ctrl => ctrl instanceof BrightnessTemperatureControl);
    this._olMap.removeControl(btControl!);
  }

  public toggleImageLayer(layerData: any, param: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, () => true);
    const brightnessTemperatureControl = new BrightnessTemperatureControl({});
    // if (layer) {
    //   if (layer.get('id') === 'btMSUGS') {
    //     const btControl = this._olMap
    //     .getControls()
    //     .getArray()
    //       .find(ctrl => ctrl instanceof BrightnessTemperatureControl);
    //     this._olMap.removeControl(btControl!);
    //   }
    // }
    // console.log(944, layerData, param, layer)

    if (layerData.id === 'btMSUGS') {
      this._olMap.addControl(brightnessTemperatureControl);
    }
    if (layerData.id !== 'individualGeoton') {
      const previousUrl = new URL(layerData.config.options.url);
      previousUrl.searchParams.set('datetime', param);
      layerData.config.options.url = decodeURI(previousUrl.href);
    }
    layer = this._parseLayer(layerData);
    debug.log('toggleImageLayer', layer);

    this.addUserLayer(layer);
  }

  public updateImageLayer(layerData: any, url: string) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    // const layer = this._findChildLayerBy(group, item => item.get('id') === layerData.id);
    // console.log(964, layerData, url)
    const layer = this._findChildLayerBy(group, () => true);
    const brightnessTemperatureControl = new BrightnessTemperatureControl({});
    if (layer) {
      const previousUrl = new URL(layerData.config.options.url);
      // @ts-ignore
      const source = layer.getSource();
      previousUrl.searchParams.set('datetime', url);
      source.setUrl(decodeURI(previousUrl.href));
      layer.set('id', layerData.id);
      if (layerData.id === 'btMSUGS') {
        this._olMap.addControl(brightnessTemperatureControl);
      } else {
        const btControl = this._olMap
          .getControls()
          .getArray()
          .find(ctrl => ctrl instanceof BrightnessTemperatureControl);
        this._olMap.removeControl(btControl!);
      }
    } else {
      this.toggleImageLayer(layerData, url);
    }
    debug.log('updateImageLayer', layer);
  }

  public toggleProductPreviewLayer(identifier: string, url: string, bbox: Extent) {
    const layerData = {
      name: `productPreview:${identifier}`,
      config: {
        options: {
          url: url,
          visibility: true,
          attribution: '© Роскосмос',
          //
          extent: bbox,
        },
      },
      type: 'XYZ',
    };

    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
    if (layer) {
      group.getLayers().remove(layer);
      return;
    }

    layer = this._parseLayer(layerData);
    debug.log('toggleProductPreviewLayer', layer);
    this.addUserLayer(layer);

    this.getOlMap().getView().fit(bbox, { maxZoom: 12 });
  }

  // metadata, vector layer
  public addMetadataResults(results: ImageMetadata[], append = false) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    if (!layer) {
      // layer = new VectorLayer({
      //   source: new VectorSource({
      //     features: [],
      //   }),
      //   name: 'metadataLayer', // 'featureLayer',
      //   // visible: true,
      //   zIndex: 31,
      // });
      const bundle: CreateLayerBundle = {
        id: 666,
        name: 'metadataLayer',
        options: { url: '', zIndex: 31 },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    if (!append) {
      // clear vector features
      layer.getSource()!.clear();
      // remove image previews
      const previewLayers = group
        .getLayers()
        .getArray()
        .filter(_layer => _layer.get('name').startsWith('previews'));
      for (const previewLayer of previewLayers) group.getLayers().remove(previewLayer);
      // remove image full
      const imageLayers = group
        .getLayers()
        .getArray()
        .filter(_layer => _layer.get('name').startsWith('images'));
      for (const imageLayer of imageLayers) group.getLayers().remove(imageLayer);
    }

    const features: any = [];
    results.forEach(item => {
      // проверка на "пересечение" с полюсами
      if (item.poleAreaIntersection === null || item.poleAreaIntersection === undefined) {
        const _feature = this._parseFeature(item);
        _feature && features.push(_feature);
      }
    });
    layer.getSource()!.addFeatures(features);
  }

  public ensureCurrentRegionLayer(layerName: string = 'regionLayer', zIndex: number = 90) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === layerName
    ) as VectorLayer;
    if (!layer) {
      const bundle: CreateLayerBundle = {
        id: 777,
        name: layerName,
        options: { url: '', zIndex: zIndex },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    return layer;
  }

  public setRequestRegionGeometry(value: object | null, append = false) {
    const layer = this.ensureCurrentRegionLayer();
    if (!append) {
      layer.getSource()!.clear();
    }
    if (value === null) {
      return;
    }

    // const format = new WKT();
    // const format = new GeoJSON();
    const feature = this.readGeometry(value, 'GeoJSON');
    // const feature = format.readFeature(value, {
    // dataProjection: 'EPSG:4326',
    // featureProjection: 'EPSG:3857',
    // });
    feature.setId(1001); // increment shape/draw Geometry
    feature.set('_type', 'region');
    feature.setStyle(selectStyle);

    const features: Feature[] = [feature];
    layer.getSource()!.addFeatures(features);
    // if (isZoomNeed) {
    const extent = feature.getGeometry()!.getExtent();
    // console.log(extent);
    // TODO: - экспериментальная формула смещения вправо из-за левого меню
    // вариант 2: fit -> get zoom level; px -> метры (получить отступ меню); сместить на х местров
    // extent[0] =
    //   extent[0] -
    //   (Math.abs(extent[0]) / 100) *
    //     (Math.abs(Math.abs(extent[0]) - Math.abs(extent[2])) > 1000000 ? 25 : 5); // 10-30%
    // debugger;
    this.getOlMap().getView().fit(extent, { maxZoom: 10 });
    // }
  }

  // addBaseLayer(layer) {}

  private _addLayerToGroup(groupName: LAYER_GROUPS, value: any) {
    const group = this._findLayerGroup(groupName);
    // group.addLayer(this._parseLayer(layer));

    group.getLayers().push(this._parseLayer(value));
  }

  public addImageLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.imageLayers, layer);
  }

  public addUserLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.userLayers, layer);
  }

  public addTopLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.topLayers, layer);
  }
}

export default GeoportalMap;
