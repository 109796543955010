import React from 'react';
import classNames from 'classnames';

import RadioGroupContext from './context';
import { RadioChangeEvent } from './interface';

import styles from './Radio.module.scss';

export interface RadioProps {
  // wrap/containerProps:
  className?: string; // containerClassName
  // innerProps:
  name?: string;
  value: string;
  // label: string | JSX.Element; --> children
  // defaultChecked?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  // radioButtonColor?: string;
}

// TODO - styling, classes ...
// RadioButton
const Radio: React.FC<RadioProps & React.InputHTMLAttributes<HTMLInputElement>> = props => {
  const { children, className, multiline, ...restProps } = props;
  const context = React.useContext(RadioGroupContext);

  const onChange = (e: RadioChangeEvent) => {
    if (props.onChange) {
      props.onChange(e);
    }

    if (context?.onChange) {
      context.onChange(e);
    }
  };

  const radioProps: React.InputHTMLAttributes<HTMLInputElement> = { ...restProps };
  if (context) {
    radioProps.name = context.name;
    radioProps.onChange = onChange;
    radioProps.checked = props.value === context.value;
    radioProps.disabled = props.disabled || context.disabled; // isBoolean(props.disabled) ? props.disabled : context.disabled
  }

  return (
    <label
      className={classNames(
        styles.root,
        { [styles.disabled]: radioProps.disabled },
        { [styles.multilineLabel]: multiline },
        className
        // {
        //   disabled: radioProps.disabled,
        // }
      )}
    >
      <span className={styles.checkMark}>
        <input className={styles.inputRadio} type="radio" /* readOnly */ {...radioProps} />
        {radioProps.checked && <span className={styles.checkMarkChecked} />}
      </span>
      {children !== undefined ? <span className={styles.labelBlock}>{children}</span> : null}
    </label>
  );

  // return (
  //   <label className={classNames(className, { disabled: radioProps.disabled })}>
  //     {/* <div> */}
  //     <input
  //       // id={`${groupName}-${button.value}`}
  //       type="radio"
  //       // name={name}
  //       // value={value}
  //       // onChange={handleChange}
  //       // checked={currentValue === button.value}
  //       // readOnly
  //       // disabled={disabled}
  //       {...radioProps}
  //     />
  //     {/* <label /* className={styles.inputLabel} * / htmlFor={`${groupName}-${button.value}`}> */}
  //     {/* {label} */}
  //     {children !== undefined ? <span>{children}</span> : null}
  //     {/* </div> */}
  //   </label>
  // );
};

export default React.memo(Radio);
