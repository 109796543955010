import { select, call, put, all, takeLatest, take } from 'redux-saga/effects'; // debounce, fork, delay, takeEvery
import { getType, ActionType } from 'deox';
import { i18nMark } from '@lingui/react';

import { requestFlowNew } from './network';
import { notifyActions } from 'ducks/message';
import { fetchSimpleRequests, createSimpleRequest } from 'ducks/simpleRequest';
import API from 'api';
import { parseApiObject, serializeDataV2 } from 'components/utils/apiDataConverter'; // serializeParams
import { iamActions, getIsAuthenticated } from 'ducks/authIAM';

import { Debugger } from 'utils/logging';
const debug = Debugger('SimpleRequestSaga'); // TAG

export function* fetchSimpleRequestsWatch() {
  yield takeLatest(getType(fetchSimpleRequests.request), fetchSimpleRequestsFlow);
}
export function* fetchSimpleRequestsFlow(action: ActionType<typeof fetchSimpleRequests.request>) {
  try {
    // const params = action.payload || {};
    // TODO - вынести в отдельную saga декоратор
    const isAuthenticated = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }
    const respData = yield call(requestFlowNew, API.getSimpleRequests, {});
    debug.log('Get simple requests response data', respData);
    const parsedData = parseApiObject(respData);
    yield put(fetchSimpleRequests.success(parsedData));
  } catch (error) {
    debug.error(error);
    yield put(fetchSimpleRequests.failure(error));
  }
}

export function* createSimpleRequestWatch() {
  yield takeLatest(getType(createSimpleRequest.request), createSimpleRequestFlow);
}
export function* createSimpleRequestFlow(action: ActionType<typeof createSimpleRequest.request>) {
  try {
    const params = action.payload || {};
    const paramsSerialized = serializeDataV2(params);
    debug.log('Request data', JSON.stringify(paramsSerialized, null, 2)); // temp output

    const respData = yield call(requestFlowNew, API.createSimpleRequest, params);
    debug.log('Create simple requests response data', respData);
    // yield put(createSimpleRequest.success());
    yield put(
      notifyActions.push({
        message: i18nMark('Заявка успешно создана'),
        place: 'bc',
      })
    );
  } catch (error) {
    debug.error(error);
    yield put(createSimpleRequest.failure(error));
    yield put(
      notifyActions.push({
        message: i18nMark('Ошибка при создании заявки'),
        place: 'bc',
      })
    );
  }
}

export default function* orderRoot() {
  yield all([fetchSimpleRequestsWatch(), createSimpleRequestWatch()]);
}
