import React, { PureComponent } from 'react';


const styles = require('./ErrorPage-styles.module.scss');

class ErrorPage extends PureComponent {
  render() {
    const { children } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
