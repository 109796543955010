import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';

import { addMonths, isValid } from 'date-fns';
import React from 'react';

import DateInput from '@geobank/components/src/forms/DateInput/DateInput';

const styles = require('./DateRangeFieldset-styles.module.scss');

interface DateRangeFieldsetProps {
  source: 'archive' | 'shooting' | undefined; // string;

  dateBegin?: Date;
  dateEnd?: Date;
  onChange: (field: string, value: any) => void;
}

const DateRangeFieldset: React.FC<DateRangeFieldsetProps> = props => {
  const { dateBegin, dateEnd, onChange, source } = props;

  const handleChange = (field: string, value?: Date) => {
    onChange(field, value);
  };

  const handleDateRangeSetClick = (value: number) => {
    // const { dateBegin, dateEnd } = props;
    if (source === 'shooting' && dateBegin) {
      handleChange('acquisitionDateBefore', addMonths(dateBegin, value));
      // } else if (source === 'archive' && dateEnd) {
      //   handleChange('acquisitionDateAfter', addMonths(dateEnd, -value));
    } else {
      const currentDate = new Date();
      handleChange('acquisitionDateBefore', currentDate);
      handleChange('acquisitionDateAfter', addMonths(currentDate, -value));
    }
  };

  return (
    <>
      <I18n>
        {({ i18n }) => (
          <div className={styles.dateRangeContainer}>
            <div className={styles.dateInput}>
              <DateInput
                value={dateBegin}
                onChange={handleChange.bind(null, 'acquisitionDateAfter')}
                placeholder={i18n._(t`От`)}
                disabledDays={isValid(dateEnd) ? { after: dateEnd! } : undefined}
                width="100%"
                format="dd.MM.yyyy"
              />
            </div>
            <div className={styles.dateInput}>
              <DateInput
                value={dateEnd}
                onChange={handleChange.bind(null, 'acquisitionDateBefore')}
                placeholder={i18n._(t`До`)}
                pullRight={true}
                disabledDays={isValid(dateBegin) ? { before: dateBegin! } : undefined}
                width="100%"
                format="dd.MM.yyyy"
              />
            </div>
          </div>
        )}
      </I18n>
      <div className={styles.dateRangeSet}>
        <span onClick={handleDateRangeSetClick.bind(null, 1)}>
          <Trans>Месяц</Trans>
        </span>
        <span onClick={handleDateRangeSetClick.bind(null, 6)}>
          <Trans>Полгода</Trans>
        </span>
        <span onClick={handleDateRangeSetClick.bind(null, 12)}>
          <Trans>Год</Trans>
        </span>
      </div>
    </>
  );
};

export default DateRangeFieldset;
