import { Trans } from '@lingui/macro';

import classNames from 'classnames';
import React from 'react';

import Button from '@geobank/components/src/common/Button/Button';

// import { connect } from 'react-redux';
// import * as store from 'ducks';
import { getIsAuthenticated, iamActions } from 'ducks/authIAM';
import { useDispatch, useSelector } from 'react-redux';

const styles = require('./AuthButtonIAM-styles.module.scss');

interface AuthButtonProps {
  className?: string;
  // isAuthenticated?: boolean;
  // loginUser: typeof iamActions.login;
}

const AuthButtonIAM: React.FC<AuthButtonProps> = props => {
  const { className } = props;

  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();

  const handleClick = () => {
    // this.props.loginUser({});
    dispatch(iamActions.login({ popup: true }));
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Button onClick={handleClick} height="45px">
        <Trans>Вход и регистрация</Trans>
      </Button>
      <div style={{ display: 'none' }}>isAuthenticated: {isAuthenticated ? 'true' : 'false'}</div>
    </div>
  );
};

// rerender if props changes
export default React.memo(AuthButtonIAM);
/* export default connect(
  (state: store.RootState) => ({
    isAuthenticated: getIsAuthenticated(state),
  }),
  {
    loginUser: iamActions.login,
    // loginUser: authenticateUser.login,
    // registerUser: registerUser.request,
    // restorePassword: restorePassword.request,
  }
)(AuthButton); */
