import * as React from 'react';
import styles from './RequestManagement.css';
import EditIcon from '@geobank/assets/src/icons/EditIcon';
import EyeHiddenIcon from '@geobank/assets/src/icons/EyeHiddenIcon';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import TabletIcon from '@geobank/assets/src/icons/TabletIcon';

interface IRequestManagementContent {
  label: string;
  icon: JSX.Element;
}

const requestManagementItems: IRequestManagementContent[] = [
  { label: 'История заявки', icon: <TabletIcon /> },
  { label: 'Редактировать заявку', icon: <EditIcon /> },
  { label: 'Скрыть заявку', icon: <EyeHiddenIcon /> },
  { label: 'Удалить заявку', icon: <TrashIcon /> },
];

export default class RequestManagement extends React.Component {
  private readonly getRequestManagementItem = (content: IRequestManagementContent[]) => {
    return content.map((item, index) => (
      <div key={index} className={styles.request_management__content}>
        <div className={styles.request_management__content_icon}>{item.icon}</div>
        <div className={styles.request_management__content_text}>
          <span>{item.label}</span>
        </div>
      </div>
    ));
  };

  public render() {
    return (
      <div className={styles.request_management__container}>
        <div className={styles.request_management__container_panel}>
          {this.getRequestManagementItem(requestManagementItems)}
        </div>
      </div>
    );
  }
}
