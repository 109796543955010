import Fill, { Options as FillOptions } from 'ol/style/Fill';
import Stroke, { Options as StrokeOptions } from 'ol/style/Stroke';

// import { Style, Fill, Stroke, Icon } from 'ol/style'; // Circle
import Style from 'ol/style/Style';

export const emptyStyle = new Style();

export const thinStyle = new Style({
  stroke: new Stroke({
    color: 'blue',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgba(0, 0, 255, 0)',
  }),
});

export const boldStyle = new Style({
  stroke: new Stroke({
    color: 'green',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(0, 255, 0, 0.1)',
  }),
});

export const selectStyle = new Style({
  stroke: new Stroke({
    color: 'red',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.0)',
  }),
});

export const blockedStyle = new Style({
  stroke: new Stroke({
    color: 'gray',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgba(128, 128, 128, 0)',
  }),
});

export const intersectionStyle = new Style({
  stroke: new Stroke({
    color: 'red',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.1)',
  }),
});

export const geofenceStyle = new Style({
  stroke: new Stroke({
    color: '#1f5a08', // '#14306a',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(207, 250, 152, 0.0)',
  }),
});

export const disabledStyle = new Style({
  stroke: new Stroke({
    color: 'gray',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(128, 128, 128, 0)',
  }),
});

//
interface CustomStyleParams {
  fill?: FillOptions;
  stroke?: StrokeOptions;
}

export const getCustomStyle = (styles: CustomStyleParams) => {
  const { fill, stroke } = styles;
  // Generate style for gradient or pattern fill
  // const fill = new Fill();
  const style = new Style({
    stroke: stroke
      ? new Stroke({
          color: stroke.color || '#333',
          width: stroke.width || 2,
        })
      : new Stroke(),
    fill: fill ? new Fill({ color: fill.color || 'rgba(0, 0, 255, 0)' }) : new Fill(),
  });
  return style;
};
