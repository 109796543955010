import React, { useEffect } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { withI18nProps, withI18n } from '@lingui/react';

interface HeadTitle {
  value: MessageDescriptor | string;
}

const HeadTitle: React.FC<HeadTitle & withI18nProps> = ({ i18n, value }) => {
  useEffect(() => {
    // value={t`some title`} === { id: "some title"}
    document.title = typeof value === 'object' ? i18n._(value) : value;
    //
    // не знаю точно как, но заголовок меняется при смене языка (возможно, происходит перерисовка page?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return null;
};

export default withI18n()(HeadTitle);
