import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import sortBy from 'lodash/sortBy';
import { combineReducers } from 'redux';

import { LicenseValue, UsageTimeValue } from 'locales/dicts/license';
import { RootState as RootStateType } from './root';
import { ImageSource } from './types/orderTypes';
// import { ImageMetadata as Metadata } from './metadata';

// export type BandСombination =
export type ProductPMS = 'pansharp' | 'bundle' | 'ms' | 'pan';

export interface ProductFile {
  name: string;
  size: number;
  url: string; // endpoint to get url -> { url: <s3 presign url> }
  // for s3 access
  bucket: string;
  key: string;
}
// OrderProductContainer
export interface OrderProduct {
  id: number;
  files: ProductFile[];
  identifier: string;
  previewUrls: string[];
  removeDate: Date;
  createdAt: Date;
}

/* {
  "srs_name": "utm",
  "product_code": "L2",
  "bytes_per_pixel": 1
} */
export interface CurrentCartParams {
  srsName: string;
  productCode: string;
  bytesPerPixel: number;
  elevation: boolean;
  certification: boolean;
  license: LicenseValue;
  usageTime: UsageTimeValue;
  tags: string[];
}

export interface CartImage {
  metadataIdentifier: string;
  croppingPolygon?: string;
  geometry?: string;
  bandCombination: ProductPMS;
  bands?: string[];
  // pansharpening: boolean;
  // elevation: boolean;
  price: number;
  area?: number;
  // md?: ImageMetadata;
}

/*
{
  "cropping_polygon": "POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))",
  "elevation": false,
  "band_combination": "pansharp",
  "bands": [
    "RED",
    "GREEN",
    "BLUE"
  ],
  "images": [
    {
      "metadata_identifier": "ETRIS.KV1.PSS.5700.3.0.2014-07-02.L0.NTSOMZ_MSK"
    }
  ]
}
*/
export interface QuotationRequestParams {
  // metadataIdentifier: string;
  croppingPolygon?: string;
  bandCombination: ProductPMS;
  bands?: string[];
  // pansharpening: boolean;
  elevation: boolean;
  // price: number;
  // new - отличие от CartImage, что metadataIdentifier в массиве
  images: Array<{ metadataIdentifier: string }>;
  callback?: (result: any) => void;
}

/* {
  "srs_name": "utm",
  "product_code": "L2",
  "bytes_per_pixel": 1,
  "images": [
    {
      "metadata_identifier": "ETRIS.KV1.PSS.5700.3.0.2014-07-02.L0.NTSOMZ_MSK",
      "cropping_polygon": "POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))",
      "price": 750
    }
  ],
  "total_price": 2500
} */
// CartCurrent
export interface CurrentCart {
  srsName: string;
  productCode: string;
  bytesPerPixel: number;
  elevation: boolean;
  certification: boolean;
  totalPrice: number;
  images: CartImage[];
  license: LicenseValue;
  usageTime: UsageTimeValue;
  tags: string[];
}

export interface CartRequestStatus {
  status: string;
  comment: string | null;
  createdAt: string;
}

/* {
  "id": 100500,
  "number": "00001-20200423",
  "status": {"status": "created", "comment": null},
  "created_at": "2020-04-26T15:20:22.363Z",
  "srs_name": "utm",
  "product_code": "L2",
  "bytes_per_pixel": 1,
  "images": [
    {
      "metadata_identifier": "ETRIS.KV1.PSS.5700.3.0.2014-07-02.L0.NTSOMZ_MSK",
      "cropping_polygon": "POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))",
      "price": 750
    }
  ],
  "total_price": 2500

  payment_params: {​​
    form_url: "https://3dsec.sberbank.ru/payment/merchants/sbersafe/payment_ru.html?mdOrder=e49a52bc-48b0-71eb-896d-74505e27f576",
    order_id: "e49a52bc-48b0-71eb-896d-74505e27f576"
  }
} */
export interface CartRequest {
  id: number;
  number: string;
  statuses: CartRequestStatus[];
  createdAt: string;
  srsName: string; // temp
  productCode: string; // temp
  bytesPerPixel: number; // temp
  elevation: boolean; // temp
  totalPrice: number;
  totalArea?: number; // calculated
  images?: CartImage[];
  products?: OrderProduct[];
  license: LicenseValue;
  usageTime: UsageTimeValue;
  tags?: string[];
  paymentParams?: {
    formUrl: string;
    orderId: string;
  };
}

interface FetchRequestsParams {
  orderId?: string;
  tags?: string[];
  offset?: number;
  limit?: number;
  callback?: (result: any) => void;
}

/**
 * Actions
 */
// export const simpleRouteOrder = {
//   create:
// export const createSimpleRouteOrder = {
//   request: createActionCreator('METADATA_SEARCH_REQUEST', resolve => (params: object) =>
//     resolve(params),
//   ),
//   success: createActionCreator('METADATA_SEARCH_SUCCESS', resolve => (data: Metadata[]) =>
//     resolve(data),
//   ),
//   failure: createActionCreator('METADATA_SEARCH_FAILURE', resolve => error => resolve(error)),
// };

// export const simpleRouteCartActions = {
/* export const pickSimpleRouteCart = {
  add: createActionCreator('ROUTE_ORDER/ADD_ROUTE', resolve => (route: Metadata) => resolve(route)),
  remove: createActionCreator('ROUTE_ORDER/REMOVE_ROUTE', resolve => (route: Metadata) =>
    resolve(route)
  ),
  clear: createActionCreator('ROUTE_ORDER/CLEAR_CART', resolve => (route: Metadata) =>
    resolve(route)
  ),
}; */

export const updateCurrentCartImages = {
  // async actions
  request: createActionCreator(
    'ROUTE_ORDER/UPDATE_CART_IMAGES_REQUEST', // CART_IMAGES_UPDATE_REQUEST
    // data: Metadata
    // resolve => (data: CartImage, effect: 'add' | 'remove') =>
    // resolve => (data: ImageMetadata | ImageMetadata[], effect: 'add' | 'remove') =>
    resolve => (data: CartImage | CartImage[], effect: 'add' | 'remove') =>
      resolve(data, { effect })
  ),
  // success: createActionCreator(
  //   'ROUTE_ORDER/UPDATE_CART_IMAGES_SUCCESS'
  //   /* resolve => (data: CurrentCart) => resolve(data) */
  // ),
  // failure: createActionCreator('ROUTE_ORDER/UPDATE_CART_IMAGES_FAILURE', resolve => error =>
  //   resolve(error)
  // ),
  // upd store
  add: createActionCreator(
    'ROUTE_ORDER/CART_IMAGES_ADD',
    resolve => (data: CartImage, index?: number) => resolve(data, { index })
  ),
  // TODO - update (для цены); хз как сделать, по индексу не факт, что корректно. искать по идентификатору внутри reducer ?
  remove: createActionCreator('ROUTE_ORDER/CART_IMAGES_REMOVE', resolve => (data: CartImage) =>
    resolve(data)
  ),
};

// export const updateCurrentCartParams = createActionCreator(
//   'ROUTE_ORDER/UPDATE_CART_PARAMS',
//   resolve => (data: CurrentCartParams) => resolve(data)
// );
export const updateCurrentCartParams = {
  request: createActionCreator(
    'ROUTE_ORDER/UPDATE_CART_PARAMS_REQUEST',
    resolve => (data: Partial<CurrentCartParams>) => resolve(data)
  ),
  success: createActionCreator(
    'ROUTE_ORDER/UPDATE_CART_PARAMS_SUCCESS',
    resolve => (data: CurrentCart) => resolve(data)
  ),
  failure: createActionCreator('ROUTE_ORDER/UPDATE_CART_PARAMS_FAILURE', resolve => error =>
    resolve(error)
  ),
};

export const clearCurrentCartRequest = createActionCreator(
  'ROUTE_ORDER/CLEAR_CART',
  resolve => () => resolve()
);

export const fetchCurrentCart = {
  request: createActionCreator('ROUTE_ORDER/FETCH_CURRENT_CART_REQUEST'),
  success: createActionCreator(
    'ROUTE_ORDER/FETCH_CURRENT_CART_SUCCESS',
    resolve => (data: CurrentCart) => resolve(data)
  ),
  failure: createActionCreator('ROUTE_ORDER/FETCH_CURRENT_CART_FAILURE', resolve => error =>
    resolve(error)
  ),
};

// TODO -> CartOrders
export const fetchCartRequests = {
  request: createActionCreator(
    'ROUTE_ORDER/FETCH_CART_REQUESTS_REQUEST',
    resolve => (params: FetchRequestsParams) => resolve(params)
  ),
  success: createActionCreator(
    'ROUTE_ORDER/FETCH_CART_REQUESTS_SUCCESS',
    resolve => (data: { count: number; results: CartRequest[] }) => resolve(data)
  ),
  failure: createActionCreator('ROUTE_ORDER/FETCH_CART_REQUESTS_FAILURE', resolve => error =>
    resolve(error)
  ),
};
// TODO - temp
export const findCartRequests = {
  request: createActionCreator(
    'ROUTE_ORDER/FIND_CART_REQUESTS_REQUEST',
    resolve => (params: FetchRequestsParams) => resolve(params)
  ),
};

// detail order
export const fetchCartOrder = {
  request: createActionCreator(
    'ROUTE_ORDER/FETCH_CART_ORDER_REQUEST',
    resolve => (orderNumber: string, params?: any, callback?: (req: CartRequest) => void) =>
      resolve({ orderNumber, params, callback })
  ),
  success: createActionCreator(
    'ROUTE_ORDER/FETCH_CART_ORDER_SUCCESS',
    resolve => (data: CartRequest) => resolve(data)
  ),
  failure: createActionCreator('ROUTE_ORDER/FETCH_CART_ORDER_FAILURE', resolve => error =>
    resolve(error)
  ),
};

export const fetchImagesQuotation = {
  request: createActionCreator(
    'ROUTE_ORDER/FETCH_IMAGES_QUOTATION_REQUEST',
    resolve => (data: QuotationRequestParams) => resolve(data)
  ),
};

export const sortCartRequests = createActionCreator(
  'ROUTE_ORDER/SORT_CART_REQUESTS',
  resolve => (field: string, direction: 'ASC' | 'DESC') => resolve({ field, direction })
  // resolve => (sortFunc: (...args: any) => any) => resolve(sortFunc)
);

export const createCartRequest = {
  request: createActionCreator('ROUTE_ORDER/CREATE_CART_REQUEST_REQUEST'),
  success: createActionCreator('ROUTE_ORDER/CREATE_CART_REQUEST_SUCCESS'),
  failure: createActionCreator('ROUTE_ORDER/CREATE_CART_REQUEST_FAILURE', resolve => error =>
    resolve(error)
  ),
};

export const setImageSource = createActionCreator(
  'ROUTE_ORDER/SET_IMAGE_SOURCE',
  resolve => (sourceName: ImageSource) => resolve(sourceName)
);

export const fetchUserOrderTags = {
  request: createActionCreator('ROUTE_ORDER/FETCH_USER_TAGS_REQUEST', resolve => () => resolve()),
  success: createActionCreator(
    'ROUTE_ORDER/FETCH_USER_TAGS_SUCCESS',
    resolve => (data: Array<{ name: string; count: number }>) => resolve(data)
  ),
  failure: createActionCreator('ROUTE_ORDER/FETCH_USER_TAGS_FAILURE', resolve => error =>
    resolve(error)
  ),
};

/**
 * Reducers and state
 */
/* export const defaultRouteCartState: Metadata[] = []; // { byId: {}, allIds: [] }

export const simpleRouteCartReducer = createReducer(defaultRouteCartState, handleAction => [
  handleAction(pickSimpleRouteCart.add, (state, { payload }) => {
    // [...state, payload]
    if (state.find(md => md.id === payload.id)) {
      return state;
    } else {
      return [...state, payload];
    }
  }),
  handleAction(pickSimpleRouteCart.remove, (state, { payload }) => {
    // TODO - проверить, можно ли с этой либой deox изменять state внутри обработчика
    const newState = [...state];
    const index = newState.indexOf(payload);
    if (index > -1) {
      newState.splice(index, 1);
      return newState;
    } else {
      return state;
    }
  }),
  handleAction(pickSimpleRouteCart.clear, () => []),
]); */

export const currentCartImagesDefaultState: CartImage[] = [];

export const currentCartImagesReducer = createReducer(
  currentCartImagesDefaultState,
  handleAction => [
    handleAction(updateCurrentCartImages.add, (state, { payload, meta }) => {
      const result = [...state];
      // Number.isInteger( - not work for ts
      if (meta?.index !== undefined) {
        result.splice(meta.index, 0, payload);
      } else {
        result.push(payload);
      }
      return result;
    }),
    handleAction(updateCurrentCartImages.remove, (state, { payload }) => {
      const newState = [...state];
      const index = newState.indexOf(payload);
      if (index > -1) {
        newState.splice(index, 1);
        return newState;
      } else {
        return state;
      }
    }),
    handleAction(fetchCurrentCart.success, (_, { payload }) => payload.images),
    handleAction(createCartRequest.success, () => []),
    // меняется цена! (можно проверять: если цена не изменилась, то возвращать state)
    handleAction(updateCurrentCartParams.success, (_, { payload }) => payload.images),
  ]
);

export const currentCartPriceDefaultState: number = 0;

export const currentCartPriceReducer = createReducer(currentCartPriceDefaultState, handleAction => [
  handleAction(fetchCurrentCart.success, (_, { payload }) => payload.totalPrice),
  handleAction(updateCurrentCartImages.remove, (state, { payload }) => state - payload.price),
  // temp?
  handleAction(updateCurrentCartImages.add, (state, { payload }) => state + payload.price),

  handleAction(updateCurrentCartParams.success, (_, { payload }) => payload.totalPrice),
]);

// etris default state
export const currentCartParamsDefaultState: CurrentCartParams = {
  srsName: 'UTM', // EPSG:4326 WGS84
  productCode: 'L2',
  bytesPerPixel: 1,
  elevation: false,
  certification: false,
  license: 'individually',
  usageTime: 'five',
  tags: [],
};
export const bbpCurrentCartParamsDefaultState: CurrentCartParams = {
  ...currentCartParamsDefaultState,
  srsName: 'UTM',
  productCode: 'L2',
  bytesPerPixel: 2,
  elevation: false,
};

export const currentCartParamsReducer = createReducer(
  currentCartParamsDefaultState,
  handleAction => [
    handleAction(updateCurrentCartParams.request, (state, { payload }) => ({
      ...state,
      ...payload,
    })),
    handleAction(fetchCurrentCart.success, (_, { payload }) => ({
      srsName: payload.srsName,
      productCode: payload.productCode,
      bytesPerPixel: payload.bytesPerPixel,
      elevation: payload.elevation,
      certification: payload.certification,
      license: payload.license, // || currentCartParamsDefaultState.license,
      usageTime: payload.usageTime, // || currentCartParamsDefaultState.usageTime,
      tags: payload.tags,
    })),
    handleAction(createCartRequest.success, () => currentCartParamsDefaultState),
    // handleAction(setImageSource, (state, { payload }) => {
    //   return payload === 'etris'
    //     ? currentCartParamsDefaultState
    //     : payload === 'bbp'
    //     ? bbpCurrentCartParamsDefaultState
    //     : state;
    // }),
  ]
);

export const cartRequestsDefaultState: CartRequest[] = [];

export const cartRequestsReducer = createReducer(cartRequestsDefaultState, handleAction => [
  handleAction(fetchCartRequests.success, (_, { payload }) =>
    sortBy(payload.results, 'createdAt').reverse()
  ),
  handleAction(sortCartRequests, (state, { payload: { field, direction } }) => {
    const result = sortBy(state, field);
    return direction === 'ASC' ? result : result.reverse();
  }),
  handleAction(fetchCartOrder.success, (state, { payload }) => {
    const index = state.findIndex(item => item.id === payload.id);
    if (index > -1) {
      let totalArea = 0;
      if (payload.images) {
        totalArea = payload.images
          .map(image => image.area)
          .reduce<number>((prev, curr) => (typeof curr === 'number' ? prev + curr : prev), 0);
      }
      state[index] = { ...state[index], ...payload, totalArea };
      // const newState = [...state];
      // newState[index] = { ...state[index], ...payload };
      // return newState;
    }
    return state;
  }),
]);

export const totalCartRequestsDefaultState = 0;
export const totalCartRequestsReducer = createReducer(
  totalCartRequestsDefaultState,
  handleAction => [handleAction(fetchCartRequests.success, (_, { payload }) => payload.count)]
);

export const isFetchingCartRequestsDefaultState: boolean = false;
export const isFetchingCartRequestsReducer = createReducer(
  isFetchingCartRequestsDefaultState,
  handleAction => [
    handleAction(fetchCartRequests.request, () => true),
    handleAction(fetchCartRequests.success, () => false),
    handleAction(fetchCartRequests.failure, () => false),
  ]
);

export const sortingCartRequestsDefaultState: { field: string; direction: 'ASC' | 'DESC' } = {
  field: 'createdAt',
  direction: 'DESC',
};
export const sortingCartRequestsReducer = createReducer(
  sortingCartRequestsDefaultState,
  handleAction => [handleAction(sortCartRequests, (_, { payload }) => payload)]
);

export const paginationRequestsDefaultState: {
  currentPage: number;
  pageSize: number;
} = {
  currentPage: 1,
  pageSize: 5,
};
export const paginationCartRequestsReducer = createReducer(
  paginationRequestsDefaultState,
  handleAction => [
    handleAction(fetchCartRequests.request, (state, { payload: { offset, limit } }) => {
      return {
        ...state,
        currentPage: typeof offset === 'number' ? offset / state.pageSize + 1 : state.currentPage,
        pageSize: limit ? limit : state.pageSize,
      };
    }),
  ]
);

// const isEtris = (id: string) => id.startsWith('ETRIS.');
// const isBbp = (id: string) => id.startsWith('BBP.');

// images source
const imageSourceNameDefaultState = null as ImageSource;
export const imageSourceNameReducer = createReducer(imageSourceNameDefaultState, handleAction => [
  handleAction(setImageSource, (state, { payload }) => {
    return payload;
  }),
  // handleAction(updateCurrentCartImages.remove, (state, { payload }) => {
  //   const imageSource = isEtris(payload.metadataIdentifier)
  //     ? 'etris'
  //     : isBbp(payload.metadataIdentifier)
  //     ? 'bbp'
  //     : null;
  //   return state !== imageSource ? imageSource : state;
  // }),
  // handleAction(updateCurrentCartImages.add, (state, { payload }) => state + payload.price),
]);

export const userOrderTagsDefaultState: Array<{ name: string; count: number }> = [];
export const userOrderTagsReducer = createReducer(userOrderTagsDefaultState, handleAction => [
  // handleAction(fetchUserOrderTags.request, () => []),
  handleAction(fetchUserOrderTags.success, (state, { payload }) => payload),
  // handleAction(fetchUserOrderTags.failure, () => []),
]);

// metadataRootReducer
export default combineReducers({
  // simpleRouteCart: simpleRouteCartReducer,
  currentCartImages: currentCartImagesReducer,
  currentCartPrice: currentCartPriceReducer,
  currentCartParams: currentCartParamsReducer,
  cartRequests: cartRequestsReducer,
  totalCartRequests: totalCartRequestsReducer,
  isFetchingCartRequests: isFetchingCartRequestsReducer,
  sortingCartRequests: sortingCartRequestsReducer,
  pagination: paginationCartRequestsReducer,
  imageSourceName: imageSourceNameReducer,
  userOrderTags: userOrderTagsReducer,
});

/**
 * Selectors
 */
// old
// export const getSimpleRouteCart = (state: RootStateType) => state.order.simpleRouteCart;
// export const getSimpleRouteCartCount = (state: RootStateType) => state.order.simpleRouteCart.length;
// new
export const getCurrentCartImages = (state: RootStateType) => state.order.currentCartImages;
export const getCurrentCartPrice = (state: RootStateType) => state.order.currentCartPrice;
export const getCurrentCartParams = (state: RootStateType) => state.order.currentCartParams;
export const getCartRequests = (state: RootStateType) => state.order.cartRequests;
export const getTotalCartRequests = (state: RootStateType) => state.order.totalCartRequests;
export const getIsFetchingCartRequests = (state: RootStateType) =>
  state.order.isFetchingCartRequests;
export const getSortingCartRequests = (state: RootStateType) => state.order.sortingCartRequests;
export const getPagination = (state: RootStateType) => state.order.pagination;
export const getImageSourceName = (state: RootStateType) => state.order.imageSourceName;
export const getUserOrderTags = (state: RootStateType) => state.order.userOrderTags;
