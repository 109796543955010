import { t } from '@lingui/macro'; // Trans
import { withI18n, withI18nProps } from '@lingui/react';
import * as Sentry from '@sentry/browser';
import React, { PureComponent } from 'react';

import ErrorFragment from 'components/common/ErrorPage/ErrorFragment';
import ErrorPage from 'components/common/ErrorPage/ErrorPage';
import ReportSentryButton from 'components/common/ErrorPage/ReportSentryButton';
import { ym } from 'modules/metrics/yandex';

interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string | null;
}

class ErrorBoundary extends PureComponent<withI18nProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  public static getDerivedStateFromError(error: Error | null) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error | null, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    ym('reachGoal', 'error-boundary');
  }

  public render() {
    const { hasError, eventId } = this.state;
    const { i18n } = this.props;

    if (hasError) {
      return (
        <ErrorPage>
          <ErrorFragment code="" title={i18n._(t`Что-то пошло не так...`)} />
          {/* кнопка отправки отчёта через форму Sentry */}
          {eventId && <ReportSentryButton eventId={eventId} />}
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default withI18n()(ErrorBoundary);
