import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom'; // Redirect

import { getIFetching, getIsAuthenticated, iamActions } from 'ducks/authIAM';

const PrivateRoute: React.FC<RouteProps> = props => {
  const { children, location, ...rest } = props;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isFetching = useSelector(getIFetching);

  if (!isFetching && !isAuthenticated) {
    dispatch(iamActions.login({ redirectUri: location?.pathname }));
    return null;
  }

  return (
    <Route
      location={location}
      {...rest}
      render={() => {
        return isAuthenticated ? children : null;
        // <Redirect
        //   to={{
        //     pathname: '/login',
        //     state: { from: location },
        //   }}
        // />;
      }}
    />
  );
};

export default PrivateRoute;
