import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

// import Checkbox, { CHECKED, UNCHECKED } from '../../SearchResultItem/CheckBox/Checkbox';
import Checkbox, { CHECKED, UNCHECKED } from 'components/common/forms/CheckBox/CheckBox';
// import Checkbox from '@geobank/components/src/forms/CheckBox/Checkbox';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import { catFileIdentifier } from 'components/utils/format';
// import { ImageMetadata, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { PreparedImage } from '../types';

import styles from './ConflictImage.module.scss';

// interface ConflictImageProps {
//   data: ImageMetadata; // ImageMetadata
//   isChecked: boolean;
//   onToggleCheck: (md: ImageMetadata) => void;
//   onRemove: (md: ImageMetadata) => void;
// }
interface ConflictImageProps {
  data: PreparedImage;
  isChecked: boolean;
  onToggleCheck: (prImage: PreparedImage) => void;
  onRemove: (prImage: PreparedImage) => void;
}

const ConflictImage: React.FC<ConflictImageProps> = props => {
  const { data, isChecked } = props; // i18n

  const handleRemove = () => {
    props.onRemove(data);
  };

  const handleCheck = () => {
    props.onToggleCheck(data);
  };

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <Checkbox
          // name="md:888"
          value="md:888"
          // checked={isChecked}
          // value={isChecked ? CHECKED : UNCHECKED}
          checked={isChecked ? CHECKED : UNCHECKED}
          onChange={handleCheck}
          // label=""
        />
        <span className={styles.label}>
          {/* {'label'} */}
          <>
            <span
              className={styles.itemLabelContainer}
              // onDoubleClick={this.handleSelectRoute.bind(this, item)}
              // onClick={handleActivate}
            >
              <span className={styles.itemLabel} style={{ maxWidth: '270px' }}>
                {catFileIdentifier(data.identifier, 1)}
              </span>
            </span>
            <div className={styles.itemHeadActions}>
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.itemHeadActionButton, styles.removeButton)}
                // title={i18n._(t('image_actions.remove_from_results')`Удалить из результатов`)}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleRemove();
                }}
              >
                <TrashIcon fill="currentColor" />
              </Button>
            </div>
          </>
        </span>
      </div>
      <div className={styles.conflicts}>
        <Trans>Доступный вариант комплектации: {data.hasMultiSpectrSensor ? 'MS' : 'PAN'}</Trans>
      </div>
    </div>
  );
};

export default ConflictImage;
