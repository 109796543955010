import React /* useState , useEffect */ from 'react';
// import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
// import { css } from 'astroturf';
import { t } from '@lingui/macro'; // Trans
import { I18n } from '@lingui/react'; // i18nMark

import HeadTitle from 'pages/HeadTitle';
import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import TransparentHeader from 'components/layout/Header/TransparentHeader';

import PersonDataTab from './tabs/PersonDataTab/PersonDataTab';
// import RequestsTab from './tabs/RequestsTab/RequestsTab';
import RequestsWithSortTab from './tabs/RequestsTab/RequestsWithSortTab';
import OrderDetailView from './tabs/OrderDetailView/OrderDetailView';

import styles from './PersonalAreaPage.module.scss';

enum PersonalAreaTabs {
  // PersonData = 'PERSON_DATA',
  // MyRequests = 'MY_REQUESTS',
  profile = 'profile',
  orders = 'orders', // requests
}

// const urlMapping: { [x: string]: PersonalAreaTabs } = {
//   profile: PersonalAreaTabs.PersonData,
//   requests: PersonalAreaTabs.MyRequests,
// };

// TODO - перевод так не работает!
/* const tabsList = [
  { value: PersonalAreaTabs.PersonData, label: i18nMark('Личные данные') },
  { value: PersonalAreaTabs.MyRequests, label: i18nMark('Мои заявки') },
  // { value: 'notifications', active: false, label: 'Уведомления' },
  // { value: 'pay_info', active: false, label: 'Платежи' },
]; */

// interface PersonalAreaPageState {
//   activeTab: string;
// }

const PersonalAreaPage: React.FC = () => {
  const history = useHistory();
  const { subpage, orderNumber } = useParams<{
    subpage: 'profile' | 'orders';
    orderNumber?: string;
  }>();
  // const [activeTab, setActiveTab] = useState(PersonalAreaTabs.MyRequests as string);
  // if (subpage === undefined) {
  //   return null;
  // }
  const activeTab = subpage; // urlMapping[subpage];
  const setActiveTab = (value: string) => {
    history.push(`/account/${value}`);
  };

  // useEffect(() => {
  //   if (subpage !== undefined && urlMapping[subpage] !== activeTab) {
  //     setActiveTab(urlMapping[subpage]);
  //   }
  // }, [subpage, activeTab]);

  const getContent = (value: string) => {
    switch (value) {
      case PersonalAreaTabs.profile:
        return <PersonDataTab />;
      case PersonalAreaTabs.orders:
        // return <RequestsTab />;
        if (orderNumber) {
          return <OrderDetailView orderNumber={orderNumber} />;
        } else {
          return <RequestsWithSortTab />;
        }
    }
  };

  const handleGoBack = () => {
    // history.goBack();
    history.push('/');
  };

  return (
    <>
      <HeadTitle value={t`Личный кабинет - Геопортал`} />

      {/* TODO - убраться строку поиска? */}
      <TransparentHeader search={false} />
      {/* <div className={styles.root}>
        <Link to="/" style={{ color: 'black', lineHeight: '61px' }}>
          X
        </Link>
        <h2>PersonalAreaPage Page</h2>
      </div> */}
      <div className={styles.root}>
        <div className={styles.tabsContainer}>
          <I18n>
            {({ i18n }) => (
              <>
                {/* TODO - в некоторых случаях может понадобится компонент типа ButtonLink ? */}
                <Button
                  color={ButtonColor.TRANSPARENT}
                  className={styles.backButton}
                  title={i18n._(t`Вернуться назад`)}
                  onClick={handleGoBack}
                >
                  <CrossIcon />
                </Button>
                <ButtonTabs
                  tabsList={[
                    { value: PersonalAreaTabs.profile, label: i18n._(t`Личные данные`) },
                    { value: PersonalAreaTabs.orders, label: i18n._(t`Мои заявки`) },
                  ]}
                  classNames={{
                    tabContainer: styles.tabContainer,
                    tabContainerActive: styles.tabContainerActive,
                    tabText: '',
                  }}
                  onChange={value => setActiveTab(value)}
                  activeTab={activeTab}
                />
              </>
            )}
          </I18n>
        </div>
        <div className={styles.contentContainer}>{getContent(activeTab)}</div>
      </div>
    </>
  );
};

export default PersonalAreaPage;
