/**
 * Модуль логирования важных сообщений
 * вывод сообщений только в режиме разработки (development)
 */
const DEBUG = process.env.NODE_ENV === 'production' ? false : true;

const emptyLogFunction = () => {
  //
};

// при таком подходе не теряется контекст/строчка вызова логера
export const Debugger = (tag: string = 'WebApp') => {
  const debug: any = {};

  for (const m in console) {
    // @ts-ignore
    if (typeof console[m] === 'function') {
      // @ts-ignore
      debug[m] = DEBUG ? console[m].bind(window.console, `[${tag}]`) : emptyLogFunction;
    }
  }
  return debug;
};

// альтернативы:
// https://www.npmjs.com/search?q=debug
//   https://github.com/azu/debug (@deps/debug)
//   https://github.com/visionmedia/debug
