import { call, put, all, takeLatest, delay } from 'redux-saga/effects';
import { getType, ActionType } from 'deox';
import { t } from '@lingui/macro';

import API from 'api/realAPI';
import { downloadProduct } from 'ducks/orderProduct';
import { notifyActions } from 'ducks/message';
import { OrderProduct } from 'ducks/order';
import { captureSagaException } from 'modules/monitor/sentryHelper';
import { Debugger } from 'utils/logging';

import { requestFlowNew } from './network';
import authRefresh from './networkIAM';

const debug = Debugger('OrderProductSaga'); // TAG

const DOWNLOAD_DELAY = 1000;

const flattenProducts = (products: OrderProduct[]) => {
  const list: { url: string; name: string }[] = [];

  products.forEach(product => {
    product.files.forEach(file => {
      list.push({ url: file.url, name: file.name });
    });
  });

  return list;
};

export function* downloadProductsWatch() {
  yield takeLatest(getType(downloadProduct.start), downloadProductFlow);
}
export function* downloadProductFlow(action: ActionType<typeof downloadProduct.start>) {
  const { products, callback } = action.payload;
  const urlCreator = window.URL || window.webkitURL;

  try {
    const productUrls = flattenProducts(products);
    let loadedFiles = 0;
    for (const { url: fileUrl, name: fileName } of productUrls) {
      let getPresignApiUrl = fileUrl.replace('&download=true', '');
      if (process.env.NODE_ENV === 'development') {
        getPresignApiUrl = getPresignApiUrl.replace('/orders/', '/v0/orders/');
      }

      const presignUrlResponseData = yield call(
        authRefresh,
        requestFlowNew,
        API.get,
        getPresignApiUrl
      );

      const imageResponse = yield call(API.get, presignUrlResponseData.url, {
        headers: {
          Authorization: undefined,
        },
        responseType: 'blob',
      });

      const imageUrl = urlCreator.createObjectURL(imageResponse.data);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      // the file name to display / save
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      // TODO ?
      // URL.revokeObjectURL(_href);

      loadedFiles++;
      if (callback) {
        callback({
          totalFiles: productUrls.length,
          loadedFiles,
        });
      }

      // optional delay between downloads
      yield delay(DOWNLOAD_DELAY);
    }

    yield put(
      notifyActions.push({
        message: t('messages.download_images_successed')`Все файлы успешно скачаны`,
        place: 'bc',
      })
    );
  } catch (error) {
    debug.error(error);
    captureSagaException(error, action.type, {});
    yield put(
      notifyActions.push({
        color: 'alert',
        message: t(
          'messages.download_images_failed'
        )`Во время скачивания продукции произошла ошибка`,
        place: 'bc',
      })
    );
  }
}

export default function* orderRoot() {
  yield all([downloadProductsWatch()]);
}
