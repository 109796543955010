import React from 'react';

import { Select, SelectOption } from '@geobank/components/src/forms/Select';
import { ProductOptionType } from '../helpers/fieldTypes';

interface SrsNameFieldProps {
  name: string; // srsName
  value: string;
  options: ProductOptionType[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SrsNameField: React.FC<SrsNameFieldProps> = props => {
  const { name, value, options, onChange } = props;

  return (
    <Select name={name} value={value} onChange={onChange}>
      {/*
      <SelectOption value="EPSG:4326">WGS84</SelectOption>
      <SelectOption value="UTM">UTM</SelectOption>
      <SelectOption value="EPSG:7683">GSK-2011</SelectOption>
      */}
      {options.map(param => (
        <SelectOption key={param.value} value={param.value}>
          {param.title}
        </SelectOption>
      ))}
    </Select>
  );
};

export default React.memo(SrsNameField);
