import React from 'react';
import classNames from 'classnames';

import { Trans } from '@lingui/macro';

import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import PersonIcon from '@geobank/assets/src/icons/PersonIcon';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as store from 'ducks';

// import { authenticateUser } from 'ducks/auth';
import { iamActions } from 'ducks/authIAM';
import { getMetadata, clearMetadata, ImageMetadata } from 'ducks/metadata';

const styles = require('./ProfileButton-styles.module.scss');

interface ProfileButtonProps {
  className?: string;
  metadata: ImageMetadata[];
  // logoutUser: typeof authenticateUser.logout;
  logoutUser: typeof iamActions.logout;
  clearMetadata: typeof clearMetadata;
}

const ProfileButton: React.FC<ProfileButtonProps> = props => {
  const handleLogout = () => props.logoutUser();
  // Включение очистки метаданных при переключении в личный кабинет
  // const ids = props.metadata.map(item => item.id);
  // const handleClearMetadata = () => props.clearMetadata(ids);

  return (
    <div className={props.className}>
      <Dropdown dropup={false} pullRight={true} className={styles.dropdown}>
        <Dropdown.Toggle style={{ borderRadius: '0' }}>
          <div /* style={{ padding: '5px 5px' }} */>
            {/* TODO - w and h */}
            <PersonIcon /* width="19" height="20" */ />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.MenuHeader>Подложки</Dropdown.MenuHeader> */}
          <Dropdown.MenuItem>
            <Link
              to="/account/profile"
              className={classNames(styles.item, styles.link)}
              // Включение очистки метаданных при переключении в личный кабинет
              // onClick={handleClearMetadata}
            >
              <span>
                <Trans>Личный кабинет</Trans>
              </span>
            </Link>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <Link
              to="/account/orders"
              className={classNames(styles.item, styles.link)}
              // Включение очистки метаданных при переключении в личный кабинет
              // onClick={handleClearMetadata}
            >
              <span>
                <Trans>Заявки</Trans>
              </span>
            </Link>
          </Dropdown.MenuItem>
          {/* <Dropdown.MenuItem>Заявки (2)</Dropdown.MenuItem> */}
          {/* <Dropdown.MenuItem>Уведомления (6)</Dropdown.MenuItem> */}
          {/* <Dropdown.MenuItem>Платежи</Dropdown.MenuItem> */}
          <Dropdown.MenuDivider />
          <Dropdown.MenuItem onClick={handleLogout}>
            <span className={styles.item}>
              <Trans>Выход</Trans>
            </span>
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

// export default ProfileButton;
export default connect((state: store.RootState) => ({ metadata: getMetadata(state) }), {
  // logoutUser: authenticateUser.logout,
  logoutUser: iamActions.logout,
  clearMetadata: clearMetadata,
})(ProfileButton);
