import * as turf from '@turf/turf';
// import GeoJSON from 'ol/format/GeoJSON';
// import WKT from 'ol/format/WKT';
import { wktToGeoJSON } from 'components/map/Map/utils';
import { Position } from 'geojson';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';

export const checkIntersection = (geometries: any[]) => {
  const intersections: any[] = [];
  for (const geometry of geometries.slice(1)) {
    intersections.push(turf.intersect(geometries[0], geometry));
  }
  return !intersections.every(geom => geom === null);
};

export const isValidImagesForMosaic = (cartImages: any[]) => {
  const isIntersects: boolean[] = [];
  if (cartImages.length > 1) {
    const images = [...cartImages];
    const mosaicImagesGeometries = images.map(image => {
      return turf.getGeom(wktToGeoJSON(image.geometry));
    });
    for (const imageGeometry of mosaicImagesGeometries) {
      const checkGeometries = mosaicImagesGeometries.filter(
        item => JSON.stringify(item) !== JSON.stringify(imageGeometry)
      );
      checkGeometries.unshift(imageGeometry);
      isIntersects.push(checkIntersection(checkGeometries));
    }
  }
  return isIntersects.every(intersection => intersection);
};

export const isValidGeofenceIntersection = (
  featureCoordinates: Position[][],
  geofenceCoordinates: Position[][]
): boolean => {
  const featureGeometry = turf.polygon(featureCoordinates);
  const geofenceGeometry = turf.polygon(geofenceCoordinates);
  if (turf.area(featureGeometry) > turf.area(geofenceGeometry)) {
    return false;
  }
  const intersection = turf.difference(featureGeometry, geofenceGeometry);
  return intersection === null;
};

export const getCroppingPolygonIntersection = (interestsRegion: any, geometry: any) => {
  const formatWKT = new WKT();
  const formatGeoJSON = new GeoJSON();

  const croppingPolygonGeoJSON = interestsRegion
    ? formatGeoJSON.writeFeature(formatWKT.readFeature(interestsRegion))
    : interestsRegion;
  const geometryGeoJSON = formatGeoJSON.writeFeature(formatWKT.readFeature(geometry));

  const croppingPolygon = turf.getGeom(
    JSON.parse(croppingPolygonGeoJSON ? croppingPolygonGeoJSON : geometryGeoJSON)
  );
  const imageGeometry = turf.getGeom(JSON.parse(geometryGeoJSON));
  const intersection = turf.intersect(imageGeometry, croppingPolygon);
  return intersection;
};

export const getUnionGeometries = (geometries: any[]) => {
  const baseGeometry = turf.polygon(wktToGeoJSON(geometries[0]).coordinates);
  const result = geometries.slice(1).reduce((resultCroppingPolygon, geometry) => {
    resultCroppingPolygon = turf.union(
      resultCroppingPolygon,
      turf.polygon(wktToGeoJSON(geometry).coordinates)
    );
    return resultCroppingPolygon;
  }, baseGeometry);
  return result;
};
