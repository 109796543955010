import { t } from '@lingui/macro'; // Trans
import { I18n } from '@lingui/react';

import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from 'ducks';
// import { getIsAuthenticated } from 'ducks/auth';
import { getIsAuthenticated } from 'ducks/authIAM';
import { getIsMainPanelShow, mainPanelToggle } from 'ducks/map';
import { getIsFetching, getResults, searchObjects } from 'ducks/mapObjects'; // SearchResultItem,

import ExpandIcon from '@geobank/assets/src/icons/ExpandIcon';
import SearchInput from '@geobank/components/src/forms/SearchInput/SearchInput';
// import AuthButton from 'components/map/AuthButton/AuthButton';
import AuthButtonIAM from 'components/map/AuthButton/AuthButtonIAM';
// import Button from '@geobank/components/src/common/Button/Button';
import LangSelector from 'components/map/LangSelector/LangSelector';
import MainMenu from 'components/map/MainMenu/MainMenu';
import UserMenu from 'components/map/UserMenu/UserMenu';
// import LogoDemo from './LogoDemo';

const styles = require('./TransparentHeader-styles.module.scss');

// mapStateToProps: MapStateToPropsParam<...>, mapDispatchToProps
const mapStateToProps = (state: RootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  isFetching: getIsFetching(state),
  results: getResults(state),
  // searchInput
  isMainPanelShow: getIsMainPanelShow(state),
});
const mapDispatchToProps = {
  searchObjectsRequest: searchObjects.request,
  searchObjectsClear: searchObjects.clear,
  searchObjectsSelect: searchObjects.select,
  mainPanelToggle,
};

interface TransparentHeaderProps {
  /*  mapStateToProps
  isAuthenticated: boolean;
  isFetching: boolean;
  results: SearchResultItem[] | undefined; // { [key: string]: SearchResultItem[] | undefined };
  // searchInput */

  /* mapDispatchToProps
  searchObjectsRequest: typeof searchObjects.request;
  searchObjectsClear: typeof searchObjects.clear;
  searchObjectsSelect: typeof searchObjects.select; */

  // other props
  search?: boolean;
  // userMenu?: boolean; // см. UserMenu
}

const TransparentHeader: React.FC<
  TransparentHeaderProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    RouteComponentProps<{}>
> = props => {
  const { isAuthenticated, isFetching, results, search, isMainPanelShow } = props; // history

  const handleMainPanelToggle = () => {
    props.mainPanelToggle();
  };

  return (
    <div>
      {search === true && (
        <I18n>
          {({ i18n }) => (
            <div className={styles.mapLeftSection}>
              <SearchInput
                placeholder={i18n._(t`Название, адрес или координаты на карте`)}
                isLoading={isFetching}
                // results={results.gptl}
                results={results}
                onSearchChange={val => props.searchObjectsRequest(val)}
                onResultSelect={item => {
                  props.searchObjectsSelect(item, true);
                }}
                onClear={props.searchObjectsClear}
              />
              <div className={styles.closePanelWrap}>
                <span
                  title={
                    isMainPanelShow
                      ? i18n._(t`Закрыть основную панель`)
                      : i18n._(t`Открыть основную панель`)
                  }
                  className={classNames(styles.closePanelBtn, {
                    [styles.closePanelBtnShow]: isMainPanelShow,
                  })}
                  onClick={handleMainPanelToggle}
                >
                  <ExpandIcon />
                </span>
              </div>
            </div>
          )}
        </I18n>
      )}

      {/* <div className={styles.mapLogo}>
        <LogoDemo />
      </div> */}

      {isAuthenticated ? (
        // <div className={styles.mapMainGroupTest}>
        <div className={styles.mapMainGroup}>
          {/* <Button
            onClick={() => {
              // alert('multi request');
              history.push('/requests/new');
            }}
            height="45px"
            disabled
            title="в разработке ..."
          >
            <Trans>Мультирайонная заявка</Trans>
          </Button> */}
          <LangSelector className={styles.marginLeft} />
          <UserMenu className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      ) : (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          {/* <AuthButton className={styles.marginLeft} /> */}
          <AuthButtonIAM className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      )}
    </div>
  );
};

export default withRouter(
  connect(
    /* (state: RootState) => ({
      isAuthenticated: getIsAuthenticated(state),
      // searchInput
      isFetching: getIsFetching(state),
      results: getResults(state),
    }), */
    mapStateToProps,
    /* {
      // searchInput
      searchObjectsRequest: searchObjects.request,
      searchObjectsClear: searchObjects.clear,
      searchObjectsSelect: searchObjects.select,
    } */
    mapDispatchToProps
  )(TransparentHeader)
);
