// import { I18n } from '@lingui/react';

// import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import { RootState } from 'ducks';
import filesize from 'filesize';
import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

// import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import FileDownloadIcon from '@geobank/assets/src/icons/FileDownloadIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import { MessageDescriptor } from '@lingui/core';
// import { Select, SelectOption } from '@geobank/components/src/forms/Select';
// import TextInput from '@geobank/components/src/forms/TextInput/TextInput';
// import RadioGroup from 'components/common/forms/Radio/RadioGroup';
// import Radio from 'components/common/forms/Radio/Radio';
// import { RadioChangeEvent } from 'components/common/forms/Radio/interface';
import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import { BASE_URL, S3_URL } from 'api/realAPI';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { STACItem } from 'ducks/types/stacItemsTypes';
import { isEmpty } from 'lodash';

import { getSelectedImageLayerId } from 'ducks/mapLayers';
import styles from './SettingsPanel.module.scss';

interface ImageInfo {
  name: MessageDescriptor;
  info: MessageDescriptor;
  icon: string;
}

interface PlatformInfo {
  name: MessageDescriptor;
  bt?: MessageDescriptor;
  dn?: MessageDescriptor;
}

interface Images {
  [key: string]: ImageInfo;
}

interface Platforms {
  [key: string]: PlatformInfo;
}

const platformMapping: Platforms = {
  ARCM1: {
    name: t`Арктика-М №1`,
  },
  EL2: {
    name: t`Электро-Л №2`,
  },
  EL3: {
    name: t`Электро-Л №3`,
  },
  EL4: {
    name: t`Электро-Л №4`,
  },
  A2E234: {
    name: t`Мозаика МСУ-ГС`,
  },
  'MM2-2': {
    name: t`Мозаика МСУ-МР`,
  },
  EL_ARCM: {
    name: t`Мозаика МСУ-ГС`,
    bt: t`РТ`,
    dn: t`ДН`,
  },
};

const imageInfoMapping: Images = {
  'image.tif.ms.3857._': {
    name: t`с координатной привязкой`,
    info: t`Пригоден для использования в геоинформационных приложениях, но охватывает только территории ниже широты 85 градусов`,
    icon: `${BASE_URL}/images/icons/pm_icon.png`,
  },
  'image.tif.ms.full_disk._': {
    name: t`без координатной привязки`,
    info: t`Пригоден только для просмотра, но охват территорий не ограничен`,
    icon: `${BASE_URL}/images/icons/disk_icon.png`,
  },
  'image.tif.bt.3857._': {
    name: t`с координатной привязкой`,
    info: t`Пригоден для использования в геоинформационных приложениях, но охватывает только территории ниже широты 85 градусов`,
    icon: `${BASE_URL}/images/icons/pm_icon.png`,
  },
};

const localeSizeSymbols = { B: 'Б', GB: 'ГБ', Gb: 'Гб', MB: 'МБ', Mb: 'Мб' };

interface SettingsPanelProps {
  activeItem: STACItem;
  // delay: number | undefined;
  // direction: string | undefined;
  // isPlaying: boolean | undefined;
  // step: number | undefined;
  // onDirectionChange: (event: RadioChangeEvent) => void;
  // onIncreaseDelay: () => void;
  // onDecreaseDelay: () => void;
  // onStepChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SettingsPanel: React.FC<SettingsPanelProps & withI18nProps> = props => {
  const {
    activeItem,
    //   delay,
    //   direction,
    //   step,
    i18n,
    //   onDirectionChange,
    //   onIncreaseDelay,
    //   onDecreaseDelay,
    //   onStepChange,
  } = props;
  // const [showDropdown, setShowDropdown] = useState<boolean>(true);
  // const handleClose = () => {

  // };
  const currentLocale: string = useSelector((state: RootState) => state.locale.language);
  const selectedImageLayerId = useSelector((state: RootState) => getSelectedImageLayerId(state));
  return (
    <Dropdown dropup={true} pullRight={true}>
      <Dropdown.Toggle>
        <Tooltip
          tagName="span"
          styles={{ marginLeft: '4px' }}
          tipContentClassName="tip-content--border-grey"
          arrow={false}
          background="white"
          color="black"
          direction="up"
          // distance={160}
          content={
            <div>
              <Trans>Загрузить снимки</Trans>
            </div>
          }
        >
          <FileDownloadIcon size={36} />
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: '290px' }}>
        <div className={styles.settingsContainer}>
          <div className={styles.itemTitle}>
            <a
              href={`${S3_URL}/geoportal-public/user-guide/v0.1/index.html#%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D1%81-%D0%BC%D0%BE%D0%B7%D0%B0%D0%B8%D1%87%D0%BD%D1%8B%D0%BC%D0%B8-%D0%BF%D0%BE%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D1%8F%D0%BC%D0%B8-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D1%81%D0%BD%D0%B8%D0%BC%D0%BA%D0%B0%D0%BC%D0%B8-%D0%BA%D0%B0-%D0%B0%D1%80%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D0%BC`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>О мозаике</Trans>
            </a>
          </div>
          <Dropdown.MenuDivider />
          <div className={styles.itemTitle}>
            <Trans>Скачать снимок:</Trans>
          </div>
          {!isEmpty(activeItem) &&
            Object.values(activeItem).map(image =>
              image
                .sort((a, b) => a.properties.platform.localeCompare(b.properties.platform))
                .map(item => (
                  <div key={item.properties.platform} className={styles.content}>
                    <div>
                      {i18n._(platformMapping[item.properties.platform].name)}
                      <Tooltip
                        tagName="span"
                        styles={{ marginLeft: '4px' }}
                        tipContentClassName="tip-content--border-grey"
                        arrow={false}
                        background="white"
                        color="black"
                        direction="up"
                        // distance={160}
                        content={
                          <div>
                            {selectedImageLayerId === 'btMSUGS' && (
                              <Trans>Радиационная температура</Trans>
                            )}
                            {selectedImageLayerId === 'globalMSUGS' && <Trans>День-ночь</Trans>}
                          </div>
                        }
                      >
                        {selectedImageLayerId === 'btMSUGS' &&
                          `\u00A0(${i18n._(platformMapping.EL_ARCM.bt!)})`}
                        {selectedImageLayerId === 'globalMSUGS' &&
                          `\u00A0(${i18n._(platformMapping.EL_ARCM.dn!)})`}
                      </Tooltip>
                    </div>
                    {Object.entries(item.assets)
                      .filter(asset =>
                        selectedImageLayerId === 'btMSUGS'
                          ? ['image.tif.bt.3857._'].includes(asset[0])
                          : ['image.tif.ms.3857._', 'image.tif.ms.full_disk._'].includes(asset[0])
                      )
                      .map(([key, value]) => (
                        <div key={key} className={styles.link}>
                          <div>
                            <img
                              className={styles.icon}
                              src={imageInfoMapping[key].icon}
                              alt={imageInfoMapping[key].name.id}
                            />
                          </div>
                          <div>
                            <a href={value.href} rel="noopener noreferrer">
                              {i18n._(imageInfoMapping[key].name.id)}
                              <br />
                              {value['file:size']
                                ? `( ${filesize(value['file:size'], {
                                    locale: currentLocale,
                                    symbols: currentLocale === 'ru' ? localeSizeSymbols : {},
                                  })} )`
                                : null}
                            </a>
                          </div>
                          <div style={{ marginLeft: 'auto', paddingRight: '5px' }}>
                            <Tooltip
                              tagName="span"
                              tipContentClassName="tip-content--border-grey"
                              arrow={false}
                              background="white"
                              color="black"
                              direction="left"
                              distance={260}
                              content={
                                <div style={{ width: '250px' }}>
                                  {i18n._(imageInfoMapping[key].info.id)}
                                </div>
                              }
                            >
                              <HelpIcon />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                  </div>
                ))
            )}
          {/* <Dropdown.MenuDivider />
            <div className={styles.settingsTitle} style={{ color: '#000' }}>
              <Trans>Динамический показ:</Trans>
            </div>
            <div className={styles.settingsTitle} style={{ marginLeft: '5px' }}>
              <Trans>Течение времени:</Trans>
              <Tooltip
                tagName="span"
                styles={{ marginLeft: '4px' }}
                tipContentClassName="tip-content--border-grey"
                arrow={false}
                background="white"
                color="black"
                direction="left"
                distance={160}
                content={
                  <div style={{ width: '250px' }}>
                    <Trans>
                      Определяет направление течения времени от выбранной даты, при нажатии на
                    </Trans>
                    <PlayCircleOutlineIcon style={{ color: '#5dbdf9', fontSize: 'medium' }} />
                    <Trans>(покрытие меняется от новых данных к старым и наоборот)</Trans>
                  </div>
                }
              >
                <HelpIcon />
              </Tooltip>
            </div>
            <div className={styles.content} style={{ marginLeft: '5px' }}>
              <RadioGroup name="directionPlay" value={direction} onChange={onDirectionChange}>
                <Radio value="backward">
                  <Trans>Обратное</Trans>
                </Radio>
                <Radio value="forward">
                  <Trans>Прямое</Trans>
                </Radio>
              </RadioGroup>
            </div>
            <Dropdown.MenuDivider />
            <div style={{ paddingLeft: '10px' }}>
              <div className={styles.settingsTitle}>
                <Trans>Время показа кадра:</Trans>
              </div>
              <div className={styles.settingsTitle}>
                <div className={styles.content} style={{ width: '60px' }}>
                  <TextInput value={String(delay ? delay / 1000 : delay)} />
                </div>
                <div className={styles.content}>
                  <Button
                    onClick={onDecreaseDelay}
                    color={ButtonColor.TRANSPARENT}
                    width="36px"
                    height="18px"
                  >
                    <ArrowDropUpIcon style={{ color: '#5dbdf9' }} />
                  </Button>
                  <Button
                    onClick={onIncreaseDelay}
                    color={ButtonColor.TRANSPARENT}
                    width="36px"
                    height="18px"
                  >
                    <ArrowDropDownIcon style={{ color: '#5dbdf9' }} />
                  </Button>
                </div>
                <div style={{ marginBottom: '5px', color: '#000' }}>
                  <Trans>сек.</Trans>
                </div>
              </div>
              <Dropdown.MenuDivider />
              <div className={styles.settingsTitle}>
                <Trans>Период съемки:</Trans>
              </div>
              <div className={styles.content} style={{ marginRight: '60px' }}>
                <Select
                  // name={name}
                  value={step}
                  onChange={onStepChange}
                >
                  <SelectOption value="1">{i18n._(t`15 минут`)}</SelectOption>
                  <SelectOption value="2">{i18n._(t`30 минут`)}</SelectOption>
                  <SelectOption value="4">{i18n._(t`1 час`)}</SelectOption>
                  <SelectOption value="96">{i18n._(t`1 день`)}</SelectOption>
                  <SelectOption value="288">{i18n._(t`3 дня`)}</SelectOption>
                  <SelectOption value="2880">{i18n._(t`1 месяц`)}</SelectOption>
                </Select>
              </div>
            </div> */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withI18n()(SettingsPanel);
