import { t } from '@lingui/macro'; // Trans
import { withI18n, withI18nProps } from '@lingui/react';
import React from 'react';

import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';
import FolderDownloadIcon from '@geobank/assets/src/icons/FolderDownloadIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import { bytesToHumanReadableSizeSplited } from 'components/utils/format';
import { byteSizes } from 'locales/dicts/measures';

import styles from './ProductDownloadItem.module.scss';

interface ProductDownloadItemProps {
  className?: string;
  title: string;
  hint?: string;
  url: string;
  size: number;
  removed: boolean;
  //
  getPresignUrl: (url: string, cb: (data: { url: string }) => void) => void;
  copyToClipboard: (text: string) => void;
}

const ProductDownloadItem: React.FC<ProductDownloadItemProps & withI18nProps> = props => {
  // const loadProgress = 0;
  const {
    className,
    title,
    hint,
    url,
    size,
    removed,
    getPresignUrl,
    copyToClipboard,
    i18n,
  } = props;

  const getDownloadUrl = (endpointUrl: string) => {
    return new Promise<string>((resolve, reject) => {
      if (!endpointUrl) {
        reject(new Error('Param `endpointUrl` is empty'));
        return;
      }

      getPresignUrl(endpointUrl.replace('&download=true', ''), data => {
        const presignUrl = data.url;
        if (presignUrl) {
          resolve(presignUrl);
        } else {
          reject(new Error('Error on fetch presign url'));
        }
      });
    });
  };

  const openLink = (downloadUrl: string, blank = false) => {
    // TODO - test browser block popup window
    // window.open(data.url, blank === true ? '_blank' : '_top');
    window.open(downloadUrl, '_top'); // works in firefox, chrome
    // const win = window.open(data.url, '_blank');
    // win?.focus();
  };

  const handleDownloadClick = () => {
    const endpointUrl = url;
    getDownloadUrl(endpointUrl)
      .then(downloadUrl => openLink(downloadUrl))
      .catch(err => {
        console.error('Error', err);
      });
  };

  const handleCopyToClipboard = () => {
    const endpointUrl = url;
    getDownloadUrl(endpointUrl)
      .then(downloadUrl => copyToClipboard(downloadUrl))
      .catch(err => {
        console.error('Error', err);
      });
  };

  const renderSize = (sizeInBytes: number) => {
    const [value, measure] = bytesToHumanReadableSizeSplited(sizeInBytes); // bytesToHumanReadableSize
    return `${value} ${i18n._(byteSizes[measure])}`;
  };

  return (
    <div className={className}>
      <div className={styles.itemContainer}>
        <div className={styles.fileName} title={`${hint ? hint : title}`}>
          <span>{title}</span>
        </div>
        <div className={styles.actions}>
          {/* <div>
            <span style={{ color: '#8997B4' }}>{`${loadProgress}%`}</span>
          </div> */}
          {removed && (
            <Button
              color={ButtonColor.TRANSPARENT}
              className={styles.itemHeadActionButton}
              title={i18n._(t`Скопировать в буфер обмена`)}
              onClick={handleCopyToClipboard}
            >
              <DuplicateIcon fill="currentColor" />
            </Button>
          )}
          {/* <div
            className={styles.actionBtn}
            title={i18n._(t`Скопировать в буфер обмена`)}
            onClick={handleCopyToClipboard.bind(null, url)}
          >
            <DuplicateIcon />
          </div> */}
          {removed && (
            <Button
              color={ButtonColor.TRANSPARENT}
              className={styles.itemHeadActionButton}
              // eslint-disable-next-line
              title={i18n._(t`Скачать`) + '\n' + '(' + renderSize(size) + ')'}
              onClick={handleDownloadClick}
            >
              <FolderDownloadIcon fill="currentColor" />
            </Button>
          )}
          {/* <div className={styles.actionBtn} onClick={handleDownloadClick.bind(null, url)}>
            <a
              href="#download"
              // href={url}
              // target="_blank"
              // rel="noopener noreferrer"
              title={i18n._(t`Скачать\\n(${bytesToHumanReadableSize(size)})`)}
            >
              <FolderDownloadIcon />
            </a>
          </div> */}

          {/* <div onDoubleClick={() => getDownloadUrl(url, true)} style={{ cursor: 'pointer' }}>
            <a href="#download" title={`Скачать\n(${bytesToHumanReadableSize(size)})`}>
              <FolderDownloadIcon />
            </a>
          </div> */}
        </div>
      </div>
      <div className={styles.loaderContainer}>
        <div
          style={{
            // width: `${this.state.loadProgress}%`,
            width: 0,
            height: 2, // 3
            backgroundColor: '#5AB8F1',
          }}
        />
      </div>
    </div>
  );
};

export default withI18n()(ProductDownloadItem);
