import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro'; // Trans
import { withI18nProps, withI18n } from '@lingui/react';

import { CartImage, getCurrentCartParams } from 'ducks/order';
import { decreeProductMappingVariants } from 'locales/dicts/products';

interface ProductWithHintProps {
  item: CartImage;
}

const ProductWithHint: React.FC<ProductWithHintProps & withI18nProps> = props => {
  const { item, i18n } = props;
  const params = useSelector(getCurrentCartParams);

  const bandCombinationText = item.bandCombination.toUpperCase();
  const bandsText = item.bands && item.bands.length > 0 ? `[${item.bands.length}]` : undefined;

  const getTitle = useCallback(
    (options: {
      productCode: string;
      bandCombination: string;
      elevation: boolean;
      bands?: string[];
    }) => {
      // return 'dasdsad';
      const p = decreeProductMappingVariants.find(prVar => {
        return (
          prVar.params.productCode === options.productCode &&
          prVar.params.bandCombination === options.bandCombination &&
          (prVar.params.elevation === null || prVar.params.elevation === options.elevation)
        );
      });
      let result = p ? `${i18n._(p.text)}` : '-';

      if (options.bands !== undefined) {
        result += `\n ${i18n._(t('_.bands_count')`Каналов`)}: ${options.bands.length}`;
      }

      return result;
    },
    [i18n]
  );

  return (
    <span
      title={getTitle({
        productCode: params.productCode,
        bandCombination: item.bandCombination,
        elevation: params.elevation,
        bands: item.bands,
      })}
    >
      {bandCombinationText}
      {bandsText && ` ${bandsText}`}
    </span>
  );
};

export default withI18n()(ProductWithHint);
