import React from 'react';
// import classNames from 'classnames';
// import { css } from 'astroturf';
import { CheckBoxGroupContextProvider } from './context';
import { CheckBoxChangeEvent } from './interface';

// interface CheckBox {
//   value: string;
//   label: string | JSX.Element;
//   // defaultChecked?: boolean;
//   disabled?: boolean;
// }

interface CheckBoxGroupProps {
  className?: string;
  name: string; // goup name
  value: { [value: string]: boolean }; // checked values - string[]
  // items: CheckBoxGroup[]; // checkBoxes --> children
  // onChange?: (groupName: string, value: string) => void;
  // onChange?: (value: string, checked: boolean) => void;
  children: React.ReactNode;
  onChange: (event: CheckBoxChangeEvent) => void;
  disabled?: boolean;
}

// TODO - save checkboxes in the state?
const CheckBoxGroup: React.FC<CheckBoxGroupProps> = props => {
  const { className, name: groupName, value, onChange: onCheckBoxChange } = props;
  const childrenToRender = props.children;

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (onChange !== undefined) {
  //     onChange(groupName, event.target.value);
  //   }
  // };

  // const handleChange = (value2: string, checked: boolean) => {
  //   // console.log(name, value2);
  //   if (onChange !== undefined) {
  //     onChange(value2, checked);
  //   }
  // };

  return (
    <div className={className}>
      <CheckBoxGroupContextProvider
        value={{
          onChange: onCheckBoxChange,
          value: value,
          disabled: props.disabled,
          name: groupName,
        }}
      >
        {childrenToRender}
      </CheckBoxGroupContextProvider>
    </div>
  );

  // return (
  //   <>
  //     {items.map((checkbox, index) => (
  //       <div
  //         key={checkbox.value}
  //         className={classNames(styles.root, { [styles.disabled]: checkbox.disabled })}
  //       >
  //         <Checkbox
  //           // key={checkbox.id}
  //           name={checkbox.value}
  //           label={checkbox.label}
  //           colorLabel="black"
  //           onChange={handleChange}
  //           checked={value.indexOf(checkbox.value) > -1}
  //         />
  //       </div>
  //     ))}
  //   </>
  // );
};

export default React.memo(CheckBoxGroup);
