import React from 'react';
import { useDispatch } from 'react-redux';

import { ImageMetadata } from 'ducks/metadata';
import { fetchImagesQuotation, updateCurrentCartImages } from 'ducks/order';
import * as orderTypes from 'ducks/types/orderTypes';
import OrderImageForm from './sections/SearchResult/OrderImage/OrderImageForm';

interface CustomOrderImageFormProps {
  orderImage: ImageMetadata;
  onCancel: () => void; // onBack()
}

const CustomOrderImageForm: React.FC<CustomOrderImageFormProps> = props => {
  const { orderImage, onCancel } = props;
  const dispatch = useDispatch();

  const handleAddToCart = (cartImages: orderTypes.CartImage[]) => {
    dispatch(updateCurrentCartImages.request(cartImages, 'add'));
    onCancel();
  };

  const handleCancelOrderImage = () => {
    onCancel();
  };

  const fetchImagesQuotationRequest = (params: any) => {
    dispatch(fetchImagesQuotation.request(params));
  };

  return (
    <OrderImageForm
      // ref={this.orderImageFormRef}
      orderImages={orderImage}
      regionsOfInterest={[]}
      onCancel={handleCancelOrderImage}
      onAddToCart={handleAddToCart}
      getPrice={fetchImagesQuotationRequest}
    />
  );
};

export default CustomOrderImageForm;
