import { all } from 'redux-saga/effects'; // fork

import metadataSaga from './metadata';
import mapLayersSaga from './mapLayers';
import mapSaga from './map';
import classifierSaga from './classifier';
// import authSaga from './auth';
import iamSaga from './authIAM';
import localeSaga from './locale';
import favoritesSaga from './favorites';
import mapObjectsSaga from './mapObjects';
import orderSaga from './order';
import orderProductSaga from './orderProduct';
import notifySaga from './messageNotify'; // notifierSaga
import metadataDownloadSata from './metadataDownload';
import simpleRequestSaga from './simpleRequest';
import requestSaga from './request';
import stacItemsSaga from './stacItems';

// TODO - all vs fork?!
export function* rootSaga() {
  yield all([
    metadataSaga(),
    mapLayersSaga(),
    mapSaga(),
    stacItemsSaga(),
    classifierSaga(),
    // authSaga(),
    iamSaga(),
    localeSaga(),
    favoritesSaga(),
    mapObjectsSaga(),
    orderSaga(),
    orderProductSaga(),
    notifySaga(),
    metadataDownloadSata(),
    simpleRequestSaga(),
    requestSaga(),
  ]);
  // yield all([mapLayersSaga()]); // размещать нужно, как сделано выше (в одном массиве)
  // yield fork(metadataSaga); // ok
}
