export const resolutionToInstruments = {
  // not sorted: -
  low: [], // Сверхнизкое;
  medium_low: ['MSUIKSRM'], // Низкое; MSUMR (пока недоступен для заказа), был Метеор 'MSUTM101,MSUTM102' (+ 'MSU101,MSU102')
  medium: ['SVR', 'SSR', 'GSA', 'OLITIRS', 'MSI', 'MSUTM101,MSUTM102'], // Среднее; KV MSS, иностранцы (их надо специально выбирать!)
  high: ['PSS,MSS', 'AVR'], // Высокое; KV PSS, GTNL1 МСС
  detailed: ['GTNL1', 'GTN'], // Сверхвысокое; GTNL1 ПСС
};

// resolutionToInstrumentsForNewSurvey
