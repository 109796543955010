import React from 'react';

import { Plural, Trans } from '@lingui/macro';
import differenceInDays from 'date-fns/differenceInDays';

import { formatDate } from 'components/utils/date';
import classNames from 'classnames';

const styles = require('./ProductRemoveInfo-styles.module.scss');

interface ProductRemoveInfoProps {
  removeDate: Date;
}

const ProductRemoveInfo: React.FC<ProductRemoveInfoProps> = props => {
  const { removeDate } = props;
  const diff = differenceInDays(removeDate, new Date());
  const removeDateFmt = formatDate(removeDate);
  const removeTimeFmt = formatDate(removeDate, 'HH:mm:ss');

  let content;
  if (diff < 0) {
    content = (
      <span className={styles.alert} title={removeDateFmt}>
        <Trans>Продукция уже удалена</Trans>
      </span>
    );
  } else if (diff === 0) {
    content = (
      <span className={styles.alert} title={removeDateFmt}>
        <Trans>Удаление сегодня в {removeTimeFmt}</Trans>
      </span>
    );
  } else {
    // TODO - почему-то вложенный <Plural> не сработал
    content = (
      <span className={classNames({ [styles.alert]: diff < 5 })} title={removeDateFmt}>
        <Trans>До удаления осталось {diff}</Trans>{' '}
        <Plural value={diff} one="день" few="дня" many="дней" other="дней" />
      </span>
    );
  }

  return content;
};

export default ProductRemoveInfo;
