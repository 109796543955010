const mockRequestData = [
  {
    label: 'Новая заявка 1',
    id: 'B00100112',
    status: 'Выполняется съемка',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 2',
    id: 'B00550112',
    status: 'Выполняется обработка',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 3',
    id: 'B0061122',
    status: 'Не отправлена',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 4',
    id: 'BGDV1122',
    status: 'Отправлена',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 5',
    id: 'B0757T22',
    status: 'Отклонена',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 6',
    id: 'B00HFB22',
    status: 'Ожидает оплаты',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
  {
    label: 'Новая заявка 7',
    id: 'B786OL22',
    status: 'Завершена',
    dateStart: 'mock',
    dateEnd: 'mock',
    actExtradition: '-',
    comment: 'mock',
  },
];

export default mockRequestData;
