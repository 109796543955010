import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import CheckBoxGroupContext from './context';
import { CheckBoxChangeEvent } from './interface';

import styles from './CheckBox.module.scss';

export const CHECKED = 1;
export const UNCHECKED = 2;
export const INDETERMINATE = -1;

export type CheckedValue = typeof CHECKED | typeof UNCHECKED | typeof INDETERMINATE | boolean;

// checked == value
const checkedToFlag = (value?: CheckedValue) => {
  if (value === undefined) {
    return undefined;
  }

  return typeof value === 'boolean' ? (value === true ? CHECKED : UNCHECKED) : value;
};

export interface CheckBoxProps {
  // wrap/containerProps:
  className?: string; // containerClassName
  // innerProps:
  // name?: string;
  value: string; // aka name
  // TODO - checked поле конфиктует с типом отличным от boolean;
  //   как вариант - вырезать этот prop из типов и атрибутов input
  // value: typeof CHECKED | typeof UNCHECKED | typeof INDETERMINATE;
  // checked?: boolean; // default attr (HTMLInputElement)
  checked?: CheckedValue;
  disabled?: boolean;
  multiline?: boolean;
  // buttonColor?: string;
  // children?: React.ReactNode;
  // onChange?: (name: string, value: boolean) => void;
}

// TODO - styling, classes ...
// CheckBoxButton
const CheckBox: React.FC<
  CheckBoxProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'checked'>
> = props => {
  const checkRef = useRef<HTMLInputElement>(null);
  const { children, className, checked, title, multiline, ...restProps } = props; // ,value
  let chekedFlag = checkedToFlag(checked); // chekedFlag, checkedValue
  const context = React.useContext(CheckBoxGroupContext);

  // useEffect() - был тут

  /* const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value2: boolean = get(event, 'target.checked');
    if (onChange !== undefined) {
      onChange(name, value2);
    }
  }; */
  const onChange = (e: CheckBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange(e);
    }

    if (context?.onChange) {
      context.onChange(e);
    }
  };

  const checkBoxProps: React.InputHTMLAttributes<HTMLInputElement> = { ...restProps }; // ,value
  if (context) {
    checkBoxProps.name = context.name;
    checkBoxProps.onChange = onChange;
    // checkBoxProps.checked = props.value === context.value;
    // checkBoxProps.checked = context.value[props.value] === true; // checkboxes
    // checkBoxProps.value = context.value[props.name];
    // const _value = context.value[props.value]; // chekedFlag = context.value[props.value];
    chekedFlag = checkedToFlag(context.value[props.value]);
    checkBoxProps.disabled = props.disabled || context.disabled;
  }

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.checked = chekedFlag === CHECKED;
      checkRef.current.indeterminate = chekedFlag === INDETERMINATE;
      // checkRef.current.value = '';
      // console.log(checkRef.current.checked, checkRef.current.value, checkRef.current.indeterminate);
    }
  }, [checkRef, chekedFlag]); // value -> checkBoxProps.value -> checked =))

  return (
    <label
      className={classNames(
        styles.root,
        { [styles.disabled]: checkBoxProps.disabled },
        { [styles.multilineLabel]: multiline },
        className,
        {
          disabled: checkBoxProps.disabled,
        }
      )}
    >
      <span className={styles.checkMark} title={title}>
        <input ref={checkRef} className={styles.inputCheckBox} type="checkbox" {...checkBoxProps} />
        {/* {checkBoxProps.checked && <span className={styles.checkMarkChecked} />} */}
        {/* {checkBoxProps.value === CHECKED && <span className={styles.checkMarkChecked} />} */}
        {chekedFlag === CHECKED && <span className={styles.checkMarkChecked} />}
        {chekedFlag === INDETERMINATE && <span className={styles.checkMarkIndeterminate} />}
      </span>
      {children !== undefined ? <span className={styles.labelBlock}>{children}</span> : null}
    </label>
  );
};

export default React.memo(CheckBox);
