import { DEVICE_PIXEL_RATIO } from 'ol/has';

export const getCanvasPatternV1 = () => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;

  // Gradient and pattern are in canvas pixel space, so we adjust for the
  // renderer's pixel ratio
  const pixelRatio = DEVICE_PIXEL_RATIO;

  // Generate a canvasPattern with two circles on white background
  // const pattern = (function () {
  canvas.width = 8 * pixelRatio;
  canvas.height = 8 * pixelRatio;
  // white background
  context.fillStyle = 'white';
  context.fillRect(0, 0, canvas.width, canvas.height);
  // outer circle
  context.fillStyle = 'rgba(0, 9, 140, 0.5)'; // 'rgba(102, 0, 102, 0.5)';
  context.beginPath();
  context.arc(4 * pixelRatio, 4 * pixelRatio, 3 * pixelRatio, 0, 2 * Math.PI);
  context.fill();
  // inner circle
  context.fillStyle = 'rgb(20, 48, 106)'; // 'rgb(55, 0, 170)';
  context.beginPath();
  context.arc(4 * pixelRatio, 4 * pixelRatio, 1.5 * pixelRatio, 0, 2 * Math.PI);
  context.fill();
  return context.createPattern(canvas, 'repeat')!;
  // })();
};
