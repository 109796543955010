import { IFinishedProductsValue } from './FinishedProducts';

const mockFinishedProducts: IFinishedProductsValue[] = [
  { name: 'mockName_1', link: 'link' },
  { name: 'mockName_2', link: 'link' },
  { name: 'mockName_3', link: 'link' },
  { name: 'mockName_4', link: 'link' },
  { name: 'mockName_5', link: 'link' },
  { name: 'mockName_6', link: 'link' },
  { name: 'mockName_7', link: 'link' },
];

export default mockFinishedProducts;
