import { Trans } from '@lingui/macro'; // t
import React, { useCallback } from 'react';
// import { I18n } from '@lingui/react';

import { formatDate } from 'components/utils/date';
import { formatValue } from 'components/utils/format';
import { ImageMetadata, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { instruments, platforms, platformTypes } from 'locales/dicts/classifiers';
import { renderResolution, renderSensorBands } from './helpers';

import styles from './ResultDetail.module.scss';

interface ResultDetailProps {
  metadata?: ImageMetadataDetail;
  actions?: {
    // handleAccordionToggle: (mdId: number, flag: boolean) => void;
    handleAccordionToggle: (md: ImageMetadata, flag: boolean) => void;
  };
}

const ResultDetail: React.FC<ResultDetailProps> = props => {
  const { metadata: md, actions } = props;

  // TODO - rename to 'preview' ?
  // const handleSingleImgClick = () => {
  //   // alert('img click');
  //   // this.getOlMap().getView().fit(feature.getGeometry().getExtent(), { maxZoom: 10 });
  // };

  // const handleGroupImgClick = (event: React.MouseEvent<HTMLInputElement>) => {
  //   // event.preventDefault();
  //   event.stopPropagation();
  //   // alert('img group click');
  //   // const el = event.target;
  //   // console.log(el);
  //   // console.log(event);
  // };

  const handleAccordionToggle = useCallback(() => {
    if (md !== undefined) {
      actions?.handleAccordionToggle(md, false);
    }
  }, [actions, md]);

  if (md === undefined) {
    return null;
  }

  return (
    // detailed data table
    <div className={styles.root}>
      <div className={styles.dataListItem}>
        <div className={styles.dataTitle}>
          <Trans>Дата съёмки</Trans>
        </div>
        <div className={styles.dataValue}>
          {formatDate(md.acquisitionDateBegin || md.acquisitionDateInstant)}
        </div>
      </div>
      <div className={styles.dataListItem}>
        <div className={styles.dataTitle}>
          <Trans>Космический аппарат</Trans>
        </div>
        {/* <div className={styles.dataValue}>{md.platform} (TODO - КА)</div> */}
        <div className={styles.dataValue}>
          {/* {md.platform} ({platformsById[md.platformId].platformTypeIdentifier}) */}
          {/* md.platformName */}
          <Trans id={platforms[md.platformIdentifier]} /> (
          {/* md.platformTypeName | get(platformsById, [md.platformId, 'platformTypeIdentifier']) */}
          <Trans id={platformTypes[md.platformTypeIdentifier]} />)
        </div>
      </div>
      <div className={styles.dataListItem}>
        <div className={styles.dataTitle}>
          <Trans>Съёмочное устройство</Trans>
        </div>
        {/* <div className={styles.dataValue}>TODO - СУ</div> */}
        <div className={styles.dataValue}>
          {/* TODO - при наведении выводить информацию по сенсорам */}
          {/* {md.sensors.slice(0, 1).map(sensor => (
            <span key={sensor.instrumentId}>{sensor.instrument}</span>
          ))} */}
          {/* md.instrumentName | get(item, ['sensors', '0', 'instrument'])*/}
          <Trans id={instruments[md.instrumentIdentifier]} />
        </div>
      </div>
      {(md.cloudiness || md.cloudiness === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Облачность</Trans>
          </div>
          <div className={styles.dataValue}>{formatValue(md.cloudiness, '%')}</div>
        </div>
      )}
      {(md.resolution || md.resolution === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Разрешение_</Trans>
          </div>
          <div className={styles.dataValue}>
            {/* {formatValue(md.resolution, 'м')} */}
            {/* <I18n>{({ i18n }) => formatValue(md.resolution, i18n._(t`м`))}</I18n> */}
            {renderResolution(md.resolution)}
          </div>
        </div>
      )}
      {/* --- detail --- */}
      {(md.circuitNumber || md.circuitNumber === 0) && (md.scanNumber || md.scanNumber === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Номер витка / Включение</Trans>
          </div>
          <div className={styles.dataValue}>
            {md.circuitNumber} / {md.scanNumber}
          </div>
        </div>
      )}
      {md.processingLevelCode && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Уровень обработки</Trans>
          </div>
          <div className={styles.dataValue}>{md.processingLevelCode}</div>
        </div>
      )}
      {(md.illuminationElevationAngle || md.illuminationElevationAngle === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Высота Солнца</Trans>
          </div>
          <div className={styles.dataValue}>{formatValue(md.illuminationElevationAngle, '°')}</div>
        </div>
      )}
      {(md.illuminationAzimuthAngle || md.illuminationAzimuthAngle === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Азимут Солнца</Trans>
          </div>
          <div className={styles.dataValue}>{formatValue(md.illuminationAzimuthAngle, '°')}</div>
        </div>
      )}
      {(md.nadirTiltAngle || md.nadirTiltAngle === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Угол визирования</Trans>
          </div>
          <div className={styles.dataValue}>{formatValue(md.nadirTiltAngle, '°')}</div>
        </div>
      )}
      {(md.azimuthScanAngle || md.azimuthScanAngle === 0) && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Азимут визирования</Trans>
          </div>
          <div className={styles.dataValue}>{formatValue(md.azimuthScanAngle, '°')}</div>
        </div>
      )}
      <div className={styles.dataListItem}>
        <div className={styles.dataTitle}>
          <Trans>Режим съёмки</Trans>
        </div>
        <div className={styles.dataValue}>
          {/* {md.sensors.map(item2 => `${item2.instrumentIdentifier}, `)} */}
          {/* <div>
            МСС
            <br />
            &nbsp;&nbsp;&nbsp;0.51-0.6 мкм (Зелёный), 0.63-0.69 мкм (Красный),
            0.75-0.84 мкм (Ближний ИК)
          </div> */}
          {/* TODO - между сенсорами нет разделителя.
              Думаю, что надо переделать вывод на более дружелюбное (а в hint запихнуть bands) */}
          {/* TODO 2 - может всю форму обернуть в <I18n>, кажется это оптимальнее по вложенности компонетов React */}
          {md.sensors.length > 0 ? renderSensorBands(md.sensors) : '-'}
          {/* <I18n>
            {({ i18n }) => (
              <>
                {md.sensors.map((sensor, index) => (
                  <React.Fragment key={sensor.id}>
                    {index > 0 && '; '}
                    {sensor.bands
                      .map(band => `${band.min}-${band.max} ${i18n._(t`мкм`)}`)
                      .join(', ')}
                    {/* ('; ') * /}
                  </React.Fragment>
                ))}
              </>
            )}
          </I18n> */}
        </div>
      </div>
      {md.respPartyDataStorage.length > 0 && md.respPartyDataStorage[0]?.url && (
        <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>
            <Trans>Пункт хранения</Trans>
          </div>
          <div className={styles.dataValue}>
            {/* TODO - render array (first) */}
            <a
              className={styles.link}
              href={md.respPartyDataStorage[0]?.url}
              target="_blank"
              rel="noopener noreferrer"
              title={md.respPartyDataStorage[0]?.organizationName}
            >
              <Trans>Ссылка</Trans>
            </a>
          </div>
        </div>
      )}
      {/* <div className={styles.dataListItem}>
          <div className={styles.dataTitle}>Описание</div>
          <div className={styles.dataValue}>{md.abstract}</div>
        </div> */}
      {/* --- /detail --- */}

      <div>
        {/* --- /\ --- ; закрыть */}
        <div className={styles.collapseBtn} onClick={handleAccordionToggle}>
          <Trans>свернуть</Trans>
        </div>
      </div>
      {/* Отключено отображение превью (возможно понадобится в будущем) */}
      {/* <div className={styles.dataListItem} style={{ justifyContent: 'start', marginTop: '4px' }}>
        {md.previews.slice(0, 4).map((preview, pIndex) => (
          <div key={pIndex} className={styles.imgContainer} onClick={handleSingleImgClick}>
            {pIndex === 3 && md.previews.length > 4 && (
              <div className={styles.showMoreImgStub} onClick={handleGroupImgClick}>
                +{md.previews.length - 4}
              </div>
            )}
            <img alt="preview" className={styles.img} src={preview.url} />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ResultDetail;
