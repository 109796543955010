import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

// import { createSelector } from 'reselect';
// import get from 'lodash/get';

import { RootState as RootStateType } from './root';
// import { Feature } from 'ol';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
// export type RootState = RootStateType;
// import { searchImages as searchMetadata } from './metadata';

/**
 * Types/Interfaces
 */
// ---

/**
 * Actions
 */
export const geometryFavoriteActions = {
  // не знаю как обойтись одним экшоном, т.е. например, в saga модифицировать payload
  // add: createActionCreator('FAVORITES/GEOMETRY_ADD', resolve => (feature: GeoJSONFeature) =>
  //   resolve(feature),
  // ),
  addRequest: createActionCreator(
    'FAVORITES/GEOMETRY_ADD_REQUEST',
    resolve => (feature: GeoJSONFeature) => resolve(feature)
  ),
  addSuccess: createActionCreator(
    'FAVORITES/GEOMETRY_ADD_SUCCESS',
    resolve => (feature: GeoJSONFeature) => resolve(feature)
  ),
  // remove: createActionCreator('FAVORITES/GEOMETRY_REMOVE', resolve => (id: string) => resolve(id)),
  removeRequest: createActionCreator('FAVORITES/GEOMETRY_REMOVE_REQUEST', resolve => (id: string) =>
    resolve(id)
  ),
  removeSuccess: createActionCreator('FAVORITES/GEOMETRY_REMOVE_SUCCESS', resolve => (id: string) =>
    resolve(id)
  ),
  // removeAll: createActionCreator('FAVORITES/GEOMETRY_REMOVE_ALL'),
  //
  selectRegionOnMap: createActionCreator(
    'FAVORITES/SELECT_REGION_ON_MAP',
    resolve => (feature: GeoJSONFeature) => resolve(feature)
  ),
};

/**
 * Reducers and state
 */
const favorites = JSON.parse(localStorage.getItem('regions') || '[]');
export const geometryFavoritesDefaultState: GeoJSONFeature[] = favorites;

export const geometryFavoritesReducer = createReducer(
  geometryFavoritesDefaultState,
  handleAction => [
    // handleAction(geometryFavoriteActions.add, (state, { payload }) => [...state, payload]),
    handleAction(geometryFavoriteActions.addSuccess, (state, { payload }) => [...state, payload]),
    // handleAction(geometryFavoriteActions.remove, (state, { payload }) =>
    //   state.filter(item => item.properties!._id() !== payload),
    // ),
    handleAction(geometryFavoriteActions.removeSuccess, (state, { payload }) =>
      state.filter(item => item.id !== payload)
    ),
    // handleAction(geometryFavoriteActions.removeAll, () => []),
  ]
);

// rootReducer
export default combineReducers({
  geometryFavorites: geometryFavoritesReducer,
});

/**
 * Selectors
 */
export const getGeometryFavorites = (state: RootStateType) => state.favorites.geometryFavorites;
