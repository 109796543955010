import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro'; // Trans
import { withI18nProps, withI18n } from '@lingui/react';

import ErrorPage from 'components/common/ErrorPage/ErrorPage';
import ErrorFragment from 'components/common/ErrorPage/ErrorFragment';

const NotFoundErrorPage: React.FC<withI18nProps> = ({ i18n }) => (
  <ErrorPage>
    <ErrorFragment code="404" title={i18n._(t`Страница не найдена :(`)}>
      <Link to="/">{i18n._(t`Вернуться на главную`)}</Link>
    </ErrorFragment>
  </ErrorPage>
);

export default withI18n()(NotFoundErrorPage);
