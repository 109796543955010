import { VTMS_URL } from 'api/realAPI';
import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { captureSimpleException } from 'modules/monitor/sentryHelper';

export const getLayersDataFromImageMetadata = (imagesMetadata: ImageMetadataDetail[]) => {
  /* const coverageElement = route.coverage.find((c: any) => c.formatName === 'GeoTiff' && c.resourceUrls.http !== undefined);
    const map = GeoportalMap.getInstance();
    if (coverageElement) {
      yield call([map, 'toggleViewFullImage'], coverageElement);
    } */
  // last<any>(route.coverage)

  const result: any[] = [];

  for (const image of imagesMetadata) {
    // const coverageElement = last(image.coverage)!;
    // const coverageElement = last(image.coverage)!; // [0]http,s3 - link to tiff in s3, [1]wms - mapproxy
    image.coverage?.forEach(layer => {
      const coverageElement = layer;
      coverageElement.resourceUrls = coverageElement.resourceUrls || {};
      // console.log(layer.bands , 24)
      let layerData;
      let commonName;
      if (coverageElement.resourceUrls.s3 !== undefined) {
        if (layer.platformIdentifier === 'AI2D') {
          layer.bands?.length === 1 ? (commonName = 'pan') : (commonName = 'multi');
          layerData = {
            __mdIdentifier: image.identifier,
            type: 'XYZ',
            id: coverageElement.id * -1, // TODO - пересечение с metadata???
            // commonName: commonName + '_' + image.identifier,
            // name: `images:${coverageElement.id}`,
            name: commonName + '_' + image.identifier,
            config: {
              options: {
                url: `${VTMS_URL}/{z}/{x}/{y}?url=${coverageElement.resourceUrls.s3}&${
                  commonName === 'pan' ? 'nodata=0' : ''
                }`,
                visible: true,
                zIndex: 32,
                attributions: '© Роскосмос',
              },
            },
          };
        } else {
          layerData = {
            __mdIdentifier: image.identifier,
            type: 'XYZ',
            id: coverageElement.id * -1, // TODO - пересечение с metadata???
            // name: `images:${coverageElement.id}`,
            name: `images:${image.identifier}`,
            config: {
              options: {
                url: `${VTMS_URL}/{z}/{x}/{y}?url=${coverageElement.resourceUrls.s3}&nodata=0`,
                visible: true,
                zIndex: 32,
                attributions: '© Роскосмос',
              },
            },
          };
        }
      } else if (coverageElement.resourceUrls.wms !== undefined) {
        // TODO - дублируются параметры в итоговом запросе из ol (SERVICE, VERSION, STYLES)
        layerData = {
          __mdIdentifier: image.identifier,
          type: 'WMS',
          id: coverageElement.id * -1, // TODO - пересечение с metadata???
          // name: `images:${coverageElement.id}`,
          name: `images:${image.identifier}`,
          config: {
            options: {
              url: coverageElement.resourceUrls.wms,
              visible: true,
              zIndex: 32,
              attributions: '© Роскосмос',
            },
            params: {
              // layers: 'boundaries,2018-01-19_74280',
              transparent: true,
              format: 'image/png',
            },
          },
        };
      } else {
        alert('Ошибка: Нет доступных источников для просмотра снимка в полном разрешении!');
        // throw Error
        captureSimpleException(
          new TypeError("User can't see full resolution image"),
          'element.resourceUrls is not defined or empty',
          {
            element_id: coverageElement.id,
            element_name: coverageElement.name,
            format_name: coverageElement.formatName,
            service_name: coverageElement.serviceName,
            metadata_identifier: coverageElement.metadataIdentifier,
          }
        );
      }
      if (layerData) {
        result.push(layerData);
      }
    });
  }
  return result;
};
