import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import { defaultLanguage } from 'locales';
import { RootState as RootStateType } from './root';
// export type RootState = RootStateType;

/**
 * Types/Interfaces
 */
// ---

/**
 * Actions
 */
export const selectLanguage = createActionCreator(
  'LOCALE/SELECT_LANGUAGE',
  resolve => (data: string) => resolve(data)
);

/**
 * Reducers and state
 */
export const defaultLanguageState: string = defaultLanguage; // 'ru';

export const languageReducer = createReducer(defaultLanguageState, handleAction => [
  handleAction(selectLanguage, (_, { payload }) => payload),
]);

// localeRootReducer
export default combineReducers({
  language: languageReducer,
});

/**
 * Selectors
 */
export const getLanguage = (state: RootStateType) => state.locale.language;
