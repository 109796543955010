// Test page
import React from 'react';

const SentryVerifyPage: React.FC = () => {
  // @ts-ignore
  // caldasdl();

  return (
    <>
      <div style={{ position: 'absolute', top: '1rem', left: '2rem' }}>
        <h2>Monitor Verify Page</h2>
        <div>
          <button
            onClick={() => {
              // @ts-ignore
              someHandler();
            }}
          >
            Click to make error
          </button>
        </div>
      </div>
    </>
  );
};

export default SentryVerifyPage;
