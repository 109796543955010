import WebGLTileLayer from 'libs/ol/layer/WebGLTile';

import GeoTIFF from 'ol/source/GeoTIFF';
import { CreateLayerBundle } from '../types';

const createSimpleWebGLLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle;

  const source = new GeoTIFF({
    sources: [
      {
        url:
          'https://s3.gptl.ru/stac-web-free/EL_ARCM/MSU-GS/L3/2024/06/04/ETRIS.EL_ARCM.MSU-GS._._._.2024-06-04T1430.L3._.NTSOMZ_MSK/MSU-GS_E234_202406041430_BT.3857.COG.tif',
      },
    ],
  });

  const layer = new WebGLTileLayer({
    id,
    name,
    extent: options.extent,
    preload: Infinity,
    // -- /custom args delimiter --
    visible: options.visible || options.visibility || false,
    source: source,
    zIndex: options.zIndex,
  });

  return layer;
};

export default createSimpleWebGLLayer;
