import { call, all, takeEvery } from 'redux-saga/effects';
import { getType, ActionType } from 'deox';

import { requestFlowNew } from './network';
import authRefresh from './networkIAM';
import { authFetch } from 'ducks/request';
import { parseApiObject, serializeParams } from 'components/utils/apiDataConverter';
import { captureSagaException } from 'modules/monitor/sentryHelper';
import API from 'api/realAPI';

// custom auth request
export function* authFetchRequestWatch() {
  yield takeEvery(getType(authFetch.request), authFetchRequestFlow);
}
export function* authFetchRequestFlow(action: ActionType<typeof authFetch.request>) {
  try {
    // @ts-ignore
    const { callback, url, ...params } = action.payload || {};
    const paramsSerialized = serializeParams(params);
    // TODO - вынести в отдельную saga декоратор
    // const isAuthenticated = yield select(getIsAuthenticated);
    // if (!isAuthenticated) {
    //   yield take(iamActions.success);
    // }
    const respData = yield call(authRefresh, requestFlowNew, API.get, url, {
      params: {
        ...paramsSerialized,
      },
      // baseURL: CART_REQUEST_API_URL,
    });
    // debug.log('Find cart requests response data', respData);
    const parsedData = parseApiObject(respData);
    // yield put(authFetchRequest.success(parsedData));
    if (callback !== undefined) {
      callback(parsedData); // <-- pass value (orders)
    }
  } catch (error) {
    // debug.error(error);
    // yield put(authFetchRequest.failure(error));
    // @ts-ignore
    captureSagaException(error, action.type, action.payload);
  }
}

export default function* orderRoot() {
  yield all([authFetchRequestWatch()]);
}
