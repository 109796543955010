import { combineReducers } from 'redux';

import authRootReducer from './auth';
import classifierRootReducer from './classifier';
import favoritesRootReducer from './favorites';
import iamRootReducer from './authIAM';
import layersRootReducer from './mapLayers';
import localeRootReducer from './locale';
import mapObjectsRootReducer from './mapObjects';
import mapRootReducer from './map';
import messageNotifyReducer from './messageNotify';
import metadataDownloadRootReducer from './metadataDownload';
import metadataRootReducer from './metadata';
import notifyMessageRootReducer from './message';
import orderRootReducer from './order';
import paymentAllowedReducer from './config';
import simpleRequestRootReducer from './simpleRequest';
import stacItemsRootReducer from './stacItems';

export const rootReducer = combineReducers({
  auth: authRootReducer,
  class: classifierRootReducer,
  config: paymentAllowedReducer,
  favorites: favoritesRootReducer,
  iam: iamRootReducer,
  layers: layersRootReducer,
  locale: localeRootReducer,
  map: mapRootReducer,
  messageNotify: messageNotifyReducer,
  metadata: metadataRootReducer,
  metadataDownload: metadataDownloadRootReducer,
  notify: notifyMessageRootReducer,
  objects: mapObjectsRootReducer,
  order: orderRootReducer,
  simpleRequest: simpleRequestRootReducer,
  stacItems: stacItemsRootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
