import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { getArea } from 'ol/sphere';

import BaseLayer from 'ol/layer/Base';
import {
  createCoverageWMSServerLayer,
  createGeoportalCoverageXYZLayer,
  createSimpleWebGLLayer,
  // createSimpleVectorTileLayer,
  // createSimpleMapboxVectorLayer,
  createSimpleWMSLayer,
  createSimpleWMTSCapabilities,
  createSimpleWMTSLayer,
  createSimpleXYZLayer,
  createSTACItemXYZLayer,
  // createWebGLTilelayer,
} from './layers';

import { CreateLayerBundle } from './types';

type CreateLayerFunction = (layerBundle: CreateLayerBundle) => BaseLayer | void;
interface ILayerTypeFunction {
  [key: string]: CreateLayerFunction;
}
const layerTypeFunction: ILayerTypeFunction = {
  CoverageWMSServer: createCoverageWMSServerLayer,
  GeoportalCoverage: createGeoportalCoverageXYZLayer,
  OSM: createSimpleXYZLayer,
  XYZ: createSimpleXYZLayer,
  WMS: createSimpleWMSLayer,
  WMTS: createSimpleWMTSLayer,
  WMTS2: createSimpleWMTSCapabilities,
  STACItem: createSTACItemXYZLayer,
  WebGL: createSimpleWebGLLayer,
  // MVT: createSimpleVectorTileLayer,
  // MVT: createSimpleMapboxVectorLayer,
};

// TODO - LayerFactory
// : BaseLayer | null
const createLayer = (layerType: string, layerData: any, zIndex?: number): BaseLayer => {
  // let layer = null;
  const layerId = layerData.id;
  const layerName = layerData.name;
  const layerOptions = layerData.config.options;
  const layerParams = layerData.config.params;
  const createLayerBundle = {
    id: layerId,
    name: layerName,
    options: layerOptions,
    params: layerParams,
  } as CreateLayerBundle;

  const layer = layerTypeFunction[layerType](createLayerBundle);
  return layer as BaseLayer;
};

//
// const findLayerGroup = (layers: Collection<BaseLayer>, name: LAYER_GROUPS): LayerGroup => {
//   // const group = this.getOlMap().getLayers()
//   const group = layers
//     .getArray()
//     .find(layer => layer.get('name') === name && layer instanceof LayerGroup);
//   return group as LayerGroup;
// };

const readFileAsArrayBuffer = (file: File) => {
  return new Promise<ArrayBuffer | null>((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      if (fr.result) {
        resolve(fr.result as ArrayBuffer);
      } else {
        // not tested!
        // reject(new Error('Empty file'));
        resolve(null);
      }
    };
    fr.onerror = () => {
      resolve(null);
    };
    fr.readAsArrayBuffer(file);
  });
};

const readFilesAsArrayBuffer = async (files: File[]) => {
  const result = [];
  for (const file of files) {
    if (file instanceof File) {
      result.push(await readFileAsArrayBuffer(file));
    } else {
      result.push(null);
    }
  }
  return result;
};

// sort files list (только вот FileList не имеет метода sort)
// function compareFiles(a: File, b: File) {
//   if (a.name > b.name) return 1; // если первое значение больше второго
//   if (a.name === b.name) return 0; // если равны
//   if (a.name < b.name) return -1; // если первое значение меньше второго
// }

// const feature = geoJSONFormat.readFeature(regionsOfInterest[0]);
// const geomWKT = wktFormat.writeFeature(feature);
const geoJSONToWKTAndTransform = (value: GeoJSONFeature, isTransform: boolean = false) => {
  const wktFormat = new WKT();
  const geoJSONFormat = new GeoJSON();
  let geoJSONGeometry = geoJSONFormat.readFeature(value).getGeometry()!;
  if (isTransform) {
    geoJSONGeometry = geoJSONGeometry.transform('EPSG:3857', 'EPSG:4326');
  }
  const wktGeometry = wktFormat.writeGeometry(geoJSONGeometry);
  return wktGeometry;
};

const wktToGeoJSON = (value: string) => {
  const wktFormat = new WKT();
  const geoJSONFormat = new GeoJSON();
  const wktGeometry = wktFormat.readFeature(value).getGeometry()!; // .transform('EPSG:3857', 'EPSG:4326');
  const geoJSONGeometry = geoJSONFormat.writeGeometry(wktGeometry);
  return JSON.parse(geoJSONGeometry);
};

const wktToExtent = (value: string) => {
  const extent = new WKT()
    .readFeature(value)
    .getGeometry()
    ?.transform('EPSG:4326', 'EPSG:3857')
    .getExtent();
  return extent;
};

const formatArea = (polygon: any) => {
  const area = getArea(polygon);
  let output;
  let measure;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100;
    measure = 'км';
  } else {
    output = Math.round(area * 100) / 100;
    measure = 'м';
  }
  return { output, measure };
};

export {
  createLayer,
  formatArea,
  geoJSONToWKTAndTransform,
  readFilesAsArrayBuffer,
  wktToGeoJSON,
  wktToExtent,
};
