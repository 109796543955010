import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';
import { RootState as RootStateType } from './root';

/**
 * Actions
 */

export const paymentAllowed = createActionCreator(
  'CONFIG/PAYMENT_ALLOWED',
  resolve => (data: boolean) => resolve(data)
);
/**
 * Reducers and state
 */
export const defaultPaymentAllowedState = true;

export const paymentAllowedReducer = createReducer(defaultPaymentAllowedState, handleAction => [
  handleAction(paymentAllowed, (_, { payload }) => payload),
]);

// layersRootReducer
export default combineReducers({
  paymentAllowed: paymentAllowedReducer,
});

/**
 * Selectors
 */
export const getIsPaymentAllowed = (state: RootStateType) => state.config.paymentAllowed;
