import { camelToSnake, snakeToCamel } from '@geobank/assets/src/uibook/helpers/utils';
import { QUICKLOOK_TRANSFORM_URL, SEARCH_IMAGES_API_URL } from 'api/realAPI';
import { captureSimpleMessage } from 'modules/monitor/sentryHelper';
import { formatDate, parseDate } from './date';

/**
 * Parser
 */
const parseObject = (data: any) => {
  if (data === null || data === undefined) {
    return null;
  }
  let result: any;
  // TODO - isObject()
  if (Array.isArray(data)) {
    result = data.map(item => parseObject(item));
  } else if (typeof data === 'object') {
    result = {};
    Object.keys(data).forEach(key => {
      result[snakeToCamel(key)] = parseObject(data[key]);
    });
  } else {
    result = data;
  }
  return result;
};

const parseCoverageObject = (data: any) => {
  if (!Array.isArray(data)) {
    return null;
  }
  const result = data.map(item => {
    const element = parseObject(item);
    // throw Error
    if (!element.resourceUrls) {
      captureSimpleMessage("User can't see full resolution image", undefined, {
        element_id: element.id,
        element_name: element.name,
        format_name: element.formatName,
        service_name: element.serviceName,
        metadata_identifier: element.metadataIdentifier,
      });
    }
    return element;
  });
  /* .filter(item => {
      if (!item.resourceUrls) {
        // throw Error
      }
      return true;
    }); */

  return result;
};

// parseMetadataResult
export const parseMetadataObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    switch (key) {
      case 'acquisition_date_instant':
      case 'acquisition_date_begin':
      case 'acquisition_date_end':
      case 'last_modified':
        // console.log(snakeToCamel(key), parseISODate(data[key]));
        result[snakeToCamel(key)] = data[key] ? parseDate(data[key]) : null; // parseISODate(data[key]) // , format, new Date()
        break;
      // своего рода костыль, из-за особенности использования ES
      // округление чисел с большим количеством знаков после запятой
      case 'cloudiness':
      case 'resolution':
      case 'illumination_azimuth_angle':
      case 'illumination_elevation_angle':
      case 'nadir_tilt_angle':
      case 'azimuth_scan_angle':
        result[snakeToCamel(key)] =
          Number(data[key]) === data[key] ? Math.round(data[key] * 10) / 10 : null;
        break;
      // arrays
      case 'previews':
        // .replace(/https?:\/\/www\.gptl\.ru\//, 'http://ntsomz.gptl.ru/'), // 'http://www.gptl.ru/previews/Kanopus-V1/2015/10/1428565_2.jpg'
        result[snakeToCamel(key)] = Array.isArray(data[key])
          ? data[key].map((item: any) => {
              // Перевели квиклуки в ЦОД, больше не надо делать замену (в БД обновили адреса на https://gptl.ru/)
              // item[`url`] = item.url.replace(
              //   /https?:\/\/www\.gptl\.ru\//,
              //   'http://ntsomz.gptl.ru/'
              // );
              // А вот в elastic не обновили!
              item[`url`] = item.url.replace('http://www.gptl.ru', 'https://www.gptl.ru');
              item[`url`] =
                data.identifier.startsWith('ETRIS.KV') ||
                data.identifier.startsWith('ETRIS.RDK') ||
                data.identifier.startsWith('ETRIS.BKA') ||
                data.identifier.startsWith('ETRIS.AI2D')
                  ? /* https://api.gptl.ru/transform/v1/quickloook */
                    `${QUICKLOOK_TRANSFORM_URL}?url=${item.url}&geometry=${item.geometry}`
                  : item[`url`];
              return parseObject(item);
            })
          : [];
        break;
      case 'sensors':
      case 'resp_party_data_storage': // case 'coverage':
        // TODO - parse dates!?
        result[snakeToCamel(key)] = data[key] !== null ? parseObject(data[key]) : [];
        break;
      case 'coverage':
        result[snakeToCamel(key)] = data[key] !== null ? parseCoverageObject(data[key]) : [];
        break;
      default:
        result[snakeToCamel(key)] = data[key];
    }
    // result[camelToSnake(key)] = parseValue(data[key]);
  });
  return result;
};
export const parseBbpMetadataObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    switch (key) {
      // case 'acquisition_date_instant':
      case 'acquisition_date':
      case 'acquisition_date_begin':
      case 'acquisition_date_end':
      case 'last_modified':
        // console.log(snakeToCamel(key), parseISODate(data[key]));
        result[snakeToCamel(key)] = data[key] ? parseDate(data[key]) : null; // parseISODate(data[key]) // , format, new Date()
        break;
      // округление чисел с большим количеством знаков после запятой
      // case 'cloudiness':
      // case 'resolution':
      case 'illumination_azimuth_angle':
      case 'illumination_elevation_angle':
      case 'nadir_tilt_angle':
      case 'azimuth_scan_angle':
        result[snakeToCamel(key)] =
          typeof data[key] === 'number' ? Math.round(data[key] * 10) / 10 : null;
        break;
      // arrays
      case 'previews':
        // https://bbp.ntsomz.ru/api/v1/resources/browseimages/EPSG-3857/MM2_MSU101_20201002T092654_11900800
        // /bbp/previews?id=MM2_MSU101_20201002T092654_11900800
        result[snakeToCamel(key)] = Array.isArray(data[key])
          ? data[key].map((item: any) => {
              item.url = `${SEARCH_IMAGES_API_URL}/bbp/previews?id=${data.id}`;
              return parseObject(item);
            })
          : [];
        break;
      case 'sensors':
      case 'resp_party_data_storage':
      case 'coverage':
        // TODO - parse dates!?
        result[snakeToCamel(key)] = data[key] !== null ? parseObject(data[key]) : [];
        break;
      default:
        result[snakeToCamel(key)] = data[key];
    }
    // result[camelToSnake(key)] = parseValue(data[key]);
  });
  return result;
};

// v2 of parseApiObject, parseApiDataObjectV2, api response data
export const parseApiObjectV2 = (
  data: any,
  types: { [key: string]: string } = { createdAt: 'date' }, // types: { [key: string]: string } | string
  currentType?: string
) => {
  let result: any;

  if (Array.isArray(data)) {
    result = data.map((item: any) => parseApiObjectV2(item, types));
  } else if (typeof data === 'object' && data !== null) {
    result = {};
    Object.keys(data).forEach(key => {
      const newKey = snakeToCamel(key);
      result[newKey] = parseApiObjectV2(
        data[key],
        types, // typeof types === 'object' && types[newKey] ? types[newKey] : undefined
        types[newKey] ? types[newKey] : undefined
      );
    });
  } else {
    // switch (types[newKey]) {
    switch (currentType) {
      case 'date':
        // result[newKey] = data[key] ? parseDate(data[key]) : null;
        result = data ? parseDate(data) : null;
        break;
      default:
        result = data;
    }
  }

  return result;
};

export const parseApiObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    result[snakeToCamel(key)] = parseObject(data[key]);
  });
  return result;
};

/**
 * Serializer
 */
const serializeValue = (value: any) => {
  // return value instanceof Date ? formatDate(value, 'yyy-MM-dd') : value;
  let result;
  if (value instanceof Date) {
    result = formatDate(value, "yyy-MM-dd'T'HH:mm:ss"); // before: 'yyy-MM-dd' // TODO - TimeZone (Z)
  } else if (Array.isArray(value)) {
    result = value.join(',');
  } else {
    result = value;
  }
  return result;
};

export const serializeMetadataSearchParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'source':
      case 'modal':
      case 'regionSelection':
        break;
      default:
        if (params[key] !== null || params[key] !== undefined || params[key] !== '') {
          paramsForApi[camelToSnake(key)] = serializeValue(params[key]);
        }
    }
  });
  return paramsForApi;
};

export const serializeTaskParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null || params[key] !== undefined || params[key] !== '') {
      paramsForApi[camelToSnake(key)] = params[key];
    }
  });
  return paramsForApi;
};

export const serializeParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    if (params[key] || typeof params[key] === 'boolean') {
      // TODO - сделать по красивому (фикс для диапазонов bands)
      paramsForApi[camelToSnake(key)] = key === 'bands' ? params[key] : serializeValue(params[key]);
    }
  });
  return paramsForApi;
};

/// Сериализация объектов перед отправкой в api (без конкатенации массивов в строки через ,)
export const serializeDataV2 = (data: any): any => {
  let result;

  if (Array.isArray(data)) {
    result = [];
    result = data.map(item => serializeDataV2(item));
  } else if (typeof data === 'object') {
    result = {};
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        result[camelToSnake(key)] = serializeDataV2(data[key]);
      }
    });
  } else if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
    result = data;
  }

  return result;
};

// serialize container
// export const serializeObjectContainerV2 = (data: any): any => {};
