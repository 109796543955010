import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import styles from './CheckBox.module.scss';

export const CHECKED = 1;
export const UNCHECKED = 2;
export const INDETERMINATE = -1;

/* TODO - слить с `components/form/CheckBox/CheckBox` */

interface CheckboxProps {
  // checked?: boolean;
  name: string;
  value: typeof CHECKED | typeof UNCHECKED | typeof INDETERMINATE;
  disabled?: boolean;
  // indeterminate?: boolean;
  onChange?: (name: string, value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const checkRef = useRef<HTMLInputElement>(null);
  const { onChange, /* checked, */ disabled, value, name } = props; // indeterminate

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.checked = value === CHECKED;
      checkRef.current.indeterminate = value === INDETERMINATE;
      // checkRef.current.value = '';
      // console.log(checkRef.current.checked, checkRef.current.value, checkRef.current.indeterminate);
    }
  }, [checkRef, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value2: boolean = get(event, 'target.checked');
    if (onChange !== undefined) {
      onChange(name, value2);
    }
  };

  return (
    <label className={styles.check_box__container}>
      <input
        ref={checkRef}
        name={name}
        className={styles.check_box__input}
        onChange={onChangeHandler}
        type="checkbox"
        disabled={disabled}
        // checked={checked} // checked={value === CHECKED}
      />
      <span
        className={classNames(styles.check_box__check_mark, {
          [styles.indeterminate]: value === INDETERMINATE,
        })}
      />
    </label>
  );
};

export default Checkbox;
