import { call, cancelled } from 'redux-saga/effects'; // , put, select
// import { getIsNetworkErrorPresent, addError, clearError } from 'ducks/network';
// import { logout } from 'ducks/auth';

// @ts-ignore
export const requestFlowNew = function* (fn, ...args) {
  try {
    const response = yield call(fn, ...args);

    if (response.status >= 200 && response.status < 500) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error) {
    // yield put(addError(error));
    //// if (error.response.status === 401) yield put(logout());
    throw error;
  } finally {
    if (yield cancelled()) {
      // console.log('saga request, canceled !!!');
    }
  }
};

// @ts-ignore
export default function* (fn, args) {
  // export default function*(fn: (...args: any) => {}, ...args: any) {
  try {
    const response = yield call(fn, args);
    // const isNetworkErrorPresent = yield select(getIsNetworkErrorPresent);
    // if (isNetworkErrorPresent) {
    //   yield put(clearError());
    // }

    //// return response;
    ////
    // response.status === 200
    // if (response.data && response.data.success === true) {
    if (response.status === 200 || response.status === 201) {
      return response.data.data ? response.data.data : response.data;
    } else {
      //// throw Error(response.data.message);
      throw response;
    }
  } catch (error) {
    // yield put(addError(error));
    //// if (error.response.status === 401) yield put(logout());

    throw error;
  } finally {
    if (yield cancelled()) {
      //// yield put(actions.requestFailure('Sync cancelled!'));
      console.log('saga request, canceled !!!');
    }
  }
}
