import React from 'react';
import { Trans, t } from '@lingui/macro';
// import { i18nMark } from '@lingui/react';

export const usageTimeOptions = [
  {
    value: 'five',
    title: (
      <span>
        <Trans id="license_ut.value_five">до 5 лет</Trans>
      </span>
    ),
  },
  {
    value: 'ten',
    title: (
      <span>
        <Trans id="license_ut.value_ten">от 5 до 10 лет</Trans>
      </span>
    ),
  },
  {
    value: 'unlimited',
    title: (
      <span>
        <Trans id="license_ut.value_unlimited">бессрочно</Trans>
      </span>
    ),
  },
] as const;

export type UsageTimeValue = typeof usageTimeOptions[number]['value'];

/* export const licenseOptions = [
  {
    value: 'individually',
    title: <span>Индивидуальная</span>,
    hint: '',
  },
  {
    value: 'limited_group',
    // Групповая огранич.
    title: <span>Групповая ограниченная</span>,
  },
  {
    value: 'unlimited_group',
    // Групповая неогранич.
    title: <span>Групповая неограниченная</span>,
  },
  {
    value: 'individually_extra_processing',
    title: <span>Индивидуальная расширенная</span>,
  },
  {
    value: 'limited_group_extra_processing',
    title: <span>Групповая огранич. расширенная</span>,
  },
  {
    value: 'unlimited_group_extra_processing',
    title: <span>Групповая неогранич. расширенная</span>,
  },
  {
    value: 'network_placement',
    title: <span>Публичная</span>,
  },
] as const; */
export const licenseOptions = [
  {
    value: 'individually',
    title: (
      <span>
        <Trans id="license.value_individually">Без права передачи</Trans>
      </span>
    ),
    // hint: 'использование данных в любой материальной форме\n без права передачи третьим лицам',
    // hint: i18nMark('использование данных в любой материальной форме\n без права передачи третьим лицам'),
    hint: t(
      'license.hint_individually'
    )`использование данных в любой материальной форме\\n без права передачи третьим лицам`,
  },
  {
    value: 'limited_group',
    title: (
      <span>
        <Trans id="license.value_limited_group">С правом передачи (ограниченно)</Trans>
      </span>
    ),
    hint: t(
      'license.hint_limited_group'
    )`использование данных в любой материальной форме\\n с правом передачи ограниченному кругу третьих лиц`,
  },
  {
    value: 'unlimited_group',
    title: (
      <span>
        <Trans id="license.value_unlimited_group">С правом передачи (неограниченно)</Trans>
      </span>
    ),
    hint: t(
      'license.hint_unlimited_group'
    )`использование данных в любой материальной форме\\n с правом передачи неограниченному кругу третьих лиц`,
  },
  {
    value: 'individually_extra_processing',
    title: (
      <span>
        <Trans id="license.value_individually_extra_processing">
          Переобработка без права передачи
        </Trans>
      </span>
    ),
    hint: t(
      'license.hint_individually_extra_processing'
    )`дополнительная обработка данных и(или) создание\\n производных продуктов или их частей\\n без права передачи третьим лицам`,
  },
  {
    value: 'limited_group_extra_processing',
    title: (
      <span>
        <Trans id="license.value_limited_group_extra_processing">
          Переобработка без права передачи (огранич.)
        </Trans>
      </span>
    ),
    hint: t(
      'license.hint_limited_group_extra_processing'
    )`дополнительная обработка данных и(или) создание\\n производных продуктов или их частей\\n с правом передачи ограниченному кругу третьих лиц`,
  },
  {
    value: 'unlimited_group_extra_processing',
    title: (
      <span>
        <Trans id="license.value_unlimited_group_extra_processing">
          Переобработка без права передачи (неогранич.)
        </Trans>
      </span>
    ),
    hint: t(
      'license.hint_unlimited_group_extra_processing'
    )`дополнительная обработка данных и(или) создание\\n производных продуктов или их частей\\n с правом передачи неограниченному кругу третьих лиц`,
  },
  {
    value: 'network_placement',
    title: (
      <span>
        <Trans id="license.value_network_placement">Размещение в сети Интернет</Trans>
      </span>
    ),
    hint: t(
      'license.hint_network_placement'
    )`размещение данных в информационно-телекоммуникационной\\n сети "Интернет"`,
  },
] as const;

export type LicenseValue = typeof licenseOptions[number]['value'];
