import React from 'react';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import EditIcon from '@geobank/assets/src/icons/EditIcon';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';

import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';

// tslint:disable-next-line: no-empty-interface
interface IAreaInfoPanelProps {}

interface IAreaInfoPanelState {
  isOpenAreaInfo: boolean;
}

interface IAreaInfoItem {
  itemName: string;
  countPhoto: string | number;
}

const mockItems: IAreaInfoItem[] = [
  { itemName: 'mockName1', countPhoto: '1' },
  { itemName: 'mockName2', countPhoto: '2' },
  { itemName: 'mockName3', countPhoto: '3' },
  { itemName: 'mockName4', countPhoto: '4' },
  { itemName: 'mockName5', countPhoto: '5' },
  { itemName: 'mockName6', countPhoto: '6' },
  { itemName: 'mockName7', countPhoto: '7' },
  { itemName: 'mockName8', countPhoto: '8' },
  { itemName: 'mockName9', countPhoto: '9' },
  { itemName: 'mockName10', countPhoto: '10' },
  { itemName: 'mockName11', countPhoto: '11' },
  { itemName: 'mockName11', countPhoto: '12' },
  { itemName: 'mockName11', countPhoto: '13' },
  { itemName: 'mockName11', countPhoto: '14' },
  { itemName: 'mockName11', countPhoto: '15' },
  { itemName: 'mockName11', countPhoto: '16' },
];

const styles = require('./AreaInfoPanel-styles.module.scss');

export default class AreaInfoPanel extends React.Component<
  IAreaInfoPanelProps,
  IAreaInfoPanelState
> {
  constructor(props: IAreaInfoPanelProps) {
    super(props);
    this.state = {
      isOpenAreaInfo: false,
    };
  }

  private readonly getAreaInfo = (value: IAreaInfoItem): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 12,
        }}
      >
        <div>
          <span style={{ color: '#5DBDF9', cursor: 'pointer' }}>{value.itemName}</span>
        </div>
        <div>
          <span style={{ color: '#ffffff' }}>{value.countPhoto} фотографий</span>
        </div>
        <div style={{ display: 'flex', paddingRight: 10 }}>
          <Button color={ButtonColor.TRANSPARENT} width={'35px'} height={'35px'}>
            <ContourIcon />
          </Button>
          <Button color={ButtonColor.TRANSPARENT} width={'35px'} height={'35px'}>
            <EditIcon />
          </Button>
          <Button color={ButtonColor.TRANSPARENT} width={'35px'} height={'35px'}>
            <TrashIcon />
          </Button>
        </div>
      </div>
    );
  };

  private readonly getAreaInfoPanel = (value: IAreaInfoItem[]): JSX.Element[] | JSX.Element => {
    if (value.length <= 3 || this.state.isOpenAreaInfo) {
      return value.map((item, key) => (
        <div key={`key_${item.itemName}`}>
          {this.getAreaInfo({ itemName: item.itemName, countPhoto: item.countPhoto })}
        </div>
      ));
    }
    return (
      <>
        {value.slice(0, 3).map((item, key) => (
          <div key={`key_${item.itemName}`}>
            {this.getAreaInfo({ itemName: item.itemName, countPhoto: item.countPhoto })}
          </div>
        ))}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          onClick={() => this.setState({ isOpenAreaInfo: true })}
        >
          <span className={styles.spanClick}>Показать все {value.length}</span>
        </div>
      </>
    );
  };

  public render() {
    return (
      <div style={{ backgroundColor: '#14306A', padding: 20, borderRadius: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span style={{ fontSize: 19, color: '#ffffff' }}>Районы</span>
          </div>
          <div>
            <span className={styles.spanClick}>Добавить</span>
          </div>
        </div>
        <CustomScrollBarContainer heightMin="80px" heightMax="144px" color="#CEEAFB">
          {this.getAreaInfoPanel(mockItems)}
        </CustomScrollBarContainer>
      </div>
    );
  }
}
