import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import EditIcon from '@geobank/assets/src/icons/EditIcon';
import { formatDate } from 'components/utils/date';
import { CartRequest } from 'ducks/order';
import { formatCurrency, formatNumber } from 'components/utils/format';
import { statuses } from 'locales/dicts/requestStatus';
import { licenseOptions, usageTimeOptions } from 'locales/dicts/license';

import ChipTag from 'components/order/ChipTag/ChipTag';

import styles from './RequestDetailPanel.module.scss';

type RequestDetailPanelAction = 'edit' | 'close' | 'back';

interface RequestDetailPanelProps {
  request: CartRequest;
  onClose: () => void;
  onTagClick?: (value: string) => void;
  actions: RequestDetailPanelAction[];
}

const RequestDetailPanel: React.FC<RequestDetailPanelProps> = props => {
  const { request, onClose, onTagClick, actions } = props;
  const currentStatus: CartRequest['statuses'][0] = get(request, 'statuses[0]', {});

  // console.log('--tags--', request.tags);
  // mdn Array.splice
  // mdn Array.slice;

  return (
    <div className={styles.root}>
      <div className={styles.infoPanel}>
        {actions.indexOf('back') !== -1 && (
          <div style={{ marginBottom: '16px' }}>
            <Link to="/account/orders">
              <Trans>{'<-'} Вернуться к списку заявок</Trans>
            </Link>
          </div>
        )}

        <div className={styles.infoPanelHeader}>
          <span>
            <Trans>Информация по заявке</Trans> {/* <br /> */} "{request.number}"
          </span>
          <div className={styles.infoPanelHeaderActions}>
            {actions.indexOf('edit') !== -1 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={styles.actionButton}
                title="Редактировать заявку"
                onClick={() => {
                  /*console.log('edit click')*/
                }}
                disabled
              >
                <EditIcon />
              </Button>
            )}
            {actions.indexOf('close') !== -1 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={styles.actionButton}
                title="Закрыть панель"
                // onClick={() => console.log('close click')}
                onClick={onClose}
              >
                <CrossIcon />
              </Button>
            )}
          </div>
        </div>
        {/* <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Идентификатор</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            {/* <span>B00100112</span> * /}
            <span>{request.id}</span>
          </div>
        </div> */}
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Условия использования</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            <span>
              {licenseOptions.find(item => item.value === request.license)?.title},{' '}
              {usageTimeOptions.find(item => item.value === request.usageTime)?.title}
            </span>
          </div>
        </div>
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Параметры обработки</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            <span>
              {request.productCode}, {request.srsName}
              {request.elevation && <>, ЦМР</>}
            </span>
          </div>
        </div>
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Суммарная площадь</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            <span>
              {request.totalArea && (
                <>
                  {formatNumber(request.totalArea, { maximumFractionDigits: 1 })} <Trans>км</Trans>
                  <sup>
                    <small>2</small>
                  </sup>
                </>
              )}
            </span>
          </div>
        </div>
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Дата создания</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            <span title={formatDate(new Date(request.createdAt), 'dd.MM.yyyy HH:mm:ss (X)')}>
              {formatDate(new Date(request.createdAt), 'dd.MM.yyyy')}
            </span>
          </div>
        </div>
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Статус</Trans>:
            </span>
          </div>
          {/* TODO - вывод списка/истории статусов request.statuses[] */}
          <div className={styles.infoPanelContentValue}>
            <span>
              <Trans id={statuses[currentStatus.status]} />
              {/* Выполняется обработка */}
            </span>
            <br />
            <span title={formatDate(new Date(currentStatus.createdAt), 'dd.MM.yyyy HH:mm:ss (X)')}>
              ({formatDate(new Date(currentStatus.createdAt), 'dd.MM.yyyy')})
            </span>
          </div>
        </div>
        {request.tags && request.tags.length > 0 && (
          <div className={styles.infoPanelContent}>
            <div className={styles.infoPanelContentLabel}>
              <span>
                <Trans>Теги</Trans>:
              </span>
            </div>
            <div className={styles.infoPanelContentValue}>
              <div>
                {request.tags.slice(0, 4).map((tagName, index) => (
                  // <span
                  //   key={index}
                  //   className={styles.chipTag}
                  //   onClick={() => onTagClick && onTagClick(tagName)}
                  // >
                  //   {tagName}
                  // </span>
                  <ChipTag
                    key={index}
                    className={styles.chipTag}
                    onClick={() => onTagClick && onTagClick(tagName)}
                  >
                    {tagName}
                  </ChipTag>
                  // <React.Fragment key={index}>
                  //   {index > 0 && ', '}
                  //   <span>{tag}</span>
                  // </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelContentLabel}>
            <span>
              <Trans>Цена</Trans>:
            </span>
          </div>
          <div className={styles.infoPanelContentValue}>
            {/* <span>{request.totalPrice} ₽</span> */}
            <span>{formatCurrency(request.totalPrice)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDetailPanel;
