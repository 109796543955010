// https://medium.com/@trmaphi/lodash-isempty-value-you-might-be-using-it-the-wrong-way-d83210d7decf
// https://lodash.com/docs/4.17.15#isNil
// https://lodash.com/docs/4.17.15#isEmpty
export function isEmptyValue(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0)
  );
}

export const envVar = (name: string) => {
  // Return runtime or global environment variable
  if (Boolean((window as any).env) && Boolean((window as any).env[name])) {
    return (window as any).env[name];
  } else {
    return process.env[name];
  }
};
