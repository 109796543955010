import React, { useRef } from 'react';
import { connect } from 'react-redux';


import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import RusFlagIcon from '@geobank/assets/src/icons/flag/RusFlagIcon';
import EngFlagIcon from '@geobank/assets/src/icons/flag/EngFlagIcon';

import { RootState } from 'ducks';
import { getLanguage, selectLanguage } from 'ducks/locale';

const styles = require('./LangSelector-styles.module.scss');

interface LangItem {
  code: string;
  label: string;
  icon: JSX.Element;
}

const langs: LangItem[] = [
  { code: 'ru', label: 'Rus', icon: <RusFlagIcon /> },
  { code: 'en', label: 'Eng', icon: <EngFlagIcon /> },
];

interface LangSelectorProps {
  className?: string;
  language: string;
  selectLanguage: typeof selectLanguage;
}

const LangSelector: React.FC<LangSelectorProps> = props => {
  const ddRef = useRef<Dropdown>(null);

  const handleSelectLanguage = (lang: string) => {
    ddRef.current?.handleToggle();
    props.selectLanguage(lang);
  };

  let activeLang: LangItem;
  let otherLangs: LangItem[] = [];
  activeLang = langs.find(item => item.code === props.language)!;
  otherLangs = langs.filter(item => item.code !== activeLang.code);

  return (
    <div className={props.className}>
      <Dropdown dropup={false} pullRight={true} ref={ddRef}>
        <Dropdown.Toggle>
          <div className={styles.itemContainer}>
            {activeLang.icon} <span className={styles.itemLabel}>{activeLang.label}</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: 'auto' }}>
          {otherLangs.map((item, index) => (
            <Dropdown.MenuItem key={index} onClick={handleSelectLanguage.bind(null, item.code)}>
              <div className={styles.itemContainer}>
                {item.icon} <span className={styles.itemLabel}>{item.label}</span>
              </div>
            </Dropdown.MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    language: getLanguage(state),
  }),
  {
    selectLanguage,
  }
)(LangSelector);
