// renamed FooterActions -> SearchResultItemFooter
import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import classNames from 'classnames';
import React from 'react';
// import Tooltip from 'react-tooltip-lite';

import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
import FileDownloadIcon from '@geobank/assets/src/icons/FileDownloadIcon';
import PictureIcon from '@geobank/assets/src/icons/PictureIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import { ImageMetadataDetail } from 'ducks/types/metadataTypes';

import styles from './SearchResultItemFooter.module.scss';

interface SearchResultItemFooterProps {
  data: ImageMetadataDetail; // ImageMetadata

  isContourActive: boolean;
  isPreviewActive: boolean;
  isFullImageActive: boolean;
  isFullImageActive2B: boolean;

  // actions: {
  onToggleContour: (md: ImageMetadataDetail) => void; // (item: ImageMetadataDetail) => void;
  onTogglePreview: (md: ImageMetadataDetail) => void;
  onToggleFullImage: (md: ImageMetadataDetail, id?: number) => void;
  onToggleFullImage2B: (md: ImageMetadataDetail, id?: number) => void;
  onMetadataDownload: (md: ImageMetadataDetail) => void;
  // };
}

const SearchResultItemFooter: React.FC<SearchResultItemFooterProps & withI18nProps> = props => {
  const { data, i18n } = props;
  const dataCoverage = data.coverage;

  const handleToggleContour = () => {
    props.onToggleContour(data);
  };

  const handleTogglePreview = () => {
    props.onTogglePreview(data);
  };

  const handleToggleFullImage2B1 = (identifier?: any) => {
    const layers2B1 = data;
    if (layers2B1.platformIdentifier === 'AI2D') {
      // console.log(layers2B1, layers2B1.coverage);
      props.onToggleFullImage(layers2B1, identifier.id);
      data.coverage = dataCoverage;
    } else {
      layers2B1.coverage = layers2B1.coverage?.filter(el => el.processingLevelCode === '2B1');
      // console.log(data, layers2B1, identifier.id, identifier.metadataIdentifier, props.isFullImageActive)
      props.onToggleFullImage(layers2B1, identifier.id);
      data.coverage = dataCoverage;
    }
  };

  const handleToggleFullImage2B = (identifier?: any) => {
    // console.log(data, 55)

    const layers2B = data;
    if (layers2B.platformIdentifier === 'AI2D') {
      // console.log(layers2B, layers2B.coverage);
      props.onToggleFullImage2B(layers2B, identifier.id);
      // console.log(layers2B, 67);
      data.coverage = dataCoverage;
    } else {
      layers2B.coverage = layers2B.coverage?.filter(el => el.processingLevelCode === '2B');
      // console.log(data, layers2B, identifier.id, identifier.metadataIdentifier, props.isFullImageActive2B)
      props.onToggleFullImage2B(layers2B, identifier.id);
      data.coverage = dataCoverage;
    }
    // console.log(data.coverage, 61)
  };

  const handleMetadataDownload = () => {
    props.onMetadataDownload(data);
  };

  return (
    <div className={styles.listItemFooter}>
      <div className={styles.test12}>
        <div className={styles.test12}>
          <div className={styles.test11}>
            <Trans>Карта:</Trans>
          </div>
          <div className={styles.test11}>
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                active: props.isContourActive,
              })}
              title={i18n._(t('image_actions.contour')`Контур`)}
              onClick={handleToggleContour}
            >
              <ContourIcon fill="currentColor" />
            </Button>
          </div>
          <div className={styles.test11}>
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                active: props.isPreviewActive,
              })}
              title={i18n._(t('image_actions.preview_image')`Обзорное изображение`)}
              onClick={handleTogglePreview}
            >
              <PictureIcon fill="currentColor" />
            </Button>
          </div>
          {data.platformIdentifier !== 'AI2D' &&
            Array.isArray(data.coverage) &&
            data.coverage.length > 0 &&
            data.coverage?.filter(el => el.processingLevelCode !== '2B').length !== 0 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isFullImageActive,
                })}
                title={i18n._(
                  t(
                    'image_actions.full_resolution_image_multispectral_image'
                  )`Снимок в полном разрешении (мультиспектральное изображение)`
                )}
                onClick={handleToggleFullImage2B1}
                // onClick={showSomething}
                // disabled={data.coverage.length === 0}
              >
                <EyeIcon fill="currentColor" />
              </Button>
            )}
          {data.platformIdentifier !== 'AI2D' &&
            Array.isArray(data.coverage) &&
            data.coverage.length > 0 &&
            data.coverage?.filter(el => el.processingLevelCode !== '2B1').length !== 0 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isFullImageActive2B,
                })}
                title={i18n._(
                  t(
                    'image_actions.full_resolution_image_panchromatic_image'
                  )`Снимок в полном разрешении (панхроматическое изображение)`
                )}
                onClick={handleToggleFullImage2B}
                // onClick={showSomething}
                // disabled={data.coverage.length === 0}
              >
                <EyeIcon fill="#393939" />
              </Button>
            )}
          {Array.isArray(data.coverage) &&
            data.platformIdentifier === 'AI2D' &&
            data.coverage.length > 0 &&
            // data.coverage.filter((el: any) => el?.bands !== null && el?.bands !== undefined) &&
            data.coverage.filter((el: any) => el.bands && el.bands.length > 1).length !== 0 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isFullImageActive,
                })}
                title={i18n._(
                  t(
                    'image_actions.full_resolution_image_multispectral_image'
                  )`Снимок в полном разрешении (мультиспектральное изображение)`
                )}
                onClick={handleToggleFullImage2B1}
                // onClick={showSomething}
                // disabled={data.coverage.length === 0}
              >
                <EyeIcon fill="currentColor" />
              </Button>
            )}
          {Array.isArray(data.coverage) &&
            data.platformIdentifier === 'AI2D' &&
            data.coverage.length > 0 &&
            // data.coverage?.filter((el: any) => el?.bands !== null && el?.bands !== undefined) &&
            data.coverage.filter((el: any) => el.bands && el.bands.length > 1).length !== 0 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isFullImageActive2B,
                })}
                title={i18n._(
                  t(
                    'image_actions.full_resolution_image_panchromatic_image'
                  )`Снимок в полном разрешении (панхроматическое изображение)`
                )}
                onClick={handleToggleFullImage2B}
                // onClick={showSomething}
                // disabled={data.coverage.length === 0}
              >
                <EyeIcon fill="#393939" />
              </Button>
            )}
          {/* {Array.isArray(data.coverage) &&
            data.coverage.length > 0 &&
            data.coverage?.filter(el => el.processingLevelCode !== '2B').length !== 0 && (
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isFullImageActive,
                })}
                title={i18n._(
                  t(
                    'image_actions.full_resolution_image_multispectral_image'
                  )`Снимок в полном разрешении (мультиспектральное изображение)`
                )}
                onClick={handleToggleFullImage2B1}
                // onClick={showSomething}
                // disabled={data.coverage.length === 0}
              >
                <EyeIcon fill="currentColor" />
              </Button>
            )}
          {data.coverage?.filter(el => el.processingLevelCode !== '2B1').length !== 0 && (
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                active: props.isFullImageActive2B,
              })}
              title={i18n._(
                t(
                  'image_actions.full_resolution_image_panchromatic_image'
                )`Снимок в полном разрешении (панхроматическое изображение)`
              )}
              onClick={handleToggleFullImage2B}
              // onClick={showSomething}
              // disabled={data.coverage.length === 0}
            >
              <EyeIcon fill="#393939" />
            </Button>
          )} */}
        </div>
        <div>
          <div className={styles.test11}>
            <Trans>Метаданные:</Trans>
          </div>

          <div className={styles.test11}>
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton)}
              title={i18n._(t('image_actions.metadata_download')`Скачать метаданные`)}
              onClick={handleMetadataDownload}
            >
              <FileDownloadIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withI18n()(SearchResultItemFooter);
