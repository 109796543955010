// import { t } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import { USE_FAKE_API } from 'api/realAPI';
import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import cloneDeep from 'lodash/cloneDeep';
// import last from 'lodash/last';
import get from 'lodash/get';
import remove from 'lodash/remove';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
// import requestFlow from './network'; // './request';
import { requestFlowNew } from './network';
import authRefresh from './networkIAM';

import { notifyActions } from 'ducks/message';
import { ImageMetadataDetail, SearchMetadataParams } from 'ducks/types/metadataTypes';
import {
  clearMetadata,
  fetchImageMetadata,
  getActiveImageContours,
  getActiveImagePreviews,
  // toggleMetadataRouteContour,
  getActiveViewFullImages,
  // toggleMetadataRoutePreview,
  getActiveViewFullImages2B,
  // toggleViewFullImage,
  getMetadata,
  removeMetadataRoute,
  searchImages /* , getSearchParams */,
  // ImageMetadata,
  selectMetadataRoute,
  toggleImageContour,
  toggleImageFullRes,
  toggleImageFullRes2B,
  toggleImagePreview,
} from '../ducks/metadata';

import GeoportalMap from 'components/map/Map/Map';
import { s3Client } from './authIAM';

// import * as API from './api'
import API from 'api';
// import { prepareCatalogApiErrorMessage } from 'api/helperErrorMessage';
import {
  parseApiObjectV2,
  parseBbpMetadataObject,
  parseMetadataObject,
  serializeMetadataSearchParams,
} from 'components/utils/apiDataConverter';
import { genId } from 'components/utils/identifier';

import { getIsFree } from 'ducks/authIAM';
import { getLanguage } from 'ducks/locale';
import { captureSagaException } from 'modules/monitor/sentryHelper'; // captureSimpleException
import { Debugger } from 'utils/logging';
const debug = Debugger('MetadataSaga'); // TAG

// import axios from 'axios';
// const CancelToken = axios.CancelToken;

const kvPSSSensor = {
  number: 1,
  instrumentIdentifier: 'PSS',
  id: 2,
  bands: [
    {
      min: 0.54,
      max: 0.86,
      description: 'Панхром',
      id: 30,
    },
  ],
  isPanchromatic: true,
};

// TODO - вынести в утилиты и объединить с (src\components\utils\order.ts, src\pages\HomePage\sections\SearchResult\OrderImage\utils.ts)
const isBbpSensor = (sensorId: string) => {
  return ['MSUTM101,MSUTM102', 'MSUMR', 'OLITIRS', 'MSI'].indexOf(sensorId) > -1;
};
const isSTACSensor = (sensorId: string) => {
  return ['MSU-GS-E', 'MSU-GS-A'].indexOf(sensorId) > -1;
};

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getPresignedUrl = async (url: string) => {
  const parsedUrl = new URL(url);
  let bucketS3;
  let objectS3;
  if (['http:', 'https:'].includes(parsedUrl.protocol)) {
    bucketS3 = parsedUrl.pathname.split('/')[1];
    objectS3 = parsedUrl.pathname.split('/').slice(2).join('/');
  } else if (parsedUrl.protocol === 's3:') {
    bucketS3 = parsedUrl.pathname.split('/')[3];
    objectS3 = parsedUrl.pathname.split('/').slice(4).join('/');
  }
  return s3Client.presignedGetObject(bucketS3, objectS3, 24 * 60 * 60);
};

type SearchMetadataProvider = 'etris' | 'bbp' | 'stac';

export function* fetchResource(params: any, provider: SearchMetadataProvider) {
  try {
    let results;
    switch (provider) {
      case 'etris': {
        const paramsForApi = serializeMetadataSearchParams(params);
        const data = yield call(authRefresh, requestFlowNew, API.searchImagesV2, paramsForApi);
        data.results = data.results.map((mdObject: any) => parseMetadataObject(mdObject));
        results = data;
        break;
      }
      case 'bbp': {
        try {
          const paramsForApi = serializeMetadataSearchParams(params);
          const data = yield call(authRefresh, requestFlowNew, API.searchBbpScenes, paramsForApi);
          data.results = data.results.map((mdObject: any) => parseBbpMetadataObject(mdObject));
          results = data;
        } catch (error) {
          yield put(
            notifyActions.push({
              timeout: 10000,
              color: 'alert',
              message: i18nMark('bbp_service_unavailable'),
              place: 'bc',
            })
          );
        }
        break;
      }
      default:
        throw Error('Provider not found!');
    }
    // console.log('fetchResource results', results);
    return results;
    // yield put(searchObjects.success({ name: provider, results }));
  } catch (error) {
    // console.log('fetchResource error', error);
    //// debugger;
    // стало
    // if (get(error, 'response.status') === 400) {
    throw error;
    // }
    //// yield put(searchObjects.failure(provider));
    // было
    // return null;
  }
}

export function* searchImagesWatch() {
  yield takeLatest(getType(searchImages.request), searchImagesFlow);
}
export function* searchImagesFlow(action: ActionType<typeof searchImages.request>) {
  const messageId = genId();
  // @ts-ignore
  // qqwe11();
  try {
    const params = cloneDeep(action.payload); //  избегание мутирования массива instruments при удалении MSU101
    const isFreeUser: boolean = yield select(getIsFree);
    const bbpDiffParams: Partial<SearchMetadataParams> = {
      instrumentIdentifiers: [],
    };
    const stacDiffParams: Partial<SearchMetadataParams> = {
      instrumentIdentifiers: [],
    };
    if (params.instrumentIdentifiers) {
      bbpDiffParams.instrumentIdentifiers = remove(params.instrumentIdentifiers, isBbpSensor);
      if (!isFreeUser) {
        bbpDiffParams.instrumentIdentifiers = bbpDiffParams.instrumentIdentifiers.filter(
          sensor => !['MSUTM101,MSUTM102'].includes(sensor)
        );
      }
      stacDiffParams.instrumentIdentifiers = remove(params.instrumentIdentifiers, isSTACSensor);
      // костыль. подменяем PSS,MSS -> MSS
      params.instrumentIdentifiers = params.instrumentIdentifiers.map((item, index) =>
        item === 'PSS,MSS' ? 'MSS' : item
      );
      // Удаление сенсоров для платных пользователей. Временное решение
      if (!isFreeUser) {
        params.instrumentIdentifiers = params.instrumentIdentifiers.filter(
          sensor => !['AVR', 'MSU101,MSU102'].includes(sensor)
        );
      }
    }
    if (params.acquisitionDateAfter instanceof Date) {
      params.acquisitionDateAfter.setHours(0, 0, 0, 0);
    }
    if (params.acquisitionDateBefore instanceof Date) {
      params.acquisitionDateBefore.setHours(23, 59, 59, 0);
    }

    // @ts-ignore
    // qqwe22();

    // Add pagination
    // offet/page, limit save in store ?
    const append = get(params, 'offset', 0) !== 0;

    yield put(
      notifyActions.push({
        timeout: -1,
        id: messageId,
        message: i18nMark('Идет поиск снимков ...'),
      })
    );

    // TODO - отображать пользователю результаты, как только они приходят с сервера
    const [etrisResults, bbpResults, stacResults]: any = yield all([
      // call(fetch, '/repos'),
      params.instrumentIdentifiers!.length > 0 ||
      (bbpDiffParams.instrumentIdentifiers!.length === 0 &&
        stacDiffParams.instrumentIdentifiers!.length === 0)
        ? call(fetchResource, params, 'etris')
        : null,
      bbpDiffParams.instrumentIdentifiers!.length > 0
        ? call(fetchResource, { ...params, ...bbpDiffParams }, 'bbp')
        : null,
      stacDiffParams.instrumentIdentifiers!.length > 0
        ? call(fetchResource, { ...params, ...stacDiffParams }, 'stac')
        : null,
    ]);
    // костыль. подменяем MSS -> PSS,MSS
    // "sensors":[{"number":1,"instrument_identifier":"PSS","id":2,"bands":[{"min":0.54,"max":0.86,"description":"Панхром","id":30}],"is_panchromatic":true}],
    if (etrisResults) {
      etrisResults.results = etrisResults.results.map((md: any) =>
        md.instrumentIdentifier === 'MSS' ? md.sensors.unshift(kvPSSSensor) && md : md
      );
    }
    const data = {
      count:
        get(stacResults, 'count', 0) + get(bbpResults, 'count', 0) + get(etrisResults, 'count', 0),
      results: [
        ...get(stacResults, 'results', []),
        ...get(bbpResults, 'results', []),
        ...get(etrisResults, 'results', []),
      ],
    };

    // const paramsForApi = serializeMetadataSearchParams(params);
    // const data = yield call(authRefresh, requestFlowNew, API.searchImagesV2, paramsForApi);
    // if (get(bbpDiffParams, 'instrumentIdentifiers', []).length > 0) {
    //   console.log('bbpDiffParams', bbpDiffParams);
    // }
    // const count = data.count;
    // let results = data.results.map((mdObject: any) => parseMetadataObject(mdObject));
    const count = data.count;
    let results = data.results;

    if (USE_FAKE_API === 'yes') {
      const delayValue = getRandomInt(100, 5000);
      debug.log('delayValue', delayValue);
      yield delay(delayValue);
    }

    yield put(notifyActions.remove(messageId));

    const map = GeoportalMap.getInstance();
    yield call([map, 'addMetadataResults'], results, append); // false // resData.data
    debug.log(results);

    if (append === true) {
      const metadataRoutes = yield select(getMetadata); // getSearchResults
      results = [...metadataRoutes, ...results];
    }

    yield put(searchImages.success({ count, results }));
    if (count === 0) {
      yield put(
        notifyActions.push({
          timeout: 4000,
          color: 'info',
          message: i18nMark('Cнимков не найдено!'),
          place: 'bc',
        })
      );
    }
  } catch (error) {
    debug.log(error);
    const locale = yield select(getLanguage);
    yield put(searchImages.failure(error));
    yield put(notifyActions.remove(messageId));

    // if (get(error, 'response.status') === 400 && get(error, 'response.data.errors')) {
    if (error.isAxiosError === true && String(error.response.status).startsWith('4')) {
      // const errorsResp = error.response.data.errors;
      const errorsParsed = parseApiObjectV2(error.response.data);
      // const errorMessage = prepareCatalogApiErrorMessage('', errorsParsed);
      const errorMessage = errorsParsed[locale];

      yield put(
        notifyActions.push({
          color: 'alert',
          message: errorMessage,
          // errorsResp.geometry
          //   ? t('messages.search_error_geometry')`Невалидная геометрия`
          //   : t('messages.search_error_params')`Ошибка в параметрах запроса`,
          place: 'bc',
        })
      );
      //
    } else {
      yield put(
        notifyActions.push({
          color: 'alert',
          message: i18nMark('Ошибка при поиске снимков'),
          place: 'bc',
        })
      );
    }

    //
    // throw new Error('ddddddddddddddddddddddddd')
    // throw error;
    // Sentry.captureException(error);
    // scope breadcrumbs:
    // action: action.type
    captureSagaException(error, action.type, action.payload);
    //
  }
}

export function* fetchImageMetadataWatch() {
  yield takeLatest(getType(fetchImageMetadata.request), fetchImageMetadataFlow);
}
export function* fetchImageMetadataFlow(action: ActionType<typeof fetchImageMetadata.request>) {
  try {
    const { callback, ...params } = action.payload;

    const paramsForApi = serializeMetadataSearchParams(params);
    const data = yield call(authRefresh, requestFlowNew, API.searchImagesV2, paramsForApi);
    data.results = data.results.map((mdObject: any) => parseMetadataObject(mdObject));
    // console.log(data);
    if (callback !== undefined) {
      callback(data);
    }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    // console.error('Error', error);
  }
}

export function* selectMetadataRouteWatch() {
  // yield takeLatest(getType(selectMetadataRoute), selectMetadataRouteFlow);
  yield takeLatest(selectMetadataRoute, selectMetadataRouteFlow);
}
export function* selectMetadataRouteFlow(action: ActionType<typeof selectMetadataRoute>) {
  try {
    const { metadataId, isZoomNeed } = action.payload;
    const metadata: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImagePreviews> = yield select(
      getActiveImagePreviews
    );
    const map = GeoportalMap.getInstance();
    // gpMap.selectMetadataFeature(metadataId);
    yield call([map, 'selectMetadataFeature'], metadataId, isZoomNeed);
    yield call([map, 'toggleImagePreview'], metadata, false);
    yield put(
      toggleImagePreview.success(
        activeImageIds.filter(item => item !== metadataId),
        false
      )
    );
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export function* clearMetadataFeaturesWatch() {
  yield takeLatest(clearMetadata, clearMetadataFlow);
}

export function* clearMetadataFlow(action: ActionType<typeof clearMetadata>) {
  try {
    const { ids } = action.payload;
    const map = GeoportalMap.getInstance();
    yield call([map, 'clearMetadataFeatures'], ids);
  } catch (error) {
    captureSagaException(error, action.type);
    console.error('Error', error);
  }
}

export function* removeMetadataRouteWatch() {
  yield takeLatest(removeMetadataRoute, removeMetadataRouteFlow);
}
export function* removeMetadataRouteFlow(action: ActionType<typeof removeMetadataRoute>) {
  try {
    const { metadataId } = action.payload;

    const map = GeoportalMap.getInstance();
    yield call([map, 'removeMetadataFeature'], metadataId);
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

// export function* toggleMetadataRouteContourWatch() {
//   // yield takeLatest(getType(toggleMetadataRouteContour), toggleMetadataRouteContourFlow);
//   yield takeLatest(toggleMetadataRouteContour, toggleMetadataRouteContourFlow);
// }
// export function* toggleMetadataRouteContourFlow(
//   action: ActionType<typeof toggleMetadataRouteContour>
// ) {
//   try {
//     const { metadataId } = action.payload;
//     const activeViewFullImages = yield select(getActiveViewFullImages);
//     const activeImageContours = yield select(getActiveImageContours);
//     const isViewFullImageActive =
//       activeViewFullImages.indexOf(String(metadataId)) > -1 ? true : false;
//     const isImageContourActive =
//       activeImageContours.indexOf(String(metadataId)) > -1 ? true : false;

//     const map = GeoportalMap.getInstance();
//     yield call(
//       [map, 'toggleMetadataContour'],
//       metadataId,
//       isViewFullImageActive,
//       isImageContourActive
//     );
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }
export function* toggleImageContourWatch() {
  yield takeLatest(getType(toggleImageContour.request), toggleImageContourFlow);
}
export function* toggleImageContourFlow(action: ActionType<typeof toggleImageContour.request>) {
  try {
    const { ids } = action.payload; // flag, isZoomNeed
    // const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImageContours> = yield select(
      getActiveImageContours
    );

    const [_flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    // const idsToUpdate = flag === true ? inactived : actived;
    // inverting
    const flag = !_flag;
    const idsToUpdate = flag === true ? actived : inactived;
    // activeImageContours
    // const imagesToUpdate = images.filter(md => idsToUpdate.indexOf(String(md.id)) > -1);
    // console.log(flag, inactived, actived);

    const activeViewFullImages: ReturnType<typeof getActiveViewFullImages> = yield select(
      getActiveViewFullImages
    );
    const isImagesFullResActive = idsToUpdate.map(mdId => activeViewFullImages.indexOf(mdId) > -1);

    // const activeImageContours = yield select(getActiveImageContours);
    // const isViewFullImageActive =
    //   activeViewFullImages.indexOf(String(metadataId)) > -1 ? true : false;
    // const isImageContourActive =
    //   activeImageContours.indexOf(String(metadataId)) > -1 ? true : false;

    const map = GeoportalMap.getInstance();
    yield call([map, 'toggleImageContour'], idsToUpdate, isImagesFullResActive, flag);

    yield put(toggleImageContour.success(idsToUpdate, flag)); // (ids, flag)
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

// export function* toggleMetadataRoutePreviewWatch() {
//   // yield takeLatest(getType(toggleMetadataRoutePreview), toggleMetadataRoutePreviewFlow);
//   yield takeLatest(toggleMetadataRoutePreview, toggleMetadataRoutePreviewFlow);
// }
// export function* toggleMetadataRoutePreviewFlow(
//   action: ActionType<typeof toggleMetadataRoutePreview>
// ) {
//   try {
//     const { metadataId } = action.payload; // isZoomNeed
//     const metadataRoutes = yield select(getMetadata); // getSearchResults
//     // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
//     const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

//     const map = GeoportalMap.getInstance();
//     yield call([map, 'createImageFeature'], route); // previewsTemp
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }

/* const getToggleFlag = (activeImageIds: any[], selectedIds: any[]) => {
  let state = null as any;
  for (let id of selectedIds) {
    let s = activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
    // console.log('for loop ', i, s);
    if (state === null) {
      state = s;
    } else if (state !== s) {
      state = 'mixed';
      break;
    }
  }
  const flag = state === 'active' ? false : true;
  return flag;
}; */
const getToggleFlagDetailed = (activeImageIds: string[], selectedIds: string[]) => {
  const inactived: string[] = [];
  const actived: string[] = [];

  for (const id of selectedIds) {
    const activeId = activeImageIds.find(aId => aId === id);
    if (activeId !== undefined) {
      actived.push(activeId);
    } else {
      inactived.push(id);
    }
  }

  const flag = selectedIds.length === actived.length ? false : true;
  return [flag, inactived, actived] as [boolean, string[], string[]];
};

// export function* toggleMetadataRoutePreviewWatch() {
//   // yield takeLatest(getType(toggleMetadataRoutePreview), toggleMetadataRoutePreviewFlow);
//   yield takeLatest(toggleMetadataRoutePreview, toggleMetadataRoutePreviewFlow);
// }
// export function* toggleMetadataRoutePreviewFlow(
//   action: ActionType<typeof toggleMetadataRoutePreview>
// ) {
//   try {
//     const { metadataId } = action.payload; // isZoomNeed
//     const metadataRoutes = yield select(getMetadata); // getSearchResults
//     // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
//     const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

//     const map = GeoportalMap.getInstance();
//     yield call([map, 'createImageFeature'], route); // previewsTemp
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }
export function* toggleImagePreviewWatch() {
  // yield takeLatest(getType(toggleImagePreview), toggleMetadataRoutePreviewFlow);
  yield takeLatest(getType(toggleImagePreview.request), toggleImagePreviewFlow);
}
export function* toggleImagePreviewFlow(action: ActionType<typeof toggleImagePreview.request>) {
  try {
    // ids - selected ids
    const { ids } = action.payload; // flag, isZoomNeed
    const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImagePreviews> = yield select(
      getActiveImagePreviews
    );
    // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
    // const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

    // let state =
    //   activeImageIds.find(activeId => activeId === ids[0]) !== undefined ? 'active' : 'inactive';
    // // for (let id of ids) {
    // for (let i = 1; i < ids.length; i++) {
    //   let id = ids[i];
    //   let s =
    //     activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
    //   // console.log('for loop ', i, s);
    //   if (state !== s) {
    //     state = 'mixed';
    //     break;
    //   }
    // }
    /* let state = null as any;
    for (let id of ids) {
      let s =
        activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
      // console.log('for loop ', i, s);
      if (state === null) {
        state = s;
      } else if (state !== s) {
        state = 'mixed';
        break;
      }
    }
    // debugger;
    // console.log('end for loop ', state);
    const flag = state === 'active' ? false : true;
    const selectedImages = images.filter(md => ids.indexOf(String(md.id)) > -1); */

    // console.log(getToggleFlagDetailed(activeImageIds, ids));
    const [flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    const idsToUpdate = flag === true ? inactived : actived;
    const imagesToUpdate = images.filter(md => idsToUpdate.indexOf(md.identifier) > -1);
    // console.log(flag, inactived, actived);

    const map = GeoportalMap.getInstance();
    // yield call([map, 'createImageFeature'], route); // previewsTemp
    yield call([map, 'toggleImagePreview'], imagesToUpdate, flag);

    yield put(toggleImagePreview.success(ids, flag));
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export function* toggleImageFullResWatch() {
  // yield takeLatest(getType(toggleViewFullImage), toggleViewFullImageFlow);
  yield takeLatest(getType(toggleImageFullRes.request), toggleImageFullResFlow);
}
export function* toggleImageFullResFlow(action: ActionType<typeof toggleImageFullRes.request>) {
  try {
    // ids - selected ids
    const { ids, layId } = action.payload; // flag, isZoomNeed
    const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveViewFullImages> = yield select(
      getActiveViewFullImages
    );

    // const metadataRoutes = yield select(getMetadata); // getSearchResults
    // const route: ImageMetadataDetail = metadataRoutes.find(
    //   (item: ImageMetadata) => item.id === metadataId
    // );

    const [_flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    let flag = _flag;
    const idsToUpdate = flag === true ? inactived : actived;
    let imagesToUpdate = images.filter(md => {
      const _md = md as ImageMetadataDetail;
      if (!Array.isArray(_md.coverage) || _md.coverage.length === 0) {
        return false;
      }
      return idsToUpdate.indexOf(md.identifier) > -1;
    });
    if (imagesToUpdate.length === 0) {
      imagesToUpdate = images.filter(md => actived.indexOf(md.identifier) > -1);
      flag = false;
    }
    // console.log(flag, imagesToUpdate);
    // console.log(inactived, actived);
    // console.log('toggleViewFullImageFlow', route);
    // if (Array.isArray(route.coverage) && route.coverage.length > 0) {
    // if (!Array.isArray(route.coverage) || route.coverage.length === 0) {
    //   return;
    // }

    // if (layerData !== undefined) {
    //   const map = GeoportalMap.getInstance();
    //   yield call([map, 'toggleViewFullImage'], layerData, route.identifier);
    // }
    const map = GeoportalMap.getInstance();
    // const layers = getLayersFromImageMetadata(imagesToUpdate as ImageMetadataDetail[]);
    yield call([map, 'toggleImageFullRes'], imagesToUpdate as ImageMetadataDetail[], flag, layId);

    // prettier-ignore
    yield put(toggleImageFullRes.success(imagesToUpdate.map(md => md.identifier), flag, layId)); // (ids, flag)
    // }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export function* toggleImageFullResWatch2B() {
  // yield takeLatest(getType(toggleViewFullImage), toggleViewFullImageFlow);
  yield takeLatest(getType(toggleImageFullRes2B.request), toggleImageFullResFlow2B);
}
export function* toggleImageFullResFlow2B(action: ActionType<typeof toggleImageFullRes2B.request>) {
  try {
    // ids - selected ids
    const { ids, layId } = action.payload; // flag, isZoomNeed
    const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveViewFullImages2B> = yield select(
      getActiveViewFullImages2B
    );

    // const metadataRoutes = yield select(getMetadata); // getSearchResults
    // const route: ImageMetadataDetail = metadataRoutes.find(
    //   (item: ImageMetadata) => item.id === metadataId
    // );

    const [_flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    let flag = _flag;
    const idsToUpdate = flag === true ? inactived : actived;
    let imagesToUpdate = images.filter(md => {
      const _md = md as ImageMetadataDetail;
      // console.log([_md], 596)
      if (!Array.isArray(_md.coverage) || _md.coverage.length === 0) {
        return false;
      }
      return idsToUpdate.indexOf(md.identifier) > -1;
    });
    if (imagesToUpdate.length === 0) {
      imagesToUpdate = images.filter(md => actived.indexOf(md.identifier) > -1);
      flag = false;
    }
    // console.log(flag, imagesToUpdate);
    // console.log(inactived, actived);
    // console.log('toggleViewFullImageFlow', imagesToUpdate);
    // if (Array.isArray(route.coverage) && route.coverage.length > 0) {
    // if (!Array.isArray(route.coverage) || route.coverage.length === 0) {
    //   return;
    // }

    // if (layerData !== undefined) {
    //   const map = GeoportalMap.getInstance();
    //   yield call([map, 'toggleViewFullImage'], layerData, route.identifier);
    // }
    const map = GeoportalMap.getInstance();
    // const layers = getLayersFromImageMetadata(imagesToUpdate as ImageMetadataDetail[]);
    yield call([map, 'toggleImageFullRes2B'], imagesToUpdate as ImageMetadataDetail[], flag, layId);

    // prettier-ignore
    yield put(toggleImageFullRes2B.success(imagesToUpdate.map(md => md.identifier), flag, layId)); // (ids, flag)
    // }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

// TODO - all vs fork?!
// export default function* metadataRoot() {
//   yield all([
//     takeLatest(getType(searchMetadata.request), searchMetadataFlow),
//     // takeLatest(getType(addTodo.next), addTodoSaga),
//   ]);
// }
export default function* root() {
  yield fork(searchImagesWatch);
  yield fork(fetchImageMetadataWatch);
  yield fork(selectMetadataRouteWatch);
  yield fork(clearMetadataFeaturesWatch);
  yield fork(removeMetadataRouteWatch);
  yield fork(toggleImageContourWatch);
  yield fork(toggleImagePreviewWatch);
  yield fork(toggleImageFullResWatch);
  yield fork(toggleImageFullResWatch2B);
}
