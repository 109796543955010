import React from 'react';


const styles = require('./ErrorFragment-styles.module.scss');

interface ErrorFragmentProps {
  code: string;
  title: string;
}

const ErrorFragment: React.FC<ErrorFragmentProps> = ({ code, title, children }) => (
  <React.Fragment>
    <h1 className={styles.title}>{code}</h1>
    <h2 className={styles.subTitle}>{title}</h2>
    <div>{children}</div>
  </React.Fragment>
);

export default ErrorFragment;
