import { createActionCreator, createReducer } from 'deox';
import { combineReducers } from 'redux';
// import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { RootState as RootStateType } from './root';

/**
 * Types/Interfaces
 */
type UserGroup =
  | '/geoportal-customers'
  | '/geoportal-users'
  | '/Группы/Потребители'
  | '/Группы/Пользователи геопортала';

// KeycloakProfile
export interface UserProfile {
  id: string; // sub (subject)
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  attributes: any; // {}
  isFree: boolean;
  groups: UserGroup[]; // ["/geoportal-customers", "/geoportal-users"]
  isGeofenced: boolean;
  geofence: string;
}

export class User {
  public profile: UserProfile;

  // если надо обновить профиль - создай пользователя заново!
  constructor(profile: UserProfile) {
    this.profile = profile;
  }

  public getProfile() {
    return this.profile;
  }

  // TODO - pay free. нужно ли платить пользователю. сделал на стороне фронта. может бэк должен возвращать 0 цену
  // isNeedPay(), !isFreePay()
  public haveToPay() {
    return !this.profile.isFree;
    // return !(
    //   this.profile.groups.indexOf('/geoportal-customers') > -1 ||
    //   this.profile.groups.indexOf('/Группы/Потребители') > -1
    // );
  }
}

export interface LoginRegisterParams {
  popup?: boolean;
  redirectUri?: string;
}

/**
 * Actions
 */
export const iamActions = {
  check: createActionCreator('IAM/CHECK'), // init: 'IAM/INIT' || IAM/CHECK_REQUEST
  login: createActionCreator('IAM/LOGIN_REQUEST', resolve => (data?: LoginRegisterParams) =>
    resolve(data)
  ), // (data?: 'popup') => resolve(data)),
  register: createActionCreator('IAM/REGISTER_REQUEST', resolve => (data?: 'popup') =>
    resolve(data)
  ),
  logout: createActionCreator('IAM/LOGOUT_REQUEST'),
  success: createActionCreator('IAM/SUCCESS', resolve => (data: User) => resolve(data)), // (data: UserProfile | null)
  failure: createActionCreator('IAM/FAILURE', resolve => error => resolve(error)),
};

/**
 * Reducers and state
 */

export const isAuthenticatedDefaultState: boolean = false;
export const isAuthenticatedReducer = createReducer(isAuthenticatedDefaultState, handleAction => [
  // handleAction(iamActions.success, () => iamProvider.authenticated || false), // true | false
  handleAction(iamActions.success, () => true),
  handleAction(iamActions.failure, () => false),
  // handleAction(iamActions.logout, () => false), // пока не используется, logout через редирект
]);

export const isFetchingDefaultState: boolean = false;
export const isFetchingReducer = createReducer(isFetchingDefaultState, handleAction => [
  handleAction(iamActions.check, () => true),
  handleAction(iamActions.success, () => false),
  handleAction(iamActions.failure, () => false),
]);

// export const profileDefaultState = null as UserProfile | null;
export const userDefaultState = null as User | null;
export const userReducer = createReducer(userDefaultState, handleAction => [
  handleAction(iamActions.success, (_, { payload }) => payload), // (payload ? payload : null)),
  // handleAction(iamActions.failure, () => null),
  // handleAction(iamActions.logout, () => null),
]);

// metadataRootReducer
export default combineReducers({
  // iamProvider: iamProviderDefaultState,
  isAuthenticated: isAuthenticatedReducer,
  isFetching: isFetchingReducer,
  // profile: profileReducer,
  user: userReducer,
});

/**
 * Selectors
 */
export const getIsAuthenticated = (state: RootStateType) => state.iam.isAuthenticated; // or iamProvider.authenticated;
export const getIFetching = (state: RootStateType) => state.iam.isFetching;
// TODO - remove
export const getProfile = (state: RootStateType) => state.iam.user?.getProfile(); // state.iam.profile; // or iamProvider.profile;
export const getUser = (state: RootStateType) => state.iam.user; // or iamProvider.profile;
export const getIsFree = (state: RootStateType) => state.iam.user.profile.isFree;
export const getIsGeofenced = (state: RootStateType) => state.iam.user.profile.isGeofenced;
