import React from 'react';
import { t } from '@lingui/macro'; // Trans
import { I18n } from '@lingui/react';

import { formatValue } from 'components/utils/format';

export const renderResolution = (value: number | string) => {
  // etris - 0.2 м
  // bbp - L M H
  return (
    <I18n>
      {({ i18n }) => (typeof value === 'number' ? formatValue(value, i18n._(t`м`)) : value)}
    </I18n>
  );
};

export const renderSensorBands = (sensors: any[]) => {
  return (
    <I18n>
      {({ i18n }) => (
        <>
          {sensors.map((sensor, index) => (
            <React.Fragment key={sensor.id || index}>
              {index > 0 && '; '}
              {sensor.bandsNames !== undefined ? (
                // bbp
                <>{sensor.bandsNames.map((bandName: any) => bandName).join(', ')}</>
              ) : (
                // etris
                <>
                  {sensor.bands
                    .map((band: any) => `${band.min}-${band.max} ${i18n._(t`мкм`)}`)
                    .join(', ')}
                </>
              )}

              {/* ('; ') */}
            </React.Fragment>
          ))}
        </>
      )}
    </I18n>
  );
};
