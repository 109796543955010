import React from 'react';
import styles from './FinishedProducts.css';
import FolderDownloadIcon from '@geobank/assets/src/icons/FolderDownloadIcon';
import FolderCheckIcon from '@geobank/assets/src/icons/FolderCheckIcon';

interface IFinishedProductsProps {
  value?: IFinishedProductsValue[];
}

interface IFinishedProductsState {
  loadProgress: number;
}

export interface IFinishedProductsValue {
  name: string;
  link: string;
}

export default class FinishedProducts extends React.Component<
  IFinishedProductsProps,
  IFinishedProductsState
> {
  constructor(props: IFinishedProductsProps) {
    super(props);
    this.state = {
      loadProgress: 0,
    };
  }

  private readonly runProgressBar = () => {
    const { loadProgress } = this.state;
    if (loadProgress < 100) {
      setTimeout(() => {
        this.setState({ loadProgress: loadProgress + 1 }, () => this.runProgressBar());
      }, 13);
    }
  };

  public render() {
    return (
      <div className={styles.finishedProductsContainer}>
        <div className={styles.finishedProductsHeader}>
          <div>
            <span className={styles.finishedProductsHeaderSpan}>Готовая продукция</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <FolderDownloadIcon />
            </div>
            <div>
              <span className={styles.spanClick}>Скачать все</span>
            </div>
          </div>
        </div>
        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <span>{'0041_0306_27836_1_07953_02_G_19.tiff'}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <span style={{ color: '#8997B4' }}>{`${this.state.loadProgress}%`}</span>
              </div>
              <div onClick={() => this.runProgressBar()} style={{ cursor: 'pointer' }}>
                {this.state.loadProgress === 100 ? <FolderCheckIcon /> : <FolderDownloadIcon />}
              </div>
            </div>
          </div>
          <div className={styles.loaderContainer}>
            <div
              style={{
                width: `${this.state.loadProgress}%`,
                height: 3,
                backgroundColor: '#5AB8F1',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
