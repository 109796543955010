// import React from 'react';
import { t } from '@lingui/macro'; // Trans

export const decreeProductMappingVariants = [
  {
    number: 1,
    // text: <Trans id="pan:L1:null">Панхроматический снимок с радиометрической и ...</Trans>
    text: t(
      'image_product_descr.PAN:L1:null'
    )`Панхроматический снимок с радиометрической и геометрической коррекцией, без трансформирования в картографическую проекцию`,
    params: { productCode: 'L1', bandCombination: 'pan', elevation: null }, // elevation: null/false
    rate: 1.0,
  },
  {
    number: 2,
    text: t(
      'image_product_descr.MS:L1:null'
    )`Цветосинтезированный снимок с радиометрической и геометрической коррекцией, без трансформирования в картографическую проекцию`,
    params: { productCode: 'L1', bandCombination: 'ms', elevation: null }, // elevation: null/false
    rate: 1.1,
  },
  {
    number: 3,
    text: t(
      'image_product_descr.PAN:L2:false'
    )`Панхроматический снимок с радиометрической и геометрической коррекцией, трансформированный в картографическую проекцию по данным бортовой автоматической системы навигации`,
    params: { productCode: 'L2', bandCombination: 'pan', elevation: false },
    rate: 1.2,
  },
  {
    number: 4,
    text: t(
      'image_product_descr.MS:L2:false'
    )`Цветосинтезированный снимок с радиометрической и геометрической коррекцией, трансформированный в картографическую проекцию по данным бортовой автоматической системы навигации`,
    params: { productCode: 'L2', bandCombination: 'ms', elevation: false },
    rate: 1.3,
  },
  {
    number: 5,
    text: t(
      'image_product_descr.PAN:L2:true'
    )`Панхроматический снимок с радиометрической и геометрической коррекцией, ортотрансформированный в картографическую проекцию по опорным точкам местности с подключением цифровой модели рельефа`,
    params: { productCode: 'L2', bandCombination: 'pan', elevation: true },
    rate: 1.4,
  },
  {
    number: 6,
    text: t(
      'image_product_descr.MS:L2:true'
    )`Цветосинтезированный снимок с радиометрической и геометрической коррекцией, ортотрансформированный в картографическую проекцию по опорным точкам местности с подключением цифровой модели рельефа`,
    params: { productCode: 'L2', bandCombination: 'ms', elevation: true },
    rate: 1.5,
  },
  {
    number: 11,
    text: t(
      'image_product_descr.PANSHARP:L1:null'
    )`Комплексированное изображение панхроматического и многоспектрального снимков одной и той же территории с плановой точностью среднеквадратичного отклонения от 5 до 30 метров`,
    params: { productCode: 'L1', bandCombination: 'pansharp', elevation: null }, // elevation: false
    rate: 2.0,
  },
  {
    number: 11,
    text: t(
      'image_product_descr.PANSHARP:L2:null'
    )`Комплексированное изображение панхроматического и многоспектрального снимков одной и той же территории с плановой точностью среднеквадратичного отклонения от 5 до 30 метров`,
    params: { productCode: 'L2', bandCombination: 'pansharp', elevation: null }, // elevation: true
    rate: 2.0,
  },
  // bundle
  {
    number: -1,
    text: t(
      'image_product_descr.BUNDLE:L1:null'
    )`Комплект из Панхроматического и Цветосинтезированного снимков с радиометрической и геометрической коррекцией, без трансформирования в картографическую проекцию`,
    params: { productCode: 'L1', bandCombination: 'bundle', elevation: null }, // elevation: false
    rate: 2.1,
  },
  {
    number: -1,
    text: t(
      'image_product_descr.BUNDLE:L2:false'
    )`Комплект из Панхроматического и Цветосинтезированного снимков с радиометрической и геометрической коррекцией, трансформированные в картографическую проекцию по данным бортовой автоматической системы навигации`,
    params: { productCode: 'L2', bandCombination: 'bundle', elevation: false },
    rate: 2.5,
  },
  {
    number: -1,
    text: t(
      'image_product_descr.BUNDLE:L2:true'
    )`Комплект из Панхроматического и Цветосинтезированного снимков с радиометрической и геометрической коррекцией, ортотрансформированные в картографическую проекцию по опорным точкам местности с подключением цифровой модели рельефа`,
    params: { productCode: 'L2', bandCombination: 'bundle', elevation: true },
    rate: 2.9,
  },
  //
];
