import { t } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import React from 'react';

// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import { formatDate, parseDate } from 'components/utils/date';
import { bytesToHumanReadableSizeSplited } from 'components/utils/format';
import { MessageData } from 'ducks/messageNotify';
import { byteSizes } from 'locales/dicts/measures';
import { messages } from 'locales/dicts/notifyMessages';

import styles from './MessageTask.module.scss';

interface MessageTaskProps {
  data: MessageData;
  onReadMessage?: (messageId: number) => void;
}

const MessageTask: React.FC<MessageTaskProps & withI18nProps> = props => {
  const { onReadMessage, i18n } = props;
  const { id, resultType, message, createdAt, extendedData } = props.data;
  // const orderDate = parseDate(extendedData.orderDate);
  const createdAtDate = parseDate(createdAt);
  // const object = extendedData.object;

  const renderMessage = (key: string) => {
    return messages[key] ? i18n._(messages[key]) : key;
  };

  const renderSize = (size: number) => {
    const [value, measure] = bytesToHumanReadableSizeSplited(size);
    return `${value} ${i18n._(byteSizes[measure])}`;
  };

  // TODO - вёрства поиснтереснее!
  return (
    <div className={styles.root}>
      {/* <div>
        &nbsp;
        <span style={{ float: 'right' }}>{formatDate(orderDate)}</span>
      </div> */}
      <div className={styles.titleContainer}>
        <span className={styles.title}>{i18n._(t`Метаданные`)}</span>
        <span className={styles.date}>{formatDate(createdAtDate)}</span>
      </div>
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          <span>{renderMessage(message)}</span>
          <br />
          {resultType === 'success' && extendedData.object !== undefined && (
            <a href={extendedData.object.downloadUrl} target="_blank" rel="noopener noreferrer">
              {i18n._(t`Скачать`)} ({renderSize(extendedData.object.size)})
            </a>
          )}
        </div>
        <div className={styles.action}>
          {/* <Button
            color={ButtonColor.TRANSPARENT}
            className={styles.actionButton}
            title="Закрыть панель"
            // onClick={() => console.log('close click')}
            // onClick={onClose}
          >
            <CrossIcon />
          </Button> */}
          {onReadMessage && (
            <IconButton width="28px" height="28px" onClick={() => onReadMessage(id)}>
              <CrossIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default withI18n()(MessageTask);
