// searchFormPanelValidation.ts
import { i18nMark } from '@lingui/react';
import { createValidation } from 'components/utils/validation/form';

const validateDateRequired = createValidation(
  a => a && a instanceof Date,
  i18nMark('Заполните дату съёмки')
  // 'acquisitionDateBefore'
);
// const createDateRequiredValidation = (fieldName: string) => {
//   createValidation(
//     form => form[fieldName] && form[fieldName] instanceof Date,
//     i18nMark('Заполните дату съёмки')
//     // 'acquisitionDateBefore'
//   );
// }
const validateInstrumentsRequired = createValidation(
  a => a && a.length > 0,
  i18nMark('Выберите съёмочное устройство')
);
// run after required
// const createDateValidation => (date1, date2) => ...
const validateDateBefore = createValidation(
  (_, formData) =>
    !formData.acquisitionDateAfter || !formData.acquisitionDateBefore
      ? true
      : formData.acquisitionDateAfter <= formData.acquisitionDateBefore,
  i18nMark('Дата окончания съёмки должна быть больше даты начала')
);

export const validationFunctions = {
  // email: createEmail('foo@'),
  acquisitionDateAfter: [validateDateRequired],
  // acquisitionDateAfter: [createDateRequiredValidation],
  acquisitionDateBefore: [validateDateBefore], // validateDateRequired - можно без даты окончания
  instrumentIdentifiers: [validateInstrumentsRequired],
};
