import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CartRequest, fetchCartOrder } from 'ducks/order';
import RequestDetailPanel from '../RequestsTab/RequestDetailPanel';
import RequestProductsPanel from '../RequestsTab/RequestProductsPanel';

import styles from './OrderDetailView.module.scss';

interface OrderDetailViewProps {
  orderNumber: string;
}

const OrderDetailView: React.FC<OrderDetailViewProps> = props => {
  const { orderNumber } = props;
  // const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<CartRequest | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchCartOrder.request(
        orderNumber,
        {
          fields:
            'id,number,statuses,created_at,total_price,product_code,srs_name,bytes_per_pixel,certification,license,usage_time,payment_params,tags,bbp_order,images,products',
        },
        (req: CartRequest) => {
          setOrder(req);
          // setIsLoading(false);
        }
      )
    );
  }, [dispatch, orderNumber]);

  if (order === null) {
    return (
      <div className={styles.emptyWhiteBlock}>
        {/* <Trans>Загрузка заявки ...</Trans> */}
        <Trans>Загрузка информации по заявке ...</Trans>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainBlockContainer}>
        {/* <div className={styles.whiteBlock}>
          <div>
            <Trans>{'< Вернуться к списку заявок'}</Trans>
          </div>
        </div> */}

        <RequestDetailPanel
          request={order}
          onClose={() => {
            /* pass */
          }}
          actions={['back']}
        />
      </div>

      <div className={styles.secondBlockContainer}>
        <div className={styles.secondBlockInner}>
          <RequestProductsPanel products={order.products || []} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetailView;
