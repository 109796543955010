import React from 'react';
import { Trans } from '@lingui/macro';
import Tooltip from 'react-tooltip-lite';

import HelpIcon from 'components/common/HelpIcon/HelpIcon';

// Warning: Failed prop type: The prop `children` is marked as required in `Tooltip`, but its value is `undefined`. in Tooltip (at productOptions.tsx:12)
// export const PansharpTitle: React.FC = props => (
export const pansharpTitle = (
  <>
    <Trans id="image_product.value_pansharp">Паншарп-изображение</Trans>{' '}
    <Tooltip
      tagName="span"
      styles={{ marginLeft: '4px' }}
      tipContentClassName="tip-content--border-grey tip-content--width-320"
      arrow={false}
      background="white"
      color="black"
      direction="right"
      content={
        <span>
          <Trans id="image_product.hint_pansharp">
            Комплексированное изображение панхроматического и многоспектрального снимков одной и той
            же территории, доступно трансформирование в картографическую проекцию (задаётся на шаге
            2)
          </Trans>
        </span>
      }
    >
      <HelpIcon />
    </Tooltip>
  </>
);

export const bundleTitle = (
  <>
    <Trans id="image_product.value_bundle">BUNDLE-комплект (PAN + MS)</Trans>{' '}
    <Tooltip
      tagName="span"
      styles={{ marginLeft: '4px' }}
      tipContentClassName="tip-content--border-grey tip-content--width-320"
      arrow={false}
      background="white"
      color="black"
      direction="right"
      content={
        <span>
          <Trans id="image_product.hint_bundle">
            Комплект из Панхроматического и Цветосинтезированного снимков с радиометрической и
            геометрической коррекцией, доступно трансформирование в картографическую проекцию
            (задаётся на шаге 2)
          </Trans>
        </span>
      }
    >
      <HelpIcon />
    </Tooltip>
  </>
);

export const msTitle = (
  <>
    <Trans id="image_product.value_ms">Мультиспектральное изображение (MS)</Trans>{' '}
    <Tooltip
      tagName="span"
      styles={{ marginLeft: '4px' }}
      tipContentClassName="tip-content--border-grey tip-content--width-320"
      arrow={false}
      background="white"
      color="black"
      direction="right"
      content={
        <span>
          <Trans id="image_product.hint_ms">
            Цветосинтезированный снимок с радиометрической и геометрической коррекцией, доступно
            трансформирование в картографическую проекцию (задаётся на шаге 2)
          </Trans>
        </span>
      }
    >
      <HelpIcon />
    </Tooltip>
  </>
);

export const panTitle = (
  <>
    <Trans id="image_product.value_pan">Панхроматическое изображение (PAN)</Trans>{' '}
    <Tooltip
      tagName="span"
      styles={{ marginLeft: '4px' }}
      tipContentClassName="tip-content--border-grey tip-content--width-320"
      arrow={false}
      background="white"
      color="black"
      direction="right"
      content={
        <span>
          <Trans id="image_product.hint_pan">
            Панхроматический снимок с радиометрической и геометрической коррекцией, доступно
            трансформирование в картографическую проекцию (задаётся на шаге 2)
          </Trans>
        </span>
      }
    >
      <HelpIcon />
    </Tooltip>
  </>
);
