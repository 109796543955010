// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
import TileWMS from 'ol/source/TileWMS';

import { CreateLayerBundle } from '../types';

// TODO - CreateLayerOptions, CreateLayerParams || WMSLayerOptions, WMSLayerParams!
const createSimpleWMSLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options, params } = bundle;
  //
  // const url = 'http://tb-autotransforming.sec45.ccr.dep4.niitp:8080/geoserver/cite/wms';
  // const url = layerOptions.url;
  // const url = params.url;

  const layer = new TileLayer({
    // getFeatureInfo: true,
    id,
    name,
    source: new TileWMS({
      url: options.url,
      // params: { LAYERS: 'cite:mosaic1_metadata', TILED: true },
      params: {
        LAYERS: params!.layers,
        FORMAT: params!.format || 'image/png',
        TRANSPARENT: params!.transparent || true,
        TILED: true,
      },
      // serverType: 'geoserver',
      // crossOrigin: 'anonymous',
      attributions: options.attribution,
    }),
    zIndex: options.zIndex,
    // ...layerOptions,
  });

  return layer;
};

export default createSimpleWMSLayer;
