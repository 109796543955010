import React from 'react';
import { connect } from 'react-redux';


import BaseLayerControl from './BaseLayerControl/BaseLayerControl';
import { getIsFetching } from 'ducks/mapLayers';
import { getIsAuthenticated } from 'ducks/auth';
import { RootState } from 'ducks';

// import ButtonWithUnderlay from '@geobank/components/src/common/ButtonWithUnderlay/ButtonWithUnderlay';
// import Tag from '@geobank/components/src/common/Tag/Tag';
import DrawControl from './DrawControl/DrawControl';
import MessagesButton from './MessagesButton/MessagesButton';
import { getIsDrawControlShow } from 'ducks/map';
// import MainMenu from './MainMenu/MainMenu';
// import AuthButton from './AuthButton/AuthButton';
// import LangSelector from './LangSelector/LangSelector';
// import UserMenu from './UserMenu/UserMenu';

const styles = require('./MapControls-styles.module.scss');

interface MapControlsProps {
  isAuthenticated: boolean;
  isDrawControlShow: boolean;
}

const MapControls: React.FC<MapControlsProps> = props => {
  // TODO take from config
  const { isDrawControlShow } = props;
  return (
    <>
      {isDrawControlShow && (
        <div className={styles.mapDrawControls}>
          <DrawControl />
        </div>
      )}
      <div className={styles.mapSettingsGroup} /* className="map-top-right" */>
        <BaseLayerControl />
        <MessagesButton />
      </div>

      {/* {isAuthenticated ? (
        // <div className={styles.mapMainGroupTest}>
        <div className={styles.mapMainGroup}>
          <ButtonWithUnderlay
            label="Мультирайонная заявка"
            onClick={() => {
              alert('multi request');
            }}
          />
          <LangSelector className={styles.marginLeft} />
          <UserMenu className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      ) : (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          <AuthButton className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      )} */}

      {/* TODO: map coords move render with <Tag> from Map to MapComponent or ... (?) */}
      {/* <div className={styles.mapCoordsSection}> */}
      {/* -- depracated 02.11.2020 -- */}
      {/* <div className={styles.mapCopyrightSection}>
        <Tag roundBorder="top">
          <span>&copy; Роскосмос</span>
          {', '}
          <span>&copy; OSM</span>
        </Tag>
      </div> */}
    </>
  );
};

export default connect((state: RootState) => ({
  isFetching: getIsFetching(state),
  isAuthenticated: getIsAuthenticated(state),
  isDrawControlShow: getIsDrawControlShow(state),
}))(MapControls);
