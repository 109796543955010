import { t, Trans } from '@lingui/macro';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import HeadTitle from 'pages/HeadTitle';
// import GeoportalMap from 'components/map/Map/Map';
// import MapComponent from 'components/map/Map/MapComponent';
// import MapControls from 'components/map/MapControls';

// import '@geobank/assets/styles/theme.scss'; // work with aliases
import '@geobank/assets/src/styles/theme.css';
import ModalBox from '@geobank/components/src/common/ModalBox/ModalBox';

// import DateInput from '@geobank/components/src/forms/DateInput/DateInput';
import TransparentHeader from 'components/layout/Header/TransparentHeader';
import LeftMenu from './sections/LeftMenu';
// import MapBalloon from 'components/map/MapBalloon/MapBalloon';

// const HomePage: React.FC = () => {
//   const gMap = new GeoportalMap();

//   return (
//     <div>
//       <h2>Home Page</h2>
//       <MapComponent gMap={gMap} />
//     </div>
//   );
// };
// class HomePage extends PureComponent {
//   componentDidMount() {}
// }

const HomePage: React.FC = () => {
  const [showIsMobile, setShowIsMobile] = useState<boolean>(isMobile);
  const handleIsMobileClose = () => {
    setShowIsMobile(false);
  };
  return (
    <div>
      <HeadTitle value={t`Главная - Геопортал`} />

      <TransparentHeader search />
      <LeftMenu />
      {/* <MapComponent /> */}
      {/* <MapControls /> */}
      {/* возможно, разместить баллон в другом компоненте */}
      {/* <MapBalloon /> */}
      {showIsMobile && (
        <ModalBox
          key="mobileWarning"
          isNeedCrossButton={false}
          onClickCrossButton={handleIsMobileClose}
        >
          <div style={{ margin: '10px', textAlign: 'center', maxWidth: '350px' }}>
            <Trans
              id='Геопортал Роскосмоса не предназначен для работы на мобильных устройcтвах. Используйте мобильное приложение <0>"Цифровая Земля"</0> для Android.'
              components={[
                <a
                  key="mobileAppLink"
                  href="https://play.google.com/store/apps/details?id=ru.gptl.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Digital Earth
                </a>,
              ]}
            />
          </div>
        </ModalBox>
      )}
    </div>
  );
};

export default HomePage;
