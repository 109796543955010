import React from 'react';

import TransparentHeader from 'components/layout/Header/TransparentHeader';
import EditPrivateData from '../EditPrivateData/EditPrivateData';
import MyRequests from '../MyRequests/MyRequests';
import ButtonTabs, { IActiveButtonTab } from '@geobank/components/src/common/ButtonTabs/ButtonTabs';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import tableData from '../MyRequests/MockData';
import NotificationTab from '../NotificationTab/NotificationTab';

const styles = require('./PersonalAreaPage-styles.module.scss');

const tabsList = [
  { value: 'person_data', active: true, label: 'Личные данные' },
  { value: 'my_request', active: false, label: 'Мои заявки' },
  { value: 'notifications', active: false, label: 'Уведомления' },
  { value: 'pay_info', active: false, label: 'Платежи' },
];

interface PersonalAreaPageState {
  activeTab: string;
  tabsButtonsList: IActiveButtonTab[];
}

export default class PersonalAreaPage extends React.Component<{}, PersonalAreaPageState> {
  constructor({ props }: { props: any }) {
    super(props);
    this.state = {
      tabsButtonsList: tabsList,
      activeTab: tabsList[0].value,
    };
  }

  private readonly getContent = (value: string) => {
    switch (value) {
      case 'person_data':
        return <EditPrivateData />;
      case 'my_request':
        return <MyRequests value={tableData} />;
      case 'notifications':
        return <NotificationTab />;
      case 'pay_info':
        return <PaymentInfo />;
      default:
        return <EditPrivateData />;
    }
  };

  public render() {
    const { activeTab, tabsButtonsList } = this.state;
    return (
      <>
        <div className={styles.root} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              backgroundColor: '#14306A',
              borderRadius: '3px',
              height: 45,
              width: 650,
              boxSizing: 'border-box',
            }}
          >
            <ButtonTabs
              tabsList={tabsButtonsList}
              classNames={{
                tabContainer: styles.tabContainer,
                tabContainerActive: styles.tabContainerActive,
                tabText: '',
              }}
              onChange={value => {
                // const activeTabValue = value.filter(item => item.active === true)[0].value;
                // @ts-ignore
                // this.setState({ tabsButtonsList: value, activeTab: activeTabValue });
                this.setState({ activeTab: value });
              }}
              activeTab={activeTab}
            />
          </div>
          <div>{this.getContent(activeTab)}</div>
          <div />
        </div>
        <TransparentHeader />
      </>
    );
  }
}
