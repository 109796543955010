import { Options as OL_BaseVectorOptions } from 'ol/layer/BaseVector';
import OL_VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { BaseLayerOptions } from './CustomBase';
// import VectorSource from 'ol/source/Vector';

// export interface VectorLayerOptions extends OL_VectorLayerOptions {
//   id: number;
//   name: string;
// }
export interface VectorLayerOptions extends OL_BaseVectorOptions<VectorSource>, BaseLayerOptions {}

export default class VectorLayer extends OL_VectorLayer<VectorSource> {
  // eslint-disable-next-line
  constructor(opts?: VectorLayerOptions) {
    super(opts);
  }
}
