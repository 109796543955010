import * as React from 'react';
import { format } from 'date-fns';
import styles from './PaymentInfo.css';
import TRow from '@geobank/components/src/common/Table/TRow/TRow';
import THeader from '@geobank/components/src/common/Table/THeader/THeader';
import TData from '@geobank/components/src/common/Table/TData/TData';
import Table from '@geobank/components/src/common/Table/Table';
import THead from '@geobank/components/src/common/Table/THead/THead';
import TBody from '@geobank/components/src/common/Table/TBody/TBody';

interface PaymentInfoProps {
  value?: any;
}

interface PaymentInfoState {
  value?: any;
}

export default class PaymentInfo extends React.Component<PaymentInfoProps, PaymentInfoState> {
  public render() {
    return (
      <div className={styles.notification_tab__container}>
        <div>
          <span>История платежей</span>
        </div>
        <Table>
          <THead>
            <TRow>
              <THeader>
                <span className={styles.notification_tab__header_span}>№ заявки</span>
              </THeader>
              <THeader>
                <span className={styles.notification_tab__header_span}>Дата оплаты</span>
              </THeader>
              <THeader>
                <span className={styles.notification_tab__header_span}>Оплачено</span>
              </THeader>
            </TRow>
          </THead>
          <TBody>
            <TRow>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>1030</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>{format(new Date(), 'dd mm yyyy').replace(/ /g, '-')}</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>20000 ₽</span>
                </div>
              </TData>
            </TRow>
            <TRow>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>1031</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>{format(new Date(), 'dd mm yyyy').replace(/ /g, '-')}</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>20000 ₽</span>
                </div>
              </TData>
            </TRow>
            <TRow>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>1032</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>{format(new Date(), 'dd mm yyyy').replace(/ /g, '-')}</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>20000 ₽</span>
                </div>
              </TData>
            </TRow>
            <TRow>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>1034</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>{format(new Date(), 'dd mm yyyy').replace(/ /g, '-')}</span>
                </div>
              </TData>
              <TData>
                <div className={styles.notification_tab__table_data_content}>
                  <span>20000 ₽</span>
                </div>
              </TData>
            </TRow>
          </TBody>
        </Table>
      </div>
    );
  }
}
