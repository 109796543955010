import { useCallback, useEffect, useRef, useState } from 'react';

type IuseDebounceOpen = (
  timeEnter?: number,
  timeLeave?: number
) => [boolean, (flag: boolean, fast?: boolean) => void];

// function useDebounceOpen(timeEnter: number = 800, timeLeave: number = 500) {
const useDebounceOpen: IuseDebounceOpen = (timeEnter = 800, timeLeave = 500) => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // false, undefined
  const timerId = useRef<NodeJS.Timeout>();

  const setIsShown = useCallback(
    (flag: boolean, fast: boolean = false) => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }

      if (fast) {
        setIsOpen(flag);
        return;
      }

      if (flag === false && isOpen !== false) {
        timerId.current = setTimeout(() => {
          setIsOpen(flag);
        }, timeEnter);
      } else if (flag === true && isOpen !== true) {
        // setIsOpen(flag);
        timerId.current = setTimeout(() => {
          setIsOpen(flag);
        }, timeLeave);
      }
    },
    [timerId, isOpen, setIsOpen, timeEnter, timeLeave]
  );

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  // return [isOpen, setIsOpen, setIsShown];
  return [isOpen, setIsShown];
};

export default useDebounceOpen;
