import { Options as OL_BaseTileOptions } from 'ol/layer/BaseTile';
import OL_TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import { BaseLayerOptions } from './CustomBase';
// import TileWMS from 'ol/source/TileWMS';

export interface TileLayerOptions extends OL_BaseTileOptions<TileSource>, BaseLayerOptions {}

// Line 10:  Useless constructor  @typescript-eslint/no-useless-constructor (???)
export default class TileLayer extends OL_TileLayer<TileSource> {
  // eslint-disable-next-line
  constructor(opts?: TileLayerOptions) {
    super(opts);
  }
}
