import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import get from 'lodash/get';
import { Extent } from 'ol/extent';
import { createSelector } from 'reselect';
import * as mapLayersTypes from './types/mapLayersTypes';

import { RootState as RootStateType } from './root';

/**
 * Actions
 */
export const fetchLayers = {
  // METADATA_SEARCH_REQUEST
  request: createActionCreator('LAYERS/FETCH_REQUEST'),
  success: createActionCreator('LAYERS/FETCH_SUCCESS', resolve => (data: any[]) => {
    // развернуть капусту =)
    const layers = data[0].templates[1].layers; // templates[0] - шаблон для текущего ГП, templates[1] - для нового (этого) ГП
    return resolve(layers as mapLayersTypes.Layer[]);
  }),
  failure: createActionCreator('LAYERS/FETCH_FAILURE', resolve => error => resolve(error)),
};

// ~ createAction()
export const selectBaseLayer = createActionCreator(
  'LAYERS/SELECT_BASE_LAYER',
  resolve => (data: number) => resolve(data)
);

export const toggleImageLayer = createActionCreator(
  'LAYERS/TOGGLE_IMAGE_LAYER',
  resolve => (data: string) => resolve(data)
);

export const toggleNameLayer = createActionCreator(
  'LAYERS/TOGGLE_NAME_LAYER',
  resolve => (data: string) => resolve(data)
);

export const toggleProductPreviewLayer = createActionCreator(
  'LAYERS/TOGGLE_PRODUCT_PREVIEW_LAYER',
  resolve => (data: { identifier: string; url: string; bbox: Extent }) => resolve(data)
);

/**
 * Reducers and state
 */
export type LayersState = mapLayersTypes.Layer[];

export const defaultLayersState: LayersState = [];

export const layersReducer = createReducer(defaultLayersState, handleAction => [
  handleAction(fetchLayers.success, (_, { payload }) => payload),
]);

export const defaultIsFetchingState = false;

export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
  handleAction(fetchLayers.request, () => true),
  handleAction([fetchLayers.success, fetchLayers.failure], () => false),
]);

export const defaultSelectedBaseLayer: any = null;

export const selectedBaseLayerReducer = createReducer(defaultSelectedBaseLayer, handleAction => [
  handleAction(selectBaseLayer, (_, { payload }) => payload),
  handleAction(fetchLayers.success, (_, { payload: layers }) => {
    const result = layers.find(
      layer => get(layer, 'config.options.visible') || get(layer, 'config.options.visibility')
    );
    return result && result.id;
  }),
]);

export const selectedImageLayerIdDefaultState: string = '';

export const selectedImageLayerIdsReducer = createReducer(
  selectedImageLayerIdDefaultState,
  handleAction => [
    handleAction(toggleImageLayer, (state, { payload }) => {
      // console.log( "SELECTED_IMAGE_ID", state, payload)
      if (state === payload) {
        return selectedImageLayerIdDefaultState;
      }
      return payload;
    }),
    // handleAction(toggleImageLayer, (_, { payload }) => payload),
  ]
);

export const selectedNameLayerIdDefaultState: string = '8660444';

export const selectedNameLayerIdReducer = createReducer(
  selectedNameLayerIdDefaultState,
  handleAction => [handleAction(toggleNameLayer, (_, { payload }) => payload)]
);

// layersRootReducer
export default combineReducers({
  layers: layersReducer,
  isFetching: isFetchingReducer,
  selectedBaseLayer: selectedBaseLayerReducer,
  selectedImageLayerId: selectedImageLayerIdsReducer,
  selectedNameLayerId: selectedNameLayerIdReducer,
});

/**
 * Selectors
 */
export const getLayers = (state: RootStateType) => state.layers.layers;
export const getIsFetching = (state: RootStateType) => state.layers.isFetching;
export const getSelectedImageLayerId = (state: RootStateType) => state.layers.selectedImageLayerId;
export const getSelectedNameLayerId = (state: RootStateType) => state.layers.selectedNameLayerId;

// with reselect
export const getBaseLayers = createSelector([getLayers], layers =>
  layers.filter(layer => get(layer, 'config.treeConfig.checkedGroup') === 'base')
);

// TODO - active layer -> map-duck; and remove this selector, and use saga put selectBaseLayer
// export const getActiveBaseLayer = createSelector(
//   [getBaseLayers, (state: RootStateType) => state.layers.selectedBaseLayer],
//   (layers, selectedBaseLayerId) => {
//     let result;
//     if (selectedBaseLayerId) {
//       result = layers.find(layer => get(layer, 'id') === selectedBaseLayerId);
//     } else {
//       result = layers.find(
//         layer => get(layer, 'config.options.visible') || get(layer, 'config.options.visibility'),
//       );
//     }
//     return result;
//   },
// ); // Layer | undefined
export const getActiveBaseLayer = (state: RootStateType) => state.layers.selectedBaseLayer;

export const imageLayers = createSelector([getLayers], layers => {
  // name: Снимки, Снимки ЦОД; type: Group;

  const layerGroup = layers.find(layer => get(layer, 'name') === 'Мозаики'); // name == Снимки

  // return get(layerGroup, 'layers', []).slice(0, 5);
  const byId: { [id: string]: any } = {};
  const allIds: string[] = [];
  for (const layer of get(layerGroup, 'layers', [])) {
    byId[layer.id] = layer;
    allIds.push(layer.id);
  }
  // console.log('IMAGE_LAYERS_DUCKS', layers, layerGroup, byId, allIds)
  return { byId, allIds };
});
// getHelperLayers (вытаскивать по config.options.XXX) - Кадастровая карта и т.п.

export const getImageLayers = createSelector([imageLayers], ({ byId, allIds }) => {
  return allIds.map(id => byId[id]);
});

export const nameLayers = createSelector([getLayers], layers => {
  // name: Снимки, Снимки ЦОД; type: Group;
  const layerGroup = layers.find(layer => get(layer, 'name') === 'Названия'); // name == Снимки
  // return get(layerGroup, 'layers', []).slice(0, 5);
  const byId: { [id: string]: any } = {};
  const allIds: string[] = [];
  for (const layer of get(layerGroup, 'layers', [])) {
    byId[layer.id] = layer;
    allIds.push(layer.id);
  }
  return { byId, allIds };
});
// getHelperLayers (вытаскивать по config.options.XXX) - Кадастровая карта и т.п.

export const getNameLayers = createSelector([nameLayers], ({ byId, allIds }) => {
  return allIds.map(id => byId[id]);
});

export const getImageLayersById = createSelector([imageLayers], ({ byId }) => byId);

export const getNameLayerById = createSelector([nameLayers], ({ byId }) => byId);

// export const getSelectedImageLayers = createSelector(
//   [imageLayers, getSelectedImageLayerIds],
//   ({ byId: layersById }, selectedIds) => {
//     const result: any[] = selectedIds.map(id => layersById[id]);
//     return result;
//   }
// );
