import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, call, select, takeLatest } from 'redux-saga/effects'; // , put

import GeoportalMap from 'components/map/Map/Map';
import {
  getImageLayersById,
  getNameLayerById,
  selectBaseLayer,
  toggleImageLayer,
  toggleNameLayer,
  toggleProductPreviewLayer,
} from 'ducks/mapLayers';

// import { getCurrentItem } from 'ducks/stacItems';
import { clearShapeGeometry, setShapeGeometry } from 'ducks/map';

import { getItems } from 'ducks/stacItems';
import { Debugger } from 'utils/logging';

const debug = Debugger('MapSaga'); // TAG

const getNestedObjectValue: any = (obj: any, target: any) =>
  target in obj
    ? obj[target]
    : // @ts-ignore
      Object.values(obj).reduce((acc, val) => {
        if (typeof val === 'object') return getNestedObjectValue(val, target);
        return acc;
        // return undefined;
      }, {});

// const getNestedObjectValue: any = (node: any) => {
//   if (node.leftChild) {
//     return getNestedObjectValue(node.leftChild);
//   } else if (node.rightChild) {
//     return getNestedObjectValue(node.rightChild);
//   } else { // node must be a leaf node
//     return node;
//   }
// }

export function* selectBaseLayerFlow(action: ActionType<typeof selectBaseLayer>) {
  try {
    const layerId: number = action.payload;

    const map = GeoportalMap.getInstance();
    yield call([map, 'selectBaseLayer'], layerId);
  } catch (error) {
    debug.error(error);
  }
}

type ImageLayers = ReturnType<typeof getImageLayersById>;

export function* toggleImageLayerFlow(action: ActionType<typeof toggleImageLayer>) {
  // console.log('toggleImageLayerFlow')
  try {
    const layerId: string = action.payload;
    const imageLayers: ImageLayers = yield select(getImageLayersById);
    const items: any[] = yield select(getItems);
    // console.log(60, layerId, items, imageLayers, layerId, imageLayers[layerId])
    const map = GeoportalMap.getInstance();

    if (items.length > 0) {
      const itemDate = getNestedObjectValue(items[0], 'properties').datetime;
      yield call([map, 'toggleImageLayer'], imageLayers[layerId], itemDate);

      // layerId === 'globalMSUMR'
      //   ?  yield call([map, 'toggleImageLayer'], imageLayers[layerId], '2024-06-24T00:00')
      //   :  yield call([map, 'toggleImageLayer'], imageLayers[layerId], itemDate)
    }
    if (layerId === 'individualGeoton') {
      yield call([map, 'toggleImageLayer'], imageLayers[layerId], '2024-06-20T10:00');
    }
  } catch (error) {
    debug.error(error);
  }
}

type NameLayers = ReturnType<typeof getNameLayerById>;

export function* toggleNameLayerFlow(action: ActionType<typeof toggleNameLayer>) {
  try {
    const layerId: string = action.payload;
    const nameLayer: NameLayers = yield select(getNameLayerById);
    const map = GeoportalMap.getInstance();
    // console.log(81, nameLayer, layerId, nameLayer[layerId])
    yield call([map, 'toggleNameLayer'], nameLayer[layerId]);
  } catch (error) {
    debug.error(error);
  }
}

export function* setRegionGeometryFlow(action: ActionType<typeof setShapeGeometry>) {
  try {
    const geoJSON = action.payload;
    const map = GeoportalMap.getInstance();
    yield call([map, 'setRequestRegionGeometry'], geoJSON);
  } catch (error) {
    debug.error(error);
  }
}

export function* clearRegionGeometryFlow() {
  try {
    const map = GeoportalMap.getInstance();
    yield call([map, 'clearRegionsOfInterest']);
  } catch (error) {
    debug.error(error);
  }
}

export function* toggleProductPreviewLayerFlow(
  action: ActionType<typeof toggleProductPreviewLayer>
) {
  try {
    const { identifier, url, bbox } = action.payload;
    // const imageLayers = yield select(getImageLayersById);
    debug.log('toggleProductPreviewLayerFlow', identifier /* imageLayers */);

    const map = GeoportalMap.getInstance();
    yield call([map, 'toggleProductPreviewLayer'], identifier, url, bbox); // imageLayers[layerId]
  } catch (error) {
    debug.error(error);
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(selectBaseLayer), selectBaseLayerFlow)]);
  yield all([takeLatest(getType(toggleImageLayer), toggleImageLayerFlow)]);
  yield all([takeLatest(getType(toggleNameLayer), toggleNameLayerFlow)]);
  yield all([takeLatest(getType(setShapeGeometry), setRegionGeometryFlow)]);
  yield all([takeLatest(getType(clearShapeGeometry), clearRegionGeometryFlow)]);
  yield all([takeLatest(getType(toggleProductPreviewLayer), toggleProductPreviewLayerFlow)]);
}
