// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
// import WMTS from 'ol/source/WMTS';
// import WMTSTileGrid from 'ol/tilegrid/WMTS';
// import { getWidth, getTopLeft } from 'ol/extent'; // getCenter
// import { get as getProjection } from 'ol/proj';

import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
// import TileLayer from 'ol/layer/Tile.js';
// import OSM from 'ol/source/OSM.js';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS.js';

import { CreateLayerBundle } from '../types';

const createSimpleWMTSCapabilitiesLayer = (bundle: CreateLayerBundle) => {
  const { id, name /* options, params */ } = bundle;
  //
  // const url = 'http://tb-autotransforming.sec45.ccr.dep4.niitp:8080/geoserver/gwc/service/wmts';
  // const url = layerOptions.url;
  // const format = layerParams.format || 'image/png';
  // const version = '1.0.0';

  const parser = new WMTSCapabilities();
  const request = new XMLHttpRequest();
  request.open(
    'GET',
    'https://pkk5.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer/WMTS/1.0.0/WMTSCapabilities.xml',
    false
  ); // `false` makes the request synchronous
  request.send(null);
  if (request.status === 200) {
    // console.log(request.responseText);
  }
  const result = parser.read(request.responseText);
  const wmtsOptions = optionsFromCapabilities(result, {
    layer: 'BaseMaps_BaseMap',
    matrixSet: 'GoogleMapsCompatible',
  });

  /* fetch('data/WMTSCapabilities.xml')
    .then(function(response) {
      return response.text();
    })
    .then(function(text) {
      var result = parser.read(text);
      var options = optionsFromCapabilities(result, {
        layer: 'layer-7328',
        matrixSet: 'EPSG:3857',
      });
    }); */

  const layer = new TileLayer({
    id,
    name,
    // name: 'wmtsLayer',
    // opacity: 0.0,
    source: new WMTS(/** @type {!module:ol/source/WMTS~Options} */ wmtsOptions!),
  });

  return layer;
};

export default createSimpleWMTSCapabilitiesLayer;
