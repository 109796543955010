import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchLayers } from 'ducks/mapLayers';

// import * as API from './api'
import API from 'api';

export function* fetchMapLayersFlow(_action: ActionType<typeof fetchLayers.request>) {
  try {
    const data = yield call(API.getMapLayers);
    // console.log(12, data)
    yield put(fetchLayers.success(data));
  } catch (error) {
    console.error('Error', error);
    yield put(fetchLayers.failure(error));
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(fetchLayers.request), fetchMapLayersFlow)]);
}
