import { call } from 'redux-saga/effects';

// import { iamProvider } from 'ducks/authIAM';
import { iamProvider } from './authIAM';
import { setTokensToLocalStorage } from 'utils/localStorage';
import { setTokenApi } from 'api/realAPI';

// @ts-ignore
export default function* (fn, ...args) {
  try {
    const keycloak = iamProvider;
    const minValidity = 30;
    if (keycloak.isTokenExpired(minValidity)) {
      yield call([keycloak, 'updateToken'], minValidity);
      // set/update kc tokens (keycloak.token, keycloak.refreshToken)
      setTokensToLocalStorage({
        accessToken: keycloak.token!,
        refreshToken: keycloak.refreshToken!,
      });
      setTokenApi(keycloak.token!);
    }
    const response = yield call(fn, ...args);
    return response;
  } catch (error) {
    throw error;
  }
}
