import React from 'react';
import { Trans } from '@lingui/macro';

import ModalBox from '@geobank/components/src/common/ModalBox/ModalBox';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';

interface NewShootingModalProps {
  onClose: () => void;
}

interface NewShootingModalState {
  count: number;
}

class NewShootingModal extends React.PureComponent<NewShootingModalProps, NewShootingModalState> {
  state = {
    count: 30,
  };

  timerId?: NodeJS.Timeout;

  handleModalClose = () => this.props.onClose();

  countDown = () => {
    const { count } = this.state;
    if (count > 0) {
      const newValue = count - 1;
      this.setState({ count: newValue });
      this.timerId = setTimeout(this.countDown, 1000);
    } else {
      window.location.href = 'https://gptl.ru';
    }
  };

  componentDidMount() {
    this.countDown();
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  render() {
    const { count } = this.state;

    return (
      <ModalBox isNeedCrossButton={true} onClickCrossButton={this.handleModalClose}>
        <div /* className={styles.modalContent} */ style={{ padding: '20px', width: '500px' }}>
          {/* {getModalContent(modal)} */}
          <div>
            <h3>
              <Trans id="new_survey.header">Заказ съёмки</Trans>
            </h3>
            <div>
              <p>
                <Trans id="new_survey.title">
                  На данный момент заказ проведения съёмки осуществляется в предыдущей версии
                </Trans>{' '}
                {/* eslint-disable-next-line */}
                <a href="https://gptl.ru" target="_blank">
                  <Trans id="new_survey.link">Геопортала Роскосмоса</Trans>
                </a>{' '}
                <Trans>("Продукты систем ДЗЗ") .</Trans>
              </p>
              <div>
                <Trans id="new_survey.task_list">
                  Также Вы сможете выполнить следующие задачи:
                </Trans>
                <ul>
                  {/* <li>заявка съёмки,</li> */}
                  <li>
                    <Trans id="new_survey.first_task">создание мультирайонной заявки;</Trans>
                  </li>
                  <li>
                    <Trans id="new_survey.second_task">обработка средствами систем ДЗЗ;</Trans>
                  </li>
                  <li>
                    <Trans id="new_survey.third_task">
                      бесплатное выполнение заявок коммерческих организаций для выполнения работ по
                      госзаказу.
                    </Trans>
                  </li>
                </ul>
              </div>
              <p>
                <Trans id="new_survey.confirm">
                  Перейти для заказа проведения съёмки или выполнения одной из вышеперечисленных
                  задач?
                </Trans>
              </p>

              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button color={ButtonColor.LIGHT} onClick={this.handleModalClose}>
                  <Trans>Отменить</Trans>
                </Button>
                <Button onClick={() => (window.location.href = 'https://gptl.ru')}>
                  <Trans>Подтвердить</Trans>
                </Button>
              </div>

              <div style={{ /* textAlign: 'center', */ margin: '16px 0 0 80px', color: '#595964' }}>
                <Trans id="new_survey.redirect">
                  Автоматическая переадресация через {count} сек
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  }
}

export default NewShootingModal;
