// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
import TileWMS from 'ol/source/TileWMS';

import { CreateLayerBundle } from '../types';

// MapproxyWMSServer
/* const createCoverageWMSServer = (
  layerId: number,
  layerName: string,
  layerOptions: TileLayerOptions | null,
  params: any,
) => {
  const layer = new TileLayer({
    id: layerId,
    name: layerName,
    // -- /custom args delimiter --
    // zIndex: 0,
    source: new TileWMS({
      url: params.url, // 'https://gptl.ru/wms/rp_gtnl1/service',
      params: { LAYERS: params.layers || 'imageries,boundaries', TILED: true }, // todo - upperParams(params)
      // serverType: 'geoserver',
    }),
    ...layerOptions,
  });

  return layer;
}; */
const createCoverageWMSServer = (bundle: CreateLayerBundle) => {
  const { id, name, options, params } = bundle;

  const layer = new TileLayer({
    id,
    name,
    // -- /custom args delimiter --
    // zIndex: 0,
    source: new TileWMS({
      url: options.url, // 'https://gptl.ru/wms/rp_gtnl1/service',
      params: { LAYERS: params!.layers || 'imageries,boundaries', TILED: true }, // todo - upperParams(params)
      // serverType: 'geoserver',
      attributions: options.attribution,
    }),
  });

  return layer;
};

export default createCoverageWMSServer;
