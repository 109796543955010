import React from 'react';
import { format } from 'date-fns';
import styles from './NotificationTab.css';
import FilterIcon from '@geobank/assets/src/icons/FilterIcon';
import HorizontalLine from '@geobank/components/src/common/HorizontalLine/HorizontalLine';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';

interface INotificationTabProps {
  value?: any;
}

interface INotificationTabState {
  value?: any;
}

interface INotificationItem {
  date: Date;
  message: string;
}

const mockFinishedProducts: INotificationItem[] = [
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
];
const mockServiceMessage: INotificationItem[] = [
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
];
const mockRoutes: INotificationItem[] = [
  {
    date: new Date(),
    message:
      'В заявке изменён признак пригодности снимка на "Не пригоден": - заявка № 13890-РП - район "Район ШМСА-ВР" - снимок ETRIS.RP1.SVR.281.2.0.2018-11-12.L0.MCHS_MUR.NTSOMZ_MSK',
  },
];

export default class NotificationTab extends React.Component<
  INotificationTabProps,
  INotificationTabState
> {
  private readonly getNotificationItem = (value: INotificationItem): JSX.Element => {
    return (
      <div>
        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <span>{format(value.date, 'dd.MM.yyyy')}</span>
        </div>
        <div>
          <span>{value.message}</span>
        </div>
      </div>
    );
  };

  public render() {
    return (
      <div style={{ display: 'flex' }}>
        <div className={styles.notificationContainer}>
          <div
            className={styles.flexContainer}
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className={styles.notificationTextHeader}>Готовая продукция</span>
            </div>
            <div className={styles.filterButtonContainer}>
              <div className={styles.flexContainer}>
                <FilterIcon />
              </div>
              <div>
                <span style={{ color: '#5DBDF9' }}>Фильтр</span>
              </div>
            </div>
          </div>
          <div>
            <CustomScrollBarContainer heightMin={'120px'} heightMax={'33vh'} color={'#6cc8ff'}>
              {mockFinishedProducts.map((item, index) => {
                if (index + 1 !== mockFinishedProducts.length) {
                  return (
                    <>
                      {this.getNotificationItem(item)}
                      <div className={styles.horizontalLineContainer}>
                        <HorizontalLine width={'100%'} />
                      </div>
                    </>
                  );
                }
                return this.getNotificationItem(item);
              })}
            </CustomScrollBarContainer>
          </div>
        </div>
        <div className={styles.notificationContainer}>
          <div className={styles.flexContainer} style={{ justifyContent: 'space-between' }}>
            <div>
              <span className={styles.notificationTextHeader}>Сервисные сообщения</span>
            </div>
            <div className={styles.filterButtonContainer}>
              <div className={styles.flexContainer}>
                <FilterIcon />
              </div>
              <div>
                <span style={{ color: '#5DBDF9' }}>Фильтр</span>
              </div>
            </div>
          </div>
          <div>
            <CustomScrollBarContainer heightMin={'120px'} heightMax={'33vh'} color={'#6cc8ff'}>
              {mockServiceMessage.map((item, index) => {
                if (index + 1 !== mockServiceMessage.length) {
                  return (
                    <>
                      {this.getNotificationItem(item)}
                      <div className={styles.horizontalLineContainer}>
                        <HorizontalLine width={'100%'} />
                      </div>
                    </>
                  );
                }
                return this.getNotificationItem(item);
              })}
            </CustomScrollBarContainer>
          </div>
        </div>
        <div className={styles.notificationContainer}>
          <div
            className={styles.flexContainer}
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className={styles.notificationTextHeader}>Маршруты</span>
            </div>
            <div className={styles.filterButtonContainer}>
              <div className={styles.flexContainer}>
                <FilterIcon />
              </div>
              <div>
                <span style={{ color: '#5DBDF9' }}>Фильтр</span>
              </div>
            </div>
          </div>
          <div>
            <CustomScrollBarContainer heightMin={'120px'} heightMax={'33vh'} color={'#6cc8ff'}>
              {mockRoutes.map((item, index) => {
                if (index + 1 !== mockRoutes.length) {
                  return (
                    <>
                      {this.getNotificationItem(item)}
                      <div className={styles.horizontalLineContainer}>
                        <HorizontalLine width={'100%'} />
                      </div>
                    </>
                  );
                }
                return this.getNotificationItem(item);
              })}
            </CustomScrollBarContainer>
          </div>
        </div>
      </div>
    );
  }
}
