import React from 'react';
// import { t, Trans } from '@lingui/macro';
// import { withI18nProps, withI18n } from '@lingui/react';
// import classNames from 'classnames';
// import { css } from 'astroturf';

import { RadioGroupContextProvider } from './context';
import { RadioChangeEvent } from './interface';

// const styles = css`
//   .root {
//     display: flex;
//     align-items: flex-start;
//   }
//   .disabled {
//     label {
//       cursor: not-allowed;
//       color: gray;
//     }
//   }
// `;

// interface RadioButton {
//   value: string;
//   label: string | JSX.Element;
//   // defaultChecked?: boolean;
//   disabled?: boolean;
// }

interface RadioGroupProps {
  className?: string;
  name: string; // goup name
  value?: string; // current value
  // buttons: RadioButton[];
  // onChange?: (groupName: string, value: string) => void;
  onChange: (event: RadioChangeEvent) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

// TODO - styling, classes ...
// & React.HTMLAttributes<HTMLDivElement> --> ...restProps
const RadioGroup: React.FC<RadioGroupProps> = props => {
  const { className, name: groupName, value: currentValue, onChange: onRadioChange } = props;
  const childrenToRender = props.children;

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (onChange !== undefined) {
  //     onChange(groupName, event.target.value);
  //   }
  // };

  return (
    <div className={className}>
      <RadioGroupContextProvider
        value={{
          onChange: onRadioChange,
          value: currentValue,
          disabled: props.disabled,
          name: groupName,
        }}
      >
        {childrenToRender}
      </RadioGroupContextProvider>
    </div>
  );

  // return (
  //   <>
  //     {buttons.map((button, index) => (
  //       <div
  //         key={button.value}
  //         className={classNames(styles.root, { [styles.disabled]: button.disabled })}
  //       >
  //         dasd
  //       </div>
  //     ))}
  //   </>
  // );
};

export default React.memo(RadioGroup);
