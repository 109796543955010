import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';

import React, { useCallback } from 'react';

import CheckBoxGroup, {
  CheckBoxesProp,
} from '@geobank/components/src/forms/CheckBoxGroup/CheckBoxGroup';

const styles = require('./ResolutionCheckbox-styles.module.scss');

interface ResolutionCheckboxProps {
  checked?: string[];
  onChange?: (values: string[], changed?: { name: string; value: boolean }) => void;
}

const ResolutionCheckbox: React.FC<ResolutionCheckboxProps> = props => {
  const { checked, onChange } = props;

  const handleChange = useCallback(
    (checkBoxes: CheckBoxesProp[], changed?: { name: string; value: boolean }) => {
      const resulutionsChecked: string[] = [];
      checkBoxes.forEach(item => {
        if (item.flag === true) {
          resulutionsChecked.push(item.name);
        }
      });
      if (onChange !== undefined) {
        onChange(resulutionsChecked, changed);
      }
    },
    [onChange]
  );

  return (
    <I18n>
      {({ i18n }) => (
        <div className={styles.root}>
          <div>
            {/* // TODO - переделать api CheckBoxGroup: сделать checked prop; */}
            <CheckBoxGroup
              checkBoxList={[
                // { name: 'low', label: i18n._(t`Низкое`), flag: checked?.indexOf('low') !== -1 }, // Low
                {
                  name: 'medium_low',
                  label: i18n._(t('resolutions.medium_low')`Низкое`), // Среднее низкое
                  flag: checked?.indexOf('medium_low') !== -1,
                },
                {
                  name: 'medium',
                  label: i18n._(t('resolutions.medium')`Среднее`),
                  flag: checked?.indexOf('medium') !== -1,
                },
                {
                  name: 'high',
                  label: i18n._(t('resolutions.hight')`Высокое`),
                  flag: checked?.indexOf('high') !== -1,
                },
                {
                  name: 'detailed',
                  label: i18n._(t('resolutions.detailed')`Сверхвысокое`), // Детальное
                  flag: checked?.indexOf('detailed') !== -1,
                },
              ]}
              onChange={handleChange}
            />
          </div>
          <div className={styles.descriptionContainer}>
            {/* <span className={styles.descriptionItem}>1000 {i18n._(t`м`)}</span> */}
            <span className={styles.descriptionItem}>
              {/*42-120*/} 100-1000 {i18n._(t`м`)}
            </span>
            <span className={styles.descriptionItem}>
              {/*12-30*/} 10-100 {i18n._(t`м`)}
            </span>
            <span className={styles.descriptionItem}>
              {/*2-11*/} 1-10 {i18n._(t`м`)}
            </span>
            <span className={styles.descriptionItem}>
              {/* 0,7-1 */} {'< 1'} {i18n._(t`м`)}
            </span>
          </div>
        </div>
      )}
    </I18n>
  );
};

export default ResolutionCheckbox;
