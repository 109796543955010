import React from 'react';
import { withI18nProps, withI18n } from '@lingui/react';
import { t } from '@lingui/macro'; // Trans


import { bandNames } from 'locales/dicts/classifiers';
// import CheckBoxGroup, {
//   CheckBoxesProp,
// } from '@geobank/components/src/forms/CheckBoxGroup/CheckBoxGroup';
import CheckBox from 'components/common/forms/CheckBox/CheckBox';
import { CheckBoxChangeEvent } from 'components/common/forms/CheckBox/interface';
import CheckBoxGroup from 'components/common/forms/CheckBox/CheckBoxGroup';
// import { InstrumentSensor } from 'ducks/types/classifierTypes';
import { Band } from 'ducks/types/metadataTypes';

export interface SimpleSensorWithBands {
  isPanchromatic: boolean;
  bands: Band[] | [{ min: number; max: number; name?: string; description: string }];
}

const styles = require('./SpectralChannelsFieldNext-styles.module.scss');

interface SpectralChannelsFieldProps {
  sensors: SimpleSensorWithBands[]; // InstrumentSensor[];
  // checked: string[];
  checked: { [key: string]: boolean };
  // onChange?: (values: string[]) => void;
  onChange?: (values: { [key: string]: boolean }) => void;
  disabled?: boolean;
}

class SpectralChannelsField extends React.PureComponent<
  SpectralChannelsFieldProps & withI18nProps
> {
  handleChange = (e: CheckBoxChangeEvent) => {
    const { value, checked } = e.target;
    const { checked: prevChecked, onChange } = this.props;

    // const nextChecked: string[] = [...prevChecked];
    // const index = nextChecked.indexOf(value);
    // if (index > -1 && checked === false) {
    //   nextChecked.splice(index, 1);
    // } else {
    //   nextChecked.push(value);
    // }
    const nextChecked = { ...prevChecked };
    // @ts-ignore
    nextChecked[value] = checked;

    if (onChange !== undefined) {
      onChange(nextChecked);
    }
  };

  getBandColor = (band: Band) => {
    let color = '#b3b3b3';
    const text = band.name ? band.name : band.description;
    switch (text.toLocaleLowerCase()) {
      case 'панхром': // Панхром
        color = '#b3b3b3';
        break;
      case 'красный': // Красный
      case 'red':
        color = 'red';
        break;
      case 'зелёный': // Зелёный
      case 'green':
        color = '#2bc12b'; // green
        break;
      case 'синий': // Синий
      case 'blue':
        color = '#6ac3fa'; // blue
        break;
      case 'дальний ик': // Дальний ИК
      case 'средний ик': // Средний ИК
      case 'ближний ик': // Ближний ИК
      case 'nir':
        color = '#fbcbcb';
        break;
    }
    return color;
  };

  // TODO - сделать mapping другой(?), т.к. может быть несколько одинаковых каналов по имени (или переложить это на апи)
  // --> RED, RED.1, ...
  getBandId = (band: Band) => {
    let bandId = '';
    const text = band.name ? band.name : band.description;

    switch (text.toLocaleLowerCase()) {
      case 'панхром':
        bandId = 'PAN';
        break;
      case 'красный':
      case 'red':
        bandId = 'RED';
        break;
      case 'зелёный':
      case 'green':
        bandId = 'GREEN';
        break;
      case 'синий':
      case 'blue':
        bandId = 'BLUE';
        break;
      case 'дальний ик':
        bandId = 'LWIR';
        break;
      case 'средний ик':
        bandId = 'MWIR';
        break;
      case 'ближний ик':
      case 'nir':
        bandId = 'NIR';
        break;
      default:
        bandId = `${band.min}_${band.max}`;
    }

    return bandId;
  };

  renderBands() {
    const { sensors, disabled, i18n } = this.props; // checked
    const list: any = [];
    sensors.forEach(sensor => {
      if (sensor.bands && sensor.isPanchromatic === false) {
        sensor.bands.forEach(band => {
          list.push(
            <CheckBox
              className={styles.checkbox}
              key={`${band.min}_${band.max}`}
              // value={`${band.min}_${band.max}`}
              value={this.getBandId(band as Band)}
              // checked={checked.indexOf(`${band.min}_${band.max}`) !== -1}
              disabled={disabled}
              title={disabled ? i18n._(t`Выбор каналов не доступен`) : undefined}
            >
              <span>
                <span
                  className={styles.bandColoredBox}
                  style={{ backgroundColor: this.getBandColor(band as Band) }}
                />
                {band.min} - {band.max} {i18n._(t`мкм`)}{' '}
                {band.name
                  ? `(${band.name})`
                  : band.description
                  ? `(${i18n._(bandNames[band.description])})`
                  : ''}
              </span>
            </CheckBox>
          );
        });
      }
    });

    return list;
  }

  render() {
    const { checked, disabled, sensors } = this.props; // sensors, i18n

    // проставляем галками все пункты при отключённом выборе
    if (disabled || Object.keys(checked).length === 0) {
      sensors.forEach(sensor => {
        if (sensor.bands && sensor.isPanchromatic === false) {
          sensor.bands.forEach(band => {
            checked[this.getBandId(band as Band)] = true;
          });
        }
      });
    }

    return (
      <div>
        <CheckBoxGroup name="msChannels" value={checked} onChange={this.handleChange}>
          {this.renderBands()}
        </CheckBoxGroup>
      </div>
    );
  }
}

// export default SpectralChannelsField;
export default withI18n()(SpectralChannelsField);
