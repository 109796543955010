import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

// import Checkbox, { CHECKED, INDETERMINATE, UNCHECKED } from '../../SearchResultItem/CheckBox/Checkbox';
import Checkbox, {
  CHECKED,
  INDETERMINATE,
  UNCHECKED,
} from 'components/common/forms/CheckBox/CheckBox';
// import CheckboxOld from '@geobank/components/src/forms/CheckBox/Checkbox';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import ConflictImage from './ConflictImage';
// import { ImageMetadata } from 'ducks/types/metadataTypes';
import { PreparedImage } from '../types';

import styles from './ConflictList.module.scss';

interface ConflictListProps {
  // orderImages: ImageMetadata[];
  conflictedImages: PreparedImage[];
  onRemove: (prImage: PreparedImage | PreparedImage[]) => void;
}

const ConflictList: React.FC<ConflictListProps> = props => {
  const { /* orderImages, */ conflictedImages, onRemove } = props;
  const [checked, setChecked] = useState<string[]>([]);

  const handleCheckAll = () => {
    if (checked.length > 0) {
      setChecked([]);
    } else {
      setChecked(conflictedImages.map(item => item.md.identifier));
    }
  };

  const handleCheckItem = (prImage: PreparedImage) => {
    // if (Array.isArray(payload)) {
    //   return payload.map(id => String(id));
    // }

    // const metadataId = payload; // payload.metadataId;
    const imageIndex = checked.indexOf(prImage.md.identifier);
    const result = [...checked];
    if (imageIndex > -1) {
      result.splice(imageIndex, 1);
    } else {
      result.push(prImage.md.identifier);
    }
    // return result;
    setChecked(result);
  };

  const handleRemoveItems = (prImage?: PreparedImage) => {
    if (prImage !== undefined) {
      onRemove(prImage);
      const itemIndex = checked.indexOf(prImage.md.identifier);
      if (itemIndex > -1) {
        // const newChecked = [...checked];
        checked.splice(itemIndex, 1);
        setChecked(checked);
      }
    } else {
      const prImages = conflictedImages.filter(item => checked.indexOf(item.md.identifier) > -1);
      onRemove(prImages);
      setChecked([]);
    }
  };

  if (conflictedImages.length === 0) {
    return (
      <>
        <div className={styles.marginBottom}>Все конфликты успешно устранены!</div>
      </>
    );
  }

  return (
    <>
      <div /* className={styles.marginBottom} */ style={{ marginRight: '10px' }}>
        {/* <CheckboxOld
          name="md:888"
          // checked={isAllChecked}
          // value={isAllChecked ? CHECKED : UNCHECKED}
          // onChange={handleCheck}
          label="Всё выбрать"
          colorLabel="black"
        /> */}
        {/* <br /> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Checkbox
            value="md:888" // name="md:888"
            // value \|/
            checked={
              checked.length > 0
                ? checked.length < conflictedImages.length
                  ? INDETERMINATE
                  : CHECKED
                : UNCHECKED
            }
            onChange={handleCheckAll}
            // colorLabel="black"
          >
            <Trans>Всё выбрать</Trans>
          </Checkbox>
          <Button
            color={ButtonColor.TRANSPARENT}
            className={classNames(styles.itemHeadActionButton, styles.removeButton)}
            // title={i18n._(t('image_actions.remove_from_results')`Удалить из результатов`)}
            onClick={() => handleRemoveItems()}
          >
            <TrashIcon fill="currentColor" />
          </Button>
        </div>
        {/* <div className={styles.delimeter} /> */}
      </div>
      <CustomScrollBarContainer heightMax={`calc(100vh - 286px)`} color="#CEEAFB">
        {conflictedImages.map((item, index) => (
          <div key={item.identifier} className={styles.itemContainer}>
            {/* <div className={styles.horizontalLine2} */}
            {/* {index !== 0 && <div className={styles.delimeter} />} */}
            <div className={styles.delimeter} />

            {/* <div>{orderImages[item.index].identifier}</div> */}
            <ConflictImage
              // data={orderImages[item.index]}
              // isChecked={checked.indexOf(String(orderImages[item.index].id)) > -1}
              data={item}
              isChecked={checked.indexOf(item.md.identifier) > -1}
              onToggleCheck={handleCheckItem}
              onRemove={handleRemoveItems}
            />
          </div>
        ))}
      </CustomScrollBarContainer>
    </>
  );
};

export default ConflictList;
