import React from 'react';
import { Trans } from '@lingui/macro';

import { tagsTranslate } from 'locales/dicts/tags';

export const getTranslatedTag = (children: any) => {
  if (typeof children === 'string' && tagsTranslate[children]) {
    // @ts-ignore
    return <Trans id={tagsTranslate[children]} />;
  } else {
    return children;
  }
};
