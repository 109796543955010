import React from 'react';
import classNames from 'classnames';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';

import { getTranslatedTag } from './helper';

import styles from './ChipTag.module.scss';

interface ChipTagProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  value?: string;
  onClick?: (value?: string) => void;
  onDelete?: (value?: string) => void;
}

const ChipTag: React.FC<ChipTagProps> = props => {
  const { children, className, title, value, onClick, onDelete } = props;

  const handleClick = () => {
    // && value !== undefined
    if (typeof onClick === 'function') {
      onClick(value);
    }
  };

  const handleDelete = () => {
    // && value !== undefined
    if (typeof onDelete === 'function') {
      onDelete(value);
    }
  };

  return (
    <div
      className={classNames(styles.root, { [styles.clickable]: onClick }, className)}
      onClick={handleClick}
    >
      <span className={styles.innerLabel} title={title}>
        {getTranslatedTag(children)}
      </span>
      {onDelete && (
        <span className={styles.delete} onClick={handleDelete}>
          <CrossIcon fill="currentColor" />
        </span>
      )}
    </div>
  );
};

export default React.memo(ChipTag);
