import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import dateFnsParseISO from 'date-fns/parseISO';

export function parseDate(str: string, format?: string) {
  // const parsed = dateFnsParse(str, format, new Date(), { locale });
  let parsed;
  if (format) {
    parsed = dateFnsParse(str, format, new Date());
  } else {
    parsed = dateFnsParseISO(str);
  }
  // if (parsed) { return parsed; } else undefined;
  return parsed;
}

// , locale: Locale
export function formatDate(date: Date, format: string = 'dd.MM.yyyy HH:mm:ss') {
  // dd-MM-yyy HH:mm:ss -> dd.MM.yyyy HH:mm:ss
  // return dateFnsFormat(date, format, { locale });
  return dateFnsFormat(date, format);
}
