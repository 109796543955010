import React from 'react';

import { Select, SelectOption } from '@geobank/components/src/forms/Select';
import { ProductOptionType } from '../helpers/fieldTypes';

interface BytesPerPixelFieldProps {
  name: string; // bytesPerPixel
  value: number;
  options: ProductOptionType[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const BytesPerPixelField: React.FC<BytesPerPixelFieldProps> = props => {
  const { name, value, options, onChange } = props;

  return (
    <Select name={name} value={value} onChange={onChange}>
      {/*
      <SelectOption value="1">1</SelectOption>
      <SelectOption value="2">2</SelectOption>
      */}
      {options.map(param => (
        <SelectOption key={param.value} value={param.value}>
          {param.title}
        </SelectOption>
      ))}
    </Select>
  );
};

export default React.memo(BytesPerPixelField);
