import { Trans } from '@lingui/macro';

import { RootState } from 'ducks';
import React, { useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import PersonIcon from '@geobank/assets/src/icons/PersonIcon';
import FolderStarIcon from '@geobank/assets/src/icons/FolderStarIcon';
// import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';

import { geometryFavoriteActions, getGeometryFavorites } from 'ducks/favorites';

const styles = require('./FavoritesButton-styles.module.scss');

interface FavoritesButtonProps {
  className?: string;
  removeGeometry: typeof geometryFavoriteActions.removeRequest;
  selectGeometry: typeof geometryFavoriteActions.selectRegionOnMap;
  favorites: any[];
}

const getNameFromProperties = (properties: any) => {
  let name = null;
  if (properties) {
    Object.entries(properties).forEach(([key, value]) => {
      if (key.toLocaleLowerCase() === 'name') {
        // return value;
        name = value;
      }
    });
  }
  return name;
};

const FavoritesButton: React.FC<FavoritesButtonProps> = props => {
  // const handleLogout = () => props.logoutUser();
  // const favorites = JSON.parse(localStorage.getItem('regions') || '[]');
  const favorites = props.favorites;

  const renderMenu = useCallback(() => {
    if (favorites.length === 0) {
      return (
        <div className={styles.emptyMessage}>
          <span>
            <Trans>Список закладок c геометриями районов пуст</Trans>
            {/* Добавьте район поиска в закладки / Нарисуйте геометрию и добавьте в закладки */}
          </span>
        </div>
      );
    } else {
      return (
        <>
          <Dropdown.MenuHeader>
            <Trans>Районы_</Trans>
          </Dropdown.MenuHeader>
          {favorites.map((region: any, index: number) => {
            const name = getNameFromProperties(region.properties);
            return (
              <Dropdown.MenuItem key={region.properties._number || index + 1}>
                <div className={styles.itemRoot}>
                  <div
                    className={styles.itemHead}
                    onClick={() => {
                      props.selectGeometry(region);
                    }}
                  >
                    <Trans>Район #</Trans>
                    {region.properties._number || index + 1} {/* (Название) */}
                    {name ? (
                      <>
                        <br />
                        <span className={styles.itemHeadTitle}>{`(${name})`}</span>
                      </>
                    ) : null}
                  </div>
                  <div className={styles.itemActions}>
                    {/* <IconButton>
                    <ContourIcon />
                  </IconButton> */}
                    <IconButton
                      width="20px"
                      height="20px"
                      style={{ padding: '0' }}
                      onClick={() => {
                        props.removeGeometry(region.id);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                </div>
              </Dropdown.MenuItem>
            );
          })}
          {/* <Dropdown.MenuDivider /> */}
          {/* <Dropdown.MenuItem>Геометрия 1</Dropdown.MenuItem> */}
        </>
      );
    }
  }, [favorites, props]);

  return (
    <div className={props.className}>
      <Dropdown dropup={false} pullRight={true}>
        <Dropdown.Toggle style={{ borderRadius: '0' }}>
          <div /* style={{ padding: '5px 5px' }} */>
            {/* TODO - w and h */}
            <FolderStarIcon /* width="19" height="20" */ />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>{renderMenu()}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

// export default FavoritesButton;
export default connect(
  (state: RootState) => ({
    favorites: getGeometryFavorites(state),
  }),
  {
    removeGeometry: geometryFavoriteActions.removeRequest,
    selectGeometry: geometryFavoriteActions.selectRegionOnMap,
  }
)(FavoritesButton);
