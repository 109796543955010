import * as Sentry from '@sentry/react';

export const captureSagaException = (error: Error, actionName: string, data?: any) => {
  Sentry.withScope(scope => {
    // scope.setExtras(errorInfo);
    scope.addBreadcrumb({
      category: 'redux-action',
      message: actionName,
      data: data,
    });
    Sentry.captureException(error);
  });
};

export const captureSimpleException = (error: Error, message?: string, data?: any) => {
  Sentry.withScope(scope => {
    // scope.setExtras(errorInfo);
    scope.addBreadcrumb({
      category: 'detail', // detail | message
      message: message, // description
      data: data,
    });
    Sentry.captureException(error);
  });
};

export const captureSimpleMessage = (
  message: string,
  severity: Sentry.Severity = Sentry.Severity.Warning,
  data?: any
) => {
  Sentry.withScope(scope => {
    // scope.setExtras(errorInfo);
    scope.setLevel(severity);
    scope.addBreadcrumb({
      // level: severity,
      category: 'detail', // detail | message
      // message: message, // description
      data: data,
    });
    Sentry.captureMessage(message);
  });
};
