// MessageNotify, Notifier
import { NOTIFY_API_URL } from 'api/realAPI';
import { ActionType, getType } from 'deox';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import io from 'socket.io-client';

import { parseApiObject } from 'components/utils/apiDataConverter';
import { getIsAuthenticated, getProfile, iamActions } from 'ducks/authIAM';
import {
  // MessageContainer,
  addOrUpdMessage,
  fetchMessages,
  MessageContainerList,
  MessageContainerObject,
  readMessages,
} from 'ducks/messageNotify'; // MessageData
import { Debugger } from 'utils/logging';

// import fakeMessages from 'api/data/notify-md-messages.json';
// test user ext segment
// const userId = '4e3a4dc7-1c60-5b3b-bcfa-912e0acf4b72'; // customer_ext_niitp
const userId = undefined;

const notifyApiUrl = new URL(NOTIFY_API_URL!);

const TAG = 'SocketSaga';
const debug = Debugger(TAG);

let _socket: SocketIOClient.Socket;

const connect = () => {
  _socket = io(notifyApiUrl.origin, {
    path: notifyApiUrl.pathname,
  });
  return new Promise(resolve => {
    _socket.on('connect', () => {
      resolve(_socket);
    });
  });
};

const createSocketChannel = (socket: SocketIOClient.Socket) =>
  eventChannel(emitter => {
    const messageCallback = (data: any) => {
      emitter(data);
    };
    socket.on('message', messageCallback);
    return () => {
      socket.off('message', messageCallback);
    };
  });

// main init and watch Flow
function* watchForMessages() {
  try {
    const isAuthenticated = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }

    const user: ReturnType<typeof getProfile> = yield select(getProfile);
    const userUuid = user.id;
    //
    const socket: SocketIOClient.Socket = yield call(connect);
    socket.emit('register', userId || userUuid, socket.id);
    const socketChannel = yield call(createSocketChannel, socket);

    // пока тут. при вызове в комоненте с сообщениями вызывается (предполагаю) ещё до `register` и сообщения не приходят
    yield put(fetchMessages.request('metadata_download'));

    // test
    // const type = 'metadata_download';
    // socket.emit('get_messages', userId, type);

    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const respData: any = yield take(socketChannel);
      const msg: MessageContainerList | MessageContainerObject = parseApiObject(respData);

      debug.log(msg);

      // как взаимоисключить проверку на массив? -> const msg: MessageContainerList | MessageContainerObject
      // if (msg.type === 'get_messages' && Array.isArray(msg.data)) {
      if (msg.type === 'get_messages') {
        yield put(fetchMessages.success(msg.data));
      } else if (msg.type === 'new_message') {
        yield put(addOrUpdMessage(msg.data));
      } else if (msg.type === 'read_message') {
        const messageId = msg.data?.id;
        yield put(readMessages.success(messageId));
      }
    }
  } catch (error) {
    debug.error(error);
  } finally {
    debug.log('SocketChannel terminated');
  }
}

export function* fetchMessagesFlow(action: ActionType<typeof fetchMessages.request>) {
  try {
    const subject = action.payload;
    // const respData = yield call(requestFlowNew, API.fetchMessages, {});
    // debug.log('Create cart requests response data', respData);
    const isAuthenticated = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }

    const user: ReturnType<typeof getProfile> = yield select(getProfile);
    const userUuid = user.id;

    // const respData = fakeMessages; // as MessageData[];
    // const parsedData = parseApiObject(respData).data;
    // debug.log(subject, parsedData);
    // yield put(fetchMessages.success(parsedData));
    _socket.emit('get_messages', userId || userUuid, subject);
  } catch (error) {
    debug.error(error);
    // yield put(fetchMessages.failure(error));
  }
}
export function* fetchMessagesWatch() {
  yield takeLatest(getType(fetchMessages.request), fetchMessagesFlow);
}

export function* readMessagesFlow(action: ActionType<typeof readMessages.request>) {
  try {
    // arg1
    const param = action.payload; // messageId or subject (all messages)

    const user: ReturnType<typeof getProfile> = yield select(getProfile);
    const userUuid = user.id;

    if (typeof param === 'number') {
      const messageId = param;
      _socket.emit('read_message', userId || userUuid, messageId);
    } else {
      const subject = param;
      _socket.emit('read_messages', userId || userUuid, subject);
    }
  } catch (error) {
    debug.error(error);
  }
}
export function* readMessagesWatch() {
  yield takeLatest(getType(readMessages.request), readMessagesFlow);
}

export default function* root() {
  // yield fork(notifyStartFlow);
  yield fork(watchForMessages);
  yield fork(fetchMessagesWatch);
  yield fork(readMessagesWatch);
}
