import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro'; // Trans
import { withI18nProps, withI18n } from '@lingui/react';

import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
import { OrderProduct } from 'ducks/order';
import { toggleProductPreviewLayer } from 'ducks/mapLayers';

import styles from './ProductPreviewButtons.module.scss';

interface ProductPreviewButtonsProps {
  identifier: string;
  previewUrls: string[];
}

const ProductPreviewButtons: React.FC<ProductPreviewButtonsProps & withI18nProps> = props => {
  const { identifier, previewUrls, i18n } = props;
  const dispatch = useDispatch();

  // key - id: number or identifier: string
  const handleProductPreviewToggle = (
    // identifier: string,
    previewUrl: OrderProduct['previewUrls'][0],
    productName: string
  ) => {
    // if (!Array.isArray(previewUrls) || previewUrls.length === 0) {
    //   return;
    // }
    // const previewUrl = previewUrls[0];

    // https://next.gptl.ru/totiler/v1/tiles/12/2607/1277.png?url=/vsis3/cache-delivery/customer_niitp@dep45.niitp/00002-20201130/ETRIS.KVIK.MSS.5254.3.0.2018-06-25.L0.NTSOMZ_MSK.NTSOMZ_MSK/delivery/preview_pansharp.vrt
    // https://next.gptl.ru/totiler/v1/bounds
    //   ?url=/vsis3/cache-delivery/customer_niitp@dep45.niitp/00002-20201130/ETRIS.KVIK.MSS.5254.3.0.2018-06-25.L0.NTSOMZ_MSK.NTSOMZ_MSK/delivery/preview_pansharp.vrt
    const regReplace = new RegExp(/\/tiles\/.*\.png/);
    const getBoundsUrl = previewUrl.replace(regReplace, '/bounds');

    fetch(getBoundsUrl /* , { mode: 'cors' } */)
      .then(resp => resp.json())
      .then(bbox => {
        // console.log(bbox);
        const params = { identifier: `${identifier}-${productName}`, url: previewUrl, bbox }; // identifier: `${identifier}-${productName}`
        // console.log(params, productName);
        dispatch(toggleProductPreviewLayer(params));
      })
      .catch(err => {
        console.error(err);
      });

    // dispatch(
    //   toggleProductPreviewLayer({
    //     identifier,
    //     url: previewUrl,
    //     bbox: [48.78045518652027, 49.343174055547316, 55.493954446290076, 55.930211768213454],
    //   })
    // );
    // console.log(previewUrl);
  };

  const regProduct = new RegExp(/\/preview_(\w+)\.vrt/);

  return (
    <div className={styles.root}>
      {previewUrls.map((previewUrl, index) => {
        const productName = previewUrl.match(regProduct)![1];

        return (
          <div
            key={index}
            className={styles.productPreview}
            title={`${i18n._(t`Просмотр на карте`)} (${productName})`}
            onClick={handleProductPreviewToggle.bind(null, previewUrl, productName)}
          >
            <EyeIcon fill="currentColor" />
          </div>
        );
      })}
    </div>
  );
};

export default withI18n()(ProductPreviewButtons);
