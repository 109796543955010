import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalBox from '@geobank/components/src/common/ModalBox/ModalBox';
import * as API from 'api/realAPI';
import { paymentAllowed } from 'ducks/config';

interface Config {
  MAINTENANCE_MODE: {
    ACTIVE: boolean;
    TEXT: string;
  };
  PAYMENT: {
    ACTIVE: boolean;
    TEXT: string;
  };
  TEST_USERS: string[];
}

const maintenaceConfigDefault: Config = {
  MAINTENANCE_MODE: {
    ACTIVE: false,
    TEXT:
      'Сегодня проводятся профилактические работы. В случае недоступности сервисов повторите попытку позднее.',
  },
  PAYMENT: {
    ACTIVE: true,
    TEXT:
      'Уважаемые пользователи геопортала! Платный заказ снимков временно недоступен. Ориентировочная дата решения проблемы: 31.08.2022',
  },
  TEST_USERS: ['/groups/otd45'],
};

const MaintenanceModePage: React.FC = () => {
  const dispatch = useDispatch();
  const isShowed = sessionStorage.getItem('isWarned');
  const [maintenanceConfig, setMaintenanceConfig] = useState<Config>(maintenaceConfigDefault);
  const [modal, setModal] = useState<boolean>(false);
  const handleModalClose = () => {
    setModal(false);
    sessionStorage.setItem('isWarned', 'true');
  };

  useEffect(() => {
    const getConfig: any = async () => {
      const config = await API.getStartupConfig();
      config ? setMaintenanceConfig(config) : setMaintenanceConfig(maintenaceConfigDefault);
    };
    getConfig();
  }, []);

  useEffect(() => {
    dispatch(paymentAllowed(maintenanceConfig.PAYMENT.ACTIVE));
    setModal(
      isShowed !== 'true' &&
        (!maintenanceConfig.PAYMENT.ACTIVE || maintenanceConfig.MAINTENANCE_MODE.ACTIVE)
    );
  }, [dispatch, isShowed, maintenanceConfig]);

  return (
    <>
      {modal && (
        <ModalBox isNeedCrossButton={true} onClickCrossButton={handleModalClose}>
          <div style={{ color: 'red', margin: '10px', textAlign: 'center' }}>ВНИМАНИЕ!!!</div>
          <div style={{ display: 'flex', maxWidth: '450px', margin: '10px', textAlign: 'center' }}>
            {maintenanceConfig.MAINTENANCE_MODE.ACTIVE
              ? maintenanceConfig.MAINTENANCE_MODE.TEXT
              : maintenanceConfig.PAYMENT.TEXT}
          </div>
        </ModalBox>
      )}
    </>
  );
};

export default MaintenanceModePage;
