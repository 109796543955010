function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, reject) => {
    try {
      const successful = document.execCommand('copy');

      const msg = successful ? 'successful' : 'unsuccessful';
      if (successful === false) {
        throw new Error('unsuccessful');
      }
      console.log('Fallback: Copying text command was ' + msg);
      resolve();
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      reject(err);
    } finally {
      document.body.removeChild(textArea);
    }
  });
}

export function copyTextToClipboard(text: string) {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          // console.log('Async: Copying to clipboard was successful!');
          resolve();
        },
        err => {
          // console.error('Async: Could not copy text: ', err);
          reject(err);
        }
      );
    } else {
      fallbackCopyTextToClipboard(text).then(
        () => resolve(),
        err => reject(err)
      );
    }
  });
}
