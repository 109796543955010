import { select, put, call, takeLatest, fork } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { selectLanguage, getLanguage } from 'ducks/locale';

import { setLanguageHeader } from 'api/realAPI';
import {
  setLanguageToLocalStorage,
  getLanguageFromLocalStorage,
  removeLanguageFromLocalStorage,
} from 'utils/localStorage';
import { availableLanguages, defaultLanguageIfAbsent } from 'locales';

export function* langStartFlow() {
  const language = yield select(getLanguage);
  let localStorageLanguage = yield call(getLanguageFromLocalStorage);
  let navigatorLanguage: any = navigator.language && navigator.language.slice(0, 2); // chrome 'ru', ff 'ru-Ru'

  // TODO - может отправлять статистику по языку посетителя
  if (navigatorLanguage && availableLanguages.indexOf(navigatorLanguage) === -1) {
    navigatorLanguage = defaultLanguageIfAbsent;
  }
  if (localStorageLanguage && availableLanguages.indexOf(localStorageLanguage) === -1) {
    localStorageLanguage = null;
  }

  if (localStorageLanguage && localStorageLanguage !== language) {
    yield put(selectLanguage(localStorageLanguage));
  } else if (navigatorLanguage && navigatorLanguage !== language) {
    yield put(selectLanguage(navigatorLanguage));
    yield call(setLanguageToLocalStorage, navigatorLanguage);
  }
}

export function* selectLanguageFlow(action: ActionType<typeof selectLanguage>) {
  try {
    const lang = action.payload;
    yield call(setLanguageToLocalStorage, lang);
    setLanguageHeader(lang);
  } catch (error) {
    yield call(removeLanguageFromLocalStorage);
  }
}
export function* selectLanguageWatch() {
  yield takeLatest(selectLanguage, selectLanguageFlow);
}

export default function* localeRoot() {
  yield fork(langStartFlow);
  yield fork(selectLanguageWatch);
}
