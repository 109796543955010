import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import NotifyContainer from './NotifyContainer';

interface NotifyPortalProps {
  root: HTMLElement | null;
}

// https://reactjs.org/docs/portals.html
class NotifyPortal extends PureComponent<NotifyPortalProps> {
  el: HTMLDivElement;

  constructor(props: any) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const rootNode = this.props.root;
    rootNode !== null && rootNode.appendChild(this.el);
  }

  componentWillUnmount() {
    const rootNode = this.props.root;
    rootNode !== null && rootNode.removeChild(this.el);
  }

  render() {
    // return ReactDOM.createPortal(this.props.children, this.el);
    return ReactDOM.createPortal(<NotifyContainer />, this.el);
  }
}

export default NotifyPortal;
