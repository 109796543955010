import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'ducks';

import { notifyActions, getMessages } from 'ducks/message';
import NotifyMessage from './NotifyMessage';

import styles from './NotifyContainer.module.scss';

/* const styles = {
  // root: {
  //   position: 'fixed',
  //   zIndex: '1500',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   marginTop: '20px',
  //   left: '50%',
  //   top: '0',
  // },
}; */

const mapStateToProps = (state: RootState) => ({
  messages: getMessages(state),
});
const mapDispatchToProps = {
  pushMessage: notifyActions.push,
  removeMessage: notifyActions.remove,
};

class NotifyContainer extends PureComponent<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
> {
  handleClose = (msgId: string) => {
    this.props.removeMessage(msgId);
  };

  render() {
    const { messages: notifyMessages } = this.props;

    // TODO - add reverse sort!
    const bcPlaceMessages = notifyMessages.filter(item => item.place === 'bc');
    const brPlaceMessages = notifyMessages.filter(item => item.place === 'br');

    return (
      <div className={styles.root}>
        <div className={styles.bottomCenter}>
          {bcPlaceMessages.map(item => (
            <div key={item.id} className={styles.messageItemBottomCenter}>
              <NotifyMessage message={item} onClose={this.handleClose.bind(this, item.id!)} />
            </div>
          ))}
        </div>
        <div className={styles.bottomRight}>
          {brPlaceMessages.map(item => (
            <div key={item.id} className={styles.messageItemBottomRight}>
              <NotifyMessage message={item} onClose={this.handleClose.bind(this, item.id!)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyContainer);
