// MessageAlert, Info
import { createActionCreator, createReducer } from 'deox';
import { combineReducers } from 'redux';
import { nanoid } from 'nanoid/non-secure';
import { MessageDescriptor } from '@lingui/core';

import { RootState as RootStateType } from './root';

/**
 * Types/Interfaces
 */
/* Message
{
  message: '<html>',
  timeout: 60,
  place: 'tc',
  color: 'info',
  isOpen: true
} */
export type MessageColor = 'info' | 'success' | 'alert' | 'warning'; // 'error';

export type MessagePlace = 'bc' | 'br'; // bottom-center, bottom-right

export interface Message {
  id?: string;
  message: string | JSX.Element | MessageDescriptor; // | MessageDescriptor - t``, i18nMark - ''
  timeout?: number;
  place?: MessagePlace;
  color?: MessageColor;
  isOpen?: boolean;
}

/**
 * Actions
 */
export const notifyActions = {
  push: createActionCreator('NOTIFY/PUSH_MESSAGE', resolve => (msg: Message) => resolve(msg)),
  remove: createActionCreator('NOTIFY/REMOVE_MESSAGE', resolve => (msgId: string) =>
    resolve(msgId)
  ),
  // clear: createActionCreator('NOTIFY/CLEAR_MESSAGES'), // clearAll
};
// { pushMessage, removeMessage, clearMessages }

/**
 * Reducers and state
 */
export const messagesDefaultState: Message[] = [];

export const messagesReducer = createReducer(messagesDefaultState, handleAction => [
  handleAction(notifyActions.push, (state, { payload }) => [
    ...state,
    { id: nanoid(6), place: 'bc', ...payload },
  ]),
  handleAction(notifyActions.remove, (state, { payload }) =>
    state.filter(item => item.id !== payload)
  ),
  // handleAction(notifyActions.clear, () => []),
]);

// rootReducer
export default combineReducers({
  messages: messagesReducer,
});

/**
 * Selectors
 */
export const getMessages = (state: RootStateType) => state.notify.messages;
