import { Trans } from '@lingui/macro';
import { S3_URL } from 'api/realAPI';
import { RootState } from 'ducks';
import React from 'react';
import { useSelector } from 'react-redux';

import styles from './StylesMenu.module.scss';

interface GuidesMenuProps {
  isOpen: boolean;
}

const GuidesMenu: React.FC<GuidesMenuProps> = props => {
  const { isOpen } = props;
  const currentLocale: string = useSelector((state: RootState) => state.locale.language);
  if (isOpen) {
    return (
      <div className={styles.expanded_links}>
        {currentLocale === 'en' && (
          <a
            className={styles.item}
            href={`${S3_URL}/geoportal-public/Data_ordering_from_the_Fund_ENG.PDF`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Data ordering Guide
          </a>
        )}
        <a
          className={styles.item}
          href={`${S3_URL}/geoportal-public/user-guide/v0.1/index.html`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans id="menu.user_guide_title">Руководство пользователя</Trans>
        </a>
        <a
          className={styles.item}
          href={`${S3_URL}/geoportal-public/pro-guide/v1/index.html`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans id="menu.developer_guide_title">Спецификация API</Trans>
        </a>
      </div>
    );
  }
  return <></>;
};

export default GuidesMenu;
