// Accordion based
import React, { PureComponent } from 'react';
import classNames from 'classnames';

// import Checkbox from '@geobank/components/src/forms/CheckBox/Checkbox';
// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';
// import CartIcon from '@geobank/assets/src/icons/CartIcon';
// import TrashIcon from '@geobank/assets/src/icons/TrashIcon';

import { ImageMetadataDetail, ImageMetadata } from 'ducks/types/metadataTypes';
// import { catFileIdentifier } from 'components/utils/format';
import ResultDetail from '../ResultItem/ResultDetail';
import ResultPreview from '../ResultItem/ResultPreview';

import SearchResultItemFooter from './SearchResultItemFooter';
import SearchResultItemHeader from './SearchResultItemHeader';
import styles from './SearchResultItem.module.scss';

interface SearchResultItemProps {
  data: ImageMetadataDetail;
  // flags
  isActive: boolean;
  isChecked: boolean;
  isOpen?: boolean;
  isInCart: boolean;
  // label: string | JSX.Element;
  // right?: boolean;
  // preview?: JSX.Element;
  // handlers
  // onToggle?: (flag: boolean) => void; // flag: true - openned, false - closed

  isContourActive: boolean;
  isPreviewActive: boolean;
  isFullImageActive: boolean;
  isFullImageActive2B: boolean;

  actions: {
    // header
    onActivate: (md: ImageMetadataDetail) => void; // handleSelectRoute, onSelect
    onToggleCheck: (md: ImageMetadataDetail) => void;
    onAddToCart: (md: ImageMetadataDetail) => void;
    onRemove: (md: ImageMetadataDetail) => void;
    onCopyToClipboard: (text: string) => void;
    // body
    onToggleOpen: (md: ImageMetadata, flag?: boolean) => void; // toggle preview/full description; onExpand !!!
    // footer
    onToggleContour: (md: ImageMetadataDetail) => void;
    onTogglePreview: (md: ImageMetadataDetail) => void;
    onToggleFullImage: (md: ImageMetadataDetail) => void;
    onToggleFullImage2B: (md: ImageMetadataDetail) => void;
    onMetadataDownload: (md: ImageMetadataDetail) => void;
  };
}

// interface SearchResultItemState {
//   isActive: boolean;
// }

class SearchResultItem extends PureComponent<
  SearchResultItemProps
  // SearchResultItemState
> {
  // constructor(props: any) {
  //   super(props);

  //   this.state = {
  //     isActive: false,
  //   };
  // }

  // public componentDidMount(): void {
  //   const { isOpen } = this.props;
  //   if (isOpen) {
  //     this.setState({ isActive: isOpen });
  //   }
  // }

  // public componentDidUpdate(prevProps: SearchResultItemProps): void {
  //   if (prevProps.isOpen !== this.props.isOpen && typeof this.props.isOpen === 'boolean') {
  //     this.setState({ isActive: this.props.isOpen });
  //   }
  // }

  // private readonly changeToggle = () => {
  //   let { isActive } = this.state;
  //   isActive = !isActive;
  //   this.setState({ isActive }, () => {
  //     if (typeof this.props.onToggle === 'function') {
  //       this.props.onToggle(isActive);
  //     }
  //   });
  // };

  // handleCopyToClipboard

  // onActivate = (md: ImageMetadataDetail) => {
  //   this.props.actions.onActivate(md);
  // };

  public render() {
    // const { isActive } = this.state;
    // const { right, label, children: innerComponent, preview } = this.props;
    const { data, isActive, isChecked, isOpen, isInCart } = this.props;

    return (
      <div /* className={styles.root} */
        // id={`resultsItem-${data.id}`}
        className={classNames(styles.listItem, {
          [styles.listItemSelected]: isActive, // data.id === selectedMetadataRoute,
        })}
      >
        <div className={styles.headContainer}>
          <SearchResultItemHeader
            data={data}
            // isActive={isActive}
            isChecked={isChecked}
            isInCart={isInCart}
            onActivate={this.props.actions.onActivate}
            onToggleCheck={this.props.actions.onToggleCheck}
            onCopyToClipboard={this.props.actions.onCopyToClipboard}
            onAddToCart={this.props.actions.onAddToCart}
            onRemove={this.props.actions.onRemove}
          />
        </div>

        {/* {isActive && <div className={styles.accordion_panel__active}>{innerComponent}</div>} */}
        {/* <div className={styles.bodyContainer}>{isActive ? innerComponent : preview || null}</div> */}
        <div className={styles.bodyContainer}>
          {isOpen ? (
            <ResultDetail
              metadata={data}
              actions={{ handleAccordionToggle: this.props.actions.onToggleOpen }}
            />
          ) : (
            <ResultPreview
              metadata={data}
              actions={{ handleAccordionToggle: this.props.actions.onToggleOpen }}
            />
          )}
        </div>

        <div>
          <SearchResultItemFooter
            data={data}
            isContourActive={this.props.isContourActive}
            isPreviewActive={this.props.isPreviewActive}
            isFullImageActive={this.props.isFullImageActive}
            isFullImageActive2B={this.props.isFullImageActive2B}
            onToggleContour={this.props.actions.onToggleContour}
            onTogglePreview={this.props.actions.onTogglePreview}
            onToggleFullImage={this.props.actions.onToggleFullImage}
            onToggleFullImage2B={this.props.actions.onToggleFullImage2B}
            onMetadataDownload={this.props.actions.onMetadataDownload}
          />
        </div>
      </div>
    );
  }
}

export default SearchResultItem;
