// filename: MapComponent, Map, MapAdapter,
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { css } from 'astroturf';
// import Tag from 'components/common/Tag/Tag';

// import { transform } from 'ol/proj';
// import { toStringXY } from 'ol/coordinate';
// import Overlay from 'ol/Overlay';
// import MousePosition from 'ol/control/MousePosition';
// import { toStringHDMS } from 'ol/coordinate'; // createStringXY

// import { fetchRequest, getIsFetching, getBaseLayers, fetchFeatureInfoRequest, getFeatureInfo, updateImageLayer } from '../../../ducks/layers';
// import { getSelectedMetadataCatalog, selectMetadataCatalog } from '../../../ducks/metadata';
import { RootState } from 'ducks';
import { balloonToggle } from 'ducks/map';
import {
  fetchLayers,
  getBaseLayers,
  getIsFetching,
  getSelectedNameLayerId,
  toggleNameLayer,
} from 'ducks/mapLayers';
import { getActiveImagePreviews, selectMetadataRoute, toggleImagePreview } from 'ducks/metadata';

import GeoportalMap from './Map';
// import PopupElement from './PopupElement/PopupElement';
// import BaseLayerControl from '../BaseLayerControl/BaseLayerControl';

import 'ol/ol.css';
import 'styles/ol.css';
// import { Overlay } from 'ol';

import { Debugger } from 'utils/logging';
const debug = Debugger('MapCmp'); // TAG

// const styles = css`
//   .mapCoordsSection {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     margin-right: 20px;
//     //
//     z-index: 1;
//   }
// `;

interface MapComponentProps {
  gMap?: GeoportalMap;
  createOptions?: object;
  // connect
  fetchLayersRequest: typeof fetchLayers.request;
  selectMetadataRoute: typeof selectMetadataRoute;
  toggleImagePreview: typeof toggleImagePreview.request;
  toggleNameLayer: typeof toggleNameLayer;
  balloonToggle: typeof balloonToggle;
  activeImagePreviews: string[];
  isFetching: boolean;
  selectedNameLayerId: string;
  baseLayers: any[];
}

// TODO - create ducks/map.ts
// interface MapComponentState {
//   activeBaseLayer?: number;
// }

// class Map
class MapComponent extends Component<MapComponentProps> {
  // static propTypes = {};

  private map: GeoportalMap; // any
  private readonly mapRef: React.RefObject<HTMLInputElement>;

  private currentFeatureNum: number;
  private currentFeatureIds: number[];

  constructor(props: any) {
    super(props);

    this.mapRef = React.createRef();

    // lastClick = [];
    this.currentFeatureNum = 0;
    this.currentFeatureIds = [];

    // moved from componentDidMount()
    this.map = this.props.gMap
      ? this.props.gMap
      : new GeoportalMap({ ...this.props.createOptions });
  }

  public componentDidMount() {
    // this.map = mapInstance;
    // this.map = new GPMap().getMap();
    // this.map = this.props.gMap.getOlMap();

    // subscribe events
    // TODO - режимы: отключено, интерактив1, интерактив2, ...
    this.map.getOlMap().on('singleclick', event => {
      const features = this.map.getOlMap().getFeaturesAtPixel(event.pixel).reverse();
      debug.log('map features', features);

      if (features.length === 0) {
        // popupElement.innerHTML = '&nbsp;';
        // popupElement.style.display = 'none';
        return;
      }

      const featureIds = features.map(item => item.getId() as number); // TODO - могут быть и undefiner, string!?
      if (
        this.currentFeatureIds.sort().toString() === featureIds.sort().toString() &&
        featureIds.length > this.currentFeatureNum + 1
      ) {
        this.currentFeatureNum++;
      } else {
        this.currentFeatureNum = 0;
      }

      // test
      // todo - getId() для нарисованной фичи -> undefined
      const feature = features[this.currentFeatureNum];
      // this.props.gpMap.selectMetadataFeature(feature.getId());
      // exclude feature with id=drawGeometry
      // if (typeof feature.getId() !== 'number' && ) {
      if (feature.get('_type') === 'metadata' && feature.getId()) {
        this.props.selectMetadataRoute(feature.getId() as string, false);
        this.props.balloonToggle(false);
        this.props.toggleImagePreview([feature.getId() as string]);
      } else if (feature.get('_type') === 'gptl-images') {
        // this.props.selectMetadataRoute(feature.get('data').metadata_id as number, false);
        this.props.balloonToggle(true, feature.get('data'));
      } else if (feature.get('_type') === 'region') {
        this.props.balloonToggle(true);
      } else if (feature.get('_type') === 'object') {
        // const coordinate = this.map._original_getCoordinateFromPixel(event.pixel);
        // const coordinate = event.coordinate;
        // popupElement.style.display = 'block';
        // popupElement.innerHTML = 'Наименование объекта test 231312 123';
        // popupElement.innerHTML = feature.get('name') || 'Наименование объекта';
        // popup.setPosition(coordinate);
      } else {
        // popupElement.innerHTML = '&nbsp;';
      }

      // this.lastClick = event.pixel;
      this.currentFeatureIds = featureIds;
    });

    // const overlayContainer = document.getElementById('overlay-container')!;

    // const overlayLayer = new Overlay({
    //   element: overlayContainer,
    // });
    // this.map.getOlMap().addOverlay(overlayLayer);

    // this.map.getOlMap().on('click', event => {
    //   overlayLayer.setPosition(undefined);
    //   const overlayRegionName = document.getElementById('region-name')!;
    //   // const overlayRegionTagOrder = document.getElementById('region-tag-order')!;
    //   const overlayRegionArea = document.getElementById('region-area')!;
    //   this.map.getOlMap().forEachFeatureAtPixel(event.pixel, (feature, layer) => {
    //     const clickedCoordinate = event.coordinate;
    //     overlayRegionName.innerHTML = `${feature.getProperties().name}`;
    //     overlayRegionArea.innerHTML = `${feature.getProperties().area} ${feature.getProperties().measure}<sup>2</sup>`;
    //     overlayLayer.setPosition(clickedCoordinate);
    //     // overlayText.innerHTML = '<p>You clicked here</p>'
    //   });
    // });

    // this.map.getOlMap().on('postrender', () => {
    // });

    this.map.getOlMap().setTarget(this.mapRef.current!);

    if (!this.props.isFetching && this.props.baseLayers.length === 0) {
      this.props.fetchLayersRequest();
    }

    // const mousePositionControl = new MousePosition({
    //   coordinateFormat: coord => toStringHDMS(coord!),
    //   projection: 'EPSG:4326',
    //   target: 'mouse-position',
    //   className: 'ol-mouse-position--custom',
    //   undefinedHTML: '', // сохраняет последнее значение
    // });
    // mousePositionControl.setMap(this.map.getOlMap());
  }

  public componentWillUnmount() {
    this.map.getOlMap().setTarget(undefined);
  }

  // prevProps, prevState
  public componentDidUpdate(prevProps: any) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.map.setBaseLayers(this.props.baseLayers);
      this.props.toggleNameLayer(this.props.selectedNameLayerId);
    }
  }

  public render() {
    // const featureInfo = this.props.featureInfo;
    // const { baseLayers } = this.props;

    return (
      // <div>
      <div
        className="ol-map"
        // ref={el => (this.mapRef = el)}
        ref={this.mapRef}
        style={{ height: '100vh', width: '100%' }}
      >
        {/* <div ref="popup" className="ol-popup" /> */}
        {/* <PopupElement /> */}
        {/* <BaseLayerControl baseLayers={baseLayers} active={this.map._currentBaseLayerId} /> */}
        {/* <div id="mouse-position"></div> */}
        {/* <div className={styles.mapCoordsSection}>
          <Tag border="top">
            <span id="mouse-position" />
          </Tag>
        </div> */}
      </div>
      // </div>
    );
  }
}

// export default MapComponent;
// export default connect(
//   state => ({
//     isFetching: getIsFetching(state),
//     // baseLayer: getSelectedBaseLayer(state),
//     baseLayers: getBaseLayers(state),
//     //
//     selectedMetadataCatalog: getSelectedMetadataCatalog(state),
//     featureInfo: getFeatureInfo(state),
//   }),
//   { fetchRequest, selectMetadataCatalog, fetchFeatureInfoRequest, updateImageLayer },
// )(MapComponent);
export default connect(
  // ({ metadata }: store.RootState) => ({ metadata }),
  (state: RootState) => ({
    // layers: getLayers(state),
    baseLayers: getBaseLayers(state),
    isFetching: getIsFetching(state),
    activeImagePreviews: getActiveImagePreviews(state),
    selectedNameLayerId: getSelectedNameLayerId(state),
  }),
  {
    fetchLayersRequest: fetchLayers.request,
    selectMetadataRoute,
    toggleImagePreview: toggleImagePreview.request,
    balloonToggle,
    toggleNameLayer,
  }
)(MapComponent);
