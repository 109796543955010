import React from 'react';
import { connect } from 'react-redux';
import { I18nProvider } from '@lingui/react';

import { RootState } from 'ducks';
import { /* defaultLanguageState, */ getLanguage } from 'ducks/locale';

interface I18nLoaderProps {
  language: string;
}

interface I18nLoaderState {
  catalogs: any;
}

export class I18nLoader extends React.Component<I18nLoaderProps, I18nLoaderState> {
  public state: I18nLoaderState = {
    catalogs: {},
  };

  public loadCatalog = async (language: string) => {
    const catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `locales/${language}/messages.js`
      // `@lingui/loader!locale/${language}/messages.json`
    );

    this.setState(state => ({
      catalogs: {
        ...state.catalogs,
        [language]: catalog,
      },
    }));
  };

  public componentDidMount() {
    const language = this.props.language;

    /* !Note:
      В первой версии отключил загрузку оригинального (ru) каталога, и не создавал .json файл.
      При разработке (yarn start) проблем нет, языки переключаются, переводы мутятся. Были только Warning в консоле об отсутсвии ru catalog.
      Возникла проблема с конструкцией <Trans>до {value} %</Trans>, перевод в сборке (build) был `до {value} %`.
      Если добавить каталог ru, то в сборке всё ОК! Возможно, что-то не так в сборке (webpack) или другие решения.
      (also см. shouldComponentUpdate, render)
     */
    // if (language !== defaultLanguageState) {
    // tslint:disable-next-line: no-floating-promises
    this.loadCatalog(language);
    // }
  }

  public shouldComponentUpdate(nextProps: I18nLoaderProps, nextState: I18nLoaderState) {
    const { language } = nextProps;
    const { catalogs } = nextState;

    if (
      // language !== defaultLanguageState &&
      language !== this.props.language &&
      !catalogs[language]
    ) {
      // tslint:disable-next-line: no-floating-promises
      this.loadCatalog(language);
      return false;
    }

    return true;
  }

  public render() {
    const { children, language } = this.props;
    const { catalogs } = this.state;

    // Skip rendering when catalog isn't loaded.
    // language !== defaultLanguageState &&
    if (!catalogs[language]) {
      return null;
    }

    return (
      <I18nProvider language={language} catalogs={catalogs}>
        {children}
      </I18nProvider>
    );
  }
}

export default connect((state: RootState) => ({
  language: getLanguage(state),
}))(I18nLoader);
