// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // ,{ TileLayerOptions }
import XYZ from 'ol/source/XYZ';

import { CreateLayerBundle } from '../types';

const createGeoportalCoverageXYZLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle; // params
  // const { layerOptions, layerParams } = args; // not work?
  // const url = params.url; // 'https://gptl.ru/coverages/images_landsat';
  const url = options.url;

  const layer = new TileLayer({
    id,
    name,
    // -- /custom args delimiter --
    // visible: (layerOptions && layerOptions.visible) || false,
    visible: options.visible || false,
    source: new XYZ({
      // url: options.url,
      tileUrlFunction: (tileCoord, pixelRatio, projection) => {
        // this.url = params.url; // 'https://gptl.ru/coverages/images_landsat';
        // console.log(coordinates); // [3, 3, -5]
        // console.log(tileCoord);
        const xyz = {
          z: tileCoord[0],
          x: tileCoord[1],
          y: tileCoord[2],
          // y: tileCoord[2]  //Math.abs(tileCoord[2])
        };

        let path = url + '/' + xyz.z + '/';

        let textX = xyz.x.toString(2);
        while (textX.length < xyz.z) textX = '0' + textX;

        let textY = xyz.y.toString(2);
        while (textY.length < xyz.z) textY = '0' + textY;

        for (let i = 6; i < xyz.z; i++) {
          path += textY.substr(0, i - 5) + '-' + textX.substr(0, i - 5) + '/';
        }

        path += textY + '-' + textX + '.jpg';

        return path;
      },
      attributions: options.attribution,
    }),
  });

  return layer;
};

export default createGeoportalCoverageXYZLayer;
