// import classNames from 'classnames';
import { Trans } from '@lingui/macro'; // t
// import { i18nMark } from '@lingui/react';
import dateFnsFormat from 'date-fns/format';
import React, { PureComponent } from 'react';
// import get from 'lodash/get';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';

import { RootState } from 'ducks';
import {
  balloonToggle,
  drawControl,
  getGeofenceGeometry,
  getIsDrawControlShow,
  getRegionsOfInterest,
} from 'ducks/map'; // getIsMainPanelShow
import { notifyActions } from 'ducks/message';
import { getIsFetching, getSearchParams, searchImages } from 'ducks/metadata';
import { connect } from 'react-redux';
// import { fetchPlatforms } from 'ducks/classifier';
// import { getIsAuthenticated, getIFetching as getIsAuthenticating, iamActions } from 'ducks/authIAM';

// import { camelToSnake } from '@geobank/assets/src/uibook/helpers/utils';
// import dateFormat from 'date-fns/format';
import Loader from '@geobank/components/src/common/Loader/Loader';
import GeoportalMap from 'components/map/Map/Map';
import { genId } from 'components/utils/identifier';
import { validateFormValues } from 'components/utils/validation/form';
import { createSimpleRequest, Request } from 'ducks/simpleRequest';
// import NotifyMessage from 'components/NotifyMessage/NotifyMessage';
// import SearchResult from './SearchResult/SearchResult';
// import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { SearchMetadataParams } from 'ducks/types/metadataTypes';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { fromExtent } from 'ol/geom/Polygon';
import { Vector as VectorSource } from 'ol/source';
import { validationFunctions } from './formValidation';
import RequestProductPanel from './RequestProductPanel/RequestProductPanel';
import SearchForm from './SearchForm/SearchForm';

import { selectStyle } from 'components/map/Map/featureStyles';

import { getIsFree, getIsGeofenced } from 'ducks/authIAM';
import styles from './LeftMenu.css';

const mapStateToProps = (state: RootState) => ({
  isFetching: getIsFetching(state),
  isFreeUser: getIsFree(state),
  isGeofencedUser: getIsGeofenced(state),
  isDrawControlShow: getIsDrawControlShow(state),
  searchParams: getSearchParams(state),
  regionsOfInterest: getRegionsOfInterest(state),
  geofence: getGeofenceGeometry(state),
});
const mapDispatchToProps = {
  searchImagesRequest: searchImages.request,
  clearSearchParams: searchImages.clearParams,
  drawControlShow: drawControl.show,
  drawControlHide: drawControl.hide,
  setScreenGeometry: drawControl.setGeometry,
  balloonToggle: balloonToggle,
  createSimpleRequest: createSimpleRequest.request,
  pushMessage: notifyActions.push,
};

type SearchFormPanelProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

interface SearchFormPanelState {
  requestType: 'archive' | 'shooting';
  isShootingPanelOpen: boolean;
}

class SearchFormPanel extends PureComponent<SearchFormPanelProps, SearchFormPanelState> {
  private formRef = React.createRef<SearchForm>();

  public state: SearchFormPanelState = {
    requestType: 'archive',
    isShootingPanelOpen: false,
  };

  public componentDidMount() {
    if (!this.props.isGeofencedUser && this.props.searchParams.regionSelection === 'screen') {
      const map = GeoportalMap.getInstance();
      const extent = map.getOlMap().getView().calculateExtent();
      const polygon = fromExtent(extent);
      const screenGeometry = new WKT().writeGeometry(polygon, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      this.setScreenRegionOfInterest(screenGeometry);
    }
  }

  public componentDidUpdate(prevProps: SearchFormPanelProps, prevState: SearchFormPanelState) {
    if (this.props.isGeofencedUser && this.props.regionsOfInterest.length === 0) {
      this.props.setScreenGeometry(this.props.geofence[0]);
    }
  }

  public drawScreenFeature = (geometry: string) => {
    const map = GeoportalMap.getInstance();
    const feature = new WKT().readFeature(geometry);
    feature.setId(genId());
    feature.set('_type', 'screen');
    feature.setStyle(selectStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = map.ensureCurrentRegionLayer();
    layer.setSource(vectorSource);
  };

  public setScreenRegionOfInterest = (geometry: string) => {
    const feature = new WKT().readFeature(geometry);
    const geoJSON = new GeoJSON().writeFeatureObject(feature);
    this.props.setScreenGeometry(geoJSON);
    // this.props.balloonToggle(true);
  };

  public getRegionGeometry = () => {
    let geometry;

    if (!this.formRef.current) {
      return geometry;
    } else {
      const formState = this.formRef.current.state;
      const wktFormat = new WKT();
      const geojsonFormat = new GeoJSON();
      let polygon: any;
      if (formState.regionSelection === 'screen' && !this.props.isGeofencedUser) {
        const map = GeoportalMap.getInstance();
        const extent = map.getOlMap().getView().calculateExtent();
        polygon = fromExtent(extent);
        this.drawScreenFeature(wktFormat.writeGeometry(polygon));
        this.setScreenRegionOfInterest(
          wktFormat.writeGeometry(polygon, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          })
        );
        geometry =
          this.props.searchParams.source === 'archive'
            ? wktFormat.writeGeometry(polygon.transform('EPSG:3857', 'EPSG:4326'))
            : geojsonFormat.writeGeometry(polygon.transform('EPSG:3857', 'EPSG:4326'));
      } else {
        polygon = geojsonFormat.readFeature(this.props.regionsOfInterest[0]).getGeometry()!;
        // @ts-ignore
        geometry =
          this.props.searchParams.source === 'archive'
            ? wktFormat.writeGeometry(polygon)
            : geojsonFormat.writeGeometry(polygon);
      }
    }
    return geometry;
  };

  public handleRequestTypeChange = (value: 'archive' | 'shooting') => {
    this.setState({ requestType: value });
  };

  public handleShootingRequest = () => {
    // TODO - validate form
    const formState = this.formRef.current?.state;
    if (formState) {
      const errors = validateFormValues(formState, validationFunctions);

      if (Object.keys(errors).length === 0) {
        this.setState({ isShootingPanelOpen: true });
      } else {
        this.props.pushMessage({
          message: (
            <span>
              <Trans>Проверьте параметры заявки:</Trans>
              {Object.values<any[]>(errors).map((err, index) => (
                <React.Fragment key={index}>
                  <br /> - <Trans id={err[0]} />
                </React.Fragment>
              ))}
            </span>
          ),
          color: 'alert',
          timeout: 4000,
        });
      }
    }
  };

  public handleSubmitRequest = (regionsData: any[]) => {
    if (this.formRef.current) {
      const formState = this.formRef.current.state;
      const requestData: Request = {
        name: `Заявка от ${dateFnsFormat(new Date(), 'dd.MM.yyyy')}`,
        regions: regionsData.map(rr => ({
          // TODO - для PSS/MSS делать бандл (, т.е. добавлять второе СУ)
          ...rr,
          name: 'Район-1',
          beginShooting: dateFnsFormat(formState.acquisitionDateAfter!, 'yyyy-MM-dd'), // formState.acquisitionDateAfter!.toISOString().split('T')[0],
          endShooting: formState.acquisitionDateBefore
            ? dateFnsFormat(formState.acquisitionDateBefore, 'yyyy-MM-dd')
            : null,
          polygon: this.getRegionGeometry()!,
        })),
      };
      this.props.createSimpleRequest(requestData);

      this.setState({ isShootingPanelOpen: false });
    }
  };

  public handleCancelRequest = () => {
    this.setState({ isShootingPanelOpen: false });
  };

  public handleSearchRequest = () => {
    if (this.formRef.current) {
      const formState = this.formRef.current.state;
      const params: SearchMetadataParams = { ...formState, offset: 0, limit: 100 };
      const geometry = this.getRegionGeometry();
      if (geometry !== undefined) {
        // @ts-ignore
        params[`geometry`] = geometry;
      }
      this.props.searchImagesRequest(params);
    }
  };

  public handleClearSearchParams = () => {
    // TODO: - очистка дерека КА (сделано), сброс геометрии района интереса
    this.props.clearSearchParams();
  };

  public render() {
    const { requestType, isShootingPanelOpen } = this.state;
    const { isFetching, isFreeUser, searchParams } = this.props;

    // const formState = this.formRef.current?.state;
    const instrs = this.formRef.current?.state.instrumentIdentifiers; // searchParams.instrumentIdentifiers || [];

    return (
      <>
        {requestType === 'shooting' && isShootingPanelOpen && instrs && (
          <RequestProductPanel
            orderInstruments={instrs}
            onSubmit={this.handleSubmitRequest}
            onCancel={this.handleCancelRequest}
            // TODO не забыть прокинуть тут значение цены
            // price={'9000'}
            // imageURL={selectedSearchResultItem.previews.map(itemPreview => itemPreview.fileName)}
          />
        )}

        {isShootingPanelOpen && <div className={styles.searchPanelMask} />}

        <div className={styles.scrollable}>
          <CustomScrollBarContainer
            // heightMax={'calc(100vh - 136px)'}
            heightMax={'calc(100vh - 164px)'}
            thumbWidth={'5px'}
            color={'rgb(90, 184, 241)'}
          >
            <SearchForm
              ref={this.formRef}
              // TODO - может вынести выбор района сюда?
              // !!! скорее наоборот вынести выбор района в RegionSelectionFieldset.tsx
              // onRegionSelectionChange={this.handleRegionSelectionChange}
              // drawControlActions={this.props.drawControl}
              isFreeUser={isFreeUser}
              isGeofencedUser={this.props.isGeofencedUser}
              isDrawControlShow={this.props.isDrawControlShow}
              drawControlShow={this.props.drawControlShow}
              drawControlHide={this.props.drawControlHide}
              searchParams={searchParams} // init params
              onSourceChange={this.handleRequestTypeChange}
              // onChange={this.handleFormChange}
            />
          </CustomScrollBarContainer>
        </div>

        <div className={styles.div_search_option__search}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button color={ButtonColor.WHITE} width="154px" onClick={this.handleClearSearchParams}>
              <Trans>Сбросить параметры</Trans>
            </Button>
            {isFetching ? (
              <div style={{ padding: '4px 44px 0 0' }}>
                <Loader />
              </div>
            ) : requestType === 'archive' ? (
              <Button width="133px" onClick={this.handleSearchRequest}>
                <Trans>Найти снимки</Trans>
              </Button>
            ) : (
              // Move to отдельный компонент
              <Button width="133px" onClick={this.handleShootingRequest}>
                <Trans>Заказать съёмку</Trans>
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormPanel);
