import { YANDEX_METRICA_TRACK_NUMBER } from 'api/realAPI';
import React, { PureComponent } from 'react';
import ymOrigin, { YMInitializer as YMInitializerOrigin } from 'react-yandex-metrika';

const ymTrackNumber = YANDEX_METRICA_TRACK_NUMBER || '';

export class YMInitializerWrapped extends PureComponent {
  public render() {
    return (
      <React.Fragment>
        {ymTrackNumber.length > 0 && (
          <YMInitializerOrigin
            accounts={[Number(ymTrackNumber)]}
            options={{
              webvisor: true,
              accurateTrackBounce: true,
              clickmap: true,
              trackLinks: true,
            }}
            version="2"
          />
        )}
      </React.Fragment>
    );
  }
}

export const ym = (methodName: string, ...args: any[]) => {
  if (ymTrackNumber.length > 0) {
    ymOrigin(methodName, ...args);
  }
};
