import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { useState } from 'react';

import BurgerMenuIcon from '@geobank/assets/src/icons/BurgerMenuIcon';
import ExpandIcon from '@geobank/assets/src/icons/ExpandIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import ModalBox from '@geobank/components/src/common/ModalBox/ModalBox';

import { getModalContent, ModalType } from './modalContents';

import GuidesMenu from './GuidesMenu';

import styles from './MainMenu.module.scss';

interface MainMenuProps {
  className?: string;
}

const MainMenu: React.FC<MainMenuProps> = props => {
  // const handleExternalLink = (url: string) => { window.open() };
  const [modal, setModal] = useState<ModalType>(null);
  const handleModalClose = () => setModal(null);
  const [guidesMenuOpen, setGuidesMenuOpen] = useState<boolean>(false);
  // const [imagesMenuOpen, setImagesMenuOpen] = useState<boolean>(false);

  return (
    <div className={props.className}>
      <Dropdown dropup={false} pullRight={true} className={styles.dropdown}>
        <Dropdown.Toggle>
          <div /* style={{ padding: '5px 5px' }} */>
            <BurgerMenuIcon />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.MenuHeader>Подложки</Dropdown.MenuHeader> */}
          <Dropdown.MenuItem onClick={() => setModal('about')}>
            {/* О проекте */}
            <span className={styles.item}>
              <Trans>О портале</Trans>
            </span>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => setModal('documents')}>
            <span className={styles.item}>
              <Trans>Документы</Trans>
            </span>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => setGuidesMenuOpen(!guidesMenuOpen)}>
            <div className={styles.expand_item}>
              <span className={styles.item}>
                <Trans>Руководства</Trans>
              </span>
              <span style={guidesMenuOpen ? { transform: 'rotate(180deg)' } : {}}>
                <ExpandIcon />
              </span>
            </div>
            <GuidesMenu isOpen={guidesMenuOpen} />
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => setModal('contacts')}>
            <span className={styles.item}>
              <Trans>Контакты</Trans>
            </span>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => setModal('payment')}>
            <span className={styles.item}>
              <Trans>Способы оплаты</Trans>
            </span>
          </Dropdown.MenuItem>
          {/* <Dropdown.MenuItem>Помощь</Dropdown.MenuItem>
          <Dropdown.MenuItem>Новости</Dropdown.MenuItem> */}
          <Dropdown.MenuDivider />
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://gptl.ru/"
              target="_blank"
              rel="noopener noreferrer"
              title="Продукты Российских космических систем ДЗЗ"
            >
              <Trans id="menu.old_geoportal_title">Геопортал Роскосмоса</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            {/* TODO - добавить иконки на внешние ссылки */}
            <a
              className={classNames(styles.item, styles.link)}
              href="https://pod.gptl.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Открытые данные</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuDivider />
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://dgearth.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="menu.dgearth_title">Цифровая земля</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://bbp.ntsomz.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Базовые продукты</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
          /* onChange={handleExternalLink.bind(null, 'https://google.com')} */
          >
            {/* Тематическая обработка */}
            <a
              className={classNames(styles.item, styles.link)}
              href="http://demo.geotron.ntsomz.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Тематическая обработка</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="http://arctic.ntsomz.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="menu.arctic-m_title">О КА Арктика-М</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="http://electro.ntsomz.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="menu.electro-l_title">О КА Электро-Л</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://disk.yandex.ru/d/cJgUg2St12r7zA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="menu.zorkiy_title">данные ДЗЗ с КА Зоркий</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <span className={styles.item} onClick={() => setModal('samples')}>
              <Trans>Образцы продуктов на основе данных КА Канопус-В и Ресурс-П</Trans>
            </span>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://portal.fppd.cgkipd.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Федеральный фонд пространственных данных</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="http://meteo.ru/egfd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Единый государственный фонд данных о состоянии окружающей среды</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://earthexplorer.usgs.gov/"
              target="_blank"
              rel="noopener noreferrer"
              title="Данные зарубежных КА"
            >
              <Trans id="menu.earthexplorer">Данные зарубежных КА</Trans>
            </a>
          </Dropdown.MenuItem>
          <Dropdown.MenuDivider />
          <Dropdown.MenuItem>
            <a
              className={classNames(styles.item, styles.link)}
              href="https://geonovosti.terratech.ru/"
              target="_blank"
              rel="noopener noreferrer"
              title="Геопространственная аналитика"
            >
              <Trans id="menu.geospatial_analitics">Геопространственная аналитика</Trans>
            </a>
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
      {modal !== null && (
        <ModalBox isNeedCrossButton={true} onClickCrossButton={handleModalClose}>
          <div className={styles.modalContent}>{getModalContent(modal)}</div>
        </ModalBox>
      )}
    </div>
  );
};

export default MainMenu;
