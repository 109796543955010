import React from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

// import IconButton from '@geobank/components/src/common/IconButton/IconButton';
// import FolderStarIcon from '@geobank/assets/src/icons/FolderStarIcon';
// import CartIcon from '@geobank/assets/src/icons/CartIcon';
// import PersonIcon from '@geobank/assets/src/icons/PersonIcon';

// import { connect } from 'react-redux';
// import * as store from 'ducks';
// import {
//   fetchCurrentCart,
//   updateCurrentCartImages,
//   createCartRequest,
//   getCurrentCartImages /* , getSimpleRouteCartCount */,
//   getCurrentCartPrice,
//   CartImage,
// } from 'ducks/order';

import ProfileButton from './ProfileButton/ProfileButton';
import FavoritesButton from 'components/controls/FavoritesButton/FavoritesButton';
import CartButton from './CartButton/CartButton';

const styles = require('./UserMenu-styles.module.scss');

interface UserMenuProps {
  className?: string;
  // fetchCurrentCartRequest: typeof fetchCurrentCart.request;
  // updateCurrentCartImagesRequest: typeof updateCurrentCartImages.request;
  // createCartRequestRequest: typeof createCartRequest.request;
  // cartItems: CartImage[];
  // // cartCount: any;
  // totalPrice: number;
}

const UserMenu: React.FC<UserMenuProps> = props => {
  // const {
  //   cartItems,
  //   totalPrice,
  //   fetchCurrentCartRequest,
  //   updateCurrentCartImagesRequest,
  //   createCartRequestRequest,
  // } = props;
  const location = useLocation();

  const isAccountLocation = location.pathname.startsWith('/account');

  return (
    <div className={classNames(props.className, styles.root)}>
      {!isAccountLocation && (
        <>
          <div className={styles.item}>
            {/* <IconButton backgroundColor="white">
          <FolderStarIcon />
        </IconButton> */}
            <FavoritesButton />
          </div>
          <div className={styles.item}>
            <CartButton />
          </div>
        </>
      )}
      <div className={styles.item}>
        {/* <IconButton backgroundColor="white">
          <PersonIcon />
        </IconButton> */}
        <ProfileButton />
      </div>
    </div>
  );
};

export default UserMenu;
// export default connect(
//   (state: store.RootState) => ({
//     cartItems: getCurrentCartImages(state),
//     // cartCount: getSimpleRouteCartCount(state),
//     totalPrice: getCurrentCartPrice(state),
//   }),
//   {
//     fetchCurrentCartRequest: fetchCurrentCart.request,
//     updateCurrentCartImagesRequest: updateCurrentCartImages.request,
//     createCartRequestRequest: createCartRequest.request,
//   }
// )(UserMenu);
