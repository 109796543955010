import { Trans } from '@lingui/macro'; // t
import React, { useCallback } from 'react';
// import { I18n } from '@lingui/react';

import { formatDate } from 'components/utils/date';
import { formatValue } from 'components/utils/format';
import { ImageMetadata } from 'ducks/types/metadataTypes';
import { instruments, platforms, platformTypes } from 'locales/dicts/classifiers';
import { renderResolution } from './helpers';

import styles from './ResultPreview.module.scss';

interface ResultPreviewProps {
  metadata?: ImageMetadata;
  actions?: {
    // handleAccordionToggle: (mdId: number, flag: boolean) => void;
    handleAccordionToggle: (md: ImageMetadata, flag: boolean) => void;
  };
}

const ResultPreview: React.FC<ResultPreviewProps> = props => {
  const { metadata: md, actions } = props;

  // const handleAccordionToggle = useCallback(
  //   (mdId: number, flag: boolean) => {
  //     actions?.handleAccordionToggle(mdId, flag);
  //   },
  //   [actions]
  // );

  const handleAccordionToggle = useCallback(
    (mdData: ImageMetadata, flag: boolean) => {
      actions?.handleAccordionToggle(mdData, flag);
    },
    [actions]
  );

  if (md === undefined) {
    return null;
  }

  // ${platformsById[item.platformId].platformTypeIdentifier} -> get(platformsById, [item.platformId, 'platformTypeIdentifier'], '')
  // ${item.sensors[0].instrument} -> get(item, ['sensors', 0, 'instrument'], '')
  /* backup text:
  {`${formatValue(md.cloudiness, '%')}; ${formatValue(md.resolution, 'м')}; ${
    md.platformName
  } (${md.platformTypeName}); ${md.instrumentName}; ${formatDate(
    md.acquisitionDateBegin || md.acquisitionDateInstant
  )}`}. */
  // TODO - обёртка специально для "м" (метры) не нравится!
  return (
    // preview container
    <div className={styles.previewData}>
      {(md.cloudiness || md.cloudiness === 0) && <span>{formatValue(md.cloudiness, '%')}; </span>}
      {(md.resolution || md.resolution === 0) && <span>{renderResolution(md.resolution)}; </span>}
      <Trans id={platforms[md.platformIdentifier]} /> (
      <Trans id={platformTypes[md.platformTypeIdentifier]} />
      ); <Trans id={instruments[md.instrumentIdentifier]} />;{' '}
      {formatDate(md.acquisitionDateBegin || md.acquisitionDateInstant)}.{' '}
      <span className={styles.linkButton} onClick={() => handleAccordionToggle(md, true)}>
        <Trans>Все метаданные</Trans>
      </span>
    </div>
  );
};

export default ResultPreview;
