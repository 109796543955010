import { RootState } from 'ducks';
import React, { PureComponent } from 'react';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import PolygonIcon from '@geobank/assets/src/icons/PolygonIcon';
import SquareIcon from '@geobank/assets/src/icons/SquareIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import { t } from '@lingui/macro';
import { i18nMark, withI18n, withI18nProps } from '@lingui/react';
import * as turf from '@turf/turf';
import {
  balloonToggle,
  clearShapeGeometry,
  drawControl,
  getGeofenceGeometry,
  getIsDrawControlShow,
  getRegionsOfInterest,
} from 'ducks/map';
import { notifyActions } from 'ducks/message';
// import { resetRegionParams, resetSubscriptionParams } from 'ducks/subscribe';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import { genId } from 'components/utils/identifier';
import { isValidGeofenceIntersection } from 'components/utils/validation/checkIntersections';
import VectorLayer from 'libs/ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Type } from 'ol/geom/Geometry';
import Draw, { createBox, createRegularPolygon, GeometryFunction } from 'ol/interaction/Draw.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Map as GeoportalMap } from '../Map';
import { selectStyle } from '../Map/featureStyles';
import { formatArea } from '../Map/utils';

import styles from './DrawControl.module.scss';

const tooltipProps = {
  direction: 'right',
  hoverDelay: 800,
  padding: '8px',
  background: 'white',
  color: 'black',
};

export interface IFeatureType {
  polygonType: Type;
  geometryFunction?: GeometryFunction;
}

interface IPolygonTypeFunctionMApping {
  [key: string]: IFeatureType;
}

export const polygonTypeFunctionMapping: IPolygonTypeFunctionMApping = {
  Line: { polygonType: 'LineString' as Type, geometryFunction: undefined },
  Polygon: { polygonType: 'Polygon' as Type, geometryFunction: undefined },
  Point: { polygonType: 'Point' as Type, geometryFunction: undefined },
  Box: { polygonType: 'Circle' as Type, geometryFunction: createBox() },
  Square: { polygonType: 'Circle' as Type, geometryFunction: createRegularPolygon(4) },
};

interface DrawControlProps {
  regionOfInterest: any[];
  geofence: any[];
  clearShapeGeometry: typeof clearShapeGeometry;
  drawControlSetGeometry: typeof drawControl.setGeometry;
  balloonToggle: typeof balloonToggle;
  // clearRegionParams: typeof resetRegionParams;
  // clearSubscriptionParams: typeof resetSubscriptionParams;
  pushMessage: typeof notifyActions.push;
}

interface DrawControlState {
  isDrawActive: boolean;
  clickCounter: number;
  isValidRoi: boolean;
}

class DrawControl extends PureComponent<DrawControlProps & withI18nProps, DrawControlState> {
  public state = {
    isDrawActive: false,
    clickCounter: 0,
    isValidRoi: true,
  };
  private source?: VectorSource;
  private vector?: VectorLayer;
  private draw?: Draw;

  private getPolygonType = (polygonType: string) => {
    return polygonTypeFunctionMapping[polygonType];
  };

  public handleDrawClick = (value: string) => {
    this.setState({
      isDrawActive: true,
      clickCounter: this.state.clickCounter + 1,
      isValidRoi: true,
    });
    const { polygonType, geometryFunction }: IFeatureType = this.getPolygonType(value);

    const map = GeoportalMap.getInstance();
    // const geofenceLayer = map.getUserLayerByName('geofence');

    this.handleClear();

    if (polygonType) {
      this.draw = new Draw({
        // condition: geofenceLayer
        //   ? e => {
        //       const features = map.getOlMap().getFeaturesAtPixel(e.pixel, {
        //         layerFilter: layer => layer === geofenceLayer,
        //       });
        //       if (!(features && features.length > 0)) {
        //         this.props.pushMessage({
        //           timeout: 5000,
        //           message: i18nMark(
        //             'Район интереса должен находиться в пределах ограничительного района, заданного Оператором системы!'
        //           ),
        //           place: 'bc',
        //           color: 'alert',
        //         });
        //       }
        //       return features && features.length > 0;
        //     }
        //   : e => {
        //       return true;
        //     },
        // source: this.source,
        type: polygonType,
        geometryFunction: geometryFunction,
        stopClick: true,
      });
      this.draw.setProperties({ type: polygonType });
      this.draw.on('drawend', event => {
        const feature = event.feature;
        feature.setId(genId()); // TODO - feature.setId(1001); // 'drawGeometry'
        feature.set('_type', 'region');
        const { output, measure } = formatArea(feature.getGeometry());
        feature.set('area', output);
        feature.set('measure', measure);
        feature.set('name', `Region-${feature.getId()}`);

        setTimeout(() => {
          map.getOlMap().removeInteraction(this.draw!);
          let geometryIntersection: boolean = true;
          const format = new GeoJSON();
          const geoJSON = format.writeFeatureObject(feature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          if (this.props.geofence.length > 0) {
            geometryIntersection = isValidGeofenceIntersection(
              geoJSON.geometry.type === 'Polygon' ? geoJSON.geometry.coordinates : [],
              this.props.geofence[0].geometry.coordinates
            );
          }
          if (geometryIntersection) {
            this.props.drawControlSetGeometry(geoJSON);
            this.props.balloonToggle(true);
            this.source?.addFeature(feature);
          } else {
            const imagePolygonGeoJSON = new GeoJSON().writeFeature(feature, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            });
            const croppingPolygon = turf.getGeom(this.props.geofence[0]);
            const imageGeometry = turf.getGeom(JSON.parse(imagePolygonGeoJSON));
            const intersection = turf.intersect(imageGeometry, croppingPolygon);
            if (intersection) {
              if (intersection.geometry.type === 'MultiPolygon') {
                this.props.drawControlSetGeometry(this.props.geofence[0]);
                this.props.pushMessage({
                  timeout: 5000,
                  message: i18nMark(
                    'В качестве района интереса поддерживаются геометрии только типа "Polygon"!'
                  ),
                  place: 'bc',
                  color: 'alert',
                });
              } else {
                this.props.drawControlSetGeometry(intersection);
                const intersectionFeature = new GeoJSON().readFeature(intersection, {
                  dataProjection: 'EPSG:4326',
                  featureProjection: 'EPSG:3857',
                });
                this.source?.addFeature(intersectionFeature);
              }
            } else {
              this.setState({ ...this.state, isValidRoi: false });
              this.props.drawControlSetGeometry(this.props.geofence[0]);
              this.props.pushMessage({
                timeout: 5000,
                message: i18nMark(
                  'Район интереса выходит за пределы ограничительного района, заданного Оператором системы!'
                ),
                place: 'bc',
                color: 'alert',
              });
            }
          }
        }, 100);

        this.setState({ ...this.state, isDrawActive: false });
      });
      map.getOlMap().addInteraction(this.draw);
    }
  };

  public handleClear = () => {
    const map = GeoportalMap.getInstance();
    this.draw && this.draw.finishDrawing();
    this.draw?.setProperties({ type: '' });

    if (this.draw && this.draw.getActive()) {
      map.getOlMap().removeInteraction(this.draw);
    }
    if (this.props.geofence.length > 0) {
      this.props.drawControlSetGeometry(this.props.geofence[0]);
    }
    this.props.balloonToggle(false);
    this.props.clearShapeGeometry();
    // this.props.clearRegionParams();
    // this.props.clearSubscriptionParams();
  };

  public componentDidMount() {
    this.source = new VectorSource({ wrapX: false });

    const map = GeoportalMap.getInstance();
    this.vector = map.ensureCurrentRegionLayer();
    this.vector.setStyle(selectStyle);
    if (!this.vector.getSource()) {
      this.vector.setSource(this.source);
    }
    // this.handleDrawClick('Box');
  }

  public componentDidUpdate() {
    if (this.props.regionOfInterest.length === 0) {
      this.vector?.setSource(this.source!);
    }
    if (this.props.regionOfInterest.length === 0 && this.props.geofence.length > 0) {
      this.props.drawControlSetGeometry(this.props.geofence[0]);
    }
    const map = GeoportalMap.getInstance().getOlMap();
    if (!this.state.isValidRoi) {
      this.draw?.abortDrawing();
      map.removeInteraction(this.draw!);
    }
  }

  public componentWillUnmount() {
    this.handleClear();
  }

  public render() {
    // const { isDrawActive } = this.state;
    const { i18n } = this.props;
    // disabled={this.state.isButtonDisabled}

    // TODO - show/hide vector layer
    // if (!isShow) {
    //   // this.vector && this.vector.setVisible(false);
    //   return null;
    // }
    // this.vector && this.vector.setVisible(true);

    return (
      <div className={styles.root}>
        {/* TODO - disable actions on isDrawActive */}
        <Tooltip
          {...tooltipProps}
          content={i18n._(t('map_draw.rectangle')`Нарисовать прямоугольник`)}
        >
          <Button
            className={styles.actionButton}
            color={
              this.draw?.get('type') === 'Circle' ? ButtonColor.LIGHT : ButtonColor.TRANSPARENT
            }
            onClick={this.handleDrawClick.bind(this, 'Box')}
          >
            <SquareIcon />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} content={i18n._(t('map_draw.polygon')`Нарисовать полигон`)}>
          <Button
            className={styles.actionButton}
            color={
              this.draw?.get('type') === 'Polygon' ? ButtonColor.LIGHT : ButtonColor.TRANSPARENT
            }
            onClick={this.handleDrawClick.bind(this, 'Polygon')}
          >
            <PolygonIcon />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} content={i18n._(t('map_draw.clear')`Очистить результат`)}>
          <Button
            className={styles.actionButton}
            color={ButtonColor.TRANSPARENT}
            onClick={this.handleClear}
          >
            <CrossIcon fill="grey" />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default withI18n()(
  connect(
    (state: RootState) => ({
      isShow: getIsDrawControlShow(state),
      regionOfInterest: getRegionsOfInterest(state),
      geofence: getGeofenceGeometry(state),
    }),
    {
      drawControlSetGeometry: drawControl.setGeometry,
      clearShapeGeometry,
      balloonToggle,
      // clearRegionParams: resetRegionParams,
      // clearSubscriptionParams: resetSubscriptionParams,
      pushMessage: notifyActions.push,
    }
  )(DrawControl)
);
