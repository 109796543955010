import * as React from 'react';
import * as _ from 'lodash';
import { IRequestInfo } from '../../MyRequests';
import styles from './RequestInfoPanel.css';

interface RequestInfoPanelProps {
  requestInfo: IRequestInfo;
}

export default class RequestInfoPanel extends React.Component<RequestInfoPanelProps> {
  private readonly getRequestInfoRow = (label: string, value: string) => {
    return (
      <div className={styles.request_info_panel__content}>
        <div>
          <span className={styles.my_request_span}>{label}</span>
        </div>
        <div>
          <span>{value}</span>
        </div>
      </div>
    );
  };

  public render() {
    return (
      <div className={styles.request_info_panel__container}>
        <div>
          <span className={styles.request_info_panel__header}>{`Параметры заявки "${_.get(
            this.props,
            'requestInfo.label'
          )}`}</span>
        </div>
        {this.getRequestInfoRow('Идентификатор заявки:', _.get(this.props, 'requestInfo.id'))}
        {this.getRequestInfoRow('Статус заявки:', _.get(this.props, 'requestInfo.status'))}
        {this.getRequestInfoRow('Дата создания:', _.get(this.props, 'requestInfo.dateStart'))}
        {this.getRequestInfoRow('Дата завершения:', _.get(this.props, 'requestInfo.dateEnd'))}
        {this.getRequestInfoRow('Акт выдачи:', _.get(this.props, 'requestInfo.actExtradition'))}
        {this.getRequestInfoRow(
          'Комментарий (необязательно):',
          _.get(this.props, 'requestInfo.comment')
        )}
      </div>
    );
  }
}
