import React from 'react';
import TextInput from '@geobank/components/src/forms/TextInput/TextInput';
import InputPassword from '@geobank/components/src/forms/PasswordInput/PasswordInput';
import ButtonWithUnderlay from '@geobank/components/src/common/ButtonWithUnderlay/ButtonWithUnderlay';
import styles from './EditPrivateData.css';

interface EditPrivateDataProps {
  values?: any;
}

interface EditPrivateDataState {
  nameValue: string;
  secondNameValue: string;
  emailValue: string;
  countryValue: string;
  organizationName: string;
}

export default class EditPrivateData extends React.Component<
  EditPrivateDataProps,
  EditPrivateDataState
> {
  public render() {
    return (
      <div style={{ width: '383px', marginTop: '20px', pointerEvents: 'none' }}>
        <div className={styles.edit_private_data__container}>
          <div>
            <span className={styles.edit_private_data__header}>Редактирование личных данных</span>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Название организации</span>
            </div>
            <div>
              <TextInput width={'343px'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Имя</span>
            </div>
            <div>
              <TextInput width={'343px'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Фамилия</span>
            </div>
            <div>
              <TextInput width={'343px'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Страна</span>
            </div>
            <div>
              <TextInput width={'343px'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>E-mail</span>
            </div>
            <div>
              <TextInput width={'343px'} />
            </div>
          </div>
        </div>
        <div className={styles.edit_private_data__password_container}>
          <div>
            <span className={styles.edit_private_data__header}>Изменение пароля</span>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Старый пароль</span>
            </div>
            <div>
              <InputPassword width={'343px'} placeholder={'Введите пароль'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Новый пароль</span>
            </div>
            <div>
              <InputPassword width={'343px'} placeholder={'Придумайте пароль'} />
            </div>
          </div>
          <div className={styles.edit_private_data__text_input__container}>
            <div className={styles.edit_private_data_span_label__container}>
              <span className={styles.edit_private_data__span_label}>Подтвердите пароль</span>
            </div>
            <div>
              <InputPassword width={'343px'} placeholder={'Введите пароль'} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <ButtonWithUnderlay label={'Сохранить изменения'} width={'100%'} />
        </div>
      </div>
    );
  }
}
