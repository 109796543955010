import React from 'react';
import { withI18nProps, withI18n } from '@lingui/react';
import { t } from '@lingui/macro'; // Trans


import { bandNames } from 'locales/dicts/classifiers';
import CheckBoxGroup, {
  CheckBoxesProp,
} from '@geobank/components/src/forms/CheckBoxGroup/CheckBoxGroup';
// import { InstrumentSensor } from 'ducks/types/classifierTypes';
import { Band } from 'ducks/types/metadataTypes';

export interface SimpleSensorWithBands {
  bands: Band[] | [{ min: number; max: number; description: string }];
}

const styles = require('./SpectralChannelsField-styles.module.scss');

interface SpectralChannelsFieldProps {
  sensors: SimpleSensorWithBands[]; // InstrumentSensor[];
  checked?: string[];
  onChange?: (values: string[]) => void;
}

class SpectralChannelsField extends React.PureComponent<
  SpectralChannelsFieldProps & withI18nProps
> {
  handleChange = (checkBoxes: CheckBoxesProp[]) => {
    const { onChange } = this.props;

    // console.log(checkBoxes);
    const resulutionsChecked: string[] = [];
    checkBoxes.forEach(item => {
      if (item.flag === true) {
        resulutionsChecked.push(item.name);
      }
    });
    // console.log(resulutionsChecked); // checkBoxes, resulutionsChecked
    if (onChange !== undefined) {
      onChange(resulutionsChecked);
    }
  };

  getBandColor = (text: string) => {
    let color = '#b3b3b3';
    switch (text) {
      case 'Панхром':
        color = '#b3b3b3';
        break;
      case 'Красный':
        color = 'red';
        break;
      case 'Зелёный':
        color = '#2bc12b'; // green
        break;
      case 'Синий':
        color = '#6ac3fa'; // blue
        break;
      case 'Дальний ИК':
      case 'Средний ИК':
      case 'Ближний ИК':
        color = '#fbcbcb';
        break;
    }
    return color;
  };

  render() {
    const { sensors, checked, i18n } = this.props;
    // const checked: any[] = [];

    const list: CheckBoxesProp[] = [];
    sensors.forEach(sensor => {
      // @ts-ignore
      if (sensor.bandsNames !== undefined) {
        // @ts-ignore
        // bbp
        sensor.bandsNames.forEach(bandName => {
          list.push({
            name: `${bandName}`,
            label: (
              <span>
                <span
                  className={styles.bandColoredBox}
                  // style={{ backgroundColor: this.getBandColor(band.description) }}
                />
                {bandName}
              </span>
            ),
            flag: checked?.indexOf(`${bandName}`) !== -1,
          });
        });
      } else {
        // etris
        sensor.bands.forEach(band => {
          list.push({
            // name: String(band.id),
            name: `${band.min}_${band.max}`,
            // цветные <span> шарики; определять или по значениям или по тексту description
            label: (
              <span>
                <span
                  className={styles.bandColoredBox}
                  style={{ backgroundColor: this.getBandColor(band.description) }}
                />
                {band.min} - {band.max} {i18n._(t`мкм`)}{' '}
                {band.description ? `(${i18n._(bandNames[band.description])})` : ''}
              </span>
            ),
            // flag: false,
            // flag: checked?.indexOf(String(band.id)) !== -1,
            flag: checked?.indexOf(`${band.min}_${band.max}`) !== -1,
          });
        });
      }
    });

    return (
      <div>
        <CheckBoxGroup
          colorLabel="black"
          checkBoxList={[
            // { name: 'low', label: i18n._(t`Низкое`), flag: checked?.indexOf('low') !== -1 },
            ...list,
          ]}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

// export default SpectralChannelsField;
export default withI18n()(SpectralChannelsField);
