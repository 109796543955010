import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withI18n, withI18nProps } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import Tooltip from 'react-tooltip-lite';

import SearchInput from '@geobank/components/src/forms/SearchInput/SearchInput';
import PlusIcon from '@geobank/assets/src/icons/PlusIcon';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import ChipTag from 'components/order/ChipTag/ChipTag';
import { getTranslatedTag } from 'components/order/ChipTag/helper';
import { getUserOrderTags, fetchUserOrderTags } from 'ducks/order';
import { tagsTranslate } from 'locales/dicts/tags';

import commonStyles from '../productParameters.module.scss';

const styles = require('./TagsField-styles.module.scss');

interface TagsFieldProps {
  // name: string; // productCode
  // value: string;
  // options: ProductOptionType[];
  // onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  tags: string[];
  onChange: (tags: string[]) => void; // onSelect: (value: string) => void;
}

const TagsField: React.FC<TagsFieldProps & withI18nProps> = props => {
  const { tags, onChange, i18n } = props;

  const userOrderTags = useSelector(getUserOrderTags);
  const dispatch = useDispatch();

  const handleAddTag = (value: string) => {
    // const { tags } = this.props.params;
    // console.log('add tag:', value);

    if (tags.length === 5) {
      alert('Лимит на количество тегов достигнут!');
      return;
    }

    const tagIndex = tags.indexOf(value);
    if (tagIndex === -1) {
      const newTags = [...tags, value];
      onChange(newTags);
    }
  };

  const handleRemoveTag = (value: string) => {
    // const { tags } = this.props.params;
    // console.log('add tag:', value);

    const newTags = [...tags];
    const tagIndex = tags.indexOf(value);
    if (tagIndex > -1) {
      newTags.splice(tagIndex, 1);
      onChange(newTags);
    }
  };

  useEffect(() => {
    // if (cartRequests.length === 0) {
    dispatch(fetchUserOrderTags.request());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTags = Object.keys(tagsTranslate);
  const results = userOrderTags
    .filter(tag => filterTags.indexOf(tag.name) === -1)
    .map(tag => ({ id: tag.name, title: getTranslatedTag(tag.name) }));

  return (
    <React.Fragment>
      <div className={commonStyles.paramTitle}>
        <span>
          <Trans>Теги</Trans>
          <Tooltip
            tagName="span"
            styles={{ marginLeft: '6px' }}
            tipContentClassName="tip-content--border-grey tip-content--infoproduct"
            arrow={false}
            background="white"
            color="black"
            direction="bottom"
            content={
              <Trans id="order_image.hint_tags">
                Для удобства фильтрации заявок, заказу можно присвоить один или несколько тегов
                (ключевых слов). Для этого введите короткие теги в поле ниже, подтверждая каждый
                нажатием на "Enter". Если тег использовался раньше, его можно выбрать из выпадающего
                списка.
              </Trans>
            }
          >
            <HelpIcon />
          </Tooltip>
        </span>
      </div>
      <div className={commonStyles.paramField}>
        {/* <TagsField onSelect={this.handleAddTag} /> */}
        <SearchInput
          classes={{
            root: styles.searchRoot,
            input: styles.searchInput,
          }}
          placeholder={i18n._(t`Введите название тега`)}
          isLoading={false}
          clearOnSelect={true}
          clearOnSubmit={true}
          resultsHeightMax="200px"
          filterOptions={(options, state) =>
            options.filter(opt => opt.id.toLowerCase().indexOf(state.value.toLowerCase()) !== -1)
          }
          results={results}
          // onSearchChange={val => handleAddTag(val)}
          onSubmit={val => handleAddTag(val)}
          onResultSelect={item => handleAddTag(item.id)}
          // onClear={props.searchObjectsClear}
          icon={<PlusIcon />}
        />
        <div style={{ marginTop: '4px' }}>
          {tags.map(tagName => (
            <ChipTag
              key={tagName}
              className={styles.chipTag}
              value={tagName}
              title={tagName.length > 15 ? tagName : undefined}
              onDelete={val => val && handleRemoveTag(val)}
            >
              {tagName}
            </ChipTag>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(withI18n()(TagsField));
