import React, { PureComponent } from 'react';
// import { css } from 'astroturf';
// import get from 'lodash/get';
import { connect } from 'react-redux';
import { RootState } from 'ducks';
// import classNames from 'classnames';
// import { findDOMNode } from 'react-dom';
import { Trans } from '@lingui/macro';
import { i18nMark } from '@lingui/react';

// import { Metadata } from 'ducks/metadata';
// import Accordion from '@geobank/components/src/common/Accordion/Accordion';
import SearchParamsCompact from './SearchParamsCompact';
// import HorizontalLine from '@geobank/components/src/common/HorizontalLine/HorizontalLine';
// import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
// import IconButton from '@geobank/components/src/common/IconButton/IconButton';
// import CartIcon from '@geobank/assets/src/icons/CartIcon';
// import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
// import FileDownloadIcon from '@geobank/assets/src/icons/FileDownloadIcon';
// import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
// import PictureIcon from '@geobank/assets/src/icons/PictureIcon';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import ExpandIcon from '@geobank/assets/src/icons/ExpandIcon';
// import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';

import {
  searchImages,
  // ImageMetadata as Metadata,
  selectMetadataRoute,
  removeMetadataRoute,
  // toggleMetadataRouteContour,
  toggleImageContour,
  // toggleMetadataRoutePreview,
  toggleImagePreview,
  // toggleViewFullImage,
  toggleCheckImage,
  getSelectedMetadataRoute,
  getActiveImageContours,
  getActiveImagePreviews,
  getActiveViewFullImages,
  getActiveViewFullImages2B,
  getCheckedImages,
  ImageMetadata,
  getTotalImages,
  toggleImageFullRes,
  toggleImageFullRes2B,

} from 'ducks/metadata';
import { getUser } from 'ducks/authIAM';
import { updateCurrentCartImages, getCurrentCartImages, CartImage } from 'ducks/order';
import { createTask } from 'ducks/metadataDownload';
// import { getPlatformsById } from 'ducks/classifier';
import { ImageMetadataDetail, SearchMetadataParams } from 'ducks/types/metadataTypes';
import { notifyActions } from 'ducks/message';
// import { catFileIdentifier } from 'components/utils/format';
import { copyTextToClipboard } from 'utils/clipboard';
import { isEmptyValue } from 'utils/values';

// import ResultPreview from './ResultItem/ResultPreview';
// import ResultDetail from './ResultItem/ResultDetail';
import SearchResultItem from './SearchResultItem/SearchResultItem';
import SearchResultHeaderActions from './SearchResultHeaderActions';
import { CHECKED, UNCHECKED, INDETERMINATE } from './SearchResultItem/CheckBox/Checkbox';

import styles from './SearchResult.module.scss';

// const getInstrumentFromSensors = () => {
//   return 'TODO - СУ';
// };

// tslint:disable-next-line:no-empty-interface
interface SearchResultProps {
  searchParams: SearchMetadataParams;
  results: ImageMetadataDetail[]; // Metadata[];
  onBack: () => void;
  onAddToCartImage: (imageId: ImageMetadata) => void;

  selectedMetadataRoute: string | null;
  selectMetadataRoute: typeof selectMetadataRoute;
  removeMetadataRoute: typeof removeMetadataRoute;
  // toggleMetadataRouteContour: typeof toggleMetadataRouteContour;
  toggleImageContour: typeof toggleImageContour.request;
  // toggleMetadataRoutePreview: typeof toggleMetadataRoutePreview;
  toggleImagePreview: typeof toggleImagePreview.request;
  // toggleViewFullImage: typeof toggleViewFullImage;
  toggleImageFullRes: typeof toggleImageFullRes.request;
  toggleImageFullRes2B: typeof toggleImageFullRes2B.request;

  toggleCheckImage: typeof toggleCheckImage;
  activeImageContours: string[]; // ids
  activeImagePreviews: string[]; // ids
  activeViewFullImages: string[]; // identifiers
  activeViewFullImages2B: string[],
  checkedImages: string[];

  createTaskRequest: typeof createTask.request;

  // addToCart: typeof pickSimpleRouteCart.add;
  currentCartImages: CartImage[];
  updateCurrentCartImagesRequest: typeof updateCurrentCartImages.request;
  // platformsById: { [id: number]: any };

  pushMessage: typeof notifyActions.push;

  searchMetadataRequest: typeof searchImages.request;
  totalImages: number;

  user: ReturnType<typeof getUser>;
}

// tslint:disable-next-line:no-empty-interface
interface SearchResultState {
  currentExpandedMetadataId: ImageMetadata['identifier']; // number;
  isResultsHidden: boolean;
}

export class SearchResult extends PureComponent<SearchResultProps, SearchResultState> {
  public state = {
    currentExpandedMetadataId: '',
    isResultsHidden: false,
  };

  private readonly listRef: React.RefObject<CustomScrollBarContainer>; // React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.listRef = React.createRef();
  }

  public getCheckedImages = () => {
    // return this.props.checkedImages;
    const checkedImages: ImageMetadata[] = [];
    // TODO - сохранить сортировку (порядок) снимков
    this.props.results.forEach(md => {
      if (this.props.checkedImages.indexOf(md.identifier) > -1) {
        checkedImages.push(md);
      }
    });
    return checkedImages;
  };

  // public func to call from parent (SearchResultPanel); group action
  // addToCartCheckedImages = () => {
  //   const checkedImages = this.props.checkedImages;
  //   alert('addToCartCheckedImages');
  // };

  // md: ImageMetadata
  public handleAddToCart = (md: ImageMetadata) => {
    // this.props.addToCart(md);
    // const image: CartImage = {
    //   metadataIdentifier: md.identifier,
    //   croppingPolygon: md.geometry,
    //   price: 0,
    // };
    // this.props.updateCurrentCartImagesRequest(image, 'add');
    this.props.onAddToCartImage(md);
  };

  public handleRemoveRouteFromResults = (md: ImageMetadata) => {
    this.props.removeMetadataRoute(md.identifier);
  };

  public handleSelectRoute = (md: ImageMetadata) => {
    // alert('select route');
    // console.log('click list item, id =', item.metadataId);

    this.props.selectMetadataRoute(md.identifier);
    this.props.toggleImagePreview([md.identifier]);
  };

  // TODO - можно передавать списком выделенные снимки (checked)
  // или передавать пустой аргумент (undefined/null) и получать список в сагах по селектору
  public handleToggleContour = (md?: ImageMetadata) => {
    // const params = md !== undefined ? md.id : this.props.checkedImages.map(id => Number(id));
    // this.props.toggleMetadataRouteContour(md.id);
    if (md !== undefined) {
      // this.props.toggleMetadataRouteContour(md.id);
      this.props.toggleImageContour([md.identifier]);
    } else {
      // this.props.checkedImages.forEach(id =>
      //   this.props.toggleMetadataRouteContour(isNaN(Number(id)) ? id : Number(id))
      // ); // Number(id)
      this.props.toggleImageContour(this.props.checkedImages);
    }
  };

  public handleTogglePreview = (md?: ImageMetadata) => {
    // alert('select route');
    // console.log('click list item, id =', item.metadata_id);

    // this.props.toggleMetadataRoutePreview(md.id);
    if (md !== undefined) {
      // this.props.toggleMetadataRoutePreview(md.id);
      // this.handleSelectRoute(md);
      this.props.toggleImagePreview([md.identifier]);
    } else {
      // this.props.checkedImages.forEach(id =>
      //   this.props.toggleMetadataRoutePreview(isNaN(Number(id)) ? id : Number(id))
      // );
      this.props.toggleImagePreview(this.props.checkedImages);
    }
  };

  public handleToggleFullImage = (md?: ImageMetadata, layId?: number) => {
    // this.props.toggleViewFullImage(md.id);
    if (md !== undefined) {
      // if( md.metadataIdentifier){
      //   console.log(md.metadataIdentifier, '204', toggleImageFullRes)
      //   this.props.toggleImageFullRes([md.metadataIdentifier]);
      // }
      if( md.identifier){
        this.props.toggleImageFullRes([md.identifier], layId!);
      }
      // this.props.toggleViewFullImage(md.id);
      // this.props.selectMetadataRoute(md.id);

    } else {
      // this.props.checkedImages.forEach(id =>
      //   this.props.toggleViewFullImage(isNaN(Number(id)) ? id : Number(id))
      // );
      this.props.toggleImageFullRes(this.props.checkedImages, layId!);
    }
  };

  public handleToggleFullImage2B = (md?: ImageMetadata, layId?: number) => {
    // this.props.toggleViewFullImage(md.id);
    if (md !== undefined) {
      // if( md.metadataIdentifier){
      //   console.log(md.metadataIdentifier, '204', toggleImageFullRes)
      //   this.props.toggleImageFullRes([md.metadataIdentifier]);
      // }
      if( md.identifier){
        this.props.toggleImageFullRes2B([md.identifier], layId!);
      }
      // this.props.toggleViewFullImage(md.id);
      // this.props.selectMetadataRoute(md.id);

    } else {
      // this.props.checkedImages.forEach(id =>
      //   this.props.toggleViewFullImage(isNaN(Number(id)) ? id : Number(id))
      // );
      this.props.toggleImageFullRes2B(this.props.checkedImages, layId!);
    }
  };

  public handleToggleCheckImage = (md: ImageMetadata) => {
    this.props.toggleCheckImage(md.identifier);
  };

  public handleCheckAll = (checked: boolean) => {
    if (checked === true) {
      this.props.toggleCheckImage(this.props.results.map(md => md.identifier));
    } else {
      this.props.toggleCheckImage([]);
    }
  };

  public handleMetadataDownload = (md?: ImageMetadata) => {
    const getIdForMdDownloadTask = (_md: ImageMetadata) => {
      // return isNaN(Number(_md.id)) ? _md.identifier : Number(_md.id);
      return _md.identifier.startsWith('BBP.') || !_md.hasOwnProperty('id')
        ? _md.identifier
        : _md.id;
    };

    // const imageIds = md !== undefined ? [String(md.id)] : this.props.checkedImages;
    const imageIds =
      md !== undefined
        ? [String(md.id)]
        : this.props.results
            .filter(item => this.props.checkedImages.includes(item.identifier))
            .map(item => String(item.id));
    const identifiers = this.props.results
      .filter(_md => imageIds.indexOf(String(_md.id)) > -1)
      .map(_md => getIdForMdDownloadTask(_md));

    this.props.createTaskRequest({ metadataIds: identifiers });
  };

  handleCopyToClipboard = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        this.props.pushMessage({
          message: i18nMark('Идентификатор скопирован в буфер обмена'),
          place: 'bc',
        });
      })
      .catch(() => {
        this.props.pushMessage({
          message: i18nMark('Ошибка при копировании в буфер обмена'),
          place: 'bc',
        });
      });
  };

  /* // TODO - rename to 'preview' ?
  public handleSingleImgClick = () => {
    alert('img click');
  };

  public handleGroupImgClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // event.preventDefault();
    event.stopPropagation();
    alert('img group click');
    // const el = event.target;
    // console.log(el);
    // console.log(event);
  }; */

  // private handleAccordionToggle = (id: number, flag: boolean) => {
  handleAccordionToggle = (md: ImageMetadata, flag?: boolean) => {
    if (flag === true) {
      this.setState({ currentExpandedMetadataId: md.identifier });
    } else {
      this.setState({ currentExpandedMetadataId: '' });
    }
  };

  handleLoadMore = () => {
    const searchParams = this.props.searchParams;
    this.props.searchMetadataRequest({
      ...searchParams,
      offset: searchParams.offset + searchParams.limit,
      // TODO? scroll to new items (callback)
    });
  };

  public componentDidUpdate(prevProps: SearchResultProps) {
    if (this.props.selectedMetadataRoute !== prevProps.selectedMetadataRoute) {
      const el = document.getElementById(`resultsItem-${this.props.selectedMetadataRoute}`);
      if (!el) {
        return;
      }
      const anchorOffset = el.offsetTop;
      // const el = this[`${this.props.selectedMetadataRoute}Ref`];
      // const node = findDOMNode(el);
      // if (!node) {
      //   return;
      // }
      // const anchorOffset = node.offsetTop;

      // console.log('listRef', this.listRef.current);
      // const listNode = findDOMNode(this.listRef.current) as Element;
      // console.log('listRef domnode', listNode);
      // // listNode.scrollTop = node.offsetTop;

      // debugger;
      // el.offsetTop
      // this.listRef.current.scrollRef.current.getScrollHeight() - 15295
      // this.listRef.current.scrollRef.current.getClientHeight() - 623
      // this.listRef.current.scrollRef.current.getScrollTop()
      // @ts-ignore
      const listScrollTop = this.listRef.current.scrollRef.current.getScrollTop();
      // @ts-ignore
      const clientHeight = this.listRef.current.scrollRef.current.getClientHeight() - 20;
      if (el.offsetTop - listScrollTop > clientHeight || listScrollTop > el.offsetTop) {
        this.listRef.current!.scrollTop(anchorOffset);
      }

      // this.listRef.current!.scrollTop(anchorOffset);
      // console.log('gate', el, node);
    }
  }

  public render() {
    // const { dateBegin, dateEnd, maxCloudiness } = this.state;
    const { currentExpandedMetadataId /* , isResultsHidden */ } = this.state;
    const {
      searchParams,
      results,
      onBack,
      selectedMetadataRoute,
      currentCartImages,
      activeImageContours,
      activeImagePreviews,
      activeViewFullImages,
      activeViewFullImages2B,
      checkedImages,
      searchMetadataRequest,
      totalImages,
      // user,
    } = this.props; // platformsById
    // let activeViewFullImages2B = activeImagePreviews

    // results.forEach( item => console.log( [activeViewFullImages.indexOf(item.identifier)],  `item.identifier`))
    // const pHight = (Object.keys(searchParams).length - 1) * 22 + 'px';
    let pHight = -44; // count min = 4 (source, regionScreen, plc, geometry) -> 0; offset, limit -> -22*2
    for (const [key, value] of Object.entries(searchParams)) {
      if (
        // value !== undefined &&
        !isEmptyValue(value) &&
        [
          'illuminationElevationAngleMin',
          'illuminationElevationAngleMax',
          'nadirTiltAngleMin',
          'nadirTiltAngleMax',
        ].indexOf(key) === -1
      ) {
        pHight += 22;
      }
    }
    // const heightMin = isResultsHidden ? '0px' : '150px'; // 300px
    // const heightMax = isResultsHidden ? '0px' : `calc(100vh - 280px + 65px - ${pHight}px)`;

    const currentCartImagesIds = currentCartImages.map(cartImage => cartImage.metadataIdentifier);

    return (
      <>
        <SearchParamsCompact
          params={searchParams}
          onEditParams={onBack}
          searchMetadataRequest={searchMetadataRequest}
          count={results.length}
          total={totalImages}
        />

        <div className={styles.listHeader}>
          {/* table header */}
          {/* TODO - */}
          {/* Checkbox [], Карта: contour, preview, (fullImage?) --|-- Метаданные: metadataDownload */}
          <SearchResultHeaderActions
            checkValue={
              checkedImages.length > 0
                ? checkedImages.length < results.length
                  ? INDETERMINATE
                  : CHECKED
                : UNCHECKED
            }
            onCheckAll={this.handleCheckAll}
            onToggleContour={this.handleToggleContour}
            onTogglePreview={this.handleTogglePreview} 
            onToggleFullImage={this.handleToggleFullImage}

            onMetadataDownload={this.handleMetadataDownload}
          />
          {/* --- */}

          {/* <span>&nbsp;</span>
          <Button
            className={classNames(styles.closeButton, {
              [styles.closeButtonRotateTest]: isResultsHidden,
            })}
            title="развернуть/свернуть"
            color={ButtonColor.TRANSPARENT}
            onClick={() => this.setState({ isResultsHidden: !isResultsHidden })}
          >
            <ExpandIcon />
          </Button> */}
        </div>

        {/* className, transition: max-height 0.4s linear; overflow: hidden; max-height: 0px; */}
        <div className={styles.root} /* style={{ maxHeight: heightMax }} */>
          <CustomScrollBarContainer
            ref={this.listRef}
            heightMin="150px"
            heightMax={`calc(100vh - 280px + 65px - ${pHight}px)`}
            // heightMin={heightMin}
            // heightMax={heightMax}
            color="#CEEAFB"
          >
            {results.length === 0 && (
              <div className={styles.emptyResultMessage}>
                <Trans>По заданным условиям нет снимков</Trans>
              </div>
            )}
            {results.map((item, index) => (
            
              <React.Fragment key={item.identifier}>
                {/* {console.log(item.dateBegin, item.dateInstant)} */}
                {/* {index !== 0 && <HorizontalLine paddingLeft="16px" paddingRight="16px" />} */}
                {index !== 0 && <div className={styles.horizontalLine2} />}
                {/* <div
                  id={`resultsItem-${item.id}`}
                  // key={item.metadata_id}
                  className={classNames(styles.listItem, {
                    [styles.listItemSelected]: item.id === selectedMetadataRoute,
                  })}
                  // onDoubleClick={this.handleSelectRoute.bind(this, item)}
                > */}
                <div id={`resultsItem-${item.identifier}`}>
                  <SearchResultItem
                    data={item}
                    isActive={item.identifier === selectedMetadataRoute}
                    isChecked={checkedImages.indexOf(item.identifier) !== -1}
                    isOpen={currentExpandedMetadataId === item.identifier}
                    // isInCart={currentCartImagesIds.indexOf(item.identifier) > -1}
                    // prettier-ignore
                    isInCart={currentCartImagesIds.findIndex(value => value.startsWith(item.identifier)) > -1}
                    isContourActive={activeImageContours.indexOf(item.identifier) === -1}
                    isPreviewActive={activeImagePreviews.indexOf(item.identifier) > -1}
                    isFullImageActive={activeViewFullImages.indexOf(item.identifier) > -1}
                    isFullImageActive2B={activeViewFullImages2B.indexOf(item.identifier) > -1}
                    // isFreeUser={!user.haveToPay()}
                    actions={{
                      // header
                      onActivate: this.handleSelectRoute.bind(this, item),
                      onToggleCheck: this.handleToggleCheckImage,
                      onAddToCart: this.handleAddToCart,
                      onRemove: this.handleRemoveRouteFromResults,
                      onCopyToClipboard: this.handleCopyToClipboard,
                      // body
                      onToggleOpen: this.handleAccordionToggle,
                      // footer
                      onToggleContour: this.handleToggleContour,
                      onTogglePreview: this.handleTogglePreview,
                      onToggleFullImage: this.handleToggleFullImage,
                      onToggleFullImage2B: this.handleToggleFullImage2B,
                      onMetadataDownload: this.handleMetadataDownload,
                    }}
                  />
                </div>

                {/* </div> */}
              </React.Fragment>
            ))}
            {totalImages > 0 && searchParams.offset + searchParams.limit < totalImages && (
              <div className={styles.loadMore} onClick={this.handleLoadMore}>
                {/* Больше снимков */}
                <Trans>Загрузить больше снимков</Trans>
              </div>
            )}
          </CustomScrollBarContainer>
        </div>
      </>
    );
  }
}

// export default SearchResult;
export default connect(
  (state: RootState) => ({
    selectedMetadataRoute: getSelectedMetadataRoute(state),
    currentCartImages: getCurrentCartImages(state),
    // platformsById: getPlatformsById(state),
    activeImageContours: getActiveImageContours(state),
    activeImagePreviews: getActiveImagePreviews(state),
    activeViewFullImages: getActiveViewFullImages(state),
    activeViewFullImages2B: getActiveViewFullImages2B(state),
    checkedImages: getCheckedImages(state),
    totalImages: getTotalImages(state),

    user: getUser(state),
  }),
  {
    selectMetadataRoute,
    removeMetadataRoute,
    toggleImageContour: toggleImageContour.request,
    // toggleMetadataRoutePreview,
    toggleImagePreview: toggleImagePreview.request,
    // toggleViewFullImage,
    toggleImageFullRes: toggleImageFullRes.request,
    toggleCheckImage,
    toggleImageFullRes2B: toggleImageFullRes2B.request,
    
    createTaskRequest: createTask.request,

    // addToCart: pickSimpleRouteCart.add,
    updateCurrentCartImagesRequest: updateCurrentCartImages.request,

    pushMessage: notifyActions.push,

    searchMetadataRequest: searchImages.request,
  },
  null,
  { forwardRef: true }
)(SearchResult);
