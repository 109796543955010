import { Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { S3_URL } from 'api/realAPI';
import React, { useEffect } from 'react'; // { SyntheticEvent }
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';

import { RootState } from 'ducks';
import { getImageLayers, toggleImageLayer } from 'ducks/mapLayers';
import { clearItems, fetchDates } from 'ducks/stacItems';

import { TfiInfoAlt } from 'react-icons/tfi';
import Tooltip from 'react-tooltip-lite';

import GeoportalMap from 'components/map/Map/Map';
import styles from '../BaseLayerControl.module.scss';

const ImageLayerControl: React.FC = () => {
  const dispatch = useDispatch();

  const imageLayers: any[] = useSelector((state: RootState) => getImageLayers(state));
  const selectedImageLayerId = useSelector((state: RootState) => state.layers.selectedImageLayerId);
  // const itemDates = useSelector((state: RootState) => getItemsDates(state));

  useEffect(() => {
    if (['globalMSUGS', 'btMSUGS', 'globalMSUMR', 'regionalMSUGS'].includes(selectedImageLayerId)) {
      // console.log(29, selectedImageLayerId)
      // dispatch(clearItems());
      dispatch(fetchDates.request());
    }
  }, [dispatch, selectedImageLayerId]);

  // useEffect(() => {
  //   if (itemDates.length > 0) {
  //     dispatch(fetchItems.request())
  //   }
  // }, [dispatch, itemDates])

  const handleImageLayerClick = (event: any) => {
    const layerName: string = event.target.name;
    const map = GeoportalMap.getInstance();
    if (selectedImageLayerId === layerName) {
      map.clearImageLayer(layerName);
      if (layerName === 'btMSUGS') {
        map.clearMapControl();
      }
    }

    dispatch(clearItems());
    dispatch(toggleImageLayer(layerName));
    // console.log(43, layerName, selectedImageLayerId)
  };
  // const linkToDocs: string =
  return (
    <>
      {imageLayers.length > 0 && (
        <>
          <Dropdown.MenuHeader>
            <Trans>Снимки_</Trans>
          </Dropdown.MenuHeader>
          <Dropdown.MenuDivider />
          {imageLayers.map(item => (
            <Dropdown.MenuItem key={item.id}>
              <I18n>
                {({ i18n }) => (
                  <span key={item.id} className={styles.inputRadioLabel}>
                    <input
                      className={styles.checkbox}
                      type="radio"
                      id={`imageLayer-${item.id}`}
                      name={item.id}
                      value={selectedImageLayerId}
                      checked={item.id === selectedImageLayerId}
                      // onChange={handleImageLayerChange}
                      onClick={handleImageLayerClick}
                      readOnly
                    />
                    <label className={styles.inputRadioLabel} htmlFor={`imageLayer-${item.id}`}>
                      <div>{`${
                        i18n.language === 'en' ? item.name_en[0] : item.name[0]
                      }\u00A0`}</div>
                      <div style={{ fontSize: '0.8rem' }}>{` ( ${
                        i18n.language === 'en' ? item.name_en[1] : item.name[1]
                      }`}</div>
                      <div style={{ margin: '0px 5px' }}>
                        <IconButton
                          style={{
                            cursor: 'pointer',
                            width: '14px',
                            height: '14px',
                            marginTop: '3px',
                          }}
                        >
                          <Tooltip
                            tagName="span"
                            tipContentClassName="tip-content--border-grey"
                            arrow={false}
                            background="white"
                            color="black"
                            direction="up"
                            distance={5}
                            content={
                              i18n.language === 'en' ? 'detailed description' : 'подробное описание'
                            }
                          >
                            <a
                              href={
                                item.id === 'globalMSUGS'
                                  ? `${S3_URL}/geoportal-public/user-guide/v0.1/index.html#%D1%86%D0%B2%D0%B5%D1%82%D0%BE%D1%81%D0%B8%D0%BD%D1%82%D0%B5%D0%B7%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82-%D0%B3%D0%BB%D0%BE%D0%B1%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BC%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%D0%B0-%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D1%8B%D1%85-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D1%81-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D1%8C%D1%8E-%D0%B0%D0%BF%D0%BF%D0%B0%D1%80%D0%B0%D1%82%D1%83%D1%80%D1%8B-%D0%BC%D1%81%D1%83-%D0%B3%D1%81-%D1%81-%D0%BA%D0%B0-%D0%B0%D1%80%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D0%BC-%D0%B8-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE-%D0%BB`
                                  : item.id === 'btMSUGS'
                                  ? `${S3_URL}/geoportal-public/user-guide/v0.1/index.html#%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82-%D0%B3%D0%BB%D0%BE%D0%B1%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B1%D0%B5%D1%81%D1%88%D0%BE%D0%B2%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BC%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%D0%B0-%D1%80%D0%B0%D0%B4%D0%B8%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85-%D1%82%D0%B5%D0%BC%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80-%D0%BF%D0%BE-%D1%82%D0%B5%D0%BF%D0%BB%D0%BE%D0%B2%D0%BE%D0%BC%D1%83-%D0%BA%D0%B0%D0%BD%D0%B0%D0%BB%D1%83-%D0%BA%D0%B0-%D0%B0%D1%80%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D0%BC-%D0%B8-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE-%D0%BB`
                                  : item.id === 'globalMSUMR'
                                  ? `${S3_URL}/geoportal-public/user-guide/v0.1/index.html#%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82-%D0%B5%D0%B6%D0%B5%D1%81%D1%83%D1%82%D0%BE%D1%87%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B3%D0%BB%D0%BE%D0%B1%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BC%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%D0%B0-%D0%BF%D0%BE-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D0%BC-%D0%B0%D0%BF%D0%BF%D0%B0%D1%80%D0%B0%D1%82%D1%83%D1%80%D1%8B-%D0%BC%D1%81%D1%83-%D0%BC%D1%80-%D0%BA%D0%B0-%D0%BC%D0%B5%D1%82%D0%B5%D0%BE%D1%80-%D0%BC`
                                  : 'http://s3.gptl.ru/geoportal-public/user-guide/v0.1/index.html'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TfiInfoAlt size={'15px'} color={'#a8a6a6'} strokeWidth={'0.6px'} />
                            </a>
                          </Tooltip>
                        </IconButton>
                      </div>
                      <div style={{ fontSize: '0.8rem' }}>)</div>
                    </label>
                  </span>
                )}
              </I18n>
            </Dropdown.MenuItem>
          ))}
        </>
      )}
    </>
  );
};

export default ImageLayerControl;
