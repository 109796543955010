// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
import { getTopLeft, getWidth } from 'ol/extent'; // getCenter
import { get as getProjection } from 'ol/proj';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

import { CreateLayerBundle } from '../types';

const createSimpleWMTSLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options, params } = bundle;
  //
  // const url = 'http://tb-autotransforming.sec45.ccr.dep4.niitp:8080/geoserver/gwc/service/wmts';
  // const url = params.url;
  // const format = params.format || 'image/png';
  // const version = '1.0.0';

  const projection = getProjection('EPSG:3857'); // EPSG:900913
  const projectionExtent = projection!.getExtent();

  const size = getWidth(projectionExtent) / 256;
  // const size = getWidth(projectionExtent) / 512;
  const resolutions = new Array(19); // will be 18 max z level
  const matrixIds = new Array(19);
  for (let z = 0; z < 19; ++z) {
    resolutions[z] = size / Math.pow(2, z);
    matrixIds[z] = 'EPSG:3857:' + z; // EPSG:900913
    // matrixIds[z] = z;
  }

  const layer = new TileLayer({
    id,
    name,
    // name: 'wmtsLayer',
    // opacity: 0.0,
    source: new WMTS({
      url: options.url,
      layer: params!.layer!, // 'cite:mosaic1'
      matrixSet: 'EPSG:3857', // 'EPSG:900913',
      // matrixSet: 'GoogleMapsCompatible',
      format: params!.format || 'image/png',
      projection: projection!,
      // crossOrigin: 'Access-Control-Allow-Origin',
      // crossOrigin: null, // 'anonymous' <- Not work!
      // serverType: 'geoserver',
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions,
        matrixIds,
      }),
      style: '',
      // style: new Style({
      //     stroke: new Stroke({
      //         width: 3,
      //         color: [255, 0, 0, 1]
      //     }),
      //     fill: new Fill({
      //         color: [0, 0, 255, 0.6]
      //     })
      // }),
      wrapX: true,
      attributions: options.attribution,
    }),
  });

  return layer;
};

export default createSimpleWMTSLayer;
