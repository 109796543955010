import React from 'react';

import { t } from '@lingui/macro';

import HeadTitle from 'pages/HeadTitle';
import SolidHeader from 'components/layout/Header/SolidHeader';

const styles = require('./MultiRequestPage-styles.module.scss');

const MultiRequestPage: React.FC = () => {
  return (
    <>
      <HeadTitle value={t`Создание заявки - Геопортал`} />

      <SolidHeader />
      <div className={styles.root}>
        <h2>MultiRequestPage Page</h2>
      </div>
    </>
  );
};

export default MultiRequestPage;
