import { APP_NAME, APP_VERSION } from 'api/realAPI';
import React from 'react';

const AppInfoPage: React.FC = () => {
  return (
    <>
      {/* <HeadTitle value="App version - Geoportal" /> */}
      <div style={{ position: 'absolute', top: '1rem', left: '2rem' }}>
        <h2>App Version</h2>
        <div>
          Name: {APP_NAME}
          <br />
          Version: {APP_VERSION}
        </div>
      </div>
    </>
  );
};

export default AppInfoPage;
