import React from 'react';
import { Trans } from '@lingui/macro';
import NewRangeInput from '@geobank/components/src/forms/NewRangeInput/NewRangeInput';

interface AdditionalOptionsProps {
  sunHeightHandler: (value: number[]) => void;
  angleSystemHandler: (value: number[]) => void;
  sunHeightValue: number[];
  angleSystemValue: number[];
}

export default class AdditionalOptions extends React.Component<AdditionalOptionsProps, {}> {
  public render() {
    const { sunHeightHandler, angleSystemHandler, sunHeightValue, angleSystemValue } = this.props;
    return (
      <div style={{ padding: '0 10px' }}>
        <div style={{ color: '#ffffff', fontSize: 14, padding: '10px 0 20px 0px' }}>
          <Trans>
            <span>Высота Солнца°</span>
          </Trans>
        </div>
        <div>
          {/* input: illumination_elevation_angle */}
          <NewRangeInput
            // min={15}
            min={0}
            max={90}
            step={1}
            values={sunHeightValue}
            onChange={sunHeightHandler}
            // minLabel={'15°'}
            minLabel={'0°'}
            maxLabel={'90°'}
          />
        </div>
        <div style={{ color: '#ffffff', fontSize: 14, padding: '20px 0 20px 0px' }}>
          {/* description: */}
          {/* <span>Суммарный угол крена системы°</span> */}
          <Trans>
            <span>Угол крена°</span>
          </Trans>
        </div>
        <div>
          {/* input: nadir_tilt_angle */}
          <NewRangeInput
            min={0}
            max={90}
            step={1}
            values={angleSystemValue}
            onChange={angleSystemHandler}
            minLabel={'0°'}
            maxLabel={'90°'}
          />
        </div>
      </div>
    );
  }
}
