import React from 'react';
import Tooltip from 'react-tooltip-lite';

import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import useDebounceOpen from 'components/hooks/useDebounceOpen';
import { usageTimeOptions, UsageTimeValue } from 'locales/dicts/license';

const styles = require('./UsageTime-styles.module.scss');

interface UsageTimeProps {
  active: UsageTimeValue;
  onSelect: (value: UsageTimeValue) => void;
}

const UsageTime: React.FC<
  UsageTimeProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'>
> = props => {
  const { active, onSelect, ...rest } = props;
  const [isOpen, setIsShown] = useDebounceOpen();

  const handleSelect = (value: UsageTimeValue) => {
    onSelect(value);
    // setIsOpen(false);
    setIsShown(false, true);
    // setTimeout(() => setIsOpen(undefined), 1000);
  };

  return (
    <div {...rest}>
      <span>
        <Trans>Срок использования</Trans>:
      </span>
      {/* <span>от 5 до 10 лет</span> */}
      <Tooltip
        tagName="span"
        styles={{ marginLeft: '4px' }}
        tipContentClassName="tip-content--border-grey tip-no-padding"
        arrow={false}
        isOpen={isOpen}
        // mouseOutDelay={800}
        background="white"
        color="black"
        direction="up" // "up-end"
        content={
          // <Trans id="order_image.hint_elevation">Применять цифровую модель рельефа</Trans>
          <div
            className={styles.contentRoot}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <ul className={styles.list}>
              {/* <li><span>до 5 лет</span></li> */}
              {usageTimeOptions.map(item => (
                <li
                  key={item.value}
                  className={classNames(styles.listItem, {
                    [styles.listItemActive]: item.value === active,
                  })}
                  onClick={() => handleSelect(item.value)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span
          className={styles.selected}
          /* onClick={handleModalToggle} */
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          // onTouchStart={() => setIsShown(true)}
          // onTouchEnd={() => setIsShown(false)}
          onClick={() => setIsShown(!isOpen, true)}
        >
          {/* от 5 до 10 лет */}
          {usageTimeOptions.find(item => item.value === active)?.title}
        </span>
      </Tooltip>
    </div>
  );
};

export default UsageTime;
