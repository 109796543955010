
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from 'ducks';
// import { getIsAuthenticated } from 'ducks/auth';
import { getIsAuthenticated } from 'ducks/authIAM';
import { getIsFetching, getResults, searchObjects, SearchResultItem } from 'ducks/mapObjects';

import SearchInput from '@geobank/components/src/forms/SearchInput/SearchInput';
// import AuthButton from 'components/map/AuthButton/AuthButton';
import AuthButtonIAM from 'components/map/AuthButton/AuthButtonIAM';
// import Button from '@geobank/components/src/common/Button/Button';
import LangSelector from 'components/map/LangSelector/LangSelector';
import MainMenu from 'components/map/MainMenu/MainMenu';
import UserMenu from 'components/map/UserMenu/UserMenu';

const styles = require('./SolidHeader-styles.module.scss');

interface SolidHeaderProps {
  isAuthenticated: boolean;
  // searchInput
  searchObjectsRequest: typeof searchObjects.request;
  searchObjectsClear: typeof searchObjects.clear;
  searchObjectsSelect: typeof searchObjects.select;
  isFetching: boolean;
  results: SearchResultItem[] | undefined;
}

const SolidHeader: React.FC<SolidHeaderProps> = props => {
  const { isAuthenticated, isFetching, results } = props;

  return (
    <div className={styles.root}>
      <div className={styles.mapLeftSection}>
        <SearchInput
          placeholder="Названия, места, координаты"
          isLoading={isFetching}
          // results={results.gptl}
          results={results}
          onSearchChange={val => props.searchObjectsRequest(val)}
          onResultSelect={item => {
            props.searchObjectsSelect(item, true);
          }}
          onClear={props.searchObjectsClear}
        />
      </div>

      <Link to="/" style={{ color: 'white', lineHeight: '61px' }}>
        X
      </Link>

      {isAuthenticated ? (
        // <div className={styles.mapMainGroupTest}>
        <div className={styles.mapMainGroup}>
          {/* <ButtonWithUnderlay
            label="Мультирайонная заявка"
            onClick={() => {
              alert('multi request');
            }}
          /> */}
          <LangSelector className={styles.marginLeft} />
          <UserMenu className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      ) : (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          {/* <AuthButton className={styles.marginLeft} /> */}
          <AuthButtonIAM className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      )}
    </div>
  );
};

// export default SolidHeader;
export default connect(
  (state: RootState) => ({
    isAuthenticated: getIsAuthenticated(state),
    // searchInput
    isFetching: getIsFetching(state),
    results: getResults(state),
  }),
  {
    // searchInput
    searchObjectsRequest: searchObjects.request,
    searchObjectsClear: searchObjects.clear,
    searchObjectsSelect: searchObjects.select,
  }
)(SolidHeader);
