import { createActionCreator, createReducer } from 'deox';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { RootState as RootStateType } from './root';
import { SearchResultItem as _SearchResultItem } from '@geobank/components/src/forms/SearchInput/SearchInput';

/**
 * Types/Interfaces
 */
export interface SearchResult {
  // [name: string]: SearchResultItem[];
  name: string; // key
  results: SearchResultItem[]; // value
}

// export interface SearchResultItem {
//   id: string;
//   title: string;
//   description?: string;
//   type?: string;
//   geometry?: any;
//   data?: any;
// }
export type SearchResultItem = _SearchResultItem;

/**
 * Actions
 * MAP_OBJECTS/OBJECTS
 */
// TODO - нужно как то определять, что все объекты загружены и бросать action .finished !?
export const searchObjects = {
  request: createActionCreator('OBJECTS/FETCH_REQUEST', resolve => (data: string) => resolve(data)),
  success: createActionCreator('OBJECTS/FETCH_SUCCESS', resolve => (data: SearchResult) => {
    return resolve(data);
  }),
  failure: createActionCreator('OBJECTS/FETCH_FAILURE', resolve => (name: string, error?) =>
    resolve(name, { error })
  ),
  finished: createActionCreator('OBJECTS/FETCHED_ALL'), // Можно считать кол-во загруженного, и из этого понимать всё или нет
  clear: createActionCreator('OBJECTS/CLEAR_ALL'), // CANCEL, и завершать/отменять отправленные запросы к api

  select: createActionCreator(
    'OBJECTS/SELECT_OBJECT_ON_MAP',
    resolve => (item: SearchResultItem, isZoomNeed?: boolean) => resolve({ item, isZoomNeed })
  ),
};
// TODO - selectObject(), removeObject()
// export const selectObject = createActionCreator(
//   // 'MAP/SELECT_OBJECT',
//   'OBJECTS/SELECT_OBJECT_ON_MAP',
//   resolve => (item: SearchResultItem, isZoomNeed?: boolean) => resolve({ item, isZoomNeed }),
// );

/**
 * Reducers and state
 */
export const objectsDefaultState: { [key: string]: SearchResultItem[] | undefined } = {};

export const objectsReducer = createReducer(objectsDefaultState, handleAction => [
  handleAction(searchObjects.success, (store, { payload }) => ({
    ...store,
    [payload.name]: payload.results,
  })),
  handleAction(searchObjects.failure, (store, { payload }) => ({
    ...store,
    [payload]: undefined,
  })),
  handleAction(searchObjects.clear, () => ({})),
]);

export const defaultIsFetchingState = false; // isLoading

export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
  handleAction(searchObjects.request, () => true),
  // handleAction([searchObjects.finished /* , searchObjects.clear */], () => false),
  handleAction([searchObjects.success, searchObjects.failure], () => false),
]);

// RootReducer
export default combineReducers({
  objects: objectsReducer, // results or objects
  isFetching: isFetchingReducer,
});

/**
 * Selectors
 */
export const getResultsByProvider = (state: RootStateType) => state.objects.objects;
export const getIsFetching = (state: RootStateType) => state.objects.isFetching;

export const getResults = createSelector([getResultsByProvider], data => {
  let hasData = false;
  const res: SearchResultItem[] = [];
  // TODO - порядок по приоритетам! (gptl, osm, ); возможно, добавить помимо ключа и поле priority
  Object.entries(data).forEach(([_key, items]) => {
    if (Array.isArray(items)) {
      hasData = true;
      res.push(...items); // res = res.concat(items);
    }
  });
  return hasData ? res : undefined;
});
