import React from 'react';

import NewRangeInput from '@geobank/components/src/forms/NewRangeInput/NewRangeInput';

interface CloudinessFieldsetProps {
  cloudinessMax?: number;
  onChange: (field: string, value: any) => void;
}

const CloudinessFieldset: React.FC<CloudinessFieldsetProps> = props => {
  const { cloudinessMax, onChange } = props;

  const handleChange = (values: number[]) => {
    onChange('cloudinessMax', values[0]);
  };

  return (
    <>
      <NewRangeInput
        min={0}
        max={100} // to prevent users to order cloud areas
        step={1}
        // values={[0, 100]}
        values={[cloudinessMax || 50]} // default value
        trackColorStart={'#6AC3FA'}
        onChange={handleChange}
        minLabel={'0%'}
        maxLabel={'100%'} // must be set manually to the max value
      />
    </>
  );
};

export default CloudinessFieldset;
