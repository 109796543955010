import { Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LayersIcon from '@geobank/assets/src/icons/LayersIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';

import { RootState } from 'ducks';
import { getBaseLayers, getSelectedImageLayerId, selectBaseLayer } from 'ducks/mapLayers';
import STACItemsLayer from '../STACItems/STACItemsLayer';
import ImageLayerControl from './ImageLayerControl/ImageLayerControl';

import styles from './BaseLayerControl.module.scss';

const BaseLayerControl: React.FC = () => {
  const dispatch = useDispatch();
  const activeLayer: number | undefined = useSelector(
    (state: RootState) => state.layers.selectedBaseLayer
  );
  const baseLayers = useSelector((state: RootState) => getBaseLayers(state));

  const selectedImageLayerId = useSelector((state: RootState) => getSelectedImageLayerId(state));

  const handleBaseLayerChange = (value: number) => {
    if (value !== activeLayer) {
      dispatch(selectBaseLayer(Number(value)));
    }
  };

  const baseLayersItems = baseLayers.map(item => (
    <Dropdown.MenuItem key={item.id} onClick={() => handleBaseLayerChange(item.id)}>
      <I18n>
        {({ i18n }) => (
          <span key={item.id}>
            <input
              id={`layer-${item.id}`}
              type="radio"
              name="layer"
              value={item.id}
              // onChange={handleChange1}
              readOnly
              checked={activeLayer === item.id}
            />
            <label className={styles.inputLabel} htmlFor={`layer-${item.id}`}>
              {i18n.language === 'en' ? item.name_en : item.name}
            </label>
          </span>
        )}
      </I18n>
    </Dropdown.MenuItem>
  ));

  return (
    <>
      <Dropdown dropup={true} pullRight={true}>
        <Dropdown.Toggle>
          <LayersIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.root}>
          <ImageLayerControl />
          <Dropdown.MenuHeader>
            <Trans>Подложка</Trans>
          </Dropdown.MenuHeader>
          <Dropdown.MenuDivider />
          {baseLayersItems}
        </Dropdown.Menu>
      </Dropdown>
      {['globalMSUGS', 'btMSUGS', 'globalMSUMR', 'regionalMSUGS'].includes(
        selectedImageLayerId
      ) && <STACItemsLayer />}
    </>
  );
};

export default BaseLayerControl;
