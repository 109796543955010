// import { css } from 'astroturf';
// import classNames from 'classnames';
// import SearchOptionContainer from '../Forms/CommonComponents/SearchOptionContainer/SearchOptionContainer';
// import SearchLine from '@geobank/components/src/forms/SearchLine/SearchLine';
// import 'react-day-picker/lib/style.css';
// import DateInput from '@geobank/components/src/forms/DateInput/DateInput';
// import NewRangeInput from '@geobank/components/src/forms/NewRangeInput/NewRangeInput';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';

// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import CheckBoxGroup from '@geobank/components/src/forms/CheckBoxGroup/CheckBoxGroup';
// import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs';

// import cameraIcon from '@geobank/assets/src/icons/tabMenu/CameraIcon';
// import folderIcon from '@geobank/assets/src/icons/tabMenu/FolderIcon';
// import loadIcon from '@geobank/assets/src/icons/tabMenu/LoadIcon';
// import monitorIcon from '@geobank/assets/src/icons/tabMenu/MonitorIcon';
// import nibIcon from '@geobank/assets/src/icons/tabMenu/NibIcon';

import styles from './LeftMenu.css';
// import DateRangeFieldset from './SearchForm/DateRangeFieldset';
// import CloudinessFieldset from './SearchForm/CloudinessFieldset';

// import '@geobank/assets/src/styles/font.css';

// import { useSelector } from 'react-redux';
import * as store from 'ducks';
import { getIFetching as getIsAuthenticating, getIsAuthenticated, iamActions } from 'ducks/authIAM';
import { fetchPlatforms } from 'ducks/classifier';
import { /* drawControl, getRegionsOfInterest, */ getIsMainPanelShow } from 'ducks/map';
import {
  // getIsFetching,
  getMetadata,
  // getSearchParams,
  // searchImages,
  ImageMetadata,
} from 'ducks/metadata';
import { connect } from 'react-redux';

// import { camelToSnake } from '@geobank/assets/src/uibook/helpers/utils';
// import dateFormat from 'date-fns/format';
import CustomOrderImageForm from '../CustomOrderImageForm';
import SearchForm from './SearchForm/SearchForm';
// import Loader from '@geobank/components/src/common/Loader/Loader';
// import WKT from 'ol/format/WKT';
// import GeoJSON from 'ol/format/GeoJSON';
// import GeoportalMap from 'components/map/Map/Map';
// import SearchResult from './SearchResult/SearchResult';
// import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
// import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import SearchFormPanel from './SearchFormPanel';
import SearchResultPanel from './SearchResultPanel';

// const serializeValue = (value: any) => {
//   return value instanceof Date ? dateFormat(value, 'yyy-MM-dd') : value;
// };

// tslint:disable-next-line:no-empty-interface
interface LeftMenuProps {
  metadata: ImageMetadata[];
  fetchPlatformsRequest: typeof fetchPlatforms.request;
  isMainPanelShow: boolean;
  doLogin: typeof iamActions.login;
  doRegister: typeof iamActions.register;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

// type PanelName = 'params' | 'results';

interface LeftMenuState {
  // dateInputValue: string;
  // rangeInputValue: number[];
  // regionSelection: 'screen' | 'draw' | 'shape';
  currentPanelName: 'params' | 'results' | 'custom';
  imageAddToCart?: ImageMetadata;

  // dateBegin?: Date;
  // dateEnd?: Date;
  // cloudinessMax?: number;
}

class LeftMenu extends React.Component<LeftMenuProps, LeftMenuState> {
  // private formRef: React.RefObject<HTMLInputElement>;
  private formRef = React.createRef<SearchForm>();

  // constructor({ props }: { props: any }) { -> Argument of type 'typeof LeftMenu' is not assignable to parameter of type 'ComponentType<never>'.
  constructor(props: LeftMenuProps) {
    super(props);
    this.state = {
      // dateInputValue: '',
      // rangeInputValue: [10, 20],
      // regionSelection: 'screen',
      currentPanelName: 'params',

      // dateBegin: undefined,
      // dateEnd: undefined,
      // cloudinessMax: undefined,
    };

    // this.formRef = React.createRef();
  }

  // public handleInputChange = (field: string, value: any) => {
  //   // @ts-ignore
  //   this.setState({ [field]: value });

  //   // console.log(field, value);
  // };

  /* public handleSearchRequest = () => {
    if (!this.props.isAuthenticated) {
      alert('Для поиска и заказа нужно выполнить вход под своей учётной записью');
      return;
    }

    // TODO - validate
    if (this.formRef.current) {
      const formState = this.formRef.current.state;
      // const params = {};
      // Object.keys(formState).forEach(key => {
      //   // @ts-ignore
      //   if (formState[key]) {
      //     // @ts-ignore
      //     // params[camelToSnake(key)] = serializeValue(formState[key]);
      //     params[key] = formState[key];
      //   }
      // });
      const params = formState;
      const regionSelection = formState.regionSelection; // this.state.regionSelection;
      if (regionSelection === 'screen') {
        const map = GeoportalMap.getInstance().getOlMap();
        const extent = map.getView().calculateExtent(); // .calculateExtent(map.getSize())
        // console.log('view extent', extent);
        const [minX, minY, maxX, maxY] = extent;
        const polygon3857 = `POLYGON((${minX} ${minY}, ${minX} ${maxY}, ${maxX} ${maxY}, ${maxX} ${minY}, ${minX} ${minY}))`;
        const format = new WKT();
        const polygon = format.readGeometry(polygon3857).transform('EPSG:3857', 'EPSG:4326');
        // @ts-ignore
        params[`geometry`] = format.writeGeometry(polygon);
      } else if (regionSelection === 'draw' || regionSelection === 'shape') {
        /* if (this.props.regionGeometry.startsWith('POLYGON')) {
          // TODO - 1; params['polygon'] = format.writeGeometryText(
          // .readFeatureFromText(values.polygon)
          const format = new WKT();
          const polygon = format
            .readFeature(this.props.regionGeometry)
            .getGeometry()!
            .transform('EPSG:3857', 'EPSG:4326');
          // @ts-ignore
          params[`polygon`] = format.writeGeometry(polygon);
        } * /
        if (this.props.regionsOfInterest.length > 0) {
          const format = new GeoJSON();
          const polygon = format
            .readFeature(this.props.regionsOfInterest[0])
            .getGeometry()
            .transform('EPSG:3857', 'EPSG:4326');
          // @ts-ignore
          params[`geometry`] = new WKT().writeGeometry(polygon);
        }
      }

      // console.log('search params:', params);
      // this.props.searchMetadataRequest(params);
      this.props.searchImagesRequest(params);
    }
  };

  public handleClearSearchParams = () => {
    // TODO - очистка дерека КА, сброс геометрии района интереса
    this.props.clearSearchParams();
  }; */

  public handleDoLogin = () => {
    this.props.doLogin();
  };

  public handleDoRegister = () => {
    this.props.doRegister();
  };

  public componentDidUpdate(prevProps: LeftMenuProps) {
    if (prevProps.metadata !== this.props.metadata) {
      // console.log('hehey');
      this.setState({ currentPanelName: 'results' });
    }
  }

  public componentDidMount() {
    this.props.fetchPlatformsRequest();

    document.addEventListener('show-add-image-form', this.handleHello);
  }

  public componentWillUnmount() {
    document.removeEventListener('show-add-image-form', this.handleHello);
  }

  public handleHello = (event: any) => {
    // console.log('custom event', event, event.detail);
    console.log('change');
    const md = event.detail as ImageMetadata;
    this.setState({ currentPanelName: 'custom', imageAddToCart: md });
  };

  public render() {
    // const { dateBegin, dateEnd, cloudinessMax } = this.state;
    // Invalid hook call. Hooks can only be called inside of the body of a function component.
    // const counter = useSelector(getIsFetching);
    const {
      // isFetching,
      metadata,
      // searchParams,
      isMainPanelShow,
      isAuthenticated,
      isAuthenticating,
    } = this.props;
    const { currentPanelName, imageAddToCart } = this.state;

    if (isAuthenticating) {
      return null;
    }
    if (!isAuthenticated && !isAuthenticating) {
      return (
        <div className={classNames(styles.root, { _show: isMainPanelShow })}>
          <div className={styles.helloText}>
            <span>
              <Trans>
                Для поиска по Единому каталогу и заказа снимков из Фонда данных ДЗЗ необходимо{' '}
                <span className={styles.helloTextLink} onClick={this.handleDoLogin}>
                  войти
                </span>{' '}
                в аккаунт Геопортала Роскосмоса.
                <br />
                Нет аккаунта -{' '}
                <span className={styles.helloTextLink} onClick={this.handleDoRegister}>
                  зарегистрируйтесь
                </span>
                , это быстро.
              </Trans>
              {/* в конце бета-тестирования аккаунты будут удалены :slight_smile: */}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={classNames(styles.root, { _show: isMainPanelShow })}>
        {currentPanelName === 'custom' && imageAddToCart && (
          <div>
            <CustomOrderImageForm
              orderImage={imageAddToCart}
              onCancel={() => this.setState({ currentPanelName: 'params' })}
            />
          </div>
        )}
        {metadata.length !== 0 ? <SearchResultPanel /> : <SearchFormPanel />}
        {/* {currentPanelName === 'params' && <SearchFormPanel />}
        {currentPanelName === 'results' && (
          <SearchResultPanel
            onPanelChange={(panelName: 'params' | 'results') =>
              this.setState({ currentPanelName: panelName })
            }
          />
        )} */}
      </div>
    );
  }
}

export default connect(
  (state: store.RootState) => ({
    metadata: getMetadata(state),
    isMainPanelShow: getIsMainPanelShow(state),
    isAuthenticated: getIsAuthenticated(state),
    isAuthenticating: getIsAuthenticating(state),
  }),
  {
    fetchPlatformsRequest: fetchPlatforms.request,
    doLogin: iamActions.login,
    doRegister: iamActions.register,
  }
)(LeftMenu);
