// import { css } from 'astroturf';
import { Plural, Trans } from '@lingui/macro';
import React from 'react'; // useState, useEffect, useRef, useCallback

// import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
// import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
// import PictureIcon from '@geobank/assets/src/icons/PictureIcon';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import HorizontalLine from '@geobank/components/src/common/HorizontalLine/HorizontalLine';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import { catFileIdentifier, formatCurrency, formatNumber } from 'components/utils/format';
// import { ImageMetadata } from 'ducks/metadata';
// import { formatDate } from 'components/utils/date';
import { CartImage } from 'ducks/order';
import OutputProductParameters from './OutputProductParametrs/OutputProductParameters';
import ProductWithHint from './ProductWithHint';

import styles from './SimpleCart.module.scss';

interface SimpleCartProps {
  isOpen: boolean;
  onClose: () => void;
  // items: ImageMetadata[];
  items: CartImage[];
  totalPrice: number;
  onRemove: (item: any) => void;
  onSaveRequest: () => void;
  isProductParamsOpen: boolean;
  onProductParamsToggle: () => void;
  onClearRequest: () => void;
}

const SimpleCart: React.FC<SimpleCartProps> = props => {
  const {
    isOpen,
    items,
    totalPrice,
    onRemove,
    onClose,
    onSaveRequest,
    isProductParamsOpen,
    onProductParamsToggle,
    onClearRequest,
  } = props;

  // const item = items[0];
  // if (items.length < 1) {
  if (!isOpen) {
    return null;
  }

  // https://stackoverflow.com/questions/33577448/is-there-a-way-to-do-array-join-in-react
  // TODO - перевод и, наверное, тултипы (hints)
  // `Маршрут` - по клику отображает геометрию на карте (?)
  /* const renderParams = (item: CartImage) => {
    const labels = [
      item.bandCombination.toUpperCase(),
      item.bands && item.bands.length > 0 ? `${item.bands.length} каналов` : undefined,
      // item.elevation === true ? 'ЦМР' : undefined,
      // item.pansharpening === true ? 'Паншарп' : undefined,
    ];
    const result = labels
      .filter(item3 => item3 !== undefined)
      .map((item4, i, arr) => {
        const divider = i < arr.length - 1 && <span className={styles.itemDivider}>|</span>;
        return (
          <React.Fragment key={i}>
            <span key={i}>{item4}</span>
            {divider}
          </React.Fragment>
        );
      });
    return result;
  }; */

  // TODO - useCallback
  // const handleSaveRequest = () => {
  //   const productParams = productParamsRef.current!.state;
  //   const imageIds = items.map(item => item.id);
  //   console.log('handleSaveRequest', {
  //     ...productParams,
  //     ids: imageIds, // imageIds.join(',')
  //   });
  //   // TODO - clear cart and close panel
  //   onClose();
  // };

  // const handleToggleContour = (item: any) => {
  //   console.log('handleToggleContour', item);
  // };

  // const handleTogglePreview = (item: any) => {
  //   console.log('handleTogglePreview', item);
  // };

  if (items.length === 0) {
    return (
      <div className={styles.root}>
        <div className={styles.listItem}>
          <span>
            <Trans>Корзина пуста</Trans>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {/* <div className={styles.header}>
        <h3>
          {/* <Plural value={items.length} one="снимок" few="снимка" many="снимков" other="снимков" /> * /}
          {/* В корзине {items.length} снимка(ов) * /}
          <Trans>
            В корзине {items.length}{' '}
            <Plural value={items.length} one="снимок" few="снимка" many="снимков" other="снимков" />
            <br />
            на сумму {formatCurrency(totalPrice)}
          </Trans>
        </h3>
        <IconButton onClick={() => onClose()}>
          <CrossIcon />
        </IconButton>
      </div> */}
      <div className={styles.headerContainer}>
        <h3 className={styles.header2}>
          <Trans>
            В корзине {items.length}{' '}
            <Plural value={items.length} one="снимок" few="снимка" many="снимков" other="снимков" />
            <br />
            на сумму {formatCurrency(totalPrice)}
          </Trans>
        </h3>
        <div className={styles.stepLabel}>
          <Trans>Шаг 2 из 2</Trans>
        </div>
        <IconButton onClick={() => onClose()}>
          <CrossIcon />
        </IconButton>
      </div>
      {/* 48px - лицензия и срок использования, ещё 24px - ЦМР */}
      <CustomScrollBarContainer
        // prettier-ignore
        heightMax={`calc(100vh - 544px - 48px - 24px + ${isProductParamsOpen === true ? '0px' : '200px'})`}
        color="#CEEAFB"
      >
        {items.map((item, index) => (
          <React.Fragment key={item.metadataIdentifier}>
            {' '}
            {/* key={item.id} */}
            {/* delimeter */}
            {index !== -1 && <div className={styles.horizontalLine2} />}
            {/* item */}
            <div className={styles.listItem}>
              <div className={styles.itemHeader}>
                {/* <span>{catFileIdentifier(item.identifier, 1)}</span> */}
                <span title={item.metadataIdentifier}>
                  {catFileIdentifier(item.metadataIdentifier, 1)}
                </span>
                <Button
                  color={ButtonColor.TRANSPARENT}
                  className={styles.itemHeadActionButton}
                  title="Удалить из корзины"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    // alert('delete item');
                    onRemove(item);
                  }}
                >
                  <TrashIcon />
                </Button>
              </div>
              {/* <div className={styles.previewData}>
                {`${item.cloudiness} %; ${item.resolution} м; ${item.platformName} (${
                  item.platformName
                }); ${item.instrumentName}; ${formatDate(
                  item.acquisitionDateBegin || item.acquisitionDateInstant
                )}`}
                .{/* <br /><a href="/#">Все метаданные</a> * /}
              </div> */}
              {/* <div className="price">{index * 750} р</div> */}
              <div className={styles.itemBody}>
                {/* <div className="price">{formatCurrency(item.price)}</div> */}
                <div className={styles.priceValue}>{formatCurrency(item.price)}</div>
                {/* <div
                  className={styles.priceValue}
                  // params: getCurrentCartParams(state),
                  title={`цена рассчитана для продукта ...\n ${getFZProductDescription(item, params)}\n в соответствии с ФЗ`}
                >
                  {formatNumber(item.price)}
                </div> */}
                {item.area && (
                  <div className={styles.areaValue} /* title={`${item.area}`} */>
                    {formatNumber(item.area, { maximumFractionDigits: item.area < 0.1 ? 2 : 1 })}{' '}
                    <Trans>км</Trans>
                    <sup>
                      <small>2</small>
                    </sup>
                  </div>
                )}
                {/* className="product-params" */}
                {/* <div className={styles.itemParams}>{renderParams(item)}</div> */}
                <div className={styles.itemParams}>
                  <ProductWithHint item={item} />
                </div>
              </div>
              {/* <div className={styles.listItemFooter}>
                <div>
                  <div className={styles.test11}>Карта:</div>
                  <div className={styles.test11}>
                    <IconButton onClick={handleToggleContour.bind(null, item)}>
                      <ContourIcon />
                    </IconButton>
                  </div>
                  <div className={styles.test11}>
                    <IconButton onClick={handleTogglePreview.bind(null, item)}>
                      <EyeIcon />
                    </IconButton>
                  </div>
                  <div className={styles.test11}>
                    <IconButton>
                      <PictureIcon />
                    </IconButton>
                  </div>
                </div>
              </div> */}
            </div>
          </React.Fragment>
        ))}
      </CustomScrollBarContainer>
      {items.length !== 0 && (
        <div className={styles.outputProductParametersContainer}>
          <div className={styles.delimeter}>
            <HorizontalLine />
          </div>
          <OutputProductParameters
            isProductParamsOpen={isProductParamsOpen}
            onProductParamsToggle={onProductParamsToggle}
          />
          <div className={styles.delimeter}>
            <HorizontalLine />
          </div>
        </div>
      )}
      <div className={styles.footer}>
        <Button color={ButtonColor.WHITE} /* disabled */ onClick={onClearRequest}>
          <Trans>Очистить</Trans>
        </Button>
        <Button onClick={onSaveRequest}>
          {/* NOTE - общие параметры не влияют на цену. Кнопка ведёт на платёжный шлюз для оплаты.
          цена формируется при добавлении в корзину, в зависимости от фрагмента и доп. опций */}
          <Trans>Оплатить</Trans>
        </Button>
      </div>
    </div>
  );
};

export default SimpleCart;
