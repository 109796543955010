import { t, Trans } from '@lingui/macro';
import { i18nMark, withI18n, withI18nProps } from '@lingui/react';
import classNames from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import GeoportalMap from 'components/map/Map/Map';
// import mockProducts from './mockProducts.json';
import { catFileIdentifier } from 'components/utils/format';
import { genId } from 'components/utils/identifier';
import { notifyActions } from 'ducks/message';
import { CartImage, OrderProduct } from 'ducks/order';
import { downloadProduct } from 'ducks/orderProduct';
import { authFetch } from 'ducks/request';
import VectorLayer from 'libs/ol/layer/Vector';
import WKT from 'ol/format/WKT';
import { Vector as VectorSource } from 'ol/source';
import { copyTextToClipboard } from 'utils/clipboard';
// import FolderDownloadIcon from '@geobank/assets/src/icons/FolderDownloadIcon';
import ProductDownloadItem from './ProductDownloadItem';
import ProductPreviewButtons from './ProductPreviewButtons';
import ProductRemoveInfo from './ProductRemoveInfo';

import { S3_URL } from 'api/realAPI';
import BaseLayer from 'ol/layer/Base';
import styles from './RequestProductsPanel.module.scss';

const compareFileNames = (a: string, b: string) => {
  if (a.startsWith('metadata') && !b.startsWith('metadata')) {
    return 1;
  } else if (!a.startsWith('metadata') && b.startsWith('metadata')) {
    return -1;
  } else {
    return a.localeCompare(b);
  }
};

// scene num regexp:
// /^MM.*(\d+)\./ <-- MM22_MSUTM101_20201114T131450_11900600_N5808E05037_20201115_1D_TOAL3.tif
// /SCN(\d+)/     <-- KV6_12313_09493_01_KANOPUS_20210317_003614_003704.SCN13.MS.L2.tif

// const fileNumberRegExp = /(\d{1,3})\.tiff?/;
const fileExtentionImageRegExp = /\.tiff?/;
const fileExtentionMetadataRegExp = /metadata_.*\.zip/;
const getFileNameTitle = (fileName: string, index: number) => {
  let title = ''; // = fileName;

  // if (title.endsWith('.tiff')) {
  //   const matches = title.match(fileNumberRegExp);
  //   if (matches?.length === 2) {
  //     title = `image_${matches[1]}.tiff`;
  //   }
  // }
  if (fileExtentionImageRegExp.test(fileName)) {
    title = `scene_${index + 1}.tiff`; // `image_${index}.tiff`;
  } else if (fileExtentionMetadataRegExp.test(fileName)) {
    title = 'metadata.zip';
  } else {
    title = fileName;
  }

  return title;
};

interface RequestProductsPanelProps {
  images?: CartImage[];
  products: OrderProduct[];
}

const RequestProductsPanel: React.FC<RequestProductsPanelProps & withI18nProps> = props => {
  const { images, products, i18n } = props;
  const removed: boolean =
    products.filter(product => differenceInDays(product.removeDate, new Date()) >= 0).length === 0;
  const dispatch = useDispatch();
  const [progress, setProgress] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
  });
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);
  const [activeImagesContour, setActiveImagesContour] = useState<string[]>([]);

  const getPresignUrl = (url: string, cb: (data: { url: string }) => void) => {
    if (process.env.NODE_ENV === 'development') {
      url = url.replace('/orders/', '/v0/orders/').replace('&download=true', '');
    }
    dispatch(authFetch.request({ url, callback: cb }));
  };

  const handleCopyToClipboard = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        dispatch(
          notifyActions.push({
            message: i18nMark('Идентификатор скопирован в буфер обмена'),
            place: 'bc',
          })
        );
      })
      .catch(() => {
        dispatch(
          notifyActions.push({
            message: i18nMark('Ошибка при копировании в буфер обмена'),
            place: 'bc',
          })
        );
      });
  };

  const copyToClipboard = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        dispatch(
          notifyActions.push({
            message: i18nMark('Ссылка скопирована в буфер обмена'),
            place: 'bc',
          })
        );
      })
      .catch(() => {
        dispatch(
          notifyActions.push({
            message: i18nMark('Ошибка при копировании в буфер обмена'),
            place: 'bc',
          })
        );
      });
  };

  useEffect(() => {
    return () => {
      const map = GeoportalMap.getInstance().getOlMap();
      const productLayer: BaseLayer[] = map
        .getLayers()
        .getArray()
        .filter(layer => String(layer.get('name')).startsWith('productImage'));
      if (productLayer.length > 0) {
        productLayer.map(layer => map.removeLayer(layer));
      }
    };
  }, []);

  const handleToggleContour = (image: any) => {
    const map = GeoportalMap.getInstance().getOlMap();
    const productLayer: any = map
      .getLayers()
      .getArray()
      .filter(layer => layer.get('name') === `productImage-${image.metadataIdentifier}`)[0];
    if (productLayer) {
      setActiveImagesContour(activeImagesContour.filter(id => id !== image.metadataIdentifier));
      map.removeLayer(productLayer);
    } else {
      const format = new WKT();
      const feature = format.readFeature(
        image.croppingPolygon ? image.croppingPolygon : image.geometry,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }
      );
      feature.setId(genId());
      feature.set('_type', 'productImage');
      const vectorSource = new VectorSource({
        features: [feature],
      });
      const vector = new VectorLayer({
        id: 77777,
        name: `productImage-${image.metadataIdentifier}`,
        source: vectorSource,
      });
      // map.getLayers().forEach(layer => {
      //   if (layer && String(layer.get('name')).startsWith('productImage')) {
      //   // if (layer && layer.get('name') === `productImage-${image.metadataidentifier}`) {
      //     map.removeLayer(layer);
      //   }
      // });
      map.addLayer(vector);
      map.getView().fit(feature.getGeometry()!.getExtent());
      setActiveImagesContour([...activeImagesContour, image.metadataIdentifier]);
    }
  };

  /* const renderProductsV1 = () => {
    const result: React.ReactNode[] = [];
    products.forEach(product =>
      product.files.forEach((file, index) => {
        result.push(
          <ProductDownloadItem
            key={`${product.id}_${index}`}
            className={styles.productItem}
            title={file.name}
            url={file.url}
            size={file.size}
          />
        );
      })
    );
    return result;
  }; */

  const handleDownloadAll = () => {
    dispatch(
      downloadProduct.start(products, data => {
        const progressCalculated = Math.floor((data.loadedFiles / data.totalFiles) * 100);

        setProgress({
          loaded: data.loadedFiles,
          total: data.totalFiles,
          progress: progressCalculated,
        });
      })
    );

    setIsDownloadStarted(true);
  };

  const renderImages = () => {
    const result = images?.map(image => (
      <div key={image.metadataIdentifier} className={styles.productContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.productTitle} title={image.metadataIdentifier}>
            {catFileIdentifier(image.metadataIdentifier, 1)}
          </div>
          <div className={styles.buttonsGroup}>
            <Button
              // className={classNames(styles.itemHeadActionButton)}
              color={ButtonColor.TRANSPARENT}
              title={i18n._(
                t(
                  'image_actions.copy_to_clipboard'
                )`Скопировать идентификатор \n ${image.metadataIdentifier}`
              )}
              onClick={(e: any) => {
                e.stopPropagation();
                handleCopyToClipboard(image.metadataIdentifier);
              }}
            >
              <DuplicateIcon fill="currentColor" size={10} />
            </Button>
            {(image.croppingPolygon || image.geometry) && (
              <Button
                className={classNames(styles.mapActionButton, {
                  active: activeImagesContour.includes(image.metadataIdentifier),
                })}
                color={ButtonColor.TRANSPARENT}
                title={i18n._(t('image_actions.contour')`Контур`)}
                onClick={() => handleToggleContour(image)}
              >
                <ContourIcon fill="currentColor" />
              </Button>
            )}
          </div>
        </div>
      </div>
    ));
    return result;
  };
  const renderProducts = () => {
    // const result: React.ReactNode[] = [];
    const result = products.map(product => (
      <div key={product.id} className={styles.productContainer}>
        <div className={styles.titleContainer}>
          <div
            style={{
              maxWidth: `calc(100% - 2px - ${
                (product.previewUrls && product.previewUrls.length * 36) || 0
              }px)`,
            }}
          >
            <div
              className={styles.productTitle}
              title={product.identifier}
              // title={`${product.identifier}\n Удаление продукции: ${
              //   product.removeDate ? formatDate(product.removeDate) : ''
              // }`}
            >
              {catFileIdentifier(product.identifier, 1)}
            </div>
            {product.removeDate && (
              <div className={styles.removeInfo}>
                <ProductRemoveInfo removeDate={product.removeDate} />
              </div>
            )}
          </div>
          <div>
            {product.previewUrls && differenceInDays(product.removeDate, new Date()) >= 0 && (
              <ProductPreviewButtons
                identifier={product.identifier}
                previewUrls={product.previewUrls}
              />
            )}
          </div>
        </div>
        <div className={styles.productList}>
          {!removed &&
            product.files
              .sort((a, b) => compareFileNames(a.name, b.name))
              .map((file, index) => {
                return (
                  <ProductDownloadItem
                    key={`${product.id}_${index}`}
                    className={styles.productItem}
                    // title={file.name}
                    title={getFileNameTitle(file.name, index)}
                    hint={file.name}
                    url={file.url}
                    size={file.size}
                    removed={differenceInDays(product.removeDate, new Date()) >= 0}
                    getPresignUrl={getPresignUrl}
                    copyToClipboard={copyToClipboard}
                  />
                );
              })}
        </div>
      </div>
    ));
    return result;
  };

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerLabel}>
          <span>
            {products.length > 0 ? (
              <Trans>Готовая продукция</Trans>
            ) : (
              <Trans>Продукты в заявке</Trans>
            )}
          </span>
        </div>
        {products.length > 0 && (
          <Tooltip
            tagName="span"
            tipContentClassName="tip-content--border-grey tip-content--infoproduct"
            arrow={false}
            background="white"
            color="black"
            direction="bottom"
            mouseOutDelay={200}
            content={
              <Trans id="tooltips.download_all_description">
                После нажатия на кнопку в автоматическом режиме начнётся скачивания всех файлов
                этого заказа. Если браузер не настроен на автоматическое сохранение файлов GeoTIFF,
                то появится много диалоговых окон с вопросом, что делать с файлом (открыть или
                сохранить)? <br /> Настроить браузер для автоматического скачивания (без открытия
                диалогового окна) можно по{' '}
                <a
                  href={`${S3_URL}/geoportal-public/user-guide/v0.1/index.html`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ссылке
                </a>{' '}
                на документацию.
              </Trans>
            }
          >
            {!removed && (
              <div className={styles.downloadAllActionLabel} onClick={handleDownloadAll}>
                <Trans>Скачать всё</Trans>
              </div>
            )}
          </Tooltip>
        )}
        {/*
        <div className={styles.headerActions}>
          <div>
            <FolderDownloadIcon />
          </div>
          <div>
            <span className={styles.actionLink}>Скачать все</span>
          </div>
        </div>
        */}
      </div>
      {isDownloadStarted && (
        <>
          <div className={styles.loaderContainer}>
            <div
              style={{
                transition: 'width 1s ease 0s',
                // width: `${this.state.loadProgress}%`,
                width: `${progress.progress}%`,
                height: 2, // 3
                backgroundColor: '#5AB8F1',
              }}
            />
          </div>
          <div className={styles.progressText}>
            <span>
              <Trans>
                Скачано файлов {progress.loaded} из {progress.total}
              </Trans>
            </span>
          </div>
        </>
      )}
      <div className={styles.bodyContainer}>
        {/* // Not emplemented */}
        {/* <span>В разработке</span> */}
        <CustomScrollBarContainer heightMax={`calc(100vh - 500px)`} color="#CEEAFB">
          {/* {mockProducts.files.map((item, index) => (
            <ProductDownloadItem
              key={index}
              className={styles.productItem}
              title={item.name}
              url={item.url}
              size={item.size}
            />
          ))} */}
          {products.length > 0
            ? renderProducts()
            : // <span>
              //   <Trans>Ваша продукция пока не готова</Trans>
              // </span>
              renderImages()}
        </CustomScrollBarContainer>
        {/* <ProductDownloadItem />  */}
      </div>
    </div>
  );
};

export default withI18n()(RequestProductsPanel);
