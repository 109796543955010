import { Trans } from '@lingui/macro';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import Accordion from '@geobank/components/src/common/Accordion/Accordion';
import CheckBox from 'components/common/forms/CheckBox/CheckBox';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import { RootState } from 'ducks';
import {
  getCurrentCartImages,
  getCurrentCartParams,
  getImageSourceName,
  updateCurrentCartParams,
} from 'ducks/order';
import { LicenseValue, UsageTimeValue } from 'locales/dicts/license';
import BytesPerPixelField from './fields/BytesPerPixelField';
import License from './fields/License';
import ProductCodeField from './fields/ProductCodeField';
import SrsNameField from './fields/SrsNameField';
import UsageTime from './fields/UsageTime';

import { filterOptions } from './helpers/fieldOptions';
import { getAvailableOptions } from './helpers/sourceMapping';

import styles from './OutputProductParameters.module.scss';

import TagsField from 'components/order/productParams/TagsField/TagsField';

const mapStateToProps = (state: RootState) => ({
  params: getCurrentCartParams(state),
  imageSourceName: getImageSourceName(state),
  items: getCurrentCartImages(state),
});
const mapDispatchToProps = {
  updateCurrentCartParamsRequest: updateCurrentCartParams.request,
};

interface OutputProductParametersProps {
  isProductParamsOpen: boolean;
  onProductParamsToggle: () => void;
}

class OutputProductParameters extends PureComponent<
  OutputProductParametersProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
> {
  private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    // @ts-ignore
    this.props.updateCurrentCartParamsRequest({ [fieldName]: value });
  };

  public handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // const fieldName = event.target.name;
    const value = event.target.value;
    const imageSourceName = this.props.imageSourceName || 'etris';

    const params = { ...this.props.params, productCode: value };
    const avOptions = getAvailableOptions(imageSourceName, params);

    if (avOptions.srsName.indexOf(params.srsName) === -1) {
      params.srsName = avOptions.srsName[0];
    }
    if (avOptions.bytesPerPixel.indexOf(params.bytesPerPixel) === -1) {
      params.bytesPerPixel = avOptions.bytesPerPixel[0];
    }
    if (avOptions.elevation.indexOf(params.elevation) === -1) {
      params.elevation = avOptions.elevation[0];
    }

    // this.props.updateCurrentCartParamsRequest({ productCode: value, elevation: false });
    this.props.updateCurrentCartParamsRequest(params);
  };

  // handleCertificationChange
  public handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const checked = event.target.checked;

    // { certification: true | false }
    this.props.updateCurrentCartParamsRequest({ [name]: checked });
  };

  public handleLicenseChange = (value: LicenseValue) => {
    this.props.updateCurrentCartParamsRequest({ license: value });
  };

  public handleUsageTimeChange = (value: UsageTimeValue) => {
    this.props.updateCurrentCartParamsRequest({ usageTime: value });
  };

  public handleTagsChange = (tags: string[]) => {
    this.props.updateCurrentCartParamsRequest({ tags });
  };

  public render() {
    const {
      params: { productCode, srsName, bytesPerPixel, elevation, license, usageTime, tags }, // certification
      isProductParamsOpen,
      imageSourceName,
      onProductParamsToggle,
    } = this.props;
    const isAist: boolean =
      this.props.items.filter(item => item.metadataIdentifier.split('.').includes('AVR')).length >
      0;
    const avOptions = getAvailableOptions(
      isAist ? 'bbp' : imageSourceName || 'etris',
      this.props.params
    );

    return (
      <div className={styles.root}>
        {/* <div>
          <span className={styles.output_product_parameters__header}>
          Параметры выдачи продукции
          </span>
        </div> */}
        <Accordion
          label={
            <span className={styles.paramsHeader}>
              <Trans>Параметры выдачи продукции</Trans>
            </span>
          }
          isOpen={isProductParamsOpen}
          onToggle={onProductParamsToggle}
          right={true}
        >
          <TagsField tags={tags} onChange={this.handleTagsChange} />
          {/* --- */}
          <div className={styles.paramTitle}>
            <span>
              <Trans>Информационный продукт</Trans>
              <Tooltip
                tagName="span"
                styles={{ marginLeft: '6px' }}
                tipContentClassName="tip-content--border-grey tip-content--infoproduct"
                arrow={false}
                background="white"
                color="black"
                direction="bottom"
                // content={i18n._(t`L1 - Нетрансформированный с RPC`)}
                content={
                  <Trans>
                    L1 - Снимок с координатной привязкой RPC, не трансформированный в
                    картографическую проекцию <br />
                    L2 - Снимок с координатной привязкой RPC, трансформированный в картографическую
                    проекцию
                  </Trans>
                }
              >
                <HelpIcon />
              </Tooltip>
            </span>
          </div>
          <div className={styles.paramField}>
            <ProductCodeField
              name="productCode"
              value={productCode}
              options={filterOptions('productCode', avOptions.productCode)}
              onChange={this.handleProductChange}
            />
          </div>
          <div className={styles.paramContainer}>
            <div className={styles.width_50}>
              <div className={styles.paramTitle}>
                <span>
                  <Trans>Система координат (проекция)</Trans>
                </span>
              </div>
            </div>
            <div className={styles.width_50}>
              <div className={styles.paramTitle}>
                <span title="Байт / пикс. кан. (GeoTIFF)">
                  <Trans>Байт / пикс. кан.</Trans> {/* (GeoTIFF) */}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.paramContainer}>
            <div className={styles.width_50}>
              <div className={styles.paramField}>
                <SrsNameField
                  name="srsName"
                  value={srsName}
                  options={filterOptions('srsName', avOptions.srsName)}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className={styles.width_50}>
              <div className={styles.paramField}>
                <BytesPerPixelField
                  name="bytesPerPixel"
                  value={bytesPerPixel}
                  options={filterOptions('bytesPerPixel', avOptions.bytesPerPixel)}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.paramField}>
              <CheckBox
                // name="elevation"
                value="elevation"
                checked={elevation}
                // disabled={productCode === 'L1'}
                disabled={avOptions.elevation.length === 1}
                onChange={this.handleCheckBoxChange}
              >
                <Trans id="order_image.value_elevation">Использовать ЦМР</Trans>
                <Tooltip
                  tagName="span"
                  styles={{ marginLeft: '4px' }}
                  tipContentClassName="tip-content--border-grey"
                  arrow={false}
                  background="white"
                  color="black"
                  direction="up"
                  content={
                    <Trans id="order_image.hint_elevation">Применять цифровую модель рельефа</Trans>
                  }
                >
                  <HelpIcon />
                </Tooltip>
              </CheckBox>
            </div>
            {/* <div className={styles.paramField}>
              <CheckBox
                // name="certification"
                value="certification"
                checked={certification}
                onChange={this.handleCheckBoxChange}
              >
                <Trans id="order_image.value_certification">Заказать сертификацию</Trans>
              </CheckBox>
            </div> */}

            <div style={{ fontSize: '14px' /* marginTop: '8px', cursor: 'default' */ }}>
              {/* <div>
                <span>Лицензия:</span>{' '}
                <span style={{ cursor: 'pointer', color: 'var(--main-secondary-color)' }}>
                  Групповая огранич. расширенная (i);
                </span>
              </div> */}

              <License
                style={{ marginBottom: '8px' }}
                // active="individually"
                active={license}
                // onSelect={() => {
                //   /* pass */
                // }}
                onSelect={this.handleLicenseChange}
              />

              <UsageTime
                // active="five"
                active={usageTime}
                onSelect={this.handleUsageTimeChange}
              />
            </div>
          </div>
        </Accordion>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutputProductParameters);
