// import { i18nMark } from '@lingui/react';

// type: string ~ fieldName?: string
export const createValidation = (
  // data - value, formData - all values
  fn: (data: any, formData: any) => boolean,
  errorMsg: string,
  type?: string
) => (data: any, formData: any) => {
  const result = fn(data, formData);
  return {
    cata: (branch: {
      right: (res: boolean, field?: string) => any;
      left: (errM: string, field?: string) => any;
    }) => (result ? branch.right(result, type) : branch.left(errorMsg, type)),
    type,
  };
};

/* const validateDateRequired = createValidation(
  a => a && a instanceof Date,
  i18nMark('Заполните дату съёмки')
  // 'acquisitionDateBefore'
);
// const createDateRequiredValidation = (fieldName: string) => {
//   createValidation(
//     form => form[fieldName] && form[fieldName] instanceof Date,
//     i18nMark('Заполните дату съёмки')
//     // 'acquisitionDateBefore'
//   );
// }
const validateInstrumentsRequired = createValidation(
  a => a && a.length > 0,
  i18nMark('Выберите съёмочное устройство')
);
// run after required
// const createDateValidation => (date1, date2) => ...
const validateDateBefore = createValidation(
  (_, formData) =>
    !formData.acquisitionDateAfter || !formData.acquisitionDateBefore
      ? true
      : formData.acquisitionDateAfter <= formData.acquisitionDateBefore,
  i18nMark('Дата окончания съёмки должна быть больше даты начала')
); */
// const validateFunctions = [validateDateRequired];

// const formValues = {
// const validationSchema =
/* const validationFunctions = {
  // email: createEmail('foo@'),
  acquisitionDateAfter: [validateDateRequired],
  // acquisitionDateAfter: [createDateRequiredValidation],
  acquisitionDateBefore: [validateDateRequired, validateDateBefore],
  instrumentIdentifiers: [validateInstrumentsRequired],
}; */

export const validateFormValues = (
  values: { [key: string]: any },
  validationFns: { [key: string]: ReturnType<typeof createValidation>[] }
) => {
  const _errors: any = {};
  for (const [key, vFns] of Object.entries(validationFns)) {
    const validateResults = vFns
      .map(validate =>
        // validateCreator(key)(values).cata({
        validate(values[key], values).cata({
          left: (e: any) => e,
          right: (a: any) => a,
        })
      )
      .filter(r => r !== true);
    if (validateResults.length > 0) {
      _errors[key] = validateResults;
    }
  }
  return _errors;
};
// Object.keys(validationFns).map(key => ({
//   [key]: validationFns[key].map((validate: ReturnType<typeof createValidation>) =>
//     validate(values[key]).cata({
//       left: (e: any) => e,
//       right: (a: any) => a,
//     })
//   ),
// }));

// const errors = validateFormValues(formState, validationFunctions);
