import { i18nMark } from '@lingui/react';
// import { t } from '@lingui/macro';

export const messages: { [x: string]: string } = {
  //
  'Задание создано': i18nMark('Задание создано'),
  //
  'Метаданные успешно выгружены': i18nMark('Метаданные успешно выгружены'), // t`msg`
  'Ошибка подготовки набора данных': i18nMark('Ошибка подготовки набора данных'),
  'Ошибка публикации zip-архива с метаданными': i18nMark(
    'Ошибка публикации zip-архива с метаданными'
  ),
  'Ошибка создания шейп-файла покрытия': i18nMark('Ошибка создания шейп-файла покрытия'),
};
