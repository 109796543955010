export function getLanguageFromLocalStorage() {
  return localStorage.getItem('language');
}

export function setLanguageToLocalStorage(lang: string) {
  localStorage.setItem('language', lang);
}

export function removeLanguageFromLocalStorage() {
  localStorage.removeItem('language');
}

// AuthTokensBundle
interface TokensBundle {
  accessToken: string | null;
  refreshToken: string | null;
}

// auth tokens in local storage
export function getTokensFromLocalStorage(): TokensBundle {
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  return { accessToken, refreshToken };
}
export function setTokensToLocalStorage({ accessToken, refreshToken }: TokensBundle) {
  if (accessToken && refreshToken) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  }
}
export function removeTokensFromLocalStorage() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}
