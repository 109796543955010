import React, { PureComponent } from 'react';
import classNames from 'classnames';
// import AddAlert from '@material-ui/icons/AddAlert';
// import Snackbars from 'components/common/dashboard/Snackbar/Snackbar.jsx';
import get from 'lodash/get';
import { Trans } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';

import { Message } from 'ducks/message';
import styles from './NotifyMessage.module.scss';
// import CrossIcon from '@geobank/assets/src/icons/CrossIcon';

interface NotifyMessageProps {
  message: Message;
  // isOpen?: boolean;
  onClose: (msgId: string) => void;
}

class NotifyMessage extends PureComponent<NotifyMessageProps> {
  state = {
    isOpen: false,
  };

  timerId?: NodeJS.Timeout;

  componentDidMount() {
    const { message, onClose } = this.props;
    const timeout = message.timeout || 10000;

    this.setState({ isOpen: true });
    // use this to make the notification autoclose
    if (timeout !== -1) {
      this.timerId = setTimeout(() => {
        this.setState({ isOpen: false });
        onClose(message.id!);
      }, timeout);
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  onClose = (msgId: string) => {
    this.setState({ isOpen: false });
    this.props.onClose(msgId);
  };

  getContent = (data: string | JSX.Element | MessageDescriptor) => {
    let content = null;
    // TODO - string временно, всё с переводом переводим на MessageDescriptor (t`qq` ~ {id: 'qq'})
    if (typeof data === 'string' || get(data, 'id')) {
      content = <Trans id={data as string} />; // string || MessageDescriptor ({id: string})
    } else {
      // else if React.isValidElement(message)
      content = data; // JSX.Element
    }
    return content;
  };

  render() {
    const { isOpen } = this.state;
    const {
      message: { id, color, message },
    } = this.props; // place = 'tc', color = 'info',
    // console.log(React.isValidElement('dsad')); // false
    // console.log(React.isValidElement(message)); // true
    // message.$$typeof === Symbol.for('react.element'); // true
    // debugger;

    return (
      // <div className={styles.root}>
      <>
        {isOpen && (
          <div
            className={classNames(styles.container, styles[color || 'info'])}
            onClick={this.onClose.bind(null, id!)}
            role="alert"
          >
            <span className={styles.message}>
              {/* <Trans id={message} /> */}
              {/* <Trans id={message as string} /> */}
              {/* <Trans id={(message as MessageDescriptor).id} /> */}
              {this.getContent(message)}
            </span>
          </div>
        )}
      </>
      // </div>
    );
  }
}

export default NotifyMessage;
