import * as React from 'react';
import * as _ from 'lodash';
import styles from './MyRequests.css';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import RequestTable, { RequestData } from './components/RequestTable/RequestTable';
import mockRequestData from './MockRequestData';
import RequestInfoPanel from './components/RequestInfoPanel/RequestInfoPanel';
import RequestManagement from './components/RequestManagement/RequestManagement';
import AreaInfoPanel from './components/AreaInfoPanel/AreaInfoPanel';
import FinishedProducts from './components/FinishedProduct/FinishedProducts';
import mockFinishedProducts from './components/FinishedProduct/FinishedProductsMock';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';

interface MyRequestsProps {
  value: RequestData[];
}

interface MyRequestsState {
  requestDataPanel: IRequestInfo;
  isNeedInfoPanel: IsNeedInfo;
}

export interface IRequestInfo {
  label?: string;
  id?: string;
  status?: string;
  dateStart?: string;
  dateEnd?: string;
  actExtradition?: string;
  comment?: string;
}

export interface IsNeedInfo {
  flag: boolean;
  index: number;
}

export default class MyRequests extends React.Component<MyRequestsProps, MyRequestsState> {
  constructor({ props }: { props: MyRequestsProps }) {
    super(props);
    this.state = {
      requestDataPanel: {},
      isNeedInfoPanel: { flag: false, index: 0 },
    };
  }

  private readonly showRequestInfoPanel = (index: number) => {
    this.setState({
      isNeedInfoPanel: {
        flag: !this.state.isNeedInfoPanel.flag || this.state.isNeedInfoPanel.index !== index,
        index,
      },
      requestDataPanel: {},
    });
  };

  private readonly closeRequestInfoPanel = () => {
    this.setState({ requestDataPanel: {} });
  };

  private readonly setRequestData = (index: number) => {
    this.setState({ requestDataPanel: mockRequestData[index] });
  };

  private readonly isNeedInfoPanel = () => {
    const { requestDataPanel, isNeedInfoPanel } = this.state;
    return !_.isEmpty(requestDataPanel) && !isNeedInfoPanel.flag;
  };

  public render() {
    let marginTop = 0;
    const element = document
      .getElementsByClassName('request_table_row')
      .item(this.state.isNeedInfoPanel.index);
    if (element !== null) {
      marginTop = element.getBoundingClientRect().top - 50 - 60;
    }
    const { value } = this.props;

    return (
      <div className={styles.my_request}>
        <div className={styles.my_request__container}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ justifySelf: 'flex-start' }}>
              <h3>Актуальные заявки</h3>
            </div>
            <div style={{ justifySelf: 'flex-end', display: 'flex' }}>
              <div>
                <span>Фильтр по заявкам</span>
              </div>
            </div>
          </div>
          <div>
            <CustomScrollBarContainer heightMin="530px" heightMax="40vh" color="#CEEAFB">
              <RequestTable
                isNeedInfoPanel={this.state.isNeedInfoPanel}
                requestsData={value}
                showRequestInfoPanel={this.showRequestInfoPanel}
                closeRequestInfoPanel={this.closeRequestInfoPanel}
                setRequestData={this.setRequestData}
                requestDataPanel={this.state.requestDataPanel}
              />
            </CustomScrollBarContainer>
          </div>
        </div>
        <div style={{ marginLeft: 10, marginTop: 20 }}>
          {this.isNeedInfoPanel() && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={styles.request_info_panel__component__container}>
                <RequestInfoPanel requestInfo={this.state.requestDataPanel} />
              </div>
              <div style={{ marginTop: 20, minWidth: '400px' }}>
                <AreaInfoPanel />
              </div>
              <div style={{ marginTop: 20, minWidth: '400px' }}>
                <FinishedProducts value={mockFinishedProducts} />
              </div>
            </div>
          )}
        </div>
        <div style={{ marginLeft: 10, pointerEvents: 'none', marginTop: 20 }}>
          {this.isNeedInfoPanel() && (
            <div className={styles.request_info_panel__component__close_button}>
              <IconButton onClick={this.closeRequestInfoPanel}>
                <CrossIcon />
              </IconButton>
            </div>
          )}
          <div style={{ marginTop: marginTop }}>
            {this.state.isNeedInfoPanel.flag && <RequestManagement />}
          </div>
        </div>
      </div>
    );
  }
}
