// RequestProductPanel

import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';

import { RootState } from 'ducks';
import { getInstrumentsById, getPlatformTypesById } from 'ducks/classifier';
import { instruments } from 'locales/dicts/classifiers';
import { notifyActions } from 'ducks/message';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';

import ProductParametersForm from './ProductParametersForm';
import SpectralChannelsField from './fields/SpectralChannelsField';
// import cutIcon from './icons/cutIcon';

import styles from './RequestProductPanel.module.scss';

// TODO - calc band ids
// const SENSOR_TYPE_BAND_LIMITATION: any = {
//   GTNL1: [
//     {
//       sensors: {
//         'Многоспектральная съемка': [0.8, 0.9],
//         'Панхроматическая съемка': [0.58, 0.8],
//       },
//       error_msg: 'СППИ "Сангур-1У" 0.58-0.8 и 0.8-0.9',
//     },
//   ],
// };
const BANDS_LIMITATIONS: any = {
  // instrument: 'GTNL1',
  // limitations: [
  GTNL1: [
    {
      bands: [
        [0.8, 0.9], // 1
        [0.58, 0.8], // 79
      ], // pair
      errorMsg: <Trans>СППИ "Сангур-1У" 0.58-0.8 и 0.8-0.9</Trans>,
    },
  ],
};

const mapStateToProps = (state: RootState) => ({
  instruments: getInstrumentsById(state),
  platformTypes: getPlatformTypesById(state),
});
const mapDispatchToProps = {
  // updateCurrentCartParamsRequest: updateCurrentCartParams.request,
  pushMessage: notifyActions.push,
};

interface RequestProductPanelProps {
  orderInstruments: string[];
  onSubmit: (params: any) => void;
  onCancel: () => void;
  // price?: string;
  // imageURL?: string[];
  // cancelButtonHandler?: () => void;
  // addToCartButtonHandler?: () => void;
}

// interface RequestProductState {
//   formData: { key: string; value: string | number };
// }

// NOTE - в зависимости от instrument будут меняться доступные значения по умолчанию (битность обыграл через `-`)
const formDataDefaultState = {
  productCode: 'L2',
  srsName: '4326',
  bytesPerPixel: '-', // as string | number, // 1
};

const RequestProductPanel: React.FC<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RequestProductPanelProps
> = props => {
  // console.log(props);
  // current instrument
  const instr = props.instruments[props.orderInstruments[0]];
  const plType = props.platformTypes[instr.platformType];

  // <{ [key: string]: string | number }>
  const [formData, setFormData] = useState(formDataDefaultState);
  const [bands, setBands] = useState<string[]>([]); // useState<number[]>([]);

  const cancelButtonHandler = () => {
    props.onCancel();
  };

  const saveRequestHandler = () => {
    // 0. Валидация
    // заполнить спектральные каналы
    if (bands.length === 0) {
      props.pushMessage({
        message: (
          <span>
            <Trans>Проверьте параметры заявки:</Trans>
            <br />- <Trans>Выберите спектральные диапазоны</Trans>
          </span>
        ),
        color: 'alert',
        timeout: 4000,
      });
      return;
    }
    // Недопустимая комбинация диапазонов: ...
    // const instr: string = get(this.formRef.current?.state, 'instrumentIdentifiers[0]');
    const limitations = BANDS_LIMITATIONS[instr.identifier];
    if (limitations) {
      for (const limitation of limitations) {
        // pass
        // const intersection = limitation.bands.filter((b: number) => bands.includes(String(b)));
        // limitationBandNames
        const intersection = limitation.bands
          .map((b: number[]) => `${b[0]}_${b[1]}`)
          .filter((c: string) => bands.includes(c));
        if (intersection.length === limitation.bands.length) {
          // alert(limitation.errorMsg);
          props.pushMessage({
            message: (
              <span>
                <Trans>Проверьте параметры заявки:</Trans>
                <br />- <Trans>Недопустимая комбинация диапазонов</Trans> ({limitation.errorMsg})
              </span>
            ),
            color: 'alert',
            timeout: 6000,
          });
          return;
        }
      }
    }

    // 1. подготовить массивы bands по сенсорам (по хорошему не заполнять, если выбран весь сенсор)
    // сенсоры понадобятся в п. 2
    const bandsBySensorId: { [key: number]: string[] } = {};
    instr.sensors.forEach(sensor => {
      // bandNames
      const sensorBandIds = sensor.bands.map(b => ({ name: `${b.min}_${b.max}`, id: b.id })); // .map(b => String(b.id));
      const intersection = sensorBandIds.filter(x => bands.includes(x.name));
      if (intersection.length > 0) {
        bandsBySensorId[sensor.id] = intersection.map(z => String(z.id)); // = intersection;
      }
    });
    // console.log(bandsBySensorId);

    // 2. вычислить наиболее подходящие уровни обработки
    const plcsBySensorId: {
      [key: number]: { plcId: number; srsId?: number; formatId?: number; bitsPerPixel?: number };
    } = {};
    // Object.keys(bandsBySensorId).forEach(sensorId => { })
    instr.sensors.forEach(sensor => {
      if (bandsBySensorId[sensor.id]) {
        // formData.productCode
        const plc = [...sensor.plcs]
          .reverse()
          .find(p => p.codeNative.startsWith(formData.productCode.replace('L', '')));
        // console.log('plc', plc);
        plcsBySensorId[sensor.id] = { plcId: plc!.id };

        if (formData.productCode !== 'L1') {
          const srs = plc!.srss.find(s => s.srid === Number(formData.srsName));
          plcsBySensorId[sensor.id].srsId = srs!.srid;
        }

        // calc format
        const formatName = formData.productCode === 'L1' ? 'TIFF' : 'GeoTIFF';
        const format = plc!.formats.find(f => f.shortName === formatName);
        plcsBySensorId[sensor.id].formatId = format!.id;

        // calc bitsPerPixel
        if (Number.isNaN(parseInt(formData.bytesPerPixel, 10)) !== true) {
          plcsBySensorId[sensor.id].bitsPerPixel = Number(formData.bytesPerPixel) * 8; // : null;
        }

        // plcsBySensorId[sensor.id] = { plcId: plc!.id, srsId: srs!.srid };
      }
    });
    // console.log(plcsBySensorId);
    // requestRegions
    // const regionSensors = {
    const requestRegions = [
      {
        platformTypeId: plType.id,
        sensors: Object.keys(bandsBySensorId).map(sId => {
          const sensorId = Number(sId);
          return {
            sensorId: sensorId,
            bands: bandsBySensorId[sensorId].map(bId => ({
              bandId: Number(bId),
            })),
            plcs: plcsBySensorId[sensorId],
          };
        }),
      },
    ];
    // console.log('requestRegion', requestRegion);

    props.onSubmit(requestRegions);
  };

  return (
    <div className={styles.root}>
      <div className={classNames(styles.header, styles.marginBottom)}>
        <Trans>Заявка на новую съёмку</Trans>
      </div>

      {/* TODO - объединить формы ? */}
      {/* description */}
      <div className={classNames(styles.marginBottom)}>
        {/* Съёмочная аппаратура:<br /> СППИ "Сангур-1У" х // instr.name */}
        <span>
          <Trans>Съёмочная аппаратура</Trans>: <Trans id={instruments[instr.identifier]} />
        </span>
      </div>

      <div className={classNames(styles.content /* , styles.marginBottom */)}>
        {/* <Trans>Параметры выдачи продукции</Trans> */}
        <ProductParametersForm
          data={formData}
          onChange={setFormData}
          instrument={instr.identifier}
        />
      </div>

      <div className={classNames(styles.content, styles.marginBottom)}>
        <div className={styles.paramTitle}>
          <span>
            <Trans>Спектральные каналы</Trans>
          </span>
        </div>
        <div /* className={styles.paramField} */>
          <SpectralChannelsField sensors={instr.sensors} checked={bands} onChange={setBands} />
        </div>
        {/* <div>
          {instr.sensors.map(sensor =>
            sensor.bands.map(band => (
              <>
                <div>
                  {band.min} - {band.max} ({band.description})
                </div>
              </>
            ))
          )}
        </div> */}
      </div>

      <div className={classNames(styles.actionsContainer)}>
        <Button color={ButtonColor.WHITE} width={'150px'} onClick={cancelButtonHandler}>
          <Trans>Отменить</Trans>
        </Button>
        <Button color={ButtonColor.DEFAULT} width={'150px'} onClick={saveRequestHandler}>
          <Trans>Оформить заказ</Trans>
        </Button>
        {/* <Button color={ButtonColor.DEFAULT} width={'150px'} onClick={addToCartButtonHandler}>
          <Trans>Далее </Trans>
        </Button> */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestProductPanel);
