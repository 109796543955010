import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CartIcon from '@geobank/assets/src/icons/CartIcon';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import {
  fetchCurrentCart,
  updateCurrentCartImages,
  createCartRequest,
  getCurrentCartImages /* , getSimpleRouteCartCount */,
  getCurrentCartPrice,
  CartImage,
  clearCurrentCartRequest,
} from 'ducks/order';
import SimpleCart from '../SimpleCart/SimpleCart';

const styles = require('./CartButton-styles.module.scss');

const CartButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProductParamsOpen, setIsProductParamsOpen] = useState(true);
  const dispatch = useDispatch();
  const cartItems = useSelector(getCurrentCartImages);
  const totalPrice = useSelector(getCurrentCartPrice);

  useEffect(() => {
    if (cartItems.length === 0) {
      dispatch(fetchCurrentCart.request());
    }
    // eslint-disable-next-line
  }, []);

  const onRemove = (item: CartImage) => {
    dispatch(updateCurrentCartImages.request(item, 'remove'));
  };

  const handleSaveRequest = () => {
    dispatch(createCartRequest.request());
    setIsOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsOpen(!isOpen)}>
        <IconButton
          backgroundColor={isOpen ? '#6AC3FA' : 'white'} /* onClick={() => setIsOpen(!isOpen)} */
          borderRadius="unset"
          height="45px"
        >
          <CartIcon fill={isOpen ? 'white' : undefined} />
        </IconButton>
        {cartItems.length > 0 && (
          <div className={styles.counterWrap}>
            <span className={styles.counterValue}>{cartItems.length}</span>
          </div>
        )}
      </div>
      <SimpleCart
        isOpen={isOpen}
        items={cartItems}
        totalPrice={totalPrice}
        onRemove={onRemove}
        onClose={() => setIsOpen(false)}
        onSaveRequest={handleSaveRequest}
        isProductParamsOpen={isProductParamsOpen}
        onProductParamsToggle={() => setIsProductParamsOpen(!isProductParamsOpen)}
        onClearRequest={() => dispatch(clearCurrentCartRequest())}
      />
    </>
  );
};

export default CartButton;
