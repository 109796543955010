import { i18nMark } from '@lingui/react';
import { ActionType, getType } from 'deox';
import { all, call, put, select, takeLatest } from 'redux-saga/effects'; // debounce, fork, delay, takeEvery, take
// import formatISO from 'date-fns/formatISO';

// import { iamActions, getIsAuthenticated } from 'ducks/authIAM';
import API from 'api';
import { parseApiObject, serializeTaskParams } from 'components/utils/apiDataConverter';
import { getProfile } from 'ducks/authIAM';
import { notifyActions } from 'ducks/message'; // 'ducks/notifyMessage';
import { createTask, MetadataDownloadTask } from 'ducks/metadataDownload';
import { requestFlowNew } from './network';

import { Debugger } from 'utils/logging';
const debug = Debugger('MdDownloadSaga'); // TAG

// const userId = '4e3a4dc7-1c60-5b3b-bcfa-912e0acf4b72'; // customer_ext_niitp
const userId = undefined;

export function* createTaskWatch() {
  yield takeLatest(getType(createTask.request), createTaskFlow);
}
export function* createTaskFlow(action: ActionType<typeof createTask.request>) {
  try {
    const user: ReturnType<typeof getProfile> = yield select(getProfile);
    const params: MetadataDownloadTask = {
      ...action.payload, // metadataIds: number[];
      orderDate: new Date().toISOString(), // formatISO(new Date()),
      userUuid: userId || user.id,
      systemSegment: 'external', // 'internal' | 'external';
      addCoverageShape: true, // TODO - metadata_ids.length > 1 ? true : false,
      addQuicklook: true,
      addMetadataProfile: true,
    };
    const paramsSerialized = serializeTaskParams(params);

    // {"message": "ok", "data": {"order_date": "2020-05-23T15:27:46+03:00", "task_uuid": "99d829e2-8122-4c41-80f6-8f3c721fcc24"}, "success": true}
    const respData = yield call(requestFlowNew, API.createMetadataDownloadTask, paramsSerialized);
    debug.log('Create metadata download task response data', respData);
    const parsedData = parseApiObject(respData);

    const createdTask: MetadataDownloadTask = { ...params, taskUuid: parsedData.taskUuid };
    yield put(createTask.success(createdTask));
    yield put(
      notifyActions.push({
        // id: messageId,
        message: i18nMark('Задание успешно создано'), // t`Идет поиск снимков ...`,
        place: 'bc',
      })
    );
  } catch (error) {
    debug.error(error);
    yield put(createTask.failure(error));
    yield put(
      notifyActions.push({
        // id: messageId,
        message: i18nMark('Ошибка при создании задания'), // t`Идет поиск снимков ...`,
        place: 'bc',
      })
    );
  }
}

export default function* orderRoot() {
  yield all([createTaskWatch()]);
}
