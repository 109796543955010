import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// import App from './components/App/App';
import AppRouter from './components/AppRouter';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { APP_NAME, APP_VERSION, SENTRY_DSN, USE_FAKE_API } from 'api/realAPI';
import { Provider } from 'react-redux';
import { createStore } from './create-store';

import I18nLoader from 'components/AppRouter/I18nLoader';
import NotifyPortal from 'components/NotifyMessage/NotifyPortal';
// import { I18nProvider } from '@lingui/react';
// import catalogEn from './locales/en/messages.js';
// const catalogs = { en: catalogEn };

import * as Sentry from '@sentry/react';

// TODO - https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `${APP_NAME}@${APP_VERSION}`,
    environment: `${process.env.NODE_ENV || 'notset'}`, // production, development, stage - TODO
    beforeBreadcrumb(breadcrumb, hint) {
      // console.log(breadcrumb);
      // filter static requests
      /* if (breadcrumb.category === 'fetch' && breadcrumb.data && breadcrumb.data.url.search('/static/') !== -1) { return null; } */
      // filter yandex metrica
      if (
        breadcrumb.category === 'xhr' &&
        breadcrumb.data &&
        breadcrumb.data.url.search('https://mc.yandex.ru/') !== -1 // https://mc.yandex.ru/webvisor/, https://mc.yandex.ru/watch/
      ) {
        return null;
      } else {
        return breadcrumb;
      }
    },
  });
}

export const store = createStore();

const appRoot = document.getElementById('app-root');
const notifyRoot = document.getElementById('notify-root');

ReactDOM.render(
  <Provider store={store}>
    {/* <I18nProvider language="ru" catalogs={catalogs}> */}
    <I18nLoader>
      <BrowserRouter>
        <AppRouter />
        <NotifyPortal root={notifyRoot} />
      </BrowserRouter>
    </I18nLoader>
    {/* </I18nProvider> */}
  </Provider>,
  appRoot
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

/**
 * Init my service worker
 * example: fetch('/api/auth/authenticate.ext-json', {method: 'POST', headers: {'Content-Type':'application/x-www-form-urlencoded'}, body: 'username=user3&password=123'}).then(resp => resp.json()).then(data => console.log(data))
 * TODO: see https://medium.com/free-code-camp/how-to-customize-service-workers-with-create-react-app-4424dda6210c
 */
// Check that service workers are supported
if ('serviceWorker' in navigator && USE_FAKE_API === 'yes') {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker-custom.js') // /fake_api/service-worker.js, { scope: '/' }; scope - отвечает за то, на каких страницах будет запускаться на клиенте, а не по каким ajax урлам будет срабатывать!!! (Service-Worker-Allowed: /)
      .then(reg => {
        // регистрация сработала
        console.log('Registration succeeded. Scope is ' + reg.scope);
      })
      .catch(error => {
        // регистрация прошла неудачно
        console.log('Registration failed with ' + error);
      });
  });
} else {
  serviceWorker.unregister();
}
