import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { CartRequest, findCartRequests } from 'ducks/order';

import styles from './PaymentStyles.module.scss';

const PaymentFailPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<CartRequest | null>(null);
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');

  useEffect(() => {
    if (orderId) {
      dispatch(
        findCartRequests.request({
          orderId,
          callback: (result: any) => {
            if (result.results.length === 1) {
              setOrder(result.results[0]);
            }
            setIsLoading(false);
          },
        })
      );
    }
  }, [dispatch, orderId]);

  // {/* заказ vs заявка */}
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, styles.containerFail)}>
        <div className={styles.headTitle}>
          <Trans>Упсс, что-то пошло не так!</Trans>
        </div>

        <div className={styles.descriptionText}>
          <p>
            <Trans>
              Ваш заказ успешно оформлен. Но произошла ошибка при оплате заказа. <br />
              Выполнить оплату повторно можно из{' '}
              <Link to="/account/orders" className={styles.link}>
                <span>Личного кабинета</span>
              </Link>
            </Trans>
          </p>
        </div>

        {isLoading || !order ? (
          <div style={{ marginBottom: '16px' }}>
            <Trans>Загрузка информации по заказу...</Trans>
          </div>
        ) : (
          <dl className={styles.orderInfo}>
            <dt>
              <Trans>Номер заказа</Trans>:
            </dt>
            <dd>№{order.number}</dd>
            <dt>
              <Trans>Сумма заказа</Trans>:
            </dt>
            <dd>{order.totalPrice} RUB</dd>
          </dl>
        )}

        <div className={styles.delimeter} />

        <div className={styles.footer}>
          <p>
            <Trans>
              <Link to="/" className={styles.link}>
                Продолжить работу
              </Link>{' '}
              с Геопорталом
            </Trans>
          </p>
          {/* <Button>Перейти</Button> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentFailPage;
