import { BASE_URL } from 'api/realAPI';
import { Control } from 'ol/control';

class BrightnessTemperatureControl extends Control {
  constructor(optOptions: any) {
    const options = optOptions || {};

    const brightnessTemperature = document.createElement('img');
    brightnessTemperature.setAttribute('id', 'brightnessTemperature');
    brightnessTemperature.setAttribute('src', `${BASE_URL}/btScale.png`);
    brightnessTemperature.className = 'ol-brightness-temperature';

    super({
      element: brightnessTemperature,
      target: options.target,
    });
  }
}

export default BrightnessTemperatureControl;
