import * as Sentry from '@sentry/react';
import { configureStore } from 'deox';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './ducks/root';
import { rootSaga } from './sagas/root';

export function createStore() {
  const sagaMiddleware = createSagaMiddleware({
    // перехват ошибок, неотловленные в сагах - это критичная ситуация, т.к. ломаются все саги!
    onError: (err, errInfo) => {
      // console.log('sagaMiddleware onError', err, errInfo);
      // вместо console.log в месте ошибки можно выводить так stack trace через Breadcrumb
      // Sentry.addBreadcrumb({
      //   category: 'error',
      //   data: { error: err },
      // });
      Sentry.captureException(err, {
        // fingerprint: ['finger1', 'finger2'], // не понял, как это работает
        // tags: { tag1: 'tagvalue1', tag2: 'tagvalue2' }, // теги в верхней части экрана
        // extra: { extrakey1: 'extraval1' }, // попадают в секцию "Additional Data"
        contexts: {
          // именованные секции, похоже на extra
          TraceContext: { ErrorInfo: errInfo }, // errInfo.sagaStack, errStack: err.stack
        },
      });
    },
  });

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
