import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'; // , put
import { getActiveItem, getItems, getStartTime } from './../ducks/stacItems';

import GeoportalMap from 'components/map/Map/Map';
import {
  animateItems,
  changeActiveItem,
  changeStartTime,
  // getItems,
  fetchDates,
  fetchItems,
  getAnimateItems,
} from 'ducks/stacItems';

import { getImageLayersById, getSelectedImageLayerId } from 'ducks/mapLayers';

import * as stacItemsTypes from 'ducks/types/stacItemsTypes';
// import groupBy from 'lodash/groupBy';
import { groupBy } from 'lodash';

import API from 'api';

import { Debugger } from 'utils/logging';

const debug = Debugger('MapSaga'); // TAG

interface CollectionParams {
  collection: string;
  instrument: string;
  processingLevelCode: string;
}

const collectionToInstrumentMap: { [key: string]: CollectionParams[] } = {
  globalMSUGS: [
    { collection: 'roscosmos-opendata.EL_ARCM', instrument: 'MSU-GS', processingLevelCode: 'L3' },
  ],
  btMSUGS: [
    { collection: 'roscosmos-opendata.EL_ARCM', instrument: 'MSU-GS', processingLevelCode: 'L3' },
  ],
  globalMSUMR: [
    { collection: 'roscosmos-opendata.MM', instrument: 'MSU-MR', processingLevelCode: 'L3' },
  ],
  regionalMSUGS: [
    {
      collection: 'roscosmos-opendata.Arctic-M',
      instrument: 'MSU-GS-A',
      processingLevelCode: 'L2',
    },
    {
      collection: 'roscosmos-opendata.Electro-L',
      instrument: 'MSU-GS',
      processingLevelCode: 'L2',
    },
  ],
};

const findNearestIndex = (targetTime: string, dates: Date[]) => {
  const targetDate =
    targetTime !== ''
      ? new Date(`${dates[0].toISOString().substring(0, 11)}${targetTime}`)
      : dates[0];
  const diffArray = dates.map(date => Math.abs(targetDate.getTime() - date.getTime()));
  const closestDateIndex = diffArray.indexOf(Math.min(...diffArray));
  return closestDateIndex;
};

export function* fetchItemsDatesFlow(action: ActionType<typeof fetchDates.request>) {
  const currentDate = action.payload ? action.payload : new Date();
  const selectedImageLayerId: string = yield select(getSelectedImageLayerId);
  const year = `${currentDate.getFullYear()}`;
  const month = `${currentDate.getMonth() + 1}`.padStart(2, '0');
  const collectionsParams = collectionToInstrumentMap[selectedImageLayerId]
    .map(col => `${col.collection}.${col.instrument}.${col.processingLevelCode}.${year}.${month}`)
    .join(',');
  // console.log(76, currentDate.getFullYear(), `${currentDate.getMonth() + 1}`.padStart(2, '0'))
  //  console.log(77, 'fetchDate', action.payload, '\n' ,new Date(), '\n', collectionsParams, '\n',selectedImageLayerId, '\n', collectionToInstrumentMap)

  try {
    const response: ReturnType<typeof API.getSTACDates> = yield call(
      API.getSTACDates,
      collectionsParams
    );
    // @ts-ignore
    // const collections: stacItemsTypes.STACCollection[] = response.data.features;
    const collections: any[] = response.data.collections;
    const collectionDates = collections.map(collection => collection.title.replace(/\./g, '-'));
    // console.log(88, collectionDates, collections)
    // const setOfDates = Array.from(new Set(collectionDates));
    yield put(fetchDates.success(collectionDates));
    yield call(fetchItems.request, collectionDates[collectionDates.length - 1]);
  } catch (error) {
    yield put(fetchDates.failure(error));
  }
}

type STACItems = ReturnType<typeof API.getSTACItems>;

export function* fetchItemsFlow(action: ActionType<typeof fetchItems.request>) {
  const currentDate = action.payload ? new Date(action.payload) : new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const startLocaleDate = new Date(year, month, day);
  const endLocaleDate = new Date(startLocaleDate);
  endLocaleDate.setMinutes(endLocaleDate.getMinutes() + 1439);
  const selectedImageLayerId: string = yield select(getSelectedImageLayerId);
  const dateRange: string = `${startLocaleDate
    .toISOString()
    .slice(0, 19)}/${endLocaleDate.toISOString().slice(0, 19)}`;

  const collectionsParam = collectionToInstrumentMap[selectedImageLayerId]
    .map(col => col.collection)
    .join(',');
  const params = {
    dateRange: dateRange,
    collections: collectionsParam,
  };
  try {
    const response: STACItems = yield call(API.getSTACItems, params);
    // console.log(122, response)
    // @ts-ignore
    const features: stacItemsTypes.STACFeature[] = response.data.features;
    const groupedItems = groupBy(features, feature => {
      const localeDate = new Date(feature.properties.datetime);
      localeDate.setMinutes(localeDate.getMinutes() - timezoneOffset);
      return localeDate;
    });
    const items = Object.entries(groupedItems).map(([key, value]) => ({
      [key]: value.map(({ properties, assets }) => ({ properties, assets })),
    }));
    yield put(fetchItems.success(items));
  } catch (error) {
    yield put(fetchItems.failure(error));
  }
}

export function* setActiveItemFlow(action: ActionType<typeof fetchItems.success>) {
  try {
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    const startTime: string = yield select(getStartTime);
    if (items.length > 0) {
      const nearestIndex = findNearestIndex(
        startTime,
        items.map(item => new Date(Object.keys(item)[0]))
      );
      yield put(changeActiveItem(nearestIndex));
    } else {
      yield put(changeActiveItem(0));
    }
  } catch (error) {
    debug.error(error);
  }
}

type ImageLayers = ReturnType<typeof getImageLayersById>;

export function* changeActiveItemFlow(action: ActionType<typeof changeActiveItem>) {
  try {
    const activeItem: number = action.payload;
    const imageLayers: ImageLayers = yield select(getImageLayersById);
    const selectedImageLayerId: string = yield select(getSelectedImageLayerId);
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    // console.log('STACK_ITEMS_SAGA', activeItem, imageLayers, selectedImageLayerId, items)
    if (items.length > 0) {
      const previousUrl = new URL(imageLayers[selectedImageLayerId].config.options.url);
      previousUrl.searchParams.set(
        'datetime',
        new Date(Object.keys(items[activeItem])[0]).toISOString().substring(0, 16)
      );
      const map = GeoportalMap.getInstance();
      // yield call([map, 'updateImageLayer'], imageLayers[selectedImageLayerId], decodeURI(previousUrl.href));
      yield call(
        [map, 'updateImageLayer'],
        imageLayers[selectedImageLayerId],
        new Date(Object.keys(items[activeItem])[0]).toISOString().substring(0, 16)
      );
    }
    // yield put(changeStartTime(Object.keys(currentItem)[0].slice(16, 24)));
  } catch (error) {
    debug.error(error);
  }
}

export function* changeStartTimeFlow(action: ActionType<typeof changeStartTime.request>) {
  try {
    const activeItem: number = yield select(getActiveItem);
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    if (items.length > 0) {
      const newStartTime: string = Object.keys(items[activeItem])[0].slice(16, 24);
      yield put(changeStartTime.success(newStartTime));
    }
  } catch (error) {
    debug.error(error);
  }
}

function* animateItemsFlow() {
  const currentAnimateState: stacItemsTypes.AnimateState = yield select(getAnimateItems);
  const allItems: stacItemsTypes.STACItem[] = currentAnimateState.animateImages;
  const imageLayers: ImageLayers = yield select(getImageLayersById);
  const selectedImageLayerId: string = yield select(getSelectedImageLayerId);
  const map = GeoportalMap.getInstance();
  const items: stacItemsTypes.STACItem[] =
    currentAnimateState.direction === 'forward' ? allItems.reverse() : allItems;
  while (true) {
    for (const item of items) {
      yield call(
        [map, 'updateImageLayer'],
        imageLayers[selectedImageLayerId],
        imageLayers[selectedImageLayerId].config.options.url +
          new Date(Object.keys(item)[0]).toISOString().substring(0, 16)
      );
      // yield put(changeActiveItem({ index: items.indexOf(item), item: item }));
      yield delay(currentAnimateState.delay);
    }
    // yield put(changeActiveItem({ index: 0, item: {} }));
  }
}

export function* animateSTACItemsFlow(action: ActionType<typeof animateItems>) {
  try {
    const animateSTACItemsState: stacItemsTypes.AnimateState = yield select(getAnimateItems);
    if (animateSTACItemsState.isAnimate) {
      yield call(animateItemsFlow);
    }
    // yield put(playArcticLayer());
  } catch (error) {
    debug.error(error);
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(changeActiveItem), changeActiveItemFlow)]);
  yield all([takeLatest(getType(animateItems), animateItemsFlow)]);
  yield all([takeLatest(getType(fetchItems.request), fetchItemsFlow)]);
  yield all([takeLatest(getType(fetchItems.success), setActiveItemFlow)]);
  yield all([takeLatest(getType(fetchDates.request), fetchItemsDatesFlow)]);
  yield all([takeLatest(getType(changeStartTime.request), changeStartTimeFlow)]);
}
