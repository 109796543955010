import React from 'react';
// import { t } from '@lingui/macro'; // Trans
import { withI18nProps, withI18n } from '@lingui/react';

import { Select, SelectOption } from '@geobank/components/src/forms/Select';
import { ProductOptionType } from '../helpers/fieldTypes';

interface ProductCodeFieldProps {
  name: string; // productCode
  value: string;
  options: ProductOptionType[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ProductCodeField: React.FC<ProductCodeFieldProps & withI18nProps> = props => {
  const { name, value, options, onChange, i18n } = props;

  return (
    <Select
      name={name}
      value={value}
      // onChange={(value: any) => this.handleChange('productCode', value)} /* width={'100%'} */
      onChange={onChange}
    >
      {/* <SelectOption value="L0">
        {/* <Trans>L0 - Без обработки</Trans> --> [object Object] * /}
        {i18n._(t`L0 - Без обработки`)}
      </SelectOption> */}
      {/*
      <SelectOption value="L1">{i18n._(t`L1 - Нетрансформированный с RPC`)}</SelectOption>
      <SelectOption value="L2">{i18n._(t`L2 - В картографической проекции`)}</SelectOption>
      */}
      {options.map(param => (
        <SelectOption key={param.value} value={param.value}>
          {typeof param.title === 'function' ? param.title(i18n) : param.title}
        </SelectOption>
      ))}
    </Select>
  );
};

export default React.memo(withI18n()(ProductCodeField));
