// MessageAlert, Info
import { createActionCreator, createReducer } from 'deox';
import { combineReducers } from 'redux';
// import { nanoid } from 'nanoid/non-secure';

import { RootState as RootStateType } from './root';

/**
 * Types/Interfaces
 */
/* Message
{
  
} */
export type MessageSubject = 'clip' | 'metadata_download';

export type MessageType = 'new_message' | 'get_messages' | 'read_message';

// export interface MessageContainer {
//   type: MessageType;
//   status: string; // success
//   message: string; // ok
//   data: MessageData[] | MessageData;
// }
export interface MessageContainerList {
  type: 'get_messages';
  status: string; // success
  message: string; // ok
  data: MessageData[];
}
export interface MessageContainerObject {
  type: 'new_message' | 'read_message';
  status: string; // success
  message: string; // ok
  data: MessageData;
}

export interface MessageExtendedData {
  taskUuid: string;
  orderDate?: string; // есть только у metadata_download
  object?: MessageObject;
  // metadataIds: number[];
}

export interface MessageObject {
  id: number;
  size: number;
  downloadUrl: string;
  // path: string;
}

export interface MessageData {
  id: number;
  message: string;
  resultType: string; // success
  subject: MessageSubject;
  createdAt: string;
  extendedData: MessageExtendedData; // task_uuid, object, order_date
}

/**
 * Actions
 */
// notifyActions
export const fetchMessages = {
  request: createActionCreator(
    'NOTIFY/FETCH_FETCH_MESSAGES_REQUEST',
    resolve => (subject?: MessageSubject) => resolve(subject)
  ),
  success: createActionCreator(
    'NOTIFY/FETCH_FETCH_MESSAGES_SUCCESS',
    resolve => (data: MessageData[]) => resolve(data)
  ),
  failure: createActionCreator('NOTIFY/FETCH_FETCH_MESSAGES_FAILURE', resolve => error =>
    resolve(error)
  ),
};
// { pushMessage, removeMessage, clearMessages }

// push, add
export const addOrUpdMessage = createActionCreator(
  'NOTIFY/ADD_OR_UPD_MESSAGE',
  resolve => (data: MessageData) => resolve(data)
);
// upd, patch
// export const updateMessage = createActionCreator(
//   'NOTIFY/UPDATE_MESSAGE',
//   resolve => (data: MessageData) => resolve(data)
// );
// read ~ remove
// export const removeMessage = createActionCreator(
//   'NOTIFY/REMOVE_MESSAGE',
//   resolve => (data: MessageData) => resolve(data)
// );
export const readMessages = {
  request: createActionCreator(
    'NOTIFY/READ_MESSAGES_REQUEST',
    resolve => (subject?: number | string) => resolve(subject)
  ),
  success: createActionCreator('NOTIFY/READ_MESSAGES_SUCCESS', resolve => (id?: number) =>
    resolve(id)
  ),
};

/**
 * Reducers and state
 */
export const messagesDefaultState: MessageData[] = [];

export const messagesReducer = createReducer(messagesDefaultState, handleAction => [
  handleAction(fetchMessages.success, (state, { payload }) => payload),
  // handleAction(notifyActions.remove, (state, { payload }) =>
  //   state.filter(item => item.id !== payload)
  // ),
  // handleAction(notifyActions.clear, () => []),
  // handleAction(addMessage, (state, { payload }) => [payload, ...state]),
  handleAction(addOrUpdMessage, (state, { payload }) => {
    const result = [...state];
    const msgIndex = state.findIndex(
      item => item.extendedData.taskUuid === payload.extendedData.taskUuid
    );
    // const msgIndex = state.indexOf(payload);
    if (msgIndex > -1) {
      result.splice(msgIndex, 1, payload);
    } else {
      result.unshift(payload);
    }
    return result;
  }),
  // handleAction(removeMessage, (state, { payload }) => {
  //   const result = [...state];
  //   const msgIndex = state.indexOf(payload);
  //   result.splice(msgIndex, 1);
  //   return result;
  // }),
  handleAction(readMessages.success, (state, { payload }) => {
    if (payload) {
      return state.filter(item => item.id !== payload);
    } else {
      return [];
    }
  }),
]);

// rootReducer
export default combineReducers({
  messages: messagesReducer,
});

/**
 * Selectors
 */
export const getMessages = (state: RootStateType) => state.messageNotify.messages;
