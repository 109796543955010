import axios from 'axios';
import qs from 'qs'; // querystring
import xml2js from 'xml2js';
// import { Md5 } from 'ts-md5/dist/md5';

// import axios, { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

const CancelToken = axios.CancelToken;

// axios.defaults.headers.post['Accept'] = '*/*';

export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const BASE_URL = process.env.REACT_APP_SITE_URL;
export const CART_REQUEST_API_URL = process.env.REACT_APP_CART_REQUEST_API_URL;
export const IAM_CONFIG = process.env.REACT_APP_IAM_CONFIG;
export const NOTIFY_API_URL = process.env.REACT_APP_NOTIFY_API_URL;
export const PATH_TO_LAYERS_DATA = process.env.REACT_APP_PATH_TO_LAYERS_DATA;
export const QUICKLOOK_TRANSFORM_URL = process.env.REACT_APP_QUICKLOOK_TRANSFORM_URL;
export const S3_DOC_URL = process.env.REACT_APP_S3_DOC_URL;
export const S3_TOKEN_DURATION_TIME = process.env.REACT_APP_S3_TOKEN_DURATION;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const SEARCH_IMAGES_API_URL = process.env.REACT_APP_SEARCH_IMAGES_API_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SIMPLE_REQUEST_API_URL = process.env.REACT_APP_SIMPLE_REQUEST_API_URL;
export const STAC_API_URL = process.env.REACT_APP_STAC_API_URL;
export const TASK_API_URL = process.env.REACT_APP_TASK_API_URL;
export const USE_FAKE_API = process.env.REACT_APP_USE_FAKE_API;
export const VTMS_URL = process.env.REACT_APP_VTMS_URL;
export const YANDEX_METRICA_TRACK_NUMBER = process.env.REACT_APP_YM_TRACK_NUMBER;

const instance = axios.create({
  baseURL: BASE_URL,
  // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  // headers: { 'Content-Type': 'application/json' },
  // headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': true },
  // withCredentials: true, // нужен ли флаг?! (для кук нужен, но у нас исп jwt в заголовке)
  // !Note: ф-я экранирует спец символы, такие как пробел (вместо `+`); типа encodeURI, наверное;
  // на стороне сервиса поиска `+` не расшифровывается как пробел - для передачи времени с часовым поясом (можно отказаться)
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const oldAPI = axios.create({
  baseURL: process.env.REACT_APP_OLD_API_URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  withCredentials: true,
});

/**
 * Helper functions
 */

const s3TokenDurationTime: string = process.env.REACT_APP_S3_TOKEN_DURATION!;

export const getSTS = (token: any) => {
  const parser = new xml2js.Parser({ explicitArray: false });
  const data = {
    Action: 'AssumeRoleWithWebIdentity',
    DurationSeconds: s3TokenDurationTime,
    WebIdentityToken: token,
    Version: '2011-06-15',
  };
  return instance
    .post(S3_URL!, qs.stringify({ ...data }))
    .then(response => {
      const result = parser.parseStringPromise(response.data);
      return result;
    })
    .then(
      result => result.AssumeRoleWithWebIdentityResponse.AssumeRoleWithWebIdentityResult.Credentials
    );
};

export const setLanguageHeader = (language: string) => {
  instance.defaults.headers.common['Accept-Language'] = language;
};

export const setTokenApi = (accessToken: string) => {
  instance.defaults.headers.common[`Authorization`] = `Bearer ${accessToken}`;
};

export const clearTokenApi = () => {
  instance.defaults.headers.common[`Authorization`] = undefined;
};

/**
 * Registration & session functions
 */
// ...

/**
 * Api methods for metadata search by catalog
 */
// get startup config from S3
export const getStartupConfig = () => {
  return instance
    .get(`${S3_URL}/geoportal-public/config.json`)
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
};

// new search catalog images by ES
export const searchImagesV2 = (params: object) => {
  const source = CancelToken.source();
  // /catalog/search
  const request = instance.post(
    '/search',
    { ...params, ordering: '-acquisition_date_begin' }, // +сортировка по дате съёмки; -last_modified
    {
      baseURL: SEARCH_IMAGES_API_URL,
      cancelToken: source.token,
    }
  );
  // @ts-ignore
  request[CANCEL] = () => source.cancel('cancelled');
  return request;
};

export const searchBbpScenes = (params: any) => {
  const source = CancelToken.source();
  // /catalog/search
  const request = instance.post('/bbp/scenes', params, {
    baseURL: SEARCH_IMAGES_API_URL,
    cancelToken: source.token,
  });
  // @ts-ignore
  request[CANCEL] = () => source.cancel('cancelled');
  return request;
};

export const searchSTACImages = (params: object) => {
  const source = CancelToken.source();
  // /catalog/search
  const request = instance.post(
    '/search',
    { ...params, ordering: '-acquisition_date_begin' }, // +сортировка по дате съёмки; -last_modified
    {
      baseURL: SEARCH_IMAGES_API_URL,
      cancelToken: source.token,
    }
  );
  // @ts-ignore
  request[CANCEL] = () => source.cancel('cancelled');
  return request;
};

/**
 * Api methods for requests
 */
// export const getClassPlatforms = (params: object) => {
//   return instance.get('/request/platforms.ext-json', { params }).then(response => {
//     return response;
//   });
// };
export const getClassPlatforms = (params: object) => {
  return import('./data/platforms.ext-json.json').then(moduleJson => {
    return moduleJson.default.data;
  });
};

export const getClassDeliveryMethods = () => {
  return instance.get('/request/delivery_methods.ext-json').then(response => {
    return response;
  });
};

export const getClassStations = () => {
  return instance.get('/request/stations.ext-json').then(response => {
    return response;
  });
};

/**
 * Api methods for map (layers, search features)
 */
// export const getMapLayers = async () => {
//   const request = await instance.get('/map/public/maps.ext-json');
//   return request;
// };

export const getMapLayers = () => {
  return import(`${PATH_TO_LAYERS_DATA}`).then(moduleJson => {
    return moduleJson.default.data;
  });
};

export const getFeatureInfo = (params: any) => {
  return instance.get('/coverage/features.ext-json', { params }).then(response => {
    return response;
  });
};

export const getGeofenceGeometry = (url: string) => {
  return axios.get(url).then(response => response);
};

// !Переделать на POST запрос
export const getSTACItems = (getParams: any) => {
  const params = {
    sortby: '-datetime',
    fields: 'properties.datetime,properties.platform,assets',
    limit: '250',
    datetime: getParams.dateRange,
    collections: getParams.collections,
  };
  return instance
    .get('', { baseURL: STAC_API_URL + '/roscosmos-opendata/search', params: params })
    .then(response => response);
};

// export const getSTACDates2 = (collection: string) => {
//   const params = {
//     collections: collection,
//   };
//   return instance
//     .get('', { baseURL: STAC_API_URL + '/roscosmos-opendata/search', params: params })
//     .then(response => response);
// };

export const getSTACDates = (param?: string) => {
  const params = {
    parent_collections: param,
  };
  return instance
    .get('', { baseURL: STAC_API_URL + '/roscosmos-opendata/collections', params: params })
    .then(response => response);
};

/**
 * Api methods for searching geo objects
 */
export const searchByPortal = (value: string) => {
  const params = { q: value };
  const source = CancelToken.source();
  const request = oldAPI.post('/map/search.ext-json', qs.stringify(params), {
    cancelToken: source.token,
  });
  // @ts-ignore
  request[CANCEL] = () => source.cancel();
  return request;
};

export const searchObjectsByOSM = (value: string) => {
  const params = {
    q: value,
    format: 'json', // json, geojson
    polygon_geojson: 1,
    // addressdetails: 1,
    limit: 10,
  };
  const source = CancelToken.source();
  const request = axios.get('https://nominatim.openstreetmap.org/search', {
    params,
    cancelToken: source.token,
  });
  // @ts-ignore
  request[CANCEL] = () => source.cancel();
  return request;
};

/**
 * Cart order API
 */
export const getPrice = (params: any) => {
  const data = params;
  return instance
    .post('/quotation', data, { baseURL: CART_REQUEST_API_URL })
    .then(response => response);
};

export const getCurrentCart = () => {
  return instance.get('/my/cart', { baseURL: CART_REQUEST_API_URL }).then(response => {
    return response;
  });
};

export const addImageToCurrentCart = (params: any) => {
  return instance
    .post('/my/cart/add_item', params, { baseURL: CART_REQUEST_API_URL })
    .then(response => {
      return response;
    });
};

export const removeImageFromCurrentCart = (params: any) => {
  return instance
    .post('/my/cart/remove_item', params, { baseURL: CART_REQUEST_API_URL })
    .then(response => {
      return response;
    });
};

export const patchCurrentCart = (params: any) => {
  return instance.patch('/my/cart', params, { baseURL: CART_REQUEST_API_URL }).then(response => {
    return response;
  });
};

export const getUserOrderTags = () => {
  return instance.get('/my/tags', { baseURL: CART_REQUEST_API_URL }).then(response => {
    return response;
  });
};

// putCurrentCart

export const getCartRequests = (params: any) => {
  // fix: tags=1&tags=2 --> tags=1,2
  if (Array.isArray(params.tags)) {
    params.tags = params.tags.join(',');
  }

  return instance
    .get('/my/orders', {
      // exclude: images
      params: {
        fields:
          'id,number,statuses,created_at,total_price,product_code,srs_name,bytes_per_pixel,certification,license,usage_time,payment_params,tags', // images,bbp_order,products
        ...params,
      },
      baseURL: CART_REQUEST_API_URL,
    })
    .then(response => {
      return response;
    });
};

export const getCartOrder = (orderNumber: string, params: any = {}) => {
  return instance
    .get(`/my/orders/${orderNumber}`, {
      params: {
        fields: 'id,number,bbp_order,images,products',
        ...params,
      },
      baseURL: CART_REQUEST_API_URL,
    })
    .then(response => {
      return response;
    });
};

// note: параметры не нужны, корзина хранится на сервере
export const createCartRequest = () => {
  return instance
    .post('/my/cart/order', null, {
      baseURL: CART_REQUEST_API_URL,
      headers: { 'Content-Type': 'application/json' }, // fix для backend!
    })
    .then(response => {
      return response;
    });
};

export const createMetadataDownloadTask = (params: any) => {
  const data = `data=${JSON.stringify(params)}`;
  return instance
    .post('/metadata/download_tasks/', data, { baseURL: TASK_API_URL })
    .then(response => {
      return response;
    });
};

export const getSimpleRequests = (params: any) => {
  return instance
    .get('/request/requests.ext-json', { params, baseURL: SIMPLE_REQUEST_API_URL })
    .then(response => response);
};

export const createSimpleRequest = (params: any) => {
  const data = `data=${JSON.stringify(params)}`;
  return instance
    .post('/request/requests.ext-json', data, { baseURL: SIMPLE_REQUEST_API_URL })
    .then(response => response);
};

// export default {
//   searchMetadata,
//   getMapLayers,
// };
export default instance;
