import { t } from '@lingui/macro';

export const fieldOptions: { [key: string]: { value: string | number; title: any }[] } = {
  productCode: [
    {
      value: 'L1',
      title: (i18n: any) => i18n._(t`L1 - Нетрансформированный с RPC`),
    },
    {
      value: 'L2',
      title: (i18n: any) => i18n._(t`L2 - В картографической проекции`),
    },
  ],
  srsName: [
    // sk42: СК-42, pz90: ПЗ-90
    { value: 'UTM', title: 'WGS84 (UTM)' },
    { value: 'EPSG:4326', title: 'WGS84 (Lat/Long)' },
    { value: 'EPSG:7683', title: 'GSK-2011 (Lat/Long)' },
  ],
  bytesPerPixel: [
    { value: 1, title: '1' },
    { value: 2, title: '2' },
  ],
};

// export const filterOptions = (options: any[], availableOptions: any[]) => {
// return options.filter(o => availableOptions.indexOf(o.value) > -1);
export const filterOptions = (
  field: 'productCode' | 'srsName' | 'bytesPerPixel',
  availableOptions: any[]
) => {
  return fieldOptions[field].filter(o => availableOptions.indexOf(o.value) > -1);
};
