import { createActionCreator } from 'deox'; // createReducer

// authFetchRequest
export const authFetch = {
  request: createActionCreator('AUTH_FETCH/REQUEST', resolve => (params: any) => resolve(params)),
};

// rootReducer
// export default combineReducers({
//   simpleRouteCart: simpleRouteCartReducer,
// });
