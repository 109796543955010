import { call, put, all, takeLatest } from 'redux-saga/effects';
import { /* getType, */ ActionType } from 'deox'; // createActionCreator, createReducer
// import requestFlow from './network'; // './request';

import { fetchPlatforms } from 'ducks/classifier';

// import * as API from './api'
import API from 'api';

export function* fetchPlatformsFlow(_action: ActionType<typeof fetchPlatforms.request>) {
  try {
    // const data = yield call(requestFlow, API.getClassPlatforms, {});
    const data = yield call(API.getClassPlatforms, {});

    yield put(fetchPlatforms.success(data));
  } catch (error) {
    console.error('Error', error);
    yield put(fetchPlatforms.failure(error));
  }
}

export default function* PlatformsRoot() {
  yield all([
    takeLatest(fetchPlatforms.request, fetchPlatformsFlow),
    // takeLatest(getType(addTodo.next), addTodoSaga),
  ]);
}
