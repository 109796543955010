import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import { RootState as RootStateType } from './root';
// import sortBy from 'lodash/sortBy';
// import { ImageMetadata as Metadata } from './metadata';

/**
 * Types/Interfaces
 */
// ProcessingLevelCode
export interface RequestPLC {
  id?: number;
  plcId: number;
  formatId: number;
  srsId: number;
  bitsPerPixel: any; // TODO - convert
}

export interface RequestSensor {
  id?: number;
  sensorId: number;
  plcs: RequestPLC[];
}

export interface RequestRegion {
  id?: number;
  name: string;
  // region_type: 1
  platformId?: number;
  platformTypeId?: number;
  maxCloudiness?: number; // 30
  // minAngle?: number;
  // maxAngle?: number;
  // minAzimuth?: number;
  // maxAzimuth?: number;
  // minSun?: number;
  // maxSun?: number;
  // minAzimuthSun?: number;
  // maxAzimuthSun?: number;
  beginShooting: string;
  endShooting: string;
  // "max_file_size": null,
  // "required_date": null,
  // delivery_method_id: 1
  polygon: string;
  // status: string;
  // status_comment: null;
  sensors: RequestSensor[];
}

export interface Request {
  id?: number;
  name: string;
  received?: string;
  comment?: string;
  // "request_type": null, // архив или новая съёмка
  regions: RequestRegion[];
}

/**
 * Actions
 */
// ~ order
export const createSimpleRequest = {
  request: createActionCreator('REQUEST_ORDER/CREATE_REQUEST', resolve => (params: object) =>
    resolve(params)
  ),
  success: createActionCreator('REQUEST_ORDER/CREATE_SUCCESS', resolve => (data: object) =>
    resolve(data)
  ),
  failure: createActionCreator('REQUEST_ORDER/CREATE_FAILURE', resolve => error => resolve(error)),
};

// local update request params/data
export const updateCurrentSimpleRequest = createActionCreator(
  'REQUEST_ORDER/UPDATE_REQUEST_PARAMS',
  resolve => (data: object) => resolve(data)
  // resolve => (data: CurrentCartParams) => resolve(data)
);

export const fetchSimpleRequests = {
  request: createActionCreator('REQUEST_ORDER/FETCH_REQUEST', resolve => (params: any) =>
    resolve(params)
  ),
  success: createActionCreator(
    'REQUEST_ORDER/FETCH_SUCCESS',
    resolve => (data: { count: number; results: Request[] }) => resolve(data)
  ),
  failure: createActionCreator('REQUEST_ORDER/FETCH_FAILURE', resolve => error => resolve(error)),
};

/**
 * Reducers and state
 */
export const currentRequestDefaultState: Request = {
  name: 'Новая заявка',
  regions: [],
};
export const currentRequestReducer = createReducer(currentRequestDefaultState, handleAction => [
  handleAction(updateCurrentSimpleRequest, (state, { payload }) => {
    return { ...state, ...payload };
  }),
  handleAction(createSimpleRequest.success, (_, { payload }) => currentRequestDefaultState),
]);

// selectedRegion =

export const requestsDefaultState: Request[] = [];
export const requestsReducer = createReducer(requestsDefaultState, handleAction => [
  handleAction(fetchSimpleRequests.success, (_, { payload }) => payload.results),
]);

// export const totalSimpleRequestsDefaultState = 0;
export const totalRequestsDefaultState = 0;
export const totalRequestsReducer = createReducer(totalRequestsDefaultState, handleAction => [
  handleAction(fetchSimpleRequests.success, (_, { payload }) => payload.count),
]);

export const isFetchingRequestsDefaultState: boolean = false;
export const isFetchingRequestsReducer = createReducer(
  isFetchingRequestsDefaultState,
  handleAction => [
    handleAction(fetchSimpleRequests.request, () => true),
    handleAction(fetchSimpleRequests.success, () => false),
    handleAction(fetchSimpleRequests.failure, () => false),
  ]
);

// metadataRootReducer
export default combineReducers({
  currentRequest: currentRequestReducer,
  requests: requestsReducer,
  totalRequests: totalRequestsReducer,
  isFetchingRequests: isFetchingRequestsReducer,
});

/**
 * Selectors
 */
export const getCurrentRequest = (state: RootStateType) => state.simpleRequest.currentRequest;

export const getRequests = (state: RootStateType) => state.simpleRequest.requests;
export const getTotalRequests = (state: RootStateType) => state.simpleRequest.totalRequests;
export const getIsFetchingRequests = (state: RootStateType) =>
  state.simpleRequest.isFetchingRequests;
