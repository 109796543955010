import * as React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { IsNeedInfo, IRequestInfo } from '../../MyRequests';
import Table from '@geobank/components/src/common/Table/Table';
import TRow from '@geobank/components/src/common/Table/TRow/TRow';
import THeader from '@geobank/components/src/common/Table/THeader/THeader';
import TData from '@geobank/components/src/common/Table/TData/TData';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import OpenMenuIcon from '@geobank/assets/src/icons/BurgerMenuIcon';
import THead from '@geobank/components/src/common/Table/THead/THead';
import TBody from '@geobank/components/src/common/Table/TBody/TBody';
import styles from './RequestTable.css';

interface RequestTableProps {
  isNeedInfoPanel: IsNeedInfo;
  requestsData: RequestData[];
  showRequestInfoPanel: (index: number) => void;
  setRequestData: (index: number) => void;
  requestDataPanel: IRequestInfo;
  closeRequestInfoPanel: () => void;
}

const tRowActiveStyle: React.CSSProperties = {
  backgroundColor: '#def1fc',
};

export interface RequestData {
  name: string;
  date: Date;
  status: string;
  price: string;
  message: string;
}

const mockLabels = ['№', 'Имя', 'Дата', 'Статус', 'Цена', 'Сообщения', ''];

export default class RequestTable extends React.Component<RequestTableProps> {
  private readonly getTableHeader = (labels: string[]) => {
    return labels.map((item, index) => (
      <THeader key={item + index}>
        <div className={styles.request_table__table_row_content}>
          <span className={styles.my_requests__table_header__span}>{item}</span>
        </div>
      </THeader>
    ));
  };

  private readonly getTData = (value: string, index: number) => {
    return (
      <TData onClick={() => this.props.setRequestData(index)} className={'cursor: pointer'}>
        <div className={styles.request_table__table_row_content}>
          <span>{value}</span>
        </div>
      </TData>
    );
  };

  private readonly getTableData = (requests: RequestData[]) => {
    return requests.map((item, index) => (
      <TRow
        key={index + item.name}
        style={this.props.requestDataPanel.label === item.name ? tRowActiveStyle : undefined}
        className={classNames('request_table_row', styles.pointerCursor)}
      >
        {this.getTData((index + 1).toString(), index)}
        {this.getTData(item.name, index)}
        {this.getTData(format(item.date, 'yyyy MM dd').replace(/ /g, '-'), index)}
        {this.getTData(item.status, index)}
        {this.getTData(item.price, index)}
        {this.getTData(item.message, index)}

        <TData>
          <div className={styles.request_table__table_row_content} style={{ position: 'relative' }}>
            <IconButton onClick={() => this.props.showRequestInfoPanel(index)}>
              {this.props.isNeedInfoPanel.flag && this.props.isNeedInfoPanel.index === index ? (
                <CrossIcon />
              ) : (
                <OpenMenuIcon />
              )}
            </IconButton>
          </div>
        </TData>
      </TRow>
    ));
  };

  public render() {
    const { requestsData } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Table>
          <THead>
            <TRow>{this.getTableHeader(mockLabels)}</TRow>
          </THead>
          <TBody>{this.getTableData(requestsData)}</TBody>
        </Table>
      </div>
    );
  }
}
