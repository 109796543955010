// import { Options as OL_GroupOptions } from '@types/ol/layer/Group';
// import OL_LayerGroup from 'ol/layer/Group';
import OL_LayerGroup, { Options as OL_GroupOptions } from 'ol/layer/Group';

// extends BaseGroupOptions
export interface GroupOptions extends OL_GroupOptions {
  name?: string;
}

export class LayerGroup extends OL_LayerGroup {
  // constructor(opt_options?: GroupOptions) {
  // eslint-disable-next-line
  constructor(opts?: GroupOptions) {
    super(opts);
  }
}
