import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
// import addDays from 'date-fns/addDays';
import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip-lite';

import Accordion from '@geobank/components/src/common/Accordion/Accordion';
import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs'; // , { IActiveButtonTab }

import cameraIcon from '@geobank/assets/src/icons/CameraIcon';
import folderIcon from '@geobank/assets/src/icons/FolderIcon';
import loadIcon from '@geobank/assets/src/icons/LoadIcon';
import monitorIcon from '@geobank/assets/src/icons/MonitorIcon';
import nibIcon from '@geobank/assets/src/icons/NibIcon';

import { ReactComponent as HelpIcon } from 'components/common/HelpIcon/info.svg';
// import { drawControl } from 'ducks/map';
// import GeoportalMap from 'components/map/Map/Map';
import UploadShapeFile from 'components/map/UploadShapeFile/UploadShapeFile';
import { drawControl } from 'ducks/map';
import { defaultSearchParamsState } from 'ducks/metadata';
import * as metadataTypes from 'ducks/types/metadataTypes';
import styles from '../LeftMenu.css';
import AdditionalOptions from './AdditionalOptions';
import CloudinessFieldset from './CloudinessFieldset';
import DateRangeFieldset from './DateRangeFieldset';
import NewShootingModal from './NewShootingModal';
import PlatformFieldset from './PlatformFieldset';

// tslint:disable-next-line:no-empty-interface
interface SearchFormProps {
  // onRegionSelectionChange?: (id?: string) => void;
  // drawControlActions?: typeof drawControl;
  isFreeUser: boolean;
  isGeofencedUser: boolean;
  isDrawControlShow: boolean;
  drawControlShow: typeof drawControl.show;
  drawControlHide: typeof drawControl.hide;
  // rename to formState
  searchParams: any;
  onSourceChange: (value: 'archive' | 'shooting') => void;
  // onChange: (fieldName: string, fieldValue: any) => void;
}

// type RegionSelectionType = 'screen' | 'draw' | 'shape';
// type SourceType = 'archive' | 'shooting'; // | undefined;

// interface SearchFormState {
//   regionSelection?: RegionSelectionType;
//   source: SourceType;
//   // --
//   acquisitionDateAfter?: Date;
//   acquisitionDateBefore?: Date;
//   cloudinessMax?: number; // cloudinessMax
//   processingLevelCode: string;

//   // sunHeight: number[];
//   illuminationElevationAngleMin?: number;
//   illuminationElevationAngleMax?: number;
//   // angleSystem: number[];
//   nadirTiltAngleMin?: number;
//   nadirTiltAngleMax?: number;

//   instrumentIdentifiers?: string[];

//   modal: null | string;
// }

class SearchForm extends PureComponent<SearchFormProps> {
  // TODO - use defaultSearchParamsState from ducks/metadata
  public state: metadataTypes.SearchMetadataParams;

  constructor(props: any) {
    super(props);
    this.state = defaultSearchParamsState;
    this.state.instrumentIdentifiers = this.props.isFreeUser ? ['PSS,MSS', 'AVR'] : ['PSS,MSS'];
    this.state.regionSelection = 'screen';
  }
  // public state: SearchFormState = {
  //   regionSelection: this.props.isDrawControlShow ? 'draw' : 'screen',
  //   source: 'archive',
  //   // --
  //   acquisitionDateAfter: undefined,
  //   acquisitionDateBefore: addDays(new Date(), -30), // new Date(),
  //   cloudinessMax: 50,
  //   processingLevelCode: '0',

  //   // sunHeight: [0, 90], // 15, 90
  //   illuminationElevationAngleMin: undefined,
  //   illuminationElevationAngleMax: undefined,
  //   // angleSystem: [0, 90],
  //   nadirTiltAngleMin: undefined,
  //   nadirTiltAngleMax: undefined,

  //   instrumentIdentifiers: ['PSS,MSS'],

  //   modal: null,
  // };

  // private shapeInputRef: React.RefObject<HTMLInputElement>;
  private uploadShapeFileRef?: typeof UploadShapeFile;

  // constructor(props: any) {
  //   super(props);
  //   this.shapeInputRef = React.createRef();
  // }

  public componentDidMount() {
    this.setState({ ...this.props.searchParams });
    // if (this.props.isFreeUser) {
    //   this.setState({ instrumentIdentifiers: ['PSS,MSS', 'AVR'] });
    // }
  }

  public componentDidUpdate(prevProps: SearchFormProps) {
    if (prevProps.searchParams !== this.props.searchParams) {
      this.setState({ ...this.props.searchParams });
    }
    this.props.isDrawControlShow
      ? this.setState({ regionSelection: 'draw' })
      : this.setState({ regionSelection: this.state.regionSelection });
  }

  private handleInputChange = (field: string, value: any) => {
    // @ts-ignore
    this.setState({ [field]: value });

    // console.log(field, value);
  };

  private handleRegionSelectionChange = (value: string) => {
    // console.log(values);
    // if (this.props.drawControlActions) { // undefined
    //   console.log(values);
    //   // this.props.drawControlActions.hide();
    // }
    const activeItem = value as metadataTypes.RegionSelectionType;
    // this.props.onRegionSelectionChange && this.props.onRegionSelectionChange(activeItem!.label);

    // const map = GeoportalMap.getInstance();
    // const layer = map.ensureCurrentRegionLayer();
    if (activeItem === 'screen') {
      this.props.drawControlHide();
      // map.clearRegionsOfInterest();
      // layer.setVisible(false);
    } else if (activeItem === 'draw') {
      this.props.drawControlShow();
      // layer.setVisible(true);
    } else if (activeItem === 'shape') {
      this.props.drawControlHide();
      // layer.setVisible(true);

      // this.shapeInputRef.current!.focus();
      // this.shapeInputRef.current!.click();
      // debugger;
      if (this.uploadShapeFileRef) {
        // @ts-ignore
        this.uploadShapeFileRef.activate();
      }
    }
    this.setState({ regionSelection: activeItem });
  };

  // request type
  private handleSourceTypeChange = (value: string) => {
    const activeItem = value as metadataTypes.SourceType;
    if (activeItem === 'archive') {
      this.setState({
        acquisitionDateAfter: undefined,
        acquisitionDateBefore: new Date(), // addDays(new Date(), -30);
        source: 'archive',
        instrumentIdentifiers: defaultSearchParamsState.instrumentIdentifiers,
      });
      this.props.onSourceChange('archive');
    } else if (activeItem === 'shooting') {
      this.setState({ modal: 'shooting' });
      return;

      // temp disable
      // eslint-disable-next-line
      // this.setState({
      //   acquisitionDateAfter: new Date(),
      //   acquisitionDateBefore: undefined,
      //   source: 'shooting',
      //   instrumentIdentifiers: defaultSearchParamsState.instrumentIdentifiers,
      // });
      // this.props.onSourceChange('shooting');
    }
  };

  private readonly sunHeightHandler = (value: number[]): void => {
    // this.setState({ sunHeight: value });
    this.setState({
      illuminationElevationAngleMin: value[0],
      illuminationElevationAngleMax: value[1],
    });
  };

  private readonly angleSystemHandler = (value: number[]): void => {
    // this.setState({ angleSystem: value });
    this.setState({
      nadirTiltAngleMin: value[0],
      nadirTiltAngleMax: value[1],
    });
  };

  public handleModalClose = () => {
    this.setState({ modal: null });
  };

  public render() {
    const {
      regionSelection,
      source,
      acquisitionDateAfter: dateBegin,
      acquisitionDateBefore: dateEnd,
      cloudinessMax,
      // sunHeight,
      illuminationElevationAngleMin,
      illuminationElevationAngleMax,
      // angleSystem,
      nadirTiltAngleMin,
      nadirTiltAngleMax,

      instrumentIdentifiers,

      modal,
    } = this.state;
    // console.log('SearchForm state=', this.state);
    return (
      <>
        {modal !== null && <NewShootingModal onClose={this.handleModalClose} />}

        <div className={styles.div_search_option}>
          <div>
            <span className={styles.span_header_test}>
              <Trans>Район снимка</Trans>
            </span>
          </div>
          <div>
            <I18n>
              {({ i18n }) => (
                <ButtonTabs
                  tabsList={[
                    {
                      label: i18n._(t`Область экрана`),
                      value: 'screen',
                      icon: monitorIcon,
                    },
                    {
                      label: i18n._(t`Указать на карте`),
                      value: 'draw',
                      icon: nibIcon,
                    },
                    {
                      label: i18n._(t`Загрузить shape`),
                      value: 'shape',
                      icon: loadIcon,
                      helper: (
                        <span>
                          <Tooltip
                            tagName="span"
                            styles={{ marginLeft: '6px' }}
                            tipContentClassName="tip-content--border-grey tip-content--width-280"
                            arrow={false}
                            background="white"
                            color="black"
                            direction="right"
                            content={
                              <Trans>
                                Поддерживаются shape-файлы c одной геометрией типа Polygon
                              </Trans>
                            }
                          >
                            <HelpIcon width={16} height={16} fill="currentColor" />
                          </Tooltip>
                        </span>
                      ),
                    },
                  ]}
                  activeTab={regionSelection}
                  onChange={this.handleRegionSelectionChange}
                />
              )}
            </I18n>
            <UploadShapeFile
              onRef={ref => (this.uploadShapeFileRef = ref)}
              onChangeRegionSelectionType={this.handleRegionSelectionChange}
            />
          </div>
        </div>
        <div className={styles.div_search_option}>
          <div>
            <span className={styles.span_header_test}>
              <Trans>Источник</Trans>
            </span>
          </div>
          <div>
            <span
              style={{
                fontSize: 14,
                fontFamily: 'TT Hoves Regular',
                color: 'rgba(255, 255, 255, 0.5)',
              }}
            >
              <Trans>Найденные снимки из архива доступны для скачивания сразу</Trans>
            </span>
          </div>
          <div>
            <I18n>
              {({ i18n }) => (
                <ButtonTabs
                  tabsList={[
                    {
                      label: i18n._(t`Снимки архива`),
                      value: 'archive',
                      icon: folderIcon,
                    },
                    {
                      label: i18n._(t`Съемка на заказ`),
                      value: 'shooting',
                      icon: cameraIcon,
                    },
                  ]}
                  activeTab={source}
                  onChange={this.handleSourceTypeChange}
                />
              )}
            </I18n>
          </div>
        </div>
        <div className={styles.div_search_option}>
          <div>
            <span className={styles.span_header_test}>
              <Trans>Диапазон дат</Trans>
            </span>
          </div>
          <div className={styles.date_input_content}>
            {/* TODO - в случае типа заявки не только выставить значения по умолчанию, но и заблокировать соотв-е даты */}
            <DateRangeFieldset
              source={source}
              // --
              dateBegin={dateBegin}
              dateEnd={dateEnd}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className={styles.div_search_option}>
          <PlatformFieldset
            /* onChange={data => console.log(data)} */
            checked={instrumentIdentifiers}
            onChange={this.handleInputChange}
            source={source!}
          />
        </div>
        <div className={styles.div_search_option}>
          <div>
            <span className={styles.span_header_test}>
              <Trans>
                Облачность <span style={{ fontSize: '0.75em' }}>(не более)</span>
              </Trans>
            </span>
          </div>
          <div style={{ padding: '10px 10px 0' /* paddingTop: 20 */ }}>
            <CloudinessFieldset cloudinessMax={cloudinessMax} onChange={this.handleInputChange} />
          </div>
        </div>
        {/* <div className={styles.div_search_option}>
          <div>
            <span className={styles.span_header_test}>Дополнительные параметры</span>
          </div>
          <div style={{ paddingTop: 20 }}>
            <AdditionalOptions
              sunHeightHandler={this.sunHeightHandler}
              angleSystemHandler={this.angleSystemHandler}
              sunHeightValue={sunHeight}
              angleSystemValue={angleSystem}
            />
          </div>
        </div> */}
        <div className={styles.div_search_option} style={{ color: 'white' }}>
          <I18n>
            {({ i18n }) => (
              <Accordion label={i18n._(t`Дополнительные параметры`)} right={true}>
                <AdditionalOptions
                  sunHeightHandler={this.sunHeightHandler}
                  angleSystemHandler={this.angleSystemHandler}
                  // sunHeightValue={sunHeight}
                  sunHeightValue={[
                    illuminationElevationAngleMin || 0,
                    illuminationElevationAngleMax || 90,
                  ]}
                  // angleSystemValue={angleSystem}
                  angleSystemValue={[nadirTiltAngleMin || 0, nadirTiltAngleMax || 90]}
                />
              </Accordion>
            )}
          </I18n>
        </div>
      </>
    );
  }
}

export default SearchForm;
