import { i18nMark } from '@lingui/react';

// created, processing, partial_completed, completed
export const statuses: { [x: string]: string } = {
  created: i18nMark('request_status.created'), // Отправлена
  payment_await: i18nMark('request_status.payment_await'), // Ожидает оплаты
  paid: i18nMark('request_status.paid'), // Оплачена
  processing: i18nMark('request_status.processing'), // Выполняется обработка -> В обработке
  completed: i18nMark('request_status.completed'), // Завершена -> Выполнена
  suspended: i18nMark('request_status.suspended'), // Обработка остановлена оператором
  failed: i18nMark('request_status.failed'), // Обработка завершена с ошибкой
  partial_completed: i18nMark('request_status.partial_completed'), // Частично выполнена
  canceled: i18nMark('request_status.canceled'), // Отменена
};
