// import VectorLayer from 'ol/layer/Vector';
import VectorLayer from 'libs/ol/layer/Vector'; // ,{ VectorLayerOptions }
import VectorSource from 'ol/source/Vector';

import { CreateLayerBundle } from '../types';

const createSimpleVectorLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle;

  const layer = new VectorLayer({
    id,
    name,
    source: new VectorSource({
      features: [],
      attributions: options.attribution,
    }),
    zIndex: options.zIndex || 31,
  });

  return layer;
};

export default createSimpleVectorLayer;
