import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withI18n, withI18nProps } from '@lingui/react';
import { t } from '@lingui/macro';

import SearchInput from '@geobank/components/src/forms/SearchInput/SearchInput';

import { getUserOrderTags, fetchUserOrderTags } from 'ducks/order';
import { getTranslatedTag } from '../ChipTag/helper';

const styles = require('./SelectTag-styles.module.scss');

interface SelectTagProps {
  onSelect: (value: string) => void;
}

const SelectTag: React.FC<SelectTagProps & withI18nProps> = props => {
  const { onSelect, i18n } = props;

  const userOrderTags = useSelector(getUserOrderTags);
  const dispatch = useDispatch();

  useEffect(() => {
    // if (cartRequests.length === 0) {
    dispatch(fetchUserOrderTags.request());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const results = userOrderTags.map(tag => ({ id: tag.name, title: getTranslatedTag(tag.name) }));

  return (
    /* TODO -
      1. clearOnSelect={true}
      2. styles:
        top: -10px;
        right: 8px;

      3. fix results height!!! (50, 20vh)

      4. extract to component container with search by array func!
      */
    <SearchInput
      // className={styles.root}
      classes={{
        root: styles.searchRoot,
        input: styles.searchInput,
      }}
      placeholder={i18n._(t`Введите название тега`)}
      isLoading={false}
      clearOnSelect={true}
      resultsHeightMax="200px"
      filterOptions={(options, state) =>
        options.filter(opt => opt.id.toLowerCase().indexOf(state.value.toLowerCase()) !== -1)
      }
      results={results}
      // onSearchChange={val => handleChange(val)}
      onResultSelect={item => onSelect(item.id)}
      // onClear={props.searchObjectsClear}
    />
  );
};

export default withI18n()(SelectTag);
