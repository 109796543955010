import { ActionType, getType } from 'deox';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'; // debounce // fork,

import { balloonToggle, drawControl, getGeofenceGeometry } from 'ducks/map';
import { searchObjects } from 'ducks/mapObjects';
import get from 'lodash/get';
import requestFlow from './network';

import { i18nMark } from '@lingui/react';
import API from 'api';
import { parseSearchByPortalResults, parseSearchObjectsByOSMResults } from 'api/helpers';
import GeoportalMap from 'components/map/Map/Map';
import { geoJSONToWKTAndTransform } from 'components/map/Map/utils';
import { getCroppingPolygonIntersection } from 'components/utils/validation/checkIntersections';
import { getIsAuthenticated, getIsGeofenced } from 'ducks/authIAM';
import { notifyActions } from 'ducks/message';
import { searchImages, toggleImageContour } from 'ducks/metadata';
import { fetchResource } from './metadata';

// TODO - вынесли список провайдеров и их приоритет по отображению
type SearchObjectsProvider = 'osm' | 'gptl' | 'gptl_proxy';

export function* searchObjectsWatch() {
  yield takeLatest(getType(searchObjects.request), searchObjectsFlow); // что это даёт getType? - работает и без него
}

export function* searchObjectsFlow(action: ActionType<typeof searchObjects.request>) {
  try {
    const query = action.payload;
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    const searchCatalogParams = { identifier: query };
    // debounce by 1000ms
    yield delay(1000);

    if (query.startsWith('ETRIS.') || query.startsWith('BBP.')) {
      const isGeofenced: boolean = yield select(getIsGeofenced);
      if (isAuthenticated) {
        // const [etrisResult, bbpResult]: [any, any] = yield all([
        //   call(fetchResource, searchCatalogParams, 'etris'),
        //   call(fetchResource, searchCatalogParams, 'bbp'),
        // ]);
        const [etrisResult, bbpResult]: [any, any] = yield all([
          searchCatalogParams.identifier.startsWith('ETRIS')
            ? call(fetchResource, searchCatalogParams, 'etris')
            : null,
          searchCatalogParams.identifier.startsWith('BBP')
            ? call(fetchResource, searchCatalogParams, 'bbp')
            : null,
        ]);
        if (etrisResult) {
          // prettier-ignore
          const kvPSSSensor = { "number": 1, "instrumentIdentifier": "PSS", "id": 2, "bands": [{ "min": 0.54, "max": 0.86, "description": "Панхром", "id": 30 }], "isPanchromatic": true };
          etrisResult.results = etrisResult.results.map((md: any) =>
            md.instrumentIdentifier === 'MSS' ? md.sensors.unshift(kvPSSSensor) && md : md
          );
        }
        yield put(searchObjects.success({ name: 'etris', results: etrisResult }));
        yield put(searchObjects.success({ name: 'bbp', results: bbpResult }));
        const data = {
          count: get(bbpResult, 'count', 0) + get(etrisResult, 'count', 0),
          results: [...get(bbpResult, 'results', []), ...get(etrisResult, 'results', [])],
        };
        const count = data.count;
        const results = data.results;

        let isValid: boolean = true;

        if (isGeofenced) {
          const geofenceGeometry = yield select(getGeofenceGeometry);
          isValid =
            getCroppingPolygonIntersection(
              geoJSONToWKTAndTransform(geofenceGeometry[0]),
              data.results[0].geometry
            ) !== null;
        }
        if (isValid) {
          const map = GeoportalMap.getInstance();
          yield call([map, 'addMetadataResults'], results, false); // false // resData.data
          yield put(searchImages.success({ count, results }));
          const resultsIds: string[] = results.map(result => result.identifier);
          yield put(toggleImageContour.success(resultsIds, true));
        } else {
          yield put(
            notifyActions.push({
              timeout: 4000,
              color: 'alert',
              message: i18nMark(
                'Геометрия снимка находится за пределами района ограничения, заданного Оператором системы!'
              ),
              place: 'bc',
            })
          );
        }
      } else {
        yield put(
          notifyActions.push({
            timeout: 4000,
            color: 'info',
            message: i18nMark('Для поиска снимка по идентификатору необходимо авторизоваться'),
            place: 'bc',
          })
        );
        yield put(searchObjects.failure('Not authenticated'));
      }
    } else {
      const [osmResult, gptlResult]: [any, any] = yield all([
        call(fetchOSMResource, query, 'osm'),
        call(fetchOSMResource, query, 'gptl'),
      ]);
      yield put(searchObjects.success({ name: 'osm', results: osmResult }));
      yield put(searchObjects.success({ name: 'gptl', results: gptlResult }));
    }
  } catch (error) {
    // console.error('searchObjectsFlow error', error);
  }
}

function* fetchOSMResource(query: string, provider: SearchObjectsProvider) {
  try {
    let results;
    switch (provider) {
      case 'osm': {
        const data = yield call(requestFlow, API.searchObjectsByOSM, query);
        results = parseSearchObjectsByOSMResults(data);
        break;
      }
      case 'gptl': {
        const data = yield call(requestFlow, API.searchByPortal, query);
        results = parseSearchByPortalResults(data.results);
        break;
      }
      case 'gptl_proxy':
      default:
        throw Error('Provider not found!');
    }
    // console.log('fetchResource results', results);
    // yield put(searchObjects.success({ name: provider, results }));
    return results;
  } catch (error) {
    // console.log('fetchResource error', error);
    yield put(searchObjects.failure(provider));
  }
}

// select object on map
export function* selectObjectWatch() {
  yield takeLatest(getType(searchObjects.select), selectObjectFlow);
}
export function* selectObjectFlow(action: ActionType<typeof searchObjects.select>) {
  try {
    const { item: object, isZoomNeed } = action.payload;
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    const map = GeoportalMap.getInstance();
    // yield call([map, 'selectObject'], object, isZoomNeed);
    // добавление в корзину через поиск по идентификатору
    // if (object.data?.metadata_id) {
    //   yield put(balloonToggle(true, object.data));
    // } else {
    //   yield put(balloonToggle(false));
    // }
    const roi = yield call([map, 'addRegionOfInterestLayer'], object, isZoomNeed);
    yield put(drawControl.setGeometry(roi));
    if (isAuthenticated) {
      yield put(drawControl.show());
    }
    yield put(
      notifyActions.push({
        timeout: 8000,
        color: roi.geometry.coordinates.length === 1 ? 'success' : 'info',
        message:
          roi.geometry.coordinates.length === 1
            ? i18nMark(
                'Геометрия найденнго объекта может быть использована в качестве района интереса'
              )
            : i18nMark(
                'Геометрия найденнго объекта непригодна для использования в качестве района интереса'
              ),
        place: 'bc',
      })
    );
  } catch (error) {
    // console.error('Error', error);
  }
}

export function* clearObjectsWatch() {
  yield takeLatest(getType(searchObjects.clear), clearObjectsFlow);
}
export function* clearObjectsFlow(action: ActionType<typeof searchObjects.clear>) {
  try {
    const map = GeoportalMap.getInstance();
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    yield call([map, 'clearObjects']);
    if (!isAuthenticated) {
      yield call([map, 'clearRegionsOfInterest']);
    }
    // прячем баллон при очистке рез-в поиска по строке
    yield put(balloonToggle(false));
  } catch (error) {
    // console.error('Error', error);
  }
}

export default function* mapLayersRoot() {
  yield all([searchObjectsWatch(), selectObjectWatch(), clearObjectsWatch()]);
}
