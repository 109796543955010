import { i18nMark } from '@lingui/react';
import { t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';

export const platformTypes: { [x: string]: string } = {
  AI: i18nMark('Аист-2Д'),
  ARCM: i18nMark('Арктика-М'),
  KV: i18nMark('Канопус-В'),
  MM: i18nMark('Метеор-М'),
  RDK: i18nMark('Ресурс-ДК'),
  RP: i18nMark('Ресурс-П'),
  EL: i18nMark('Электро-Л'),
  // MM2: i18nMark('Метеор-М2'), // (?)
  LS8: i18nMark('Landsat-8'),
  SNL2: i18nMark('Sentinel-2'),
};

export const platforms: { [x: string]: string } = {
  // KV
  ARCM1: i18nMark('Арктика-М1'),
  EL2: i18nMark('Электро-Л2'),
  EL3: i18nMark('Электро-Л3'),
  AI2D: i18nMark('Аист-2Д'),
  KV1: i18nMark('Канопус-В1'),
  BKA1: i18nMark('БКА-1'),
  KV3: i18nMark('Канопус-В3'),
  KV4: i18nMark('Канопус-В4'),
  KV5: i18nMark('Канопус-В5'),
  KV6: i18nMark('Канопус-В6'),
  KVIK: i18nMark('Канопус-В-ИК'),
  // RDK
  RDK1: i18nMark('Ресурс-ДК1'),
  // RP
  RP1: i18nMark('Ресурс-П1'),
  RP2: i18nMark('Ресурс-П2'),
  RP3: i18nMark('Ресурс-П3'),
  // MM
  MM1: i18nMark('Метеор-М1'),
  MM2: i18nMark('Метеор-М2'),
  // MM21: i18nMark('Метеор-М21'),
  MM22: i18nMark('Метеор-М22'),
  // LS8
  LS8: i18nMark('Landsat-8'),
  // SNL2
  SNL2A: i18nMark('Sentinel-2A'),
  SNL2B: i18nMark('Sentinel-2B'),
};

export const instruments: { [x: string]: string } = {
  // KV
  AVR: i18nMark('Аврора'),
  PSS: i18nMark('ПСС'),
  MSS: i18nMark('МСС'),
  'PSS,MSS': i18nMark('ПСС & МСС'), // bundle
  MSUIKSRM: i18nMark('МСУ-ИК-СРМ'),
  // RDK
  GTN: i18nMark('Геотон'),
  // RP
  GSA: i18nMark('ГСА'),
  SVR: i18nMark('КШМСА-ВР'),
  SSR: i18nMark('КШМСА-СР'),
  GTNL1: i18nMark('СППИ "Сангур-1У"'),
  // MM
  MSU101: i18nMark('КМСС (МСУ-100-1)'),
  MSU102: i18nMark('КМСС (МСУ-100-2)'),
  'MSU101,MSU102': i18nMark('КМСС (МСУ-100)'),
  //   MM21..MM22
  'MSU-GS-A': i18nMark('МСУ-ГС-А'),
  'MSU-GS-E': i18nMark('МСУ-ГС'),
  MSUTM101: i18nMark('КМСС2 (МСУ-ТМ-100-1)'),
  MSUTM102: i18nMark('КМСС2 (МСУ-ТМ-100-2)'),
  'MSUTM101,MSUTM102': i18nMark('КМСС2 (МСУ-ТМ-100)'),
  MSUMR: i18nMark('МСУ-МР'),
  // LS8
  OLITIRS: i18nMark('OLI-TIRS'),
  // SNL2
  MSI: i18nMark('MSI'),
};

// TODO - отправить MR на фикс кейса:
// https://lingui.js.org/tutorials/react-patterns.html#lazy-translations
// https://github.com/types/_definitelytypedmirror/packages/86450
// (PlatformTree.tsx - <Trans id={instrumentDescriptions[sensorData.instrumentIdentifier]} />)
export const instrumentDescriptions: { [x: string]: MessageDescriptor } = {
  // KV
  // PSS: i18nMark('Панхроматическая съёмочная система, разработанная для КА Канопус-В'),
  // MSS: i18nMark('Мультиспектральная съёмочная система, разработанная для КА Канопус-В'),
  AVR: t('istrument_description.AVRORA')`Широкозахватная мультиспектральная аппаратура`,
  MSUGS: t(
    'istrument_description.MSUGS'
  )`Геостационарная мультиспектральная гидрометеорологическая съёмочная система`,
  MSUGSA: t(
    'istrument_description.MSUGSA'
  )`Высокоэллиптическая мультиспектральная гидрометеорологическая съёмочная система`,
  'PSS,MSS': t(
    'istrument_description.PSS,MSS'
  )`Панхроматическая и Многозональная съёмочные системы`, // bundle
  MSUIKSRM: t(
    'istrument_description.MSUIKSRM'
  )`Многоканальный радиометр среднего и дальнего инфракрасных диапазонов`,
  // RDK
  GTN: t('istrument_description.GTN')`Оптико-электронная аппаратура «Геотон»`,
  // RP
  GSA: t('istrument_description.GSA')`Гиперспектральная аппаратура`,
  SVR: t(
    'istrument_description.SVR'
  )`Широкозахватная мультиспектральная аппаратура высокого разрешения`,
  SSR: t(
    'istrument_description.SSR'
  )`Широкозахватная мультиспектральная аппаратура среднего разрешения`,
  GTNL1: t('istrument_description.GTNL1')`Оптико-электронная аппаратура «Геотон-Л1»`,
};

// NOTE - можно получать английские имена из API (есть поле в БД)
// или сделать mapping на основании min-max значений, с возможностью менять в том числе и русские названия
export const bandNames: { [x: string]: string } = {
  Панхром: i18nMark('Панхром'),
  Красный: i18nMark('Красный'),
  Зелёный: i18nMark('Зелёный'),
  Синий: i18nMark('Синий'),
  'Дальний ИК': i18nMark('Дальний ИК'),
  'Средний ИК': i18nMark('Средний ИК'),
  'Ближний ИК': i18nMark('Ближний ИК'),
};
