import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro'; // Trans
import { withI18nProps, withI18n } from '@lingui/react';
import * as Sentry from '@sentry/browser';

import Button from '@geobank/components/src/common/Button/Button';
import { getProfile } from 'ducks/authIAM';

// https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/

interface ReportSentryButtonProps {
  eventId: string;
}

const ReportSentryButton: React.FC<ReportSentryButtonProps & withI18nProps> = props => {
  const { eventId, i18n } = props;
  const userProfile = useSelector(getProfile);

  const user = {} as { email?: string; name?: string };
  if (userProfile) {
    user.name = `${userProfile.firstName} ${userProfile.lastName}`;
    user.email = userProfile.email;
  }

  return (
    <Button
      style={{ margin: '20px auto 8px' }}
      onClick={() =>
        Sentry.showReportDialog({
          eventId: eventId,
          user: user,
          // lang: 'ru', // [automatic]
          // заголовки
          title: i18n._(t('report_dialog.title')`Похоже у нас проблема`), // "It looks like we're having issues.",
          subtitle: i18n._(
            t(
              'report_dialog.subtitle'
            )`Опишите, пожалуйста, проблему в форме ниже. Мы постараемся исправить ошибку`
          ), // 'Our team has been notified. If you\'d like to help, tell us what happened below.',
          subtitle2: '',
          // форма
          labelName: i18n._(t('report_dialog.label_name')`Имя`), // Name
          labelEmail: i18n._(t('report_dialog.label_email')`Адрес электронной почты`), // Email
          labelComments: i18n._(t('report_dialog.label_comment')`Опишите что произошло`), // What happened?
          labelClose: i18n._(t('report_dialog.action_close')`Закрыть`), // Close
          labelSubmit: i18n._(t('report_dialog.action_submit')`Отправить отчёт`), // Submit Crash Report
          // сообщения
          errorFormEntry: i18n._(
            t(
              'report_dialog.message_form_error'
            )`Некоторые поля заполнены неправильно. Пожалуйста, исправьте и отправьте заново`
          ), // Some fields were invalid. Please correct the errors and try again.
          successMessage: i18n._(
            t('report_dialog.message_success')`Ваш отчёт был отправлен. Спасибо!`
          ), // Your feedback has been sent. Thank you!
          // errorGeneric: i18n._(t``), // An unknown error occurred while submitting your report. Please try again.
        })
      }
    >
      {i18n._(t('report_dialog.button_title')`Сообщить о проблеме`)}
    </Button>
  );
};

export default withI18n()(ReportSentryButton);
