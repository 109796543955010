import WGL_TileLayer, { Options as OL_WebGLTileOptions } from 'ol/layer/WebGLTile';
import { BaseLayerOptions } from './CustomBase';

export interface WebGLTileLayerOptions extends OL_WebGLTileOptions, BaseLayerOptions {}

// Line 10:  Useless constructor  @typescript-eslint/no-useless-constructor (???)
export default class WebGLTileLayer extends WGL_TileLayer {
  // eslint-disable-next-line
  constructor(opts?: WebGLTileLayerOptions) {
    super(opts!);
  }
}
