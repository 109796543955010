const tableData = [
  {
    name: 'Новая заявка 1',
    date: new Date(),
    status: 'Выполняется съемка',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 2',
    date: new Date(),
    status: 'Выполняется обработка',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 3',
    date: new Date(),
    status: 'Не отправлена',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 4',
    date: new Date(),
    status: 'Отправлена',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 5',
    date: new Date(),
    status: 'Отклонена',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 6',
    date: new Date(),
    status: 'Ожидает оплаты',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
  {
    name: 'Новая заявка 7',
    date: new Date(),
    status: 'Завершена',
    price: '20000 ₽',
    message: 'Изменён признак',
  },
];

export default tableData;
