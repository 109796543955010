import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import { RootState as RootStateType } from './root';

/**
 * Actions
 */
export const authenticateUser = {
  check: createActionCreator('AUTH/CHECK_REQUEST'), // ,resolve => () => resolve()
  login: createActionCreator('AUTH/LOGIN_REQUEST', resolve => (data: object) => resolve(data)), // data without type -> error on access payload
  logout: createActionCreator('AUTH/LOGOUT_REQUEST'),
  // success: createActionCreator('AUTH/SUCCESS', resolve => (data: any) => resolve(data)), // -> error in userReducer
  success: createActionCreator('AUTH/SUCCESS', resolve => (data: object) => resolve(data)),
  failure: createActionCreator('AUTH/FAILURE', resolve => error => resolve(error)),

  // register: createActionCreator(
  //   'AUTH/REGISTER_REQUEST',
  //   resolve => (data: { callback?: () => void /* ...params */ }) => resolve(data),
  // ),
  // reset: createActionCreator(
  //   'AUTH/RESET_REQUEST',
  //   resolve => (data: { callback?: () => void /* ...params */ }) => resolve(data),
  // ),
};

export const registerUser = {
  request: createActionCreator(
    'AUTH/REGISTER_REQUEST',
    resolve => (data: { callback?: () => void /* ...params */ }) => resolve(data),
  ),
  // success: createActionCreator('AUTH/REGISTER_SUCCESS', resolve => (data: object) => resolve(data)),
  // failure: createActionCreator('AUTH/REGISTER_FAILURE', resolve => error => resolve(error)),
};

export const restorePassword = {
  request: createActionCreator(
    'AUTH/RESTORE_REQUEST',
    resolve => (data: { callback?: () => void; login: string; captchaHash: string }) =>
      resolve(data),
  ),
  // success: createActionCreator('AUTH/RESTORE_SUCCESS', resolve => (data: object) => resolve(data)),
  // failure: createActionCreator('AUTH/RESTORE_FAILURE', resolve => error => resolve(error)),
};

export const fetchCaptcha = {
  request: createActionCreator('AUTH/CAPTCHA_REQUEST'),
  success: createActionCreator(
    'AUTH/CAPTCHA_SUCCESS',
    resolve => (data: { captchaHash: string; captchaUrl: string }) => resolve(data),
  ),
  failure: createActionCreator('AUTH/CAPTCHA_FAILURE', resolve => error => resolve(error)),
};

/**
 * Reducers and state
 */
export const isAuthenticatedDefaultState: boolean = false;
export const isAuthenticatedReducer = createReducer(isAuthenticatedDefaultState, handleAction => [
  handleAction(authenticateUser.success, () => true),
  handleAction(authenticateUser.failure, () => false),
  handleAction(authenticateUser.logout, () => false),
]);

export const userDefaultState: any = null;
export const userReducer = createReducer(userDefaultState, handleAction => [
  handleAction(authenticateUser.success, (state, { payload }) => (payload ? payload : state)),
  handleAction(authenticateUser.failure, () => null),
  handleAction(authenticateUser.logout, () => null),
]);

export const captchaDefaultState = {
  captchaHash: '',
  captchaUrl: '/captcha_test/03f14a6787b3f90d9bfdb009590c3d01_495ce.jpg',
};
export const captchaReducer = createReducer(captchaDefaultState, handleAction => [
  handleAction(fetchCaptcha.success, (_, { payload }) => payload),
  // handleAction(authenticateUser.failure, () => null),
  // handleAction(authenticateUser.logout, () => null),
]);

// metadataRootReducer
export default combineReducers({
  isAuthenticated: isAuthenticatedReducer,
  user: userReducer,
  captcha: captchaReducer,
});

/**
 * Selectors
 */
export const getIsAuthenticated = (state: RootStateType) => state.auth.isAuthenticated;
export const getUser = (state: RootStateType) => state.auth.user;
export const getCaptcha = (state: RootStateType) => state.auth.captcha;
