import { createActionCreator } from 'deox';
import { OrderProduct } from './order';

/**
 * Actions
 */

export const downloadProduct = {
  start: createActionCreator(
    'ORDER_PRODUCT/START_DOWNLOAD',
    resolve => (products: OrderProduct[], callback?: (result: any) => void) =>
      resolve({ products, callback })
  ),
  cancel: createActionCreator('ORDER_PRODUCT/CANCEL_DOWNLOAD'),
  failure: createActionCreator('ORDER_PRODUCT/FAILURE_DOWNLOAD', resolve => error =>
    resolve(error)
  ),
};

/**
 * Reducers and state
 */

/**
 * Selectors
 */
