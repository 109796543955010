import { ImageMetadata } from 'ducks/types/metadataTypes';

const bbpPlatformTypes = ['MM', 'MM2', 'LS8', 'SNL2'];
const stacPlatformTypes = ['AI'];

const isStacPlatformType = (metadata: ImageMetadata) => {
  return stacPlatformTypes.indexOf(metadata.platformTypeIdentifier) > -1;
};

const isBbpPlatformType = (metadata: ImageMetadata) => {
  return bbpPlatformTypes.indexOf(metadata.platformTypeIdentifier) > -1;
};

const isAnyImageStacPlatformType = (metadata: ImageMetadata | ImageMetadata[]) => {
  let result = false;
  if (!Array.isArray(metadata)) {
    metadata = [metadata];
  }

  metadata.forEach(md => {
    if (isStacPlatformType(md)) {
      result = true;
    }
  });
  return result;
};

const isAnyImageBbpPlatformType = (metadata: ImageMetadata | ImageMetadata[]) => {
  let result = false;
  if (!Array.isArray(metadata)) {
    metadata = [metadata];
  }

  metadata.forEach(md => {
    if (isBbpPlatformType(md)) {
      result = true;
    }
  });
  return result;
};

export const isAdditionalOptionsAvailable = (metadata: ImageMetadata | ImageMetadata[]) => {
  // return ['MM', 'MM2', 'LS8', 'SNL2'].indexOf(metadata.platformTypeIdentifier) === -1;
  return !isAnyImageBbpPlatformType(metadata);
};

export const isGeometryCroppingAvailable = (metadata: ImageMetadata | ImageMetadata[]) => {
  return !isAnyImageBbpPlatformType(metadata);
};

export const isBandsSelectAvailable = (metadata: ImageMetadata | ImageMetadata[]) => {
  return !isAnyImageBbpPlatformType(metadata) && !isAnyImageStacPlatformType(metadata);
};

export const isPanhsarpSelectAvailable = (metadata: ImageMetadata | ImageMetadata[]) => {
  return !isAnyImageStacPlatformType(metadata);
};

export const isBundleSelectAvailable = (metadata: ImageMetadata | ImageMetadata[]) => {
  return !isAnyImageStacPlatformType(metadata);
};
