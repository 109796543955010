import React from 'react';
import Tooltip from 'react-tooltip-lite';

import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { withI18nProps, withI18n } from '@lingui/react';

import useDebounceOpen from 'components/hooks/useDebounceOpen';
import { licenseOptions, LicenseValue } from 'locales/dicts/license';

const styles = require('./License-styles.module.scss');

interface LicenseProps {
  active: LicenseValue;
  onSelect: (value: LicenseValue) => void;
}

const License: React.FC<
  LicenseProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> & withI18nProps
> = props => {
  const { active, onSelect, i18n, i18nHash, ...rest } = props;
  const [isOpen, setIsShown] = useDebounceOpen();

  const handleSelect = (value: LicenseValue) => {
    onSelect(value);
    // setIsOpen(false);
    setIsShown(false, true);
    // setTimeout(() => setIsOpen(undefined), 1000);
  };

  return (
    <div {...rest}>
      <span>
        <Trans>Лицензия</Trans>:
      </span>
      {/* <span>Групповая огранич. расширенная (i);</span> */}
      <Tooltip
        tagName="span"
        styles={{ marginLeft: '4px' }}
        tipContentClassName="tip-content--border-grey tip-no-padding"
        arrow={false}
        isOpen={isOpen}
        // mouseOutDelay={800}
        background="white"
        color="black"
        direction="up" // "up-end"
        content={
          // <Trans id="order_image.hint_elevation">Применять цифровую модель рельефа</Trans>
          <div
            className={styles.contentRoot}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <ul className={styles.list}>
              {/* <li><span>до 5 лет</span></li> */}
              {licenseOptions.map(item => (
                <li
                  key={item.value}
                  className={classNames(styles.listItem, {
                    [styles.listItemActive]: item.value === active,
                  })}
                  title={i18n._(item.hint) /* .replace('\\n', '\n') */}
                  onClick={() => handleSelect(item.value)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span
          className={styles.selected}
          /* onClick={handleModalToggle} */
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {/* Групповая огранич. расширенная (i); */}
          {licenseOptions.find(item => item.value === active)?.title}
        </span>
      </Tooltip>
    </div>
  );
};

export default withI18n()(License);
